import React, { useEffect, useState } from "react";
import { InvestmentField } from "../ui/InvestmentField/InvestmentField";
import { ProgressBar } from "../ui/ProgressBar/ProgressBar";
import { IconInfo } from "../../static/Icons/Icons";
import { Resource } from "../../../redux/actions/lang.actions";
import { Link } from "react-router-dom";
import FieldsWithButton from "../FormsController/FieldsWithButton";
import { activatePortfolioDraft, getDateEndReinvestmentAsync } from "../../redux/investment-reducer";
import { useDispatch, useSelector } from "react-redux";
import { getVeriffSession } from "../../../redux/actions/veriff/veriff.actions";
import { VERIFY_CONFIRM } from "../../../redux/constants/person.constants";
import { depositCurrentPortfolio } from "../../redux/portfolio-reducer";
import "./PortfolioCardNew.scss";
import { createJudicialPortfolio } from "../../redux/judicial-reducer";
import { rangeNumbers } from "../tools";
import { MODAL_IS_OPEN } from "../../../redux/constants/modals.constants";
import { currentFormatting } from "../../../redux/utils/formatting";
import { currencyFormatProfit } from "../../../redux/utils/formatting";
import { ReactComponent as IconBonus } from "../../static/Icons/bonus.svg";
import { JUDICIAL } from "../../redux/actions/actionsTypes";
import { portfoliosInfoToggle, portfoliosInfo2Toggle } from "../../redux/actions/actionsCreators";

const PortfolioCardNew = (portfolio) => {
  const fieldsJudicial = useSelector((state) => state.judicialReducer.fields);
  const fieldsBalanced = useSelector((state) => state.BalancedReducer.fields);
  const { active } = useSelector((state) => state.InvestmentReducer);
  const { judicialPortfolios } = useSelector((state) => state.judicialReducer);
  const leng = useSelector((state) => state.language.langActive);
  const fields = portfolio.rulesId === 7 ? fieldsJudicial : fieldsBalanced;
  const person = useSelector((state) => state.person);
  const requiredVerifyFields = useSelector((state) => state.veriff.fields);
  const isFullFields = Object.values(requiredVerifyFields)
    .map((item) => item.value)
    .every((value) => !!value);

  const dispatch = useDispatch();

  const res = active.find((item) => {
    return item.id == portfolio.id;
  });

  const resj = judicialPortfolios.find((item) => {
    return item.portfolioId == portfolio.id;
  });


  const getNamePortfolio = (type) => {
    switch (type) {
      case 1:
        return Resource("LongTerm");
      case 2:
        return Resource("Test");
      case 3: // Старая Классическая стратегия
        return Resource("StrategyClassicTitle");
      case 4:
        return Resource("StrategyBalancedTitle");
      case 5:
        return Resource("StrategySpecialTitle");
      case 6:
        return "BayBack";
      case 7:
        return Resource("StrategyJudicialTitle");
      case 8: // Новая Классическая стратегия
        return Resource("StrategyClassicTitle");
      case 9: // Новая Сбалансированная стратегия
        return Resource("StrategyBalancedTitle");
      default:
        return null;
    }
  };

  useEffect(() => {
    getDateEndReinvestmentAsync(portfolio.id, dispatch);
  }, []);

  const addedZeroForDate = (date) => (date < 10 ? "0" + date : date);

  const getCountDays = (endDate, disabled) => {
    const currentDateDay = new Date();
    currentDateDay.setHours(0, 0, 0, 0);
    const endDateParse = new Date(Date.parse(endDate)),
      day = addedZeroForDate(endDateParse.getDate()),
      month = addedZeroForDate(endDateParse.getMonth() + 1),
      year = endDateParse.getFullYear();
    const deltaDays = Math.floor(
      (endDateParse.getTime() - currentDateDay.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    return disabled || deltaDays < 0
      ? `${day}.${month}.${year}`
      : `${deltaDays} ${Resource("DaysLabel")} / ${day}.${month}.${year}`;
  };

  const declOfNum = (n, text_forms) => {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
      return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }
    if (n1 === 1) {
      return text_forms[0];
    }
    return text_forms[2];
  };

  const submitDepositingPayment = (values, errors) => {
    console.log(portfolio);
    setIsLoadingDeposit(true);
    if (person.veriffStatusId === VERIFY_CONFIRM) {
      console.log("Верификация пройдена. Провести пополнение портфеля");
      dispatch(
        depositCurrentPortfolio(
          portfolio.id,
          values[`depositValue${portfolio.id}`].replace(/[^0-9/./,]/g, "")
        )
      ).then(() => {
        setIsLoadingDeposit(false);
      });
    } else {
      if (isFullFields) {
        console.log(
          "Обязательные поля не заполнены. Открыть модальное окно с полями для заполнения"
        );
        dispatch({
          type: MODAL_IS_OPEN,
          isOpen: true,
          id: "modalDepositingVeriff",
        });
        setIsLoadingDeposit(false);
      } else {
        console.log("Верификация не пройдена. Открыть верификацию");
        // setVerifBtnStatus(true)
        dispatch(
          getVeriffSession({
            personId: person.id,
            /*setVerifBtnStatus,*/ firstName: person.firstName,
            lastName: person.lastName,
          })
        );
        setIsLoadingDeposit(false);
      }
    }
  };

  const submitCreatedPortfolio = (values) => {
    setIsLoadingDeposit(true);
    dispatch(
      createJudicialPortfolio({
        startSum: values[`depositValue${portfolio.id}`],
        term: portfolio.monthCount,
        loanId: portfolio.id,
      })
    );
  };

  const onSubmitDeposit = () => {
    dispatch(activatePortfolioDraft(portfolio.id, portfolio.amount));
  };

  // Формула расчета ожидаемой доходности
  const getReinvets = (amount, term, perc) => {
    let a = amount,
      n = 0,
      m = 0;

    for (let i = 1; i <= term; i++) {
      m = i;
      if (m <= term) {
        n = n + (a * perc) / 12 / 100;
        m = m + 1;
      }
    }
    return Math.floor(n) === 0 ? n : Math.floor(n);
  };

  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
  const [valueExpectedIncome, setValueExpectedIncome] = useState(
    parseInt(
      getReinvets(portfolio.defValue, portfolio.monthCount, portfolio.percent)
    )
  );

  const cheсkCollection = () => {
    if (portfolio.judicial) {
      return portfolio.availableForInvestment;
    } else {
      if (portfolio.rulesId === 7) {
        return portfolio.availableForInvestment;
      } else {
        return true;
      }
    }
  };

  // Функция для обработки ожидаемой доходности
  const onCalculatingExpectedIncome = (event) => {
    const value =
      portfolio.amount +
      parseInt(event.target.value.replaceAll(/[^0-9\.]/g, ""));
    if (isNaN(value)) {
      setValueExpectedIncome(0);
    } else {
      setValueExpectedIncome(
        getReinvets(value, portfolio.monthCount, portfolio.percent)
      );
    }
  };

  // Открытие информационной модалки
  const openInfo = () => {
    dispatch(portfoliosInfoToggle(true));
  };

  const openInfo2 = () => {
    dispatch(portfoliosInfo2Toggle(true));
  };

  return (
    <div
      className={
        cheсkCollection()
          ? "portfolio-card"
          : "portfolio-card portfolio-card--completed"
      }
    >
      <div
        className={
          portfolio.rulesId === 7
            ? "portfolio-card__stripe portfolio-card__stripe--investments"
            : "portfolio-card__stripe portfolio-card__stripe--judicial"
        }
      ></div>

      <div className="portfolio-card__title">
        {getNamePortfolio(portfolio.rulesId)}{" "}
        {portfolio.rulesId !== 7
          ? portfolio.percent
          : !!portfolio.bonus && portfolio.isPortfolio
          ? portfolio.percent - portfolio.bonus
          : portfolio.percent}
        %{" "}
        {!!portfolio.bonus && (
          <IconBonus className="portfolio-card__icon-bonus" />
        )}
        {!!portfolio.bonus && (
          <div className="portfolio-card__term-block">
            <div className="portfolio-card__term">
              {portfolio.monthCount}{" "}
              {declOfNum(portfolio.monthCount, [
                Resource("MonthOne"),
                Resource("MonthSeveral"),
                Resource("MonthMany"),
              ])}
            </div>
          </div>
        )}
      </div>
      <div className="portfolio-card__term-block">
        {!!portfolio.bonus && (
          <div className="portfolio-card__bonus">
            Bonus +{portfolio.bonus}% to interest rate
          </div>
        )}
        <div
          className={
            !!portfolio.bonus
              ? "portfolio-card__term portfolio-card__term-bonus"
              : "portfolio-card__term"
          }
        >
          {portfolio.monthCount}{" "}
          {declOfNum(portfolio.monthCount, [
            Resource("MonthOne"),
            Resource("MonthSeveral"),
            Resource("MonthMany"),
          ])}
        </div>
      </div>

      <div className="portfolio-card__info">
        {portfolio.rulesId === 7 && portfolio.availableForInvestment ? (
          <div className="portfolio-card__income">
            <div className="portfolio-card__income-label">
              {Resource("ExpectedIncome")}:
            </div>
            <div className="portfolio-card__income-value">
              <div className="portfolio-card__income-sum">
                {/*{ portfolio.avgExpectedInterest }*/}
                {!isNaN(valueExpectedIncome)
                  ? currencyFormatProfit(valueExpectedIncome)
                  : currencyFormatProfit(0)}
              </div>
            </div>
            {!!portfolio.bonus && (
              <div className="portfolio-card__link-block">
                {!portfolio.more && (
                  <Link
                    id={portfolio.num && "a_link_" + portfolio.num}
                    className="portfolio-card__link"
                    to={portfolio.moreLink ?? `/portfolio/${portfolio.id}`}
                    // to={portfolio.rulesId === 7 ? `/judicial/${portfolio.id}` : `/portfolio/${portfolio.id}`}
                  >
                    {Resource("NextMoreText")}
                  </Link>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="portfolio-card__time">
            <div className="portfolio-card__time-wrapper">
              <div className="portfolio-card__time-label portfolio-card__link" onClick={openInfo2}>
                until the end of the investment period
              </div>
              <div className="portfolio-card__time-value">
                {portfolio.endDate && getCountDays(portfolio.endDate, false)}
              </div>
            </div>
            {
              (res?.dateEndReinvestment || resj?.dateEndReinvestment) && (
                <div className="portfolio-card__time-wrapper">
                <div className="portfolio-card__time-label portfolio-card__link" onClick={openInfo}>
                  until the end of the investment period<br/> (auto-investment)
                </div>
                <div className="portfolio-card__time-value">
                  { res?.dateEndReinvestment ?
                      getCountDays(res.dateEndReinvestment, false) 
                    :
                      getCountDays(resj?.dateEndReinvestment, false)
                  }
                </div>
              </div>
              )
            }
          </div>
        )}
      </div>

      <div className="portfolio-card__link-block">
        {!portfolio.more && (
          <Link
            id={portfolio.num && "a_link_" + portfolio.num}
            className="portfolio-card__link"
            to={portfolio.moreLink ?? `/portfolio/${portfolio.id}`}
            // to={portfolio.rulesId === 7 ? `/judicial/${portfolio.id}` : `/portfolio/${portfolio.id}`}
          >
            {Resource("NextMoreText")}
          </Link>
        )}
      </div>

      <div className="portfolio-card__state">
        {cheсkCollection() && portfolio.rulesId === 7 ? (
          <>
            <div className="portfolio-card__collect">
              <div className="portfolio-card__collect-text">
                {Resource("JudicialCollection")
                  .replaceAll("ХХХ1", currencyFormatProfit(portfolio.remind))
                  .replaceAll(
                    "ХХХ2",
                    currencyFormatProfit(portfolio.totalAmount)
                  )}
              </div>
              {!!!portfolio.bonus && (
                <div className="portfolio-card__collect-link">
                  {!portfolio.more && (
                    <Link
                      id={portfolio.num && "b_link_" + portfolio.num}
                      className="portfolio-card__link"
                      to={portfolio.moreLink ?? `/portfolio/${portfolio.id}`}
                      // to={portfolio.rulesId === 7  ? `/judicial/${portfolio.idJ}` : `/portfolio/${portfolio.id}`}
                    >
                      {Resource("NextMoreText")}
                    </Link>
                  )}
                </div>
              )}
            </div>
            <div className="portfolio-card__progress">
              <ProgressBar
                current={portfolio.totalAmount - portfolio.remind}
                full={portfolio.totalAmount}
              />
            </div>
          </>
        ) : (
          <>
            <div className="portfolio-card__current-income">
              <div className="portfolio-card__current-text">
                {Resource("CurrentIncome")}:{" "}
                {String(portfolio.currentInterest)
                  ? currentFormatting(portfolio.currentInterest) +
                    ` ${Resource("Out")} ` +
                    currentFormatting(portfolio.avgExpectedInterest)
                  : "–"}
              </div>
              <div className="portfolio-card__current-link">
                {/* {!portfolio.more && (
                  <Link
                    className="portfolio-card__link"
                    to={
                      portfolio.rulesId === 7
                        ? `/judicial/${portfolio.idJ}`
                        : `/portfolio/${portfolio.id}`
                    }
                  >
                    {Resource("NextMoreText")}
                  </Link>
                )} */}
                                {!portfolio.more && (
                  <Link
                    id={portfolio.num && "a_link_" + portfolio.num}
                    className="portfolio-card__link"
                    to={portfolio.moreLink ?? `/portfolio/${portfolio.id}`}
                    // to={portfolio.rulesId === 7 ? `/judicial/${portfolio.id}` : `/portfolio/${portfolio.id}`}
                  >
                    {Resource("NextMoreText")}
                  </Link>
                )}
                
              </div>
            </div>
            <div className="portfolio-card__progress">
              <ProgressBar
                current={portfolio.currentInterest && portfolio.currentInterest}
                full={
                  portfolio.avgExpectedInterest && portfolio.avgExpectedInterest
                }
              />
            </div>
          </>
        )}
        {portfolio.rulesId === 7 && cheсkCollection() && (
          <div className="portfolio-card__data-end portfolio-card__data-end-bonus">
            {leng === "EN" || leng === "DE" ? (
              <>
                <div className="portfolio-card__data-end-date">
                  {portfolio.endAvailableDate &&
                    getCountDays(portfolio.endAvailableDate, false)}{" "}
                </div>
                <div className="portfolio-card__data-end-text">
                  {Resource("JudicialCollectionEnd")}{" "}
                </div>
              </>
            ) : (
              <>
                <div className="portfolio-card__data-end-text">
                  {Resource("JudicialCollectionEnd")}{" "}
                </div>
                <div className="portfolio-card__data-end-date">
                  {portfolio.endAvailableDate &&
                    getCountDays(portfolio.endAvailableDate, false)}{" "}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <div className="portfolio-card__investment">
        {portfolio.isPortfolio ? (
          <>
            <div className="portfolio-card__investment-text">
              {Resource("InvestmentAmount")}:
            </div>
            <div className="portfolio-card__investment-sum">
              {portfolio.amount && currentFormatting(portfolio.amount)}
            </div>
          </>
        ) : (
          <div className="portfolio-card__warranty">
            <div className="portfolio-card__warranty-icon"></div>
            <div className="portfolio-card__warranty-text">
              {Resource("JudicialGarantText")}
            </div>
          </div>
        )}
      </div>

      <div className="portfolio-card__money">
        {cheсkCollection() ? (
          <div className="portfolio-card__field">
            {/*<InvestmentField */}
            {/*    inputText={ "Сумма инвестиции" }*/}
            {/*    buttonText={ "Инвестировать" }*/}
            {/*/>*/}
            <FieldsWithButton
              unicId={portfolio.id}
              fields={fields}
              className={"portfolio-card__money"}
              label={
                portfolio.judicial && !portfolio.isPortfolio
                  ? Resource("JudicialLabel")
                  : Resource(portfolio.label)
              }
              btnText={
                portfolio.judicial && !portfolio.isPortfolio
                  ? Resource("JudicialBtnText")
                  : Resource(portfolio.btnText)
              }
              disabledField={portfolio.disabledField}
              offline={portfolio.close}
              defValue={portfolio.defValue ? portfolio.defValue : null}
              loading={isLoadingDeposit}
              padding={"36px var(--maxWidthBtn) 8px var(--leftOffset)"}
              onSubmit={
                portfolio.judicial && !portfolio.isPortfolio
                  ? submitCreatedPortfolio
                  : submitDepositingPayment
              }
              onInputHandler={
                portfolio.rulesId === 7
                  ? onCalculatingExpectedIncome
                  : new Function()
              }
              validateCustom={
                portfolio.rulesId === 7 &&
                ((value) => rangeNumbers([10, portfolio.remind], value))
              }
              //loading={props.loading[DEPOSITING_GET_URL_SUCCESS] || props.loading[VERIFF_SESSION_SUCCESS]}
            />
          </div>
        ) : (
          <div className="portfolio-card__informer">
            <div className="portfolio-card__info-icon">{IconInfo(18, 18)}</div>
            <div className="portfolio-card__info-label">
              <div className="portfolio-card__info-title">
                {Resource("JudicialCollectionCompleted")}
              </div>
              <div className="portfolio-card__info-text">
                {Resource("JudicialCollectionCompletedSub")}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={
          !cheсkCollection()
            ? "collection__end collection--visible"
            : "collection__end"
        }
      >
        {!!portfolio.bonus ? (
          <div className="portfolio-card__bonus">
            Bonus +{portfolio.bonus}% to interest rate
          </div>
        ) : (
          <>
            <div className="collection__time-wrapper">
              <div className="collection__end-text" onClick={openInfo2}>
                {portfolio.rulesId === 7 && portfolio.availableForInvestment ? (
                  <>{Resource("JudicialCollectionEnd2")}:</>
                ) : (
                  <>until the end of the investment period</>
                )}
                </div>
                <div className="collection__end-date">
                {portfolio.rulesId === 7 && portfolio.availableForInvestment
                  ? portfolio.endAvailableDate &&
                    getCountDays(portfolio.endAvailableDate, false)
                  : portfolio.endDate && getCountDays(portfolio.endDate, false)}
              </div>
            </div>
              {
                (res?.dateEndReinvestment || resj?.dateEndReinvestment) && (
                  <div className="collection__time-wrapper">
                    <div className="collection__end-text" onClick={openInfo}>
                      until the end of the investment period (auto-investment)
                    </div>
                    <div className="collection__end-date">
                      { res?.dateEndReinvestment ?
                          getCountDays(res.dateEndReinvestment, false) 
                        :
                          getCountDays(resj?.dateEndReinvestment, false)
                      }
                    </div>
                  </div>
                )
              }
          </>
        )}
      </div>
    </div>
  );
};

export { PortfolioCardNew };
