import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Resource } from '../../redux/actions/lang.actions'
import { connect } from 'react-redux'

const NavBar = (props) => {

    return (
        <nav className='navigation'>
            <span className="account">
                <NavLink onClick={() => props.toggleMenu(false)} to="/settings" className="navigation__item account-settings"><i>{props.person.firstName} {props.person?.lastName}</i></NavLink>
                <Link to='/' className="navigation__item account-logout" onClick={() => {
                    props.toggleMenu(false)
                    props.logout()
                }}><i>{Resource('Exit', '', props.lang)}</i></Link>
            </span>
            <span className="menu">
                <NavLink onClick={() => props.toggleMenu(false)} className="navigation__item first" to="/my-investment"><i>{Resource('MyInvestments', '', props.lang)}</i></NavLink>
                <NavLink onClick={() => props.toggleMenu(false)} className="navigation__item" to="/depositing"><i>{Resource('Balance', '', props.lang)}</i></NavLink>
                <NavLink onClick={() => props.toggleMenu(false)} className="navigation__item" to="/information"><i>{Resource('StrategyTitle', '', props.lang)}</i></NavLink>
                <NavLink onClick={() => props.toggleMenu(false)} className="navigation__item" to="/support"><i>{Resource('Help', '', props.lang)}</i></NavLink>
            </span>
        </nav>
    )

}

const mapStateToProps = state => {
    return {
        lang: state.language.langActive
    }
}

export default connect(mapStateToProps, null)(NavBar)