import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import RenderSelect from '../../formComponents/RenderSelect'
import { Resource } from '../../../../redux/actions/lang.actions'
import LoaderButton from '../../LoaderButton'
import { SETTINGS_SAVE_SUCCESS } from '../../../../redux/constants/settings/settings.constants'

const PersonInformationForm = props => {

    const fieldID = props.fields[props.form]

    useEffect(() => {
        for (let key in fieldID)
            props.change(fieldID[key].id, fieldID[key].value)
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <form id='personal_button' onSubmit={props.handleSubmit} className="settings__form columns_2">
                <div className="box card">
                    <h3 className="box__heading">{Resource('PersonalData')}</h3>

                    <RenderInput data={fieldID['settingsName']} />
                    <RenderInput data={fieldID['settingsSurname']} />
                    <RenderInput data={fieldID['email']} />

                    {
                        fieldID['phone']?.value === null
                            ? <div className={"form__group"}>
                                <div className="form__fields-group">
                                    <RenderSelect data={fieldID['prefix']} />
                                    <RenderInput data={fieldID['phone']} />
                                </div>
                            </div>
                            : <div className={["form__group"].join(' ')}>
                                {fieldID['phone'].label && <label htmlFor={fieldID['phone'].name} className="form__label">{Resource(fieldID['phone'].label)}</label>}
                                <span className={'form__value'}>{'+' + fieldID['phone'].value}</span>
                                {fieldID['phone'].description && fieldID['phone'].value && <span className="form__description">{Resource(fieldID['phone'].description)}</span>}
                            </div>
                    }
                    
                    <RenderInput data={fieldID['settingsBirthday']} />
                    <RenderInput data={fieldID['settingsPassport']} />
                </div>
                <div className="box card">
                    <h3 className="box__heading">{Resource('Address')}</h3>

                    <RenderSelect data={fieldID['settingsCountry']} />
                    <RenderInput data={fieldID['settingsCity']} />
                    <RenderInput data={fieldID['settingsAddress_1']} />
                    <RenderInput data={fieldID['zip']} />

                </div>
            </form>

            <div className="fixed">
                <div className="container">
                    <LoaderButton
                        form="personal_button"
                        disabled={true}
                        loading={props.loading[SETTINGS_SAVE_SUCCESS]}
                    >{Resource('Save')}</LoaderButton>
                    {/* <button form="personal_button" className="button settings__button">{Resource('Save')}</button> */}
                </div>
            </div>
        </>
    )
}

export default reduxForm({ form: 'personInformationForm' })(PersonInformationForm)
