import { LANG_DROPLIST, LANG_CHANGE, TOGGLE_MENU } from "../constants/lang.constants"
import { Locale } from "../../locale/locale"
import Cookies from 'js-cookie'

export const dropdownClick = active => {
    return dispatch => {
        dispatch({ type: LANG_DROPLIST, payload: active })
    }
}

export const choiceLangClick = langActive => {
    return dispatch => {
        dispatch({ type: LANG_CHANGE, payload: langActive })
    }
}

export const Resource = (key, key_dep = '', changeLang) => {
    let transfer;

    if (!Locale[Cookies.get('language')]) {
        Cookies.set('language', 'EN', { expires: 999 })
    }
    
    if (!!key_dep) {
        transfer = Locale[!!changeLang ? changeLang : Cookies.get('language')][key][key_dep]
    } else {
        transfer = Locale[!!changeLang ? changeLang : Cookies.get('language')][key]

    }
    if (!transfer) {
        return key
    }
    return transfer
}

export const toggleMenu = (toggle) => {
    return dispatch => dispatch({ type: TOGGLE_MENU, payload: toggle })
}