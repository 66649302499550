import React, { useState, useEffect } from "react";
import { Resource } from "../../../redux/actions/lang.actions";
import { useSelector } from "react-redux";
import { ReactComponent as IconTimeForward } from "../../static/Icons/time-forward.svg";
import { ReactComponent as IconUpdate } from "../../static/Icons/update.svg";
import {
  dateFormattingWithTime,
  currentFormatting,
} from "../../../redux/utils/formatting";
import "./MyInvitations.scss";

const MyInvitations = ({ list }) => {
  const [itemlist, setItemList] = useState(8);
  const language = useSelector((state) => state.language);
  useEffect(() => {}, [language]);

  const more = () => {
    setItemList(itemlist * 2);
  };

  const getList = () => {
    const items = list.length > itemlist ? itemlist : list.length;
    const content = [];
    for (let i = 0; i < items; i++) {
      content.push(
        <div className="invit__item" key={"ntr_" + i}>
          <div className="invit__column">
            <div className="invit__column-title">{list[i].friendName}</div>
            <div className="invit__column-text">
              {Resource("bonusPeriodEnd")}:{" "}
              {dateFormattingWithTime(list[i].endOfBonusDate)}
            </div>
          </div>
          <div className="invit__column">
            <div className="invit__column-title">
              {currentFormatting(list[i].interest)}
            </div>
            <div className="invit__column-text">
              {Resource("yourBonus")}
            </div>
          </div>
        </div>
      );
    }

    return content;
  };

  return (
    <div className="invit">
      {list.length ? (
        <div className="invit__list">
          {getList()}
          {list.length > itemlist && (
            <div className="invit__more-wrapper">
              <div className="invit__more" onClick={more}>
                <div className="invit__more-text"></div>
                <IconUpdate />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="invit__plug">
          <div className="invit__plug-icon">
            <IconTimeForward />
          </div>
          <div className="invit__plug-title"></div>
          <div className="invit__plug-text">
            {Resource("refMyInvitationsNO")}
          </div>
        </div>
      )}
    </div>
  );
};

export { MyInvitations };
