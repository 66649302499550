import { SUPPORT_GET_MESSAGE_SUCCESS, SUPPORT_GET_MESSAGE_FAILURE, SUPPORT_SEND_MESSAGE_SUCCESS } from "../../constants/support/support.constants"

const initialState = {
    fields: {
        supportMessage: {
            id: 'supportMessage',
            name: 'supportMessage',
            type: 'text',
            placeholder: 'SupportTextareaPlaceholder',
            edit: true,
            validate: {
                required: true
            }
        },
    }
}

const support = (state = initialState, action) => {
    switch (action.type) {
        case SUPPORT_SEND_MESSAGE_SUCCESS: {
            return {
                ...state,
                lastMsg: action.payload
            }
        }
        case SUPPORT_GET_MESSAGE_SUCCESS:
            return {
                ...state,
                message: {
                    ...state.message,
                    ...action.payload
                }
            }
        case SUPPORT_GET_MESSAGE_FAILURE:
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}

export default support
