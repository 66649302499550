import React, {useEffect, useState} from 'react';
import classes from "./HistorySidebar.module.scss";
import LinkButton from "../Buttons/LinkButton/LinkButton";

const HistorySidebar = props => {
  
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    if(props.onRequest) {
      props.onRequest().then(resp => { setLoading(false) })
    } else {
      !props.customLoad && setLoading(false)
    }
  }, [props.customLoad])

  const outputIdWithDate = (keys, params) => {
    let result = {};
    keys.forEach(key => {
      result[key] = params[key]
    })
    return result
  }
  
  return (<div className={classes.history}>
    {loading
      ? <span className='spinner'/>
      : (
        props.data.length > 0 &&
        <>
          <div className={classes.header}>
            <span className={classes.title}>{props.title}</span>
            {props.link && <LinkButton to={props.link.url} onClick={props.link.onClick}>{props.link.text}</LinkButton>}
          </div>
          <div className={classes.column}>
            {
              props.data.map(item => {
                return <div key={item.id} className={classes.row}>
                  {
                    props.template.map(temp => {
                      return <span key={item.id + temp.cls} className={classes[temp.cls]}>
                        {
                          temp.bindFn
                            ? temp.bindFn(outputIdWithDate(temp.bind, item))
                            : <>
                              {temp.before && temp.before}
                              {temp.key
                                ? (temp.format ? temp.format(item[temp.key]) : item[temp.key])
                                : temp.text
                              }
                              {temp.after && temp.after}
                            </>
                        }
                      </span>
                    })
                  }
                </div>
              })
            }
          </div>
        </>
      )
    }
  </div>)
  
  
}

export default HistorySidebar;