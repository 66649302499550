// Отображение окна с просьбой оценить платформу, после пополнения портфеля
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupSlide } from "../ui/PopupSlide/PopupSlide";
import { judicialInfoPopupToggle } from "../../redux/actions/actionsCreators";
import { Locale } from "../../../locale/locale";
import { Resource } from "../../../redux/actions/lang.actions";
import "./JudicialSlideInfo.scss";

const JudicialSlideInfo = () => {

    const judicialInfoOpen = useSelector(state => state.PopupReducer.judicialInfoOpen);
    const dispatch = useDispatch();


    const setActive = status => {
        dispatch(judicialInfoPopupToggle(status));
    };


    return(
        <PopupSlide 
            active={judicialInfoOpen}
            setActive={setActive}
        >
           <div className="judicial-slide">
                <div className="judicial-slide__content">
                    <div className="judicial-slide__informer">
                        <div className="judicial-slide__informer">
                            Info
                        </div>
                    </div>
                        <div dangerouslySetInnerHTML={{__html: Resource("JudicialInfo")}} ></div>
              </div>
           </div>
            
        </PopupSlide>
    )
};

export { JudicialSlideInfo };