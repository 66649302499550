import React from 'react';
import classes from './NoticeComponent.module.scss'

const NoticeComponent = props => {
  return (
    <div className={classes.notice}>
      <span className={classes.icon}/>
      <div className={classes.info}>
        { props.mainText.map((text, index) => <span key={index}>{text}</span>) }
        <p>{props.descriptionText}</p>
      </div>

      {props.image && <img style={props.imageStyle} src={props.image} className={classes.image} /> }

    </div>
  )
};

export default NoticeComponent;