import { AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAILURE, LOGOUT, AUTH_GOOGLE_SUCCESS, LOGOUT_GOOGLE } from "../../constants/login/auth.constants";

let authUser = localStorage.getItem('auth')

const initialState = {
    fields: {
        email: {
            id: 'loginEmail',
            name: 'loginEmail',
            type: 'email',
            label: 'Email',
            edit: true,
            validate: {
                required: true,
                email: true
            }
        },
        password: {
            id: 'loginPassword',
            name: 'loginPassword',
            type: 'password',
            label: 'Password',
            edit: true,
            validate: {
                required: true,
                minLength: 6
            },
            password: true
        }
    },
    auth: authUser ? { logged: true } : { logged: false, loading: false, user: null },
    googleAuth: {
        user: '',
        isAuthenticated: false
    }
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_REQUEST:
            return {
                ...state,
                auth: {
                    logged: false, loading: true, user: action.email
                }
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                auth: {
                    logged: true, loading: false, user: action.email
                }
            };
        case AUTH_FAILURE:
            return {
                ...state,
                auth: {
                    logged: false, loading: false
                }
            };
        case LOGOUT:
            return {
                ...state,
                auth: {
                    logged: false, loading: false, user: null
                }
            };

        case AUTH_GOOGLE_SUCCESS:
            return {
                ...state,
                googleAuth: {
                    ...state.googleAuth,
                    user: action.payload,
                    isAuthenticated: true
                }
            }
        case LOGOUT_GOOGLE:
            return {
                ...state,
                googleAuth: {
                    ...state.googleAuth,
                    user: '',
                    isAuthenticated: false
                }
            }

        default:
            return state
    }
}

export default auth
