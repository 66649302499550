import {
  POPUP,
  FILTER,
  JUDICIAL,
  PAYTOOLS,
  STRATEGY,
  BAN,
  INVITE,
  POPUP_INFO,
} from "./actionsTypes";

export const gradePopupToggle = (status) => ({
  type: POPUP.GRADE_TOGGLE,
  payload: status,
});

export const stopWarPopupToggle = (status) => ({
  type: POPUP.STOPWAR_TOGGLE,
  payload: status,
});

export const judicialSlidePopupToggle = (status) => ({
  type: POPUP.JUDICIAL_TOGGLE,
  payload: status,
});

export const judicialInfoPopupToggle = (status) => ({
  type: POPUP.JUDICIAL_INFO_TOGGLE,
  payload: status,
});

export const judicialDbPopupToggle = (status) => ({
  type: POPUP.JUDICIAL_DB_TOGGLE,
  payload: status,
});

export const portfoliosInfoToggle = (status) => ({
  type: POPUP.PORTFOLIOS_INFO_TOGGLE,
  payload: status,
});

export const portfoliosInfo2Toggle = (status) => ({
  type: POPUP.PORTFOLIOS_INFO2_TOGGLE,
  payload: status,
});

export const filterPopupToggle = (status) => ({
  type: FILTER.FILTER_TOGGLE,
  payload: status,
});

export const getJudicial = (data) => ({ type: JUDICIAL.GET, payload: data });

export const getJudicialOne = (data) => ({
  type: JUDICIAL.GET_ONE,
  payload: data,
});

export const loadingJudicial = (status) => ({
  type: JUDICIAL.LOADING,
  payload: status,
});

export const setCardPaytools = (toolId, toolName) => ({
  type: PAYTOOLS.SET_CARD,
  payload: { toolId: toolId, toolName: toolName },
});

export const setBankPaytools = (toolId, toolName) => ({
  type: PAYTOOLS.SET_BANK,
  payload: { toolId: toolId, toolName: toolName },
});

export const checkBanCards = (status) => ({
  type: BAN.CHECK_CARD,
  payload: status,
});

export const strategysStatus = (status) => ({
  type: STRATEGY.STATUS,
  payload: status,
});

export const infoPopupToggle = (status) => ({
  type: POPUP.INFO_TOGGLE,
  payload: status,
});

export const setInviteFriends = (
  promoCodeId,
  refLink,
  totalLinkCount,
  readLinkCount
) => ({
  type: INVITE.INVITE_FRIENDS,
  payload: { promoCodeId, refLink, totalLinkCount, readLinkCount },
});

export const setMyInvitations = (
  promoCodeId,
  refLink,
  totalLinkCount,
  readLinkCount,
  totalInterest,
  gotBonusByFriend,
  details
) => ({
  type: INVITE.MY_INVITE,
  payload: {
    promoCodeId,
    refLink,
    totalLinkCount,
    readLinkCount,
    totalInterest,
    gotBonusByFriend,
    details,
  },
});

export const setInviteRedirect = () => ({ type: INVITE.REDIRECT });

export const setBonus = (percent) => ({
  type: JUDICIAL.BONUS,
  payload: percent,
});

export const popupInfoToggle = (status) => ({
  type: POPUP_INFO.POPUP_INFO_TOGGLE,
  payload: status,
});

export const popupInfoCheck = (status) => ({
  type: POPUP_INFO.POPUP_INFO_CHECK,
  payload: status,
});
