import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import RenderPassword from '../../formComponents/RenderPassword'
import { Resource } from '../../../../redux/actions/lang.actions'
import { SETTINGS_SAVE_SUCCESS } from '../../../../redux/constants/settings/settings.constants'
import LoaderButton from '../../LoaderButton'

const ChangePassword = props => {

    const fieldID = props.fields[props.form]

    useEffect(() => {
        for (let key in fieldID)
            props.change(fieldID[key].id, fieldID[key].value)
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <form id='password_button' onSubmit={props.handleSubmit} className="settings__form columns_2">
                <div className="box card">
                    <h3 className="box__heading">{Resource('PasswordChange')}</h3>

                    <RenderInput data={fieldID['settingsPasswordCurrent']} />
                    <RenderPassword data={fieldID['settingsPassNew']} />
                    <RenderPassword data={fieldID['settingsPassRepeat']} />

                </div>
            </form>
            <div className="fixed">
                <div className="container">
                    <LoaderButton
                        form="password_button"
                        disabled={true}
                        loading={props.loading[SETTINGS_SAVE_SUCCESS]}
                    >{Resource('Save')}</LoaderButton>
                </div>
            </div>
        </>
    )
}

export default reduxForm({ form: 'changePasswordForm' })(ChangePassword)
