import React from "react";
import classes from "./Navigation.module.scss";
import { Link, NavLink } from "react-router-dom";
import { Resource, toggleMenu } from "../../../redux/actions/lang.actions";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/login/auth.actions";
import { IconLogout, IconSettings, IconUser } from "../../static/Icons/Icons";
import Language from "../../../components/UI/Language";
import { SubNavigation } from "../SubNavigation/SubNavigation";
import { ReactComponent as IconMegaphone } from "../../static/Icons/megaphone.svg";
import { ProfileMenu } from "../ProfileMenu/ProfileMenu";

const Navigation = (props) => {
  return (
    <nav
      className={[
        classes.navigation,
        props.isOpen ? classes.isOpen : "",
        props.isDark ? classes.dark : "",
      ].join(" ")}
    >
      {/*<Language className={classes.language}/>*/}
      {/*<span className={classes.close} onClick={props.setIsOpen.bind(this, false)} />*/}
      <div className={classes.menu}>
        {props.navItems &&
          Object.keys(props.navItems).map((key) => {
            if (key === "StrategyTitle") {
              return (
                <SubNavigation
                  id={key}
                  key={key}
                  nameItem={key}
                  navItems={{
                    OtherStrategy: "/information",
                    LegalStrategy: "/legal",
                  }}
                  isDark={props.isDark}
                  setIsOpen={props.setIsOpen.bind(this, false)}
                />
              );
            } else {
              return (
                <NavLink
                  id={key}
                  key={key}
                  to={props.navItems[key]}
                  activeClassName={classes.active}
                  onClick={props.setIsOpen.bind(this, false)}
                >
                  {key === "InviteFriends" && <IconMegaphone />}
                  {Resource(key)}
                </NavLink>
              );
            }
          })}
      </div>

      {/* <NavLink
        onClick={props.setIsOpen.bind(this, false)}
        to={"/settings"}
        activeClassName={classes.active}
        className={classes.account}
      >
        <IconUser color="#1F242D" />
        <span>
          {props.person.firstName} {props.person?.lastName}
        </span>
        <IconSettings color="#1F242D" />
      </NavLink>
      <Link
        to="/"
        className={classes.logout}
        onClick={() => {
          props.toggleMenu(false);
          props.logout();
        }}
      >
        {Resource("Exit")}
        <IconLogout color="#1F242D" />
      </Link> */}
      <ProfileMenu
        person={props.person}
        logout={props.logout}
        toggleMenu={props.toggleMenu}
        isDark={props.isDark}
        setIsOpen={props.setIsOpen.bind(this, false)}
      />
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    person: state.person,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: (status) => dispatch(toggleMenu(status)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
