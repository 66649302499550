import React, {useEffect} from 'react';
import { useForm } from "react-hook-form";

import classes from './FormsController.module.scss'
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import {Resource} from "../../../redux/actions/lang.actions";
import {reduxForm} from "redux-form";
import {DEPOSITING_DATA_SUCCESS} from "../../../redux/constants/depositing/depositing.constants";
import {currentFormatting} from "../../../redux/utils/formatting";
import {connect} from "react-redux";
import {isCurrency, rangeNumbers} from "../tools";

const FieldsWithButton = (props) => {
  
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
    setValue
  } = useForm({
    mode: "onTouched"
  });
  
  window.clearDepositValue = setValue
  
  const onSubmit = (values) => {
    props.onSubmit(values, errors)
  };
  
  let initValues = (() => {
    // let obj = {};
    props.fields.map(item => {
      setValue(
  props.unicId 
          ? item.id + props.unicId 
          : item.id, 
  props.defValue
          ? isCurrency({prefix: '€'}, {target: {value: props.defValue}})
          : ''
      )
      // obj[props.unicId ? item.id + props.unicId : item.id] = props.defValue
      //   ? isCurrency({prefix: '€'}, {target: {value: props.defValue}})
      //   : ''
    })
    // return obj
  })
  
  useEffect(() => {
    initValues()
  }, [props.defValue])
  
  const renderErrors = (id) => {
    const [message, amount] = errors[id].message.split(' ')
    if(amount) {
      return `${Resource(message)} ${currentFormatting(Number(amount))}`
    } else {
      return Resource(message)
    }
  }
  
  const styles = {
    height: props.height,
    padding: props.padding
  }
  
  return (
    <form id={"max_" + props.unicId} onSubmit={handleSubmit(onSubmit)} className={[classes.form, props.className, props.offline ? classes.offline : ''].join(' ')}>

      {
        props.fields.map(({id, placeholder = ' ', label, type = 'text', validates, maxLength}) => {

          const clsWrapper = [
            classes.wrapper,
            errors[props.unicId ? id + props.unicId : id] ? classes.invalid : '',
            // touchedFields[props.unicId ? id + props.unicId : id] ? classes.valid : '',
            props.disabledField ? classes.disabled : ''
          ].join(' ')
          
          return (
            <div className={classes.field} key={id}>
              <div className={clsWrapper}>
                {
                  props.offline
                    ? <span className={classes.closed} 
                            style={styles}
                    />
                    : <input id={props.unicId ? id + props.unicId : id} type={type} name={props.unicId ? id + props.unicId : id} placeholder={placeholder} autoComplete='off'
                             maxLength={maxLength}
                             {...register(
                         props.unicId
                                 ? id + props.unicId
                                 : id,
                        props.validateCustom
                                  ? {...validates, validate: props.validateCustom}
                                  : {...validates}
                             )}
                      // , value: props.defValue ? isCurrency({prefix: '€'}, {target: {value: props.defValue}}) : ''
                      // defaultValue={initValues[props.unicId ? id + props.unicId : id]} 
                             key={props.unicId ? id + props.unicId : id}
                             disabled={props.disabled}
                             onInput={props.onInputHandler}
                    />
                }
                <label htmlFor={props.unicId ? id + props.unicId : id}>{props.label ? Resource(props.label) : Resource(label)}</label>
              </div>
              {errors[props.unicId ? id + props.unicId : id] && <p key={props.unicId ? id + props.unicId : id} className={classes.error}>{renderErrors(props.unicId ? id + props.unicId : id)}</p>}
            </div>
          )
        })
      }
      
      <DefaultButton
        type={props.offline ? 'button' : 'submit'}
        customClass={classes.button}
        onClick={props.offline ? () => {} : props.onClick}
        load={props.loading}
        // disabled={props.disabled}
      >{props.btnText}</DefaultButton>
    </form>
  )
}

const mstp = state => {
  return {
    language: state.language,
  }
}

export default connect(mstp, null)(FieldsWithButton)