import React, { useEffect, useRef, useState } from "react";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import { connect, useDispatch, useSelector } from "react-redux";

import classes from "./MyInvestmentPage.module.scss";
import Panel from "../../components/Panel/Panel";
import Currency from "../../components/сurrency/Currency";
import {
  currentFormatting,
  dateFormattingWithTime,
} from "../../../redux/utils/formatting";
import {
  PERSON_DATA_SUCCESS,
  VERIFY_CONFIRM,
} from "../../../redux/constants/person.constants";
import IconButton from "../../components/Buttons/IconButton/IconButton";

import iconPlus from "../../../static/image/icons/icon-plus-btn.svg";
import iconArrow from "../../../static/image/icons/icon-arrow-btn.svg";
import TabComponentItem from "../../components/Tabs/TabsComponentItem";
import { Resource } from "../../../redux/actions/lang.actions";
import TabsComponent from "../../components/Tabs/TabsComponent";
import CardPortfolio from "../../components/CardPortfolio/CardPortfolio";
import {
  getIncomeHistory,
  getInvestmentData,
  activatePortfolioDraft,
  deletePortfolioDraft,
} from "../../redux/investment-reducer";
import { changeActiveId } from "../../../redux/actions/tabs.actions";
import HistorySidebar from "../../components/History/HistorySidebar";
import iconArrowLong from "../../../static/image/icons/icon-arrow-long.svg";
import iconVeriffLogo from "../../../static/image/veriff/veriff-logo.svg";
import ModalButton from "../../../components/UI/Modal/ModalButton";
import LoaderButton from "../../../components/UI/LoaderButton";
import PaymentIframe from "../../../components/UI/statusPayment/PaymentIframe";
import VeriffIframe from "../../../components/UI/statusVeriff/VeriffIframe";
import Modal from "../../../components/UI/Modal/Modal";
import VeriffForm from "../../../components/UI/formControls/veriff/veriff.form";
import ModalComponent from "../../components/Modals/ModalComponent";
import {
  getFirstDayNextMonth,
  getLastDayCurrentMonth,
  getTemplateOfValues,
} from "../../components/tools";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import { getFormValues, isValid } from "redux-form";
import { getPersonData } from "../../../redux/actions/person.action";
import {
  getDepositingUrl,
  saveRequiredSettings,
} from "../../../redux/actions/depositing/depositing.actions";
import { DEPOSITING_CHANGE_STATUS } from "../../../redux/constants/depositing/depositing.constants";
import {
  getVeriffSession,
  saveRequiredVeriff,
  toggleVeriffPanel,
} from "../../../redux/actions/veriff/veriff.actions";
import { MODAL_IS_OPEN } from "../../../redux/constants/modals.constants";
import { getPersonSettings } from "../../../redux/actions/settings/settings.actions";
import Strategys from "../../../components/Blocks/Strategys/Strategys.container";
import BalanceHeader from "../../components/сurrency/Balance/BalanceHeader";
import SpinnerLoad from "../../components/SpinnerLoading/SpinnerLoading";
import VerifyBanner from "../../components/VerifyBanner/VerifyBanner";
import { GradePopup } from "../../components/GradePopup/GradePopup";
import { PortfolioCardNew } from "../../components/PortfolioCardNew/PortfolioCardNew";
import { StrategiesPanel } from "../../components/StrategiesPanel/StrategiesPanel";
import { FooterComponent } from "../../components/Footer/FooterComponent";
import Helper from "../../../components/UI/Modal/Helper";
import { InfoBanner } from "../../components/InfoBanner/InfoBanner";
import { InfoSlide } from "../../components/InfoSlide/InfoSlide";
import { popupInfoToggle, popupInfoCheck } from "../../redux/actions/actionsCreators";
import { InfoPopup } from "../../components/InfoPopup/InfoPopup";
import { PortfoliosInfoSlide } from "../../components/PortfoliosInfoSlide/PortfoliosInfoSlide";
import { PortfoliosInfo2Slide } from "../../components/PortfoliosInfo2Slide/PortfoliosInfo2Slide";

const MyInvestmentPage = (props) => {
  const dispatch = useDispatch();
  const [loadingPortfolio, setLoadingPortfolio] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);

  const [disableVerifBtn, setVerifBtnStatus] = useState(false);
  const [currentDepositValue, setCurrentDepostiValue] = useState(null);

  const [isBalance, setIsBalance] = useState(false);

  const refContent = useRef();

  const infoCheck = useSelector(state => state.popupInfoReducer.popupInfoCheck);


  useEffect(() => {
    props.getInvestmentData();
    props.getPersonSettings();
    if (!infoCheck) {
      dispatch(popupInfoToggle(true));
      dispatch(popupInfoCheck(true));
    }
  }, []);

  const sumbitSaveVeriff = (values) => {
    setVerifBtnStatus(true);
    const { prefix, verifyCountry } = props.selects;
    const { verifyAddress, verifyCity, verifyZip, phone } = values;

    props.saveRequiredVeriff({
      verifyAddress,
      verifyCity,
      verifyCountry: verifyCountry
        ? verifyCountry.id
        : props.veriff.fields.verifyCountry.value,
      verifyZip,
      prefix:
        prefix !== undefined ? prefix.id : props.veriff.fields.prefix.value,
      phone:
        props.veriff.fields.phone.value !== phone
          ? !!phone
            ? (prefix !== undefined
                ? prefix.id
                : String(props.veriff.fields.prefix.value)) + phone
            : null
          : !!phone
          ? (prefix !== undefined
              ? prefix.id
              : String(props.veriff.fields.prefix.value)) +
            String(props.veriff.fields.phone.value).substring(
              String(props.veriff.fields.prefix.value).length
            )
          : null,
      personId: props.person.id,
      firstName: props.person.firstName,
      lastName: props.person.lastName,
      setVerifBtnStatus,
      verifyStatusId: props.person.veriffStatusId,
      depositValue: currentDepositValue,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (refContent.current.getBoundingClientRect().top - 50 <= 0) {
        setIsBalance(true);
      } else {
        setIsBalance(false);
      }
    };

    window.ym(56965879, "hit", "/portfolio");

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const outputNameForHistory = ({ operations, portfolioId }) => {
    if (operations === 8 || operations === 9 || operations === 299)
      return Resource("PaymentsOpertions_355");
    return `${Resource("PortfolioID")} ID ${portfolioId}`;
  };

  let num = 0; // id для тестов Макса

  return (
    <main className={classes.investment}>
      <DocumentTitle title={"MyInvestments"} />

      <BalanceHeader
        className={[
          classes.balance,
          isBalance && !props.handlers.isOpenMenu ? classes.show : "",
        ].join(" ")}
        balanceValue={props.person.currentBalance.value}
      />

      <div className={classes.sidebar}>
        <Panel customClass={classes.panel}>
          <Currency
            column
            order
            className={classes.currency}
            amount={currentFormatting(props.person.currentBalance.value)}
            text={[Resource("Balance")]}
            loadId={PERSON_DATA_SUCCESS}
          />

          <div className={classes.action}>
            <IconButton
              id={"depositing_link"}
              className={classes.iconButton}
              column
              icon={iconPlus}
              link={"/depositing"}
            >
              {Resource("DepositAccountMobile")}
            </IconButton>
            <IconButton
              id={"withdrawal_link"}
              className={classes.iconButton}
              column
              icon={iconArrow}
              link={"/withdrawal"}
            >
              {Resource("WithdrawFundsMobile")}
            </IconButton>
          </div>
        </Panel>

        {props.person.veriffStatusId === VERIFY_CONFIRM ? (
          <>
            {/* <InfoBanner /> */}
            {/*TODO Добавить слайдер баннер адаптированный под сторис*/}
          </>
        ) : !props.isFullFields ? (
          <ModalButton
            isOpen={!props.modalDepositingVeriff.isOpen}
            id={props.modalDepositingVeriff.id}
            layout={
              <VerifyBanner
                name={props.person.firstName}
                loading={disableVerifBtn}
                className={classes}
              />
            }
            addClass={classes.button}
          />
        ) : (
          <VerifyBanner
            name={props.person.firstName}
            loading={disableVerifBtn}
            className={classes}
            onClickBanner={() => {
              setVerifBtnStatus(true);
              props.getVeriffSession({
                personId: props.person.id,
                setVerifBtnStatus,
                firstName: props.person.firstName,
                lastName: props.person.lastName,
              });
            }}
          />
        )}
        <Modal
          isOpen={props.modalDepositingVeriff.isOpen}
          id={props.modalDepositingVeriff.id}
          isForm={false}
          addClass={"veriff__modal"}
          isOutside={true}
          onStart={() => {
            console.log("Open verify modal");
            window.ym(56965879, "reachGoal", "Veriff_prelend_open");
          }}
        >
          <VeriffForm
            onSubmit={sumbitSaveVeriff.bind(this)}
            disableVerifBtn={disableVerifBtn}
            toggleVeriffPanel={props.toggleVeriffPanel}
            fields={props.veriff.fields}
          />
        </Modal>

        <HistorySidebar
          title={Resource("IncomeCalculation")}
          onRequest={() => props.getIncomeHistory(4)}
          data={props.investment.historyIncome}
          link={{
            url: "/operations-history",
            text: Resource("NextMoreText"),
            onClick: () => props.changeActiveId(3, "operationsTabs"),
          }}
          template={[
            {
              cls: "name",
              key: "portfolioId",
              before: `${Resource("PortfolioID")} ID `,
              bindFn: outputNameForHistory,
              bind: ["operations", "portfolioId"],
            },
            {
              cls: "amount",
              before: "+ ",
              key: "deposit",
              format: currentFormatting,
            },
            {
              cls: "date",
              key: "dateOperation",
              format: dateFormattingWithTime,
            },
          ]}
        />
        {/*<FooterComponent />*/}

        <VeriffIframe modal={props.modal["modalVeriff"]} />
      </div>
      <div ref={refContent} className={classes.content}>
        <h1 className={classes.title}>{Resource("MyInvestments")}</h1>

        <div className={classes.info}>
          <Currency
            column
            order
            className={classes.currency}
            amount={currentFormatting(props.person.investBalance.value)}
            text={[Resource("InvestmentsSumTotal")]}
          />

          <div className={classes.wrapper}>
            <Currency
              order
              className={classes.currency}
              amount={currentFormatting(props.person.interestSum.value)}
              text={[`${Resource("AccuredIncome")}:`]}
            />
            <Currency
              order
              className={classes.currency}
              amount={currentFormatting(props.person.avgExpectedInterest)}
              text={[`${Resource("ExpectedIncome")}:`]}
            />
          </div>

          {/* <div className={classes.create}>
              <i className={classes.icon} onClick={() => {
                props.onToggleModal(props.modal.modalCalculator.id, !props.modal.modalCalculator.isOpen)
              }}/>
              <span>{Resource('Invest')}</span>
            </div> */}

          <Modal
            isOpen={props.modal.modalCalculator.isOpen}
            id={props.modal.modalCalculator.id}
            isForm={false}
            addClass={classes.modalCalculator}
            isOutside={true}
          >
            <Strategys
              infoDisabled
              customClass={classes}
              disableVerifBtn={disableVerifBtn}
              setVerifBtnStatus={setVerifBtnStatus}
            />
          </Modal>
        </div>

        <StrategiesPanel
          onToggleModal={props.onToggleModal}
          id={props.modal.modalCalculator.id}
          isOpen={props.modal.modalCalculator.isOpen}
          personId={props.person.id}
        />

        <div className={classes.portfolio}>
          <h2 className={classes.title}>{Resource("PortfolioTitle")}</h2>

          <div className={classes.tabs}>
            {props.investment.isLoadingPortfolios ? (
              <div className={["spinner", classes.spinner].join(" ")} />
            ) : (
              <TabsComponent
                id={"portfolioTabs"}
                customClasses={classes}
                onTabClick={() =>
                  console.log("Click handler to button for tabs")
                }
              >
                <TabComponentItem
                  label={Resource("TabsActive")}
                  count={props.investment.active.length}
                >
                  <div className={classes.wrapper}>
                    {
                      // props.investment.active.length > 0 ? props.investment.active.map(item => {
                      //   return <CardPortfolio key={item.id} id={item.id} type={item.rulesId}
                      //                         percent={item.percent} term={item.monthCount} amount={item.amount}
                      //                         currentIncome={item.currentInterest.toFixed(2)} expectedIncome={item.avgExpectedInterest.toFixed(2)}
                      //                         endDate={item.endDate} fields={props.balancedReducer.fields} isFullFields={props.isFullFields}/>
                      // }) : ''
                      props.investment.active.length > 0
                        ? props.investment.active.map((item) => {
                            return (
                              <PortfolioCardNew
                                key={item.id}
                                id={item.id}
                                idJ={item.investedObject.id}
                                rulesId={item.rulesId}
                                percent={item.percent}
                                monthCount={
                                  item.investedObject && item.rulesId === 7
                                    ? item.investedObject.monthCount
                                    : item.monthCount
                                }
                                availableForInvestment={
                                  item.investedObject.id
                                    ? item.investedObject.availableForInvestment
                                    : null
                                }
                                avgExpectedInterest={item.avgExpectedInterest}
                                endAvailableDate={
                                  item.investedObject
                                    ? item.investedObject.endAvailableDate
                                    : null
                                }
                                remind={
                                  item.investedObject
                                    ? item.investedObject.remind
                                    : null
                                }
                                totalAmount={
                                  item.investedObject
                                    ? item.investedObject.totalAmount
                                    : null
                                }
                                currentInterest={item.currentInterest}
                                amount={item.amount}
                                endDate={item.endDate}
                                btnText={"DepositFunds"}
                                label={"SumAmount"}
                                num={++num}
                                disabledField={!item.canFillBalance}
                                close={!item.canFillBalance}
                                moreLink={
                                  item.rulesId === 7
                                    ? `/judicial/p_${item.id}`
                                    : null
                                }
                                isPortfolio
                                bonus={
                                  item.campaignBonus.additionalPercent &&
                                  item.campaignBonus.additionalPercent
                                }
                              />
                            );
                          })
                        : ""
                    }
                  </div>
                </TabComponentItem>
                <TabComponentItem
                  label={Resource("TabsDraft")}
                  count={props.investment.plan.length}
                >
                  <div className={classes.wrapper}>
                    {
                      props.investment.plan.length > 0
                        ? props.investment.plan.map((item) => {
                            return (
                              <CardPortfolio
                                key={item.id}
                                id={item.id}
                                type={item.rulesId}
                                percent={item.percent}
                                term={
                                  item.investedObject && item.rulesId === 7
                                    ? item.investedObject.monthCount
                                    : item.monthCount
                                }
                                deleteLink
                                defValue={item.amount}
                                disabledField={!item.canFillBalance}
                                close={!item.canFillBalance}
                                fields={props.balancedReducer.fields}
                                isFullFields={props.isFullFields}
                                onDelete={props.deletePortfolioDraft.bind(
                                  this,
                                  item.id
                                )}
                                onSubmitDeposit={props.activatePortfolioDraft.bind(
                                  this,
                                  { portfolioId: item.id, amount: item.amount }
                                )}
                              />
                            );
                          })
                        : ""

                      // props.investment.plan.length > 0 ? props.investment.plan.map(item => {
                      //   return <PortfolioCardNew
                      //       key={item.id}
                      //       id={item.id}
                      //       rulesId={ item.rulesId }
                      //       percent={ item.percent }
                      //       monthCount={ item.monthCount}
                      //       availableForInvestment={ item.investedObject ? item.investedObject.availableForInvestment : null }
                      //       avgExpectedInterest={ item.avgExpectedInterest }
                      //       endAvailableDate={ item.investedObject ? item.investedObject.endAvailableDate : null }
                      //       remind={ item.investedObject ? item.investedObject.remind : null }
                      //       totalAmount={ item.investedObject ? item.investedObject.totalAmount : null }
                      //       currentInterest={ item.currentInterest }
                      //       amount={ item.amount }
                      //       endDate={ item.endDate }
                      //       disabledField
                      //   />
                      // }) : ''
                    }
                  </div>
                </TabComponentItem>
                <TabComponentItem
                  label={Resource("TabsClose")}
                  count={props.investment.closed.length}
                >
                  <div className={classes.wrapper}>
                    {
                      props.investment.closed.length > 0
                        ? props.investment.closed.map((item) => {
                            return (
                              <CardPortfolio
                                key={item.id}
                                id={item.id}
                                type={item.rulesId}
                                percent={item.percent}
                                term={item.monthCount}
                                amount={item.amount}
                                currentIncome={item.currentInterest.toFixed(2)}
                                expectedIncome={
                                  item.avgExpectedInterest <= 0
                                    ? item.currentInterest.toFixed(2)
                                    : item.avgExpectedInterest.toFixed(2)
                                }
                                endDate={item.endDate}
                                fields={props.balancedReducer.fields}
                                closed
                              />
                            );
                          })
                        : ""

                      // props.investment.closed.length > 0 ? props.investment.closed.map(item => {
                      //   return <PortfolioCardNew
                      //       key={item.id}
                      //       id={item.id}
                      //       rulesId={ item.rulesId }
                      //       percent={ item.percent }
                      //       monthCount={ item.monthCount}
                      //       availableForInvestment={ item.investedObject ? item.investedObject.availableForInvestment : null }
                      //       avgExpectedInterest={ item.avgExpectedInterest }
                      //       endAvailableDate={ item.investedObject ? item.investedObject.endAvailableDate : null }
                      //       remind={ item.investedObject ? item.investedObject.remind : null }
                      //       totalAmount={ item.investedObject ? item.investedObject.totalAmount : null }
                      //       currentInterest={ item.currentInterest }
                      //       amount={ item.amount }
                      //       endDate={ item.endDate }
                      //   />
                      // }) : ''
                    }
                  </div>
                </TabComponentItem>
              </TabsComponent>
            )}
          </div>

          <ModalComponent
            data={props.modal.modalLimitDeposit}
            // title=''
            // description=''
            onCloseHandler={() => {
              window.clearDepositValue(
                "depositValue",
                `€${props.layoutData.message?.remainBalance}`
              );
            }}
            closeDisabled={true}
            content={
              <div className={classes.limit}>
                {props.modal.modalLimitDeposit.isOpen ? (
                  <>
                    <h4 className={classes.title}>
                      {getTemplateOfValues(Resource("LimitContentTitle"), {
                        1: currentFormatting(
                          props.layoutData.message?.currentBalance
                        ),
                      })}
                    </h4>
                    <p className={classes.description}>
                      {getTemplateOfValues(
                        Resource("LimitContentDescription1"),
                        { 1: currentFormatting(15000) }
                      )}
                    </p>
                    <ul className={classes.list}>
                      <li>
                        {getTemplateOfValues(Resource("LimitContentList1"), {
                          1: getLastDayCurrentMonth(),
                          2: currentFormatting(
                            props.layoutData.message?.remainBalance
                          ),
                        })}
                      </li>
                      <li>
                        {getTemplateOfValues(Resource("LimitContentList2"), {
                          1: getFirstDayNextMonth(),
                          2: currentFormatting(15000),
                        })}
                      </li>
                    </ul>
                    <p className={classes.description}>
                      {getTemplateOfValues(
                        Resource("LimitContentDescription2"),
                        { 1: currentFormatting(15000) }
                      )}
                    </p>
                  </>
                ) : null}
              </div>
            }
            footer={
              <DefaultButton close customClass={classes.closed}>
                OК
              </DefaultButton>
            }
            customClass={classes.modal}
          />

          <PaymentIframe
            modal={props.modal["modalPayment"]}
            pay={props.payData}
            closeHandler={() => {
              console.log("pressed close modal");
              props.getPersonData();
              props.getDepositPayments();
            }}
          />
        </div>
      </div>
      <GradePopup />
      <FooterComponent />
      <Helper />
      <InfoSlide
        onToggleModal={props.onToggleModal}
        id={props.modal.modalCalculator.id}
        isOpen={props.modal.modalCalculator.isOpen}
      />
      {/* <InfoPopup /> */}
      <PortfoliosInfoSlide />
      <PortfoliosInfo2Slide />
    </main>
  );
};

const mapStateToProps = (state) => {
  const fields = state.veriff.fields;
  const fieldsValues = [
    fields.verifyCountry.value,
    fields.verifyCity.value,
    fields.verifyAddress.value,
    fields.verifyZip.value,
    fields.prefix.value,
    fields.phone.value,
  ];

  return {
    person: state.person,
    language: state.language,
    investment: state.InvestmentReducer,

    balancedReducer: state.BalancedReducer,
    verifyReducer: state.VerifyReducer,
    modal: state.modals,
    payData: state.depositing.payData,
    depositingData: state.depositing.data,
    valuesForm: getFormValues("DepositingFormCard")(state),
    isValid: isValid("DepositingFormCard")(state),
    modalVeriff: state.modals.modalVeriff,
    modalDepositingVeriff: state.modals.modalDepositingVeriff,
    fieldsVerify: state.veriff.fields,
    selects: state.forms.selects,
    loading: state.loading,
    veriff: state.veriff,
    isFullFields: fieldsValues.every((value) => !!value),
    layoutData: state.LayoutReducer,
    handlers: state.HandlerReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvestmentData: () => dispatch(getInvestmentData()),
    getIncomeHistory: (count) => dispatch(getIncomeHistory(count)),
    changeActiveId: (activeId, tabsId) =>
      dispatch(changeActiveId(activeId, tabsId)),

    getPersonData: () => dispatch(getPersonData()),
    saveRequiredSettings: (settings) =>
      dispatch(saveRequiredSettings(settings)),
    changeStatusFull: (status) =>
      dispatch({ type: DEPOSITING_CHANGE_STATUS, payload: status }),
    getVeriffSession: (params) => dispatch(getVeriffSession(params)),
    onToggleModal: (id, isOpen) =>
      dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
    toggleVeriffPanel: () => dispatch(toggleVeriffPanel()),
    saveRequiredVeriff: (veriffModel) =>
      dispatch(saveRequiredVeriff(veriffModel)),
    getDepositingUrl: (orderPrice) => dispatch(getDepositingUrl(orderPrice)),
    getPersonSettings: () => dispatch(getPersonSettings()),

    activatePortfolioDraft: (params) =>
      dispatch(activatePortfolioDraft(params)),
    deletePortfolioDraft: (portfolioId) =>
      dispatch(deletePortfolioDraft(portfolioId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInvestmentPage);
