// Отображение окна с просьбой оценить платформу, после пополнения портфеля
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "../ui/Popup/Popup";
import { gradePopupToggle } from "../../redux/actions/actionsCreators";
import { Locale } from "../../../locale/locale";
import "./GradePopup.scss";

const GradePopup = () => {
    const amountStars = 5;

    const gradeOpen = useSelector(state => state.PopupReducer.gradeOpen);
    const dispatch = useDispatch();

    const [starCounter, setStarCounter] = useState(0);
    const [condition, setCondition] = useState({type: "later", flag: false, click: false});


    const selectStars = amount => {
        setStarCounter(amount);
        setCondition({...condition, click: true})
    };

    useEffect(()=>{
        if(condition.click && !condition.flag){
            if(starCounter >= 4){
                setCondition({type: "review", flag: true, click: true});
            }else{
                setCondition({type: "good", flag: true, click: true});
            }
        }
    }, [starCounter]);


    const createStars = () => {
        const stars = [];
        for(let i = 0; i < amountStars; i++ ){
            stars.push(
                <div 
                    className={i < starCounter ? "grade-content__star grade-content__star--active" : "grade-content__star"} 
                    key={"star_" + i}
                    onClick={()=>{selectStars( i + 1 )}}
                >
                    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.3309 8.65506L16.3883 7.64608L13.2848 1.35428C13.2 1.18202 13.0606 1.04256 12.8883 0.957798C12.4563 0.744517 11.9313 0.922251 11.7153 1.35428L8.61174 7.64608L1.66916 8.65506C1.47776 8.68241 1.30276 8.77264 1.16877 8.90936C1.00679 9.07585 0.917533 9.29983 0.920609 9.53209C0.923686 9.76435 1.01884 9.98589 1.18518 10.148L6.20822 15.0453L5.02151 21.9605C4.99368 22.1214 5.01148 22.2868 5.07289 22.4381C5.1343 22.5894 5.23687 22.7204 5.36896 22.8163C5.50105 22.9123 5.65738 22.9693 5.82022 22.9809C5.98305 22.9925 6.14589 22.9582 6.29026 22.882L12.5 19.6172L18.7098 22.882C18.8793 22.9722 19.0762 23.0023 19.2649 22.9695C19.7406 22.8875 20.0606 22.4363 19.9785 21.9605L18.7918 15.0453L23.8149 10.148C23.9516 10.014 24.0418 9.83905 24.0692 9.64764C24.143 9.16913 23.8094 8.72616 23.3309 8.65506Z"/>
                    </svg>
                </div>
            );
        }
        return stars;
    };

    const setActive = status => {
        dispatch(gradePopupToggle(status));
        if(!starCounter){
            window.ym(56965879,'reachGoal','Window_closed');
        }
    };

    const openLink = () => {
        setActive(false);
        window.ym(56965879,'reachGoal','Good_mark');
        window.open("https://ru.trustpilot.com/review/nibble.finance");
    };

    const clickOk = () => {
        setActive(false);
        window.ym(56965879,'reachGoal','Bad_mark');
    };

    return(
        <Popup 
            active={gradeOpen}
            setActive={setActive}
        >
            <div className="grade-content">
                <div className="grade-content__title">
                    { Locale.EN.GradeTitle }
                </div>
                <div className="grade-content__text">
                    { Locale.EN.GradeText }
                </div>
                <div className="grade-content__stars">
                    <div className="grade-content__stars-block">
                        {createStars()}
                    </div>
                </div>

                {condition.type !== "later" &&
                    <div className="grade-content__thanks">
                        {condition.type === "good"
                            ?
                                Locale.EN.GradeThanks1
                            :
                                Locale.EN.GradeThanks2
                        }
                    </div>
                }

                <div className="grade-content__nav">
                    <div className="grade-content__nav-item grade-content__nav-item-1">
                    </div>
                    <div className="grade-content__nav-item grade-content__nav-item-2">
                        {condition.type !== "good" &&
                            <div 
                                className="grade-content__nav-later"
                                onClick={()=>{setActive(false)}}
                            >
                                { Locale.EN.GradeLater }
                            </div>
                        }
                    </div>
                    <div className="grade-content__nav-item grade-content__nav-item-3">

                    {condition.type === "review" &&
                        <div 
                            className="grade-content__nav-btn"
                            onClick={openLink}
                        >
                            { Locale.EN.GradeFeedback }
                        </div>

                    }

                    {condition.type === "good" &&
                        <div 
                            className="grade-content__nav-btn"
                            onClick={clickOk}
                        >
                            { Locale.EN.GradeOk }
                        </div>
                    }

                    </div>
                </div>
                
            </div>
        </Popup>
    )
};

export { GradePopup };