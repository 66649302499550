// Отображение окна с просьбой оценить платформу, после пополнения портфеля
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupSlide } from "../ui/PopupSlide/PopupSlide";
import { portfoliosInfo2Toggle } from "../../redux/actions/actionsCreators";
import { Locale } from "../../../locale/locale";
import { Resource } from "../../../redux/actions/lang.actions";
import "./PortfoliosInfo2Slide.scss";

const PortfoliosInfo2Slide = () => {

    const { portfoliosInfoOpen2 } = useSelector(state => state.PopupReducer);
    const dispatch = useDispatch();


    const setActive = status => {
        dispatch(portfoliosInfo2Toggle(status));
    };


    return(
        <PopupSlide 
            active={portfoliosInfoOpen2}
            setActive={setActive}
        >
           <div className="portfolios-info-slide">
                <div className="portfolios-info-slide__content">
                    <div className="portfolios-info-slide__informer">
                        <div className="portfolios-info-slide__informer">
                            Info
                        </div>
                    </div>
                    <p>
                        Please note that the specified date marks the end of the initial period of your investment cycle. 
                        After this date, you will be able to withdraw funds by contacting our customer support. 
                        Unless we receive an application for the withdrawal, your funds will be automatically re-invested to prevent the loss of liquidity and ensure the best returns on your capital.
                    </p>
              </div>
           </div>
            
        </PopupSlide>
    )
};

export { PortfoliosInfo2Slide };