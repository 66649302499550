import { TABS_SET_ACTIVID } from "../../constants/tabs.constants"
import {STRATEGY_DATA_SUCCESS} from "../../constants/profile/strategys.constants";

const initialState = {
    createAccountTabs: {
        tabs: ['Individual', 'Legal'],
        activeId: 0
    },
    settingsTabs: {
        tabs: ['PersonalInfo', 'ChangePassword', 'PropsContractsDocs', 'Settings'],
        activeId: 0
    },
    strategyTabs: {
        tabs: ['StrategyNameClassic', 'StrategyNameBalanced'/*, 'StrategyNameSpecial'*/],
        activeId: 1
    },
    portfolioTabs: {
        tabs: ['InvestedLoans', 'RefillHistory'],
        activeId: 0
    },
    depositingTabs: {
        tabs: ['RefillFromCard', 'BankTransferReplenishment'],
        activeId: 0
    },
    operationsTabs: {
        tabs: ['All', 'CashDeposit', 'FundsWithdrawal', 'IncomeCalculation', 'Investing'],
        activeId: 0
    },
    partnerTabs: {
        tabs: ['Replenishments', 'Registrations'],
        activeId: 0
    }
}

const tabs = (state = initialState, action) => {
    switch (action.type) {
        case TABS_SET_ACTIVID:
            return { ...state, [action.tabsId]: { ...state[action.tabsId], activeId: action.activeId } }
        case STRATEGY_DATA_SUCCESS:
            return {
                ...state,
                strategyTabs: {
                    ...state.strategyTabs,
                    additional: {
                        ...state.strategyTabs.additional,
                        percent: [action.payload.classic.percent, action.payload.balanced.percent/*, action.payload.special.percent*/]
                    }
                }
            }
        default:
            return state
    }
}

export default tabs
