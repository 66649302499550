import React, { Component } from 'react'
import RenderTabs from '../components/UI/RenderTabs'
import { connect } from 'react-redux'
import { getPartnerInformation } from '../redux/actions/partner/partner.actions'
import { PARTNER_DATA_SUCCESS } from '../redux/constants/partner/partner.constants'
import ButtonSocial from '../components/Blocks/ButtonSocial/ButtonSocial'
import PartnerStats from '../components/Blocks/PartnerStats'
import GridTables from '../components/Blocks/GridTables/GridTables'
import { Resource } from '../redux/actions/lang.actions'
import DocumentTitle from '../components/UI/DocumentTitle'

class Partner extends Component {

    componentDidMount() {
        this.props.getPartnerInformation()

    }

    render() {
        return (
            <main className="partner">
                <DocumentTitle title='PartnerTitle' />
                <section className="partner-hero">
                    <div className="partner-hero__wrapper">
                        <h1>{Resource('PartnerTitle')}</h1>
                        <ul className="partner-path">
                            <li className="partner-path__item">
                                <span className="partner-path__num">1</span>
                                <h2 className="partner-path__title">{Resource('PartnerTitleStep1')}</h2>
                                <p className="partner-path__desc">{Resource('PartnerTextStep1')}</p>
                            </li>
                            <li className="partner-path__item">
                                <span className="partner-path__num">2</span>
                                <h2 className="partner-path__title">{Resource('PartnerTitleStep2')}</h2>
                                <p className="partner-path__desc">{Resource('PartnerTextStep2')}</p>
                            </li>
                            <li className="partner-path__item">
                                <span className="partner-path__num">3</span>
                                <h2 className="partner-path__title">{Resource('PartnerTitleStep3')}</h2>
                                <p className="partner-path__desc">{Resource('PartnerTextStep3')}</p>
                            </li>
                        </ul>
                    </div>
                    <figure className="partner-hero__image"><img src="./image/profile/person-notbg.png" alt="notbg" /></figure>
                </section>

                <section>
                    <div className="partner-columns columns_2">
                        <div className="box panel">
                            <h2 className="box__heading">{Resource('ShareLink')}</h2>
                            <div className="partner-share">
                                <div className="form__group">
                                    <label htmlFor="settingsName" className="form__label">{Resource('PartnerLinkSet')}</label>
                                    <span id="partnerInputLink" className="form__value">{this.props.partner.link}</span>
                                </div>
                                <div className="form__group">
                                    <label htmlFor="settingsName" className="form__label">{Resource('PartnerCode')}</label>
                                    <span id="partnerInputPromocode" className="form__value">{this.props.partner.promocode}</span>
                                </div>
                            </div>
                            <ButtonSocial data={this.props.partner}/>
                        </div>
                        <div className="box panel">
                            <h2 className="box__heading">{Resource('Stats')}<span className="box__heading_right">{Resource('Referals')} {this.props.partner.referalUsers} {Resource('Persons')}</span></h2>
                            <PartnerStats data={this.props.partner.cashGroupList} />
                        </div>
                    </div>
                </section>
                <section className="tabs js-tabs">
                    <h2 className="container">{Resource('RevenueFromRegistrationsAndRenewals')}</h2>
                    <RenderTabs id='partnerTabs'>
                        <GridTables data={this.props.table.replenishmentInformation} loading={this.props.loading} />
                        <GridTables data={this.props.table.registrationInformation} loading={this.props.loading} />
                    </RenderTabs>
                </section>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        table: {
            replenishmentInformation: state.partner.partnerTables.replenishmentInformation,
            registrationInformation: state.partner.partnerTables.registrationInformation
        },
        person: state.person,
        partner: state.partner,
        loading: state.loading[PARTNER_DATA_SUCCESS],
        lang: state.language.langActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPartnerInformation: () => dispatch(getPartnerInformation())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Partner)