import React, { useEffect } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { logout } from '../../redux/actions/login/auth.actions';
import { getPersonData } from '../../redux/actions/person.action';
import { connect } from 'react-redux';
import { PERSON_DATA_SUCCESS } from '../../redux/constants/person.constants';
import Language from '../../components/UI/Language';

import Login from '../Login';
import CreateAccount from '../CreateAccount';
import NotFound from '../NotFound';
import { FooterPages } from './Footer.pages';
import { DevPage } from '../../update/page/DevPage/DevPage';

const UnauthPages = props => {

    useEffect(() => {
        props.burgerActive ? document.body.classList.add('navigation--visible') : document.body.classList.remove('navigation--visible')
    }, [props.burgerActive])

    return (
        <>
            <header>
                <div className="header-wrapper">
                    <Link className="site-logo" to="/login" />
                    <Language className='language--right' />
                </div>
            </header>
            <Switch>
                <Route exact path='/' render={() => <Redirect to='/login' />} />
                <Route path='/login'><Login /></Route>
                <Route path='/create-account'><CreateAccount /></Route>
                <Route path='/dev'><DevPage /></Route>
                <Route path='/profile' render={() => <Redirect to='/' />} />
                <Route path='/my-investment' render={() => <Redirect to='/' />} />
                <Route path='/depositing' render={() => <Redirect to='/' />} />
                <Route path='/settings' render={() => <Redirect to='/' />} />
                <Route path='/support' render={() => <Redirect to='/' />} />
                <Route path='/information' render={() => <Redirect to='/' />} />
                <Route path='/partner' render={() => <Redirect to='/' />} />
                <Route path='/withdrawal' render={() => <Redirect to='/' />} />
                <Route><NotFound /></Route>
            </Switch>
            <FooterPages  />
        </>
    );
}

const MSTP = state => {
    return {
        person: state.person,
        loading: state.loading[PERSON_DATA_SUCCESS],
        burgerActive: state.language.burgerActive
    }
}

const MDTP = (dispatch) => {
    return {
        getPersonData: () => dispatch(getPersonData()),
        logout: () => dispatch(logout())
    }
}
export default connect(MSTP, MDTP)(UnauthPages)
