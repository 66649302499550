import React from 'react'
import Loader from '../UI/Loader'
import { PARTNER_DATA_SUCCESS } from '../../redux/constants/partner/partner.constants'
import { currentFormatting, dateFormatting } from '../../redux/utils/formatting'
import { Resource } from '../../redux/actions/lang.actions'

const PartnerStats = ({ data }) => {

    const renderStats = () => {
        return data && data.map((stat, ind) => {
            return (
                <li key={stat + ind} className="partner-stat__item">
                    <div className="partner-stat__item_left">
                        <span className="partner-stat__count">{stat.users} {Resource('PartnerTransaction')}</span>
                        <span className="partner-stat__date">{dateFormatting(stat.date)}</span>
                    </div>
                    <div className="partner-stat__item_right">
                        <span className="partner-stat__key">{Resource('PartnerTransactionIncome')}</span>
                        <span className="partner-stat__value">{currentFormatting(stat.amount)}</span>
                    </div>
                </li>
            )
        })
    }

    return (
        <Loader loadId={PARTNER_DATA_SUCCESS} reload>
            <ul className="partner-stat">
                { renderStats() }
            </ul>
        </Loader>
    )
}

export default PartnerStats