import { MYINVESTMENTS_TABLE_SUCCESS, MYINVESTMENTS_TABLE_FAILURE } from "../../constants/my-investments/investments.constants"
import { requestGet, errorHandler } from "../../api/request"
import { LOADING } from "../../constants/loading.constants"

export const getInvestments = () => {
    return dispatch => {
        requestGet('Portfolio/GetInvestmetns')
            .then(resp => {
                dispatch({
                    type: MYINVESTMENTS_TABLE_SUCCESS,
                    payload: resp.data
                })
                dispatch({ type: LOADING, id: MYINVESTMENTS_TABLE_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: MYINVESTMENTS_TABLE_SUCCESS, loading: false })
                dispatch({ type: MYINVESTMENTS_TABLE_FAILURE, error })
            })
        dispatch({ type: LOADING, id: MYINVESTMENTS_TABLE_SUCCESS, loading: true })
    }
}
