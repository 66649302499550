import React, {useEffect, useState} from 'react';
import classes from './TableComponent.module.scss';
import {useTable, useSortBy} from 'react-table'
import Panel from "../Panel/Panel";
import {Resource} from "../../../redux/actions/lang.actions";
import Spinner from "../../../components/UI/Spinner";
import {useMediaQuery} from "../Tools/hooks/useMediaQuery";

const renderGridLayout = (grid) => {
  let result = [];
  grid.map(item => {
    if (Array.isArray(item)) {
      let range = {
        min: item[0],
        max: item[1]
      }
      result.push(`minmax(${range.min}, ${range.max})`)
    } else {
      result.push(item + 'fr')
    }
  })
  return result.join(' ')
}

const TableComponent = (props) => {

  const [isLoading, setIsLoading] = useState(true)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    toggleSortBy
  } = useTable({
    columns: props.headers,
    data: props.body
  }, useSortBy)

  useEffect(() => {
    props.getDataHandler()
      .then(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    (props.desc || props.asc) && toggleSortBy(props.desc ?? props.asc, !!props.desc ?? !!props.asc)
  }, [props.body])


  // Media query
  const isMedia = useMediaQuery(`(max-width: ${props.tableMediaPoint}px)`)
  const styles = {
    table: isMedia => ({
      '--gridColumn': isMedia ? renderGridLayout(props.tableMediaGrid) : renderGridLayout(props.gridLayout)
    })
  }

  return (
    <>
      {
        isLoading
          ? <span className={classes.value}><Spinner container/></span>
          : <Panel customClass={[classes.table, props.light ? classes.light : ''].join(' ')}
                  style={styles.table(isMedia)} {...getTableProps()}>

            {
              props.body.length > 0 && headerGroups.map(headerGroup => (
                <div className={classes.header} {...headerGroup.getHeaderGroupProps()}>{
                  headerGroup.headers.map(column => {
                    return <span
                      className={classes.value} {...column.getHeaderProps(column.sortDescFirst)}>{Resource(column.render('Header'))}</span>
                  })
                }</div>
              ))
            }

            <div className={classes.body} {...getTableBodyProps()}>
              {
                rows.slice(0, props.renderRows).map((row, i) => {
                  prepareRow(row)
                  return (
                    <div className={classes.row} {...row.getRowProps()}>{
                      row.cells.map(cell => {
                        return <span {...cell.getCellProps()}
                                     className={[classes.value, props.classValue].join(' ')}>{cell.render('Cell')}</span>
                      })
                    }</div>
                  )
                })
              }
            </div>
          </Panel>
      }
    </>
    
  )
}

export default TableComponent