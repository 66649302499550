import { MYINVESTMENTS_TABLE_SUCCESS, MYINVESTMENTS_TABLE_FAILURE } from "../../constants/my-investments/investments.constants"

const initialState = {
    myInvestmentsTable: {
        id: 'my_investments',
        rowClick: true,
        displayLength: 8,
        columnsOptions: {
            id: {
                title: 'ID', flexGrow: 0.7, minWidth: 50, sort: true, align: 'center'
            },
            namePortfolio: {
                title: 'PortfolioID', flexGrow: 2, minWidth: 150, sort: true, className: 'td-pseudolink td-bold', format: 'string'
            },
            amount: {
                title: 'Sum', flexGrow: 1, minWidth: 100, sort: true, format: 'currency'
            },
            currentInterest: {
                title: 'CurrentIncome', flexGrow: 1.3, minWidth: 100, sort: true, className: 'td-bold td-accent', format: 'currency'
            },
            dateCreate: {
                title: 'Opened', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
            },
            daysCount: {
                title: 'TermDays', flexGrow: 1, minWidth: 100, sort: true
            },
            endDate: {
                title: 'ReturnDate', flexGrow: 1.3, minWidth: 140, sort: true, format: 'date'
            },
            percent: {
                title: 'Rate', flexGrow: 1, minWidth: 70, sort: true
            },
            avgExpectedInterest: {
                title: 'ExpectedIncome', flexGrow: 1.5, minWidth: 130, sort: true, className: 'td-bold', format: 'currency'
            },
            link: {
                flexGrow: 1, minWidth: 100, className: 'refill-button'
            },
        },
        sortColumn: 'id',
        body: []
    }
}

const myInvestments = (state = initialState, action) => {
    switch (action.type) {
        case MYINVESTMENTS_TABLE_SUCCESS:

            const getRulesWord = (id) => {
                switch (id) {
                    case 1:
                        return 'LongTerm'
                    case 2:
                        return 'Test'
                    case 3:
                        return 'StrategyClassicTitle'
                    case 4:
                        return 'StrategyBalancedTitle'
                    case 5:
                        return 'StrategySpecialTitle'
                    default:
                        return null
                }
            }

            const getDataTable = () => {
                return action.payload.map(({ id, amount, currentInterest, dateCreate, daysCount, endDate, percent, avgExpectedInterest, statusId, rulesId }) => {
                    let ruleId = getRulesWord(rulesId)
                    return {
                        id,
                        namePortfolio: [`PortfolioID`, ruleId],
                        amount: amount,
                        currentInterest: currentInterest,
                        dateCreate: dateCreate,
                        daysCount,
                        endDate: endDate,
                        percent: percent + '%',
                        avgExpectedInterest: avgExpectedInterest,
                        link: "DepositFunds",
                        options: { closed: statusId === 172 ? true : false, urlLink: '/portfolio/' + id, tagLink: true }
                    }
                })
            }

            return {
                ...state,
                myInvestmentsTable: {
                    ...state.myInvestmentsTable,
                    body: getDataTable()
                }
            }
        case MYINVESTMENTS_TABLE_FAILURE:
            return {
                ...state,
                ...action.error
            }
        default:
            return state
    }
}

export default myInvestments