export const PERSON_DATA_SUCCESS = 'PERSON/DATA_SUCCESS'
export const PERSON_DATA_FAILED = 'PERSON/DATA_FAILED'

export const VERIFY_OPEN = 189
export const VERIFY_CONFIRM = 191
export const VERIFY_REJECT = 190
export const VERIFY_PROCESS = 196
export const VERIFY_SKIP = 202

export const EMAIL_CONFIRM = 1
export const EMAIL_NOT_CONFIRM = 2