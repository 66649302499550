// Универсальный компонент для вывода данных во всплывающем окне
import React from "react";
import "./Popup.scss";

const Popup = ({active, setActive, children}) => {
    return(
        <div className={active ? "popup-modal popup-modal--active" : "popup-modal"} onClick={() => setActive(false)}>
            <div className="popup-modal__window" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
};

export { Popup };