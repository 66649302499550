export const VERIFF_SESSION_SUCCESS = 'VERIFF/SESSION_SUCCESS'
export const VERIFF_SESSION_FAILURE = 'VERIFF/SESSION_FAILURE'

export const VERIFF_KEY_SUCCESS = 'VERIFF/KEY_SUCCESS'
export const VERIFF_KEY_FAILURE = 'VERIFF/KEY_FAILURE'

export const VERIFF_SET_START_SESSION_SUCCESS = 'VERIFF/SET_START_SESSION_SUCCESS'
export const VERIFF_SET_START_SESSION_FAILURE = 'VERIFF/SET_START_SESSION_FAILURE'

export const VERIFF_TOGGLE = 'VERIFF/TOGGLE'

export const VERIFF_SAVE_DATA_SUCCESS = 'VERIFF/SAVE_DATA_SUCCESS'
export const VERIFF_SAVE_DATA_FAILURE = 'VERIFF/SAVE_DATA_FAILURE'