import React from 'react'
import Button from '../Button'
import { connect } from 'react-redux'
import { MODAL_IS_OPEN } from '../../../redux/constants/modals.constants'

const ModalButton = ({ children, type, onToggleModal, id, link, button, layout, isOpen, disabled, addClass }) => {
    const cls = [
        link && 'register-link',
        button && 'button'
    ]

    return (
        <>
            {button ? <Button type={type} disabled={disabled} onClick={() => onToggleModal(id, isOpen)} className={cls.join(' ')} addClass={addClass}>{children}</Button> : null}
            {link ? <span onClick={() => onToggleModal(id, isOpen)} className={cls.join(' ')}>{children}</span> : null}
            {layout ? React.Children.map(layout, (child) => React.cloneElement(child, {onClick: () => onToggleModal(id, isOpen)})) : null}
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id })
    }
}

export default connect(null, mapDispatchToProps)(ModalButton)
