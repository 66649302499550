import React, { Component } from 'react';
import { connect } from 'react-redux';
import Stats from '../components/Blocks/Stats';
import { PERSON_DATA_SUCCESS } from '../redux/constants/person.constants';
import WithdrawalForm from '../components/UI/formControls/withdrawal/withdrawal.form';
import { withdrawalRequest, getWithdrawal } from '../redux/actions/withdrawal/withdrawal.actions';
import { WITHDRAWAL_TABLE_SUCCESS } from '../redux/constants/withdrawal/withdrawal.constants';
import GridTables from '../components/Blocks/GridTables/GridTables';
import { Resource } from '../redux/actions/lang.actions';
import DocumentTitle from '../components/UI/DocumentTitle';

class Withdrawal extends Component {

    componentDidMount() {
        this.props.getWithdrawal()

    }

    submitWithdrawalPayment = values => {
        this.props.withdrawalRequest(values.withdrawalValue)
    }

    render() {
        return (
            <main className="depositing">
                <DocumentTitle title="FundsWithdrawal" />
                <div className="combo-title container">
                    <h1>{Resource('FundsWithdrawal')}</h1>
                    <ul className="combo-title__aside">
                        <Stats id='currentBalance' data={this.props.person} load={PERSON_DATA_SUCCESS} reload />
                    </ul>
                </div>
                <section>
                    <WithdrawalForm data={this.props.withdrawal} onSubmit={this.submitWithdrawalPayment.bind(this)} />
                </section>
                <section>
                    <h2>{Resource('WithdrawalHistory')}</h2>
                    {console.log(this.props.table)}
                    <GridTables data={this.props.table} loading={this.props.loading[WITHDRAWAL_TABLE_SUCCESS]} />
                </section>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        person: state.person,
        withdrawal: state.withdrawal,
        table: state.withdrawal.withdrawalTable,
        loading: state.loading,
        lang: state.language.langActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        withdrawalRequest: amount => dispatch(withdrawalRequest(amount)),
        getWithdrawal: () => dispatch(getWithdrawal())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal)