import React from 'react';
import classes from './Heading.module.scss';
import LinkButton from "../Buttons/LinkButton/LinkButton";

const Heading = (props) => {
  return (
    <div className={[classes.heading, props.className].join(' ')}>
      <h2>{props.title}</h2>
      <LinkButton to={props.to}>{props.link}</LinkButton>
    </div>
  )
}

export default Heading;