const HANDLER_SET_VALUE = 'HANDLER/SET_VALUE';

const initialState = {
  isOpenMenu: false
}

export const setHandlerValue = (payload) => dispatch => {
  dispatch({type: HANDLER_SET_VALUE, payload})
}

const HandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLER_SET_VALUE:
      const {isOpenMenu} = action.payload
      return {
        ...state,
        isOpenMenu
      }
    default:
      return state
  }
}

export default HandlerReducer
