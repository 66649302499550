import React, { Component, useEffect, useMemo, useState } from "react";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import { Resource } from "../../../redux/actions/lang.actions";
import {
  PERSON_DATA_SUCCESS,
  VERIFY_CONFIRM,
} from "../../../redux/constants/person.constants";
import {
  DEPOSITING_CHANGE_STATUS,
  DEPOSITING_DATA_SUCCESS,
  DEPOSITING_GET_URL_SUCCESS,
} from "../../../redux/constants/depositing/depositing.constants";
import PaymentIframe from "../../../components/UI/statusPayment/PaymentIframe";
import classes from "./WithdrawalPage.module.scss";
import List from "../../components/List/List";
import Panel from "../../components/Panel/Panel";
import {
  currencyFormatProfit,
  currentFormatting,
} from "../../../redux/utils/formatting";
import Currency from "../../components/сurrency/Currency";
import { connect, useDispatch, useSelector } from "react-redux";
import TabsComponent from "../../components/Tabs/TabsComponent";
import TabComponentItem from "../../components/Tabs/TabsComponentItem";
import TabComponentPortal from "../../components/Tabs/TabComponentPortal";
import logo_mastercard from "../../../static/image/icons/logo-mastercard.svg";
import logo_visa from "../../../static/image/icons/logo-visa.svg";
import FieldsWithButton from "../../components/FormsController/FieldsWithButton";
import TableComponent from "../../components/TableComponent/TableComponent";
import Heading from "../../components/Heading/Heading";
import { getWithdrawalNew } from "../../redux/withdrawal-reducer";
import {
  getVeriffSession,
  saveRequiredVeriff,
  toggleVeriffPanel,
} from "../../../redux/actions/veriff/veriff.actions";
import {
  getDepositingData,
  getDepositingUrl,
  saveRequiredSettings,
} from "../../../redux/actions/depositing/depositing.actions";
import { getFormValues, isValid } from "redux-form";
import { getPersonData } from "../../../redux/actions/person.action";
import { MODAL_IS_OPEN } from "../../../redux/constants/modals.constants";
import { getPersonSettings } from "../../../redux/actions/settings/settings.actions";
import { VERIFF_SESSION_SUCCESS } from "../../../redux/constants/veriff/veriff.constants";
import {
  withdrawalRequest,
  getWithdrawal,
} from "../../../redux/actions/withdrawal/withdrawal.actions";
import iconPlus from "../../../static/image/icons/icon-plus-btn.svg";
import IconButton from "../../components/Buttons/IconButton/IconButton";
import NoticeComponent from "../../components/NoticeComponent/NoticeComponent";
import noticeArrow from "../../../static/image/arrow-stroke.svg";
import VerifyBanner from "../../components/VerifyBanner/VerifyBanner";
import ModalButton from "../../../components/UI/Modal/ModalButton";
import { WITHDRAWAL_REQUEST_SUCCESS } from "../../../redux/constants/withdrawal/withdrawal.constants";
import { paytoolsRequest } from "../../redux/paytool-reducer";
import { checkCards } from "../../redux/ban-reducer";

const WithdrawalPage = (props) => {
  const dispatch = useDispatch();
  const cardActive = useSelector((state) => state.banReducer.status);

  const [disableVerifBtn, setVerifBtnStatus] = useState(false);

  const [isNotice, setIsNotice] = useState(false);

  useEffect(() => {
    props.getPersonSettings();
    props.paytoolsRequest(props.person.id);
    window.ym(56965879, "hit", "/depositing");
    checkCards(dispatch);
  }, []);

  const submitWithdrawalBank = (values) => {
    props.withdrawalRequest(
      props.person.id,
      values.bankWithdrawal.replace(/[^0-9\.]/, ""),
      props.paytools.bank.toolId
    );
  };

  const submitWithdrawalCard = (values) => {
    props.withdrawalRequest(
      props.person.id,
      values.cardWithdrawal.replace(/[^0-9\.]/, ""),
      props.paytools.card.toolId
    );
  };

  return (
    <main className={classes.balance}>
      <DocumentTitle title="FundsWithdrawal" />
      {/*<div className="container">*/}
      <div className={classes.info}>
        {/*<h2>Please keep in mind</h2>*/}
        {/*<p>Your bank may set limits on transactions made by card.</p>*/}
        {/*<List*/}
        {/*  marker='arrow'*/}
        {/*  items={[*/}
        {/*    'You can contact your bank to solve the problem',*/}
        {/*    'Deposit your account by bank transfer'*/}
        {/*  ]}*/}
        {/*  customClass={classes.list}*/}
        {/*/>*/}
      </div>
      <div className={classes.content}>
        <h1>{Resource("FundsWithdrawal")}</h1>
        <Panel customClass={classes.panel}>
          <div className={classes.action}>
            {/*action*/}
            <Currency
              column
              order
              className={classes.currency}
              amount={currentFormatting(props.person.currentBalance.value)}
              text={[Resource("AccountSumAvailable")]}
              loadId={PERSON_DATA_SUCCESS}
            />
            <IconButton icon={iconPlus} link={"/depositing"}>
              {Resource("DepositAccountMobile")}
            </IconButton>
          </div>

          <div className={classes.tabs}>
            <TabsComponent
              id={"withdrawalTabs"}
              customClasses={classes}
              onTabClick={() => console.log("Click handler to button for tabs")}
            >
              {cardActive && (
                <TabComponentItem
                  label={
                    <>
                      <span
                        style={{
                          order:
                            props.language.langActive === "EN" ||
                            props.language.langActive === "DE"
                              ? 1
                              : 0,
                        }}
                      >
                        {Resource("DepositTabsTitle1")}
                      </span>
                      <i>
                        <img src={logo_visa} alt="visa" /> /
                        <img src={logo_mastercard} alt="mastercard" />
                      </i>
                    </>
                  }
                >
                  {isNotice && (
                    <NoticeComponent
                      mainText={[
                        "Sorry, card payments are temporarily unavailable =(",
                        "We do our best to fix it",
                      ]}
                      descriptionText="Meantime, you can to-up your account by bank transfer. We apologize for the inconvenience"
                      image={noticeArrow}
                      imageStyle={{
                        position: "absolute",
                        right: "-25px",
                        bottom: "calc(100% - 160px)",
                      }}
                    />
                  )}

                  <div className={classes.withdrawal}>
                    <div className={classes.card_column}>
                      {props.paytools.card.toolId ? (
                        <div className={classes.card_active}>
                          <span className={classes.card_label}>
                            {Resource("DepositTabsTitle1")}
                          </span>
                          <span className={classes.card_text}>
                            ·{props.paytools.card.toolName}
                          </span>
                        </div>
                      ) : (
                        <div className={classes.card}>No card</div>
                      )}
                    </div>
                    <div className={classes.fild_column}>
                      <div className={classes.fild}>
                        <FieldsWithButton
                          className={classes.withdrawal_fild}
                          fields={props.withdrawalReducer.fields.cardWithdrawal}
                          onSubmit={submitWithdrawalCard}
                          loading={
                            props.loading[DEPOSITING_GET_URL_SUCCESS] ||
                            props.loading[VERIFF_SESSION_SUCCESS]
                          }
                          btnText={Resource("SendRequest")}
                          defValue={props.withdrawalReducer.valueField}
                          offline={!props.paytools.card.toolId}
                          disabledField={!props.paytools.card.toolId}
                          height={!props.paytools.card.toolId ? "50px" : "auto"}
                        />
                      </div>
                      <div className={classes.help}>
                        {/* {props.paytools.card.toolId &&
                          "The minimum withdrawal amount is €50."} */}
                      </div>
                    </div>
                  </div>
                </TabComponentItem>
              )}

              <TabComponentItem
                label={Resource("DepositTabsTitle2")}
                // disabled={props.person.veriffStatusId === VERIFY_CONFIRM}
              >
                {props.person.veriffStatusId === VERIFY_CONFIRM ? (
                  //  <BankOff />
                  <FieldsWithButton
                    fields={props.withdrawalReducer.fields.bankWithdrawal}
                    onSubmit={submitWithdrawalBank}
                    loading={
                      props.loading[WITHDRAWAL_REQUEST_SUCCESS] ||
                      props.loading[VERIFF_SESSION_SUCCESS]
                    }
                    btnText={Resource("SendRequest")}
                    defValue={props.withdrawalReducer.valueField}
                    offline={isNotice}
                    height={isNotice ? "64px" : "auto"}
                  />
                ) : !props.isFullFields ? (
                  <ModalButton
                    isOpen={!props.modalDepositingVeriff.isOpen}
                    id={props.modalDepositingVeriff.id}
                    layout={
                      <VerifyBanner
                        name={props.person.firstName}
                        loading={disableVerifBtn}
                        className={classes}
                      />
                    }
                    addClass={classes.button}
                  />
                ) : (
                  <VerifyBanner
                    name={props.person.firstName}
                    loading={disableVerifBtn}
                    className={classes}
                    onClickBanner={() => {
                      setVerifBtnStatus(true);
                      props.getVeriffSession({
                        personId: props.person.id,
                        setVerifBtnStatus,
                        firstName: props.person.firstName,
                        lastName: props.person.lastName,
                      });
                    }}
                  />
                )}

                {/* <OnlyMobile point={768}> */}
                {/* <span className={classes.description} dangerouslySetInnerHTML={{__html: Resource('DepositDescriptionBankMobile')}}/> */}
                {/* </OnlyMobile>  */}
              </TabComponentItem>
            </TabsComponent>
          </div>

          <div className={classes.footer}>
            <TabComponentPortal id={"withdrawalTabs"}>
              <TabComponentItem>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: Resource("DepositTabsTitle3"),
                  }}
                ></div>
              </TabComponentItem>

              <TabComponentItem>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: Resource("DepositTabsTitle4"),
                  }}
                />
              </TabComponentItem>
            </TabComponentPortal>
          </div>
        </Panel>

        <div>
          {props.tableData.withdrawalPayments.length > 0 && (
            <Heading
              title={Resource("WithdrawalHistory")}
              link={Resource("NextMoreText")}
              to="/operations-history"
              className={classes.heading}
            />
          )}

          <TableComponent
            headers={props.tableData.headers}
            body={props.tableData.withdrawalPayments}
            gridLayout={[0.8, 0.8, 0.3, 0.3]}
            tableMediaPoint={768}
            tableMediaGrid={[1, 0.3]}
            getDataHandler={props.getWithdrawalNew}
            renderRows={10}
            desc={"dateOperation"} // По возрастанию
            // asc={'dateOperation'} // По убыванию
            light
            classValue={classes.value}
          />
        </div>
      </div>

      <PaymentIframe
        modal={props.modal["modalPayment"]}
        pay={props.payData}
        closeHandler={() => {
          console.log("pressed close modal");
          props.getPersonData();
          props.getWithdrawal();
        }}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  const fields = state.veriff.fields;
  const fieldsValues = [
    fields.verifyCountry.value,
    fields.verifyCity.value,
    fields.verifyAddress.value,
    fields.verifyZip.value,
    fields.prefix.value,
    fields.phone.value,
  ];

  return {
    person: state.person,
    language: state.language,
    withdrawalReducer: state.WithdrawalReducer,
    verifyReducer: state.VerifyReducer,
    tableData: state.WithdrawalReducer.withdrawalTable,

    modal: state.modals,
    payData: state.depositing.payData,
    depositingData: state.depositing.data,
    valuesForm: getFormValues("DepositingFormCard")(state),
    isValid: isValid("DepositingFormCard")(state),
    modalVeriff: state.modals.modalVeriff,
    modalDepositingVeriff: state.modals.modalDepositingVeriff,
    fieldsVerify: state.veriff.fields,
    selects: state.forms.selects,
    loading: state.loading,
    veriff: state.veriff,
    isFullFields: fieldsValues.every((value) => !!value),
    layoutData: state.LayoutReducer,
    paytools: state.paytoolsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWithdrawalNew: () => dispatch(getWithdrawalNew()),

    getPersonData: () => dispatch(getPersonData()),
    saveRequiredSettings: (settings) =>
      dispatch(saveRequiredSettings(settings)),
    changeStatusFull: (status) =>
      dispatch({ type: DEPOSITING_CHANGE_STATUS, payload: status }),
    getVeriffSession: (params) => dispatch(getVeriffSession(params)),
    onToggleModal: (id, isOpen) =>
      dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
    toggleVeriffPanel: () => dispatch(toggleVeriffPanel()),
    saveRequiredVeriff: (veriffModel) =>
      dispatch(saveRequiredVeriff(veriffModel)),
    getDepositingUrl: (orderPrice) => dispatch(getDepositingUrl(orderPrice)),
    getPersonSettings: () => dispatch(getPersonSettings()),
    withdrawalRequest: (personId, amount, paytoolId) =>
      dispatch(withdrawalRequest(personId, amount, paytoolId)),
    paytoolsRequest: (personId) => paytoolsRequest(dispatch, personId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalPage);
