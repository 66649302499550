import React, { Component } from 'react';
import { connect } from 'react-redux';
import RenderTabs from '../components/UI/RenderTabs';
import { OPERATIONS_TABLE_SUCCESS } from '../redux/constants/operations/operations.constants';
import { getOperations } from '../redux/actions/operations/operations.actions';
import GridTables from '../components/Blocks/GridTables/GridTables';
import { Resource } from '../redux/actions/lang.actions';
import DocumentTitle from '../components/UI/DocumentTitle';


class Operations extends Component {

    componentWillMount() {
        this.props.getOperations()

    }

    render() {
        return (
            <main className="history">
                <DocumentTitle title='History' />
                <h1 className="container">{Resource('History')}</h1>
                <section className="tabs js-tabs">
                    <RenderTabs id='operationsTabs'>
                        <GridTables data={this.props.tableAll} loading={this.props.loading} />
                        <GridTables data={this.props.tableDeposit} loading={this.props.loading} />
                        <GridTables data={this.props.tableWithdrawal} loading={this.props.loading} />
                        <GridTables data={this.props.tableIncome} loading={this.props.loading} />
                        <GridTables data={this.props.tableInvesting} loading={this.props.loading} />
                    </RenderTabs>
                </section>
            </main>
        )
    }
}


const mapStateToProps = state => {

    return {
        person: state.person,
        loading: state.loading[OPERATIONS_TABLE_SUCCESS],
        tableAll: state.operations.operationsTable.all,
        tableDeposit: state.operations.operationsTable.deposit,
        tableWithdrawal: state.operations.operationsTable.withdrawal,
        tableIncome: state.operations.operationsTable.income,
        tableInvesting: state.operations.operationsTable.investing,
        lang: state.language.langActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOperations: () => dispatch(getOperations())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Operations)