import React, { useState } from 'react'
import Chart from '../../UI/Chart'
import { currentFormatting } from '../../../redux/utils/formatting'
import Loader from '../../UI/Loader'
import { STRATEGY_DATA_SUCCESS } from '../../../redux/constants/profile/strategys.constants'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { addProductId } from '../../../redux/actions/profile/strategys.actions'
import { Resource } from '../../../redux/actions/lang.actions'
import CountUp from 'react-countup'
import HelperLink from '../../UI/Modal/HelperLink'

const Information = ({ dataProduct, id, idProduct, addProductId }) => {
    const { percent, helper, risk, description, nameplate, monthMin, monthMax, amountMin, amountMax } = dataProduct[id]

    useEffect(() => {
        addProductId(idProduct[id])
    }, [idProduct, id, addProductId])

    const renderRiskInfomation = () => {
        return Object.keys(risk.percent).map((item, i) => {
            return <li key={risk.percent[item] + i} className={`rank rank-${item.toLowerCase()}`}>{risk.percent[item]}%&nbsp;–&nbsp;{item}</li>
        })
    }

    const [openInfo, setOpenInfo] = useState(false);
    const toggleInfo = () =>{
        setOpenInfo(!openInfo);
    }

    return (
        <>
            <div className="strategy-info">
            <div className="strategy-info__link strategy-info__link-first" onClick={toggleInfo}>
                    {Resource('NextMoreText')}
                </div>
                <HelperLink keyHelper={id} className='strategy-info__link strategy-info__link-second'>
                    {Resource('NextMoreText')}
                </HelperLink>
                <div className={!openInfo ? "strategy-info__wrapper" : "strategy-info__wrapper strategy-info__wrapper--active"}>
                    <div className="strategy-info__panel">
                        <div className="strategy-info__bb">
                            { 
                            nameplate && <div className="strategy-info__bbg">
                                    <div className="strategy-info__icon"></div><span>{nameplate}</span>
                                </div>
                            }
                        </div>
                        <div className="strategy-info__more">
                            <HelperLink keyHelper={id} className='strategy-info__more'> {Resource('NextMoreText')}</HelperLink>
                        </div>
                    </div>
                    <div className="strategy-info__text">
                        {Resource(description)}
                    </div>
                </div>
            </div>

{/* 
            <ul className="strategy-spec">
                <li className="strategy__desc">
                    <p>
                        {Resource(description)}
                        <HelperLink keyHelper={id} className='strategy__more'> {Resource('NextMoreText')}...</HelperLink>
                    </p>
                </li>
                <HelperLink keyHelper={id} className='strategy__desc--mobile'>{Resource('CurrentAnnualRate')}</HelperLink>
            </ul>
            { nameplate && <span className='strategy__nameplate'>{nameplate}</span> } */}
        </>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.language.langActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProductId: (idProduct) => dispatch(addProductId(idProduct))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Information)