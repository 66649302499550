import React from 'react'
import { connect } from 'react-redux'

const Loader = ({ children, loading, loadId, data, reload }) => {

    return (
        <>
            {
                reload
                    ? !!loading[loadId]
                        ? <div className="spinner"></div>
                        : children
                    : !String(data) && !!loading[loadId]
                        ? <div className="spinner"></div>
                        : children
            }
        </>
    )
}

const mapStateToProps = ({ loading }) => {
    return {
        loading
    }
}

export default connect(mapStateToProps, null)(Loader)