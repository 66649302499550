import React from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import { changeShowPassword } from '../../../redux/actions/settings/settings.actions'
import { Resource } from '../../../redux/actions/lang.actions'
import { renderValidation } from './Validation'
import { statusPassword } from '../../../redux/actions/create-account/account.actions'

const renderField = ({ input, id, type, className, placeholder, disabled, meta: { touched, error }, autofill }) => {
    return (
        <>
            <input
                {...input}
                id={id}
                type={type}
                className={[className, touched && (error ? 'invalid' : null)].join(' ')}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete={autofill ? 'new-password' : ''}
            />
            {touched && (error && <div className='invalid'>{error}</div>)}
        </>
    )
}

const RenderPassword = ({ data: { id, name, type, required, label, placeholder, value, edit, description, validate, complexity, autofill }, changeShowPassword, password, valid, statusPassword }) => {
 
    return (
        <div className="form__group" style={complexity && complexity.show ? { marginBottom: '40px' } : {}}>
            {label && <label htmlFor="settingsPassNew" className="form__label">{Resource(label)}</label>}
            <div className="form__icon">
                <span className={['form__icon__image toggle__password', !password[id] ? '' : 'show'].join(' ')} onClick={() => changeShowPassword(id, password[id])}></span>
                <Field
                    component={renderField}
                    autofill={autofill}
                    id={id}
                    name={name}
                    type={!password[id] ? type : 'text'}
                    className={'field field__input'}
                    placeholder={placeholder}
                    validate={
                        renderValidation({
                            required: validate?.required,
                            email: validate?.email,
                            only: validate?.only,
                            minLength: validate?.minLength,
                            maxLength: validate?.maxLength,
                            match: validate?.match
                        })
                    }
                    onChange={(event, newValue, previousValue, id) => !!complexity ? statusPassword(id, true, newValue, complexity.classPass, complexity.status) : false}
                />
                {
                    complexity && complexity.show
                        ? <div className={["form__status", complexity.classPass].join(' ')}>
                            <span className="form__status__text">{Resource(complexity.status)}</span>
                            <div>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        : null
                }
            </div>
            {description && <span className="form__description">{Resource(description)}</span>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        password: state.forms.passowrdShow,
        lang: state.language.langActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeShowPassword: (id, show) => dispatch(changeShowPassword(id, show)),
        statusPassword: (id, show, value, classPass, status) => dispatch(statusPassword(id, show, value, classPass, status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderPassword)