import React, {Component, PureComponent, useState} from 'react'
import { connect } from 'react-redux'
import {
    fixedValues,
    createPortfolio,
    setCurrentValue,
    getProductList
} from '../../../redux/actions/profile/strategys.actions'
import { STRATEGY_DATA_SUCCESS, STRATEGY_ID, CREATE_PORTFOLIO_SUCCESS } from '../../../redux/constants/profile/strategys.constants'
import RenderTabs from '../../UI/RenderTabs'
import Information from './Information'
import Calculator from './Calculator'
import Profit from './Profit'
import {PERSON_DATA_SUCCESS, VERIFY_CONFIRM} from '../../../redux/constants/person.constants'
import Loader from '../../UI/Loader'
import { Resource } from '../../../redux/actions/lang.actions'
import LoaderButton from '../../UI/LoaderButton'
import { toast } from 'react-toastify'
import Modal from '../../UI/Modal/Modal'
import ModalButton from '../../UI/Modal/ModalButton'
import ModalConfirm from '../../UI/Modal/ModalConfirm'
import classes from "../../../update/page/Profile/profile.module.scss";
import List from "../../../update/components/List/List";
import Currency from "../../../update/components/сurrency/Currency";
import {currencyFormatProfit, currentFormatting} from "../../../redux/utils/formatting";
import {isValid} from "redux-form";
import DepositVeriffForm from "../../UI/formControls/veriff/depositVeriff.form";
import VeriffIframe from "../../UI/statusVeriff/VeriffIframe";
import {getVeriffSession, saveRequiredVeriff} from "../../../redux/actions/veriff/veriff.actions";

const CalculatorVeriff = (props) => {
    const [disableVerifBtn, setVerifBtnStatus] = useState(false)
    
    const sumbitSaveVeriff = (values) => {
        setVerifBtnStatus(true)
        
        const { prefix } = props.selects
        props.saveRequiredVeriff({
            veriffFirstName: values['veriffFirstName'],
            veriffLastName: values['veriffLastName'],
            prefix: prefix !== undefined ? prefix.id : props.fields.prefix.value,
            phone: props.fields.phone.value,
            personId: props.person.id,
            setVerifBtnStatus
        })
    }
    
    return (
      <>
          {/*<Modal*/}
          {/*  isOpen={props.isOpen}*/}
          {/*  id={props.id}*/}
          {/*  addClass={'modal__depositing'}*/}
          {/*  setValue={props.setValue}*/}
          {/*  isForm={false}*/}
          {/*>*/}
          {/*    <div className="veriff-action">*/}
          {/*        <h2 className="veriff__title">{Resource('VeriffInfoTitle')}</h2>*/}
          {/*        <div className="veriff-wrapper">*/}
          {/*            <div className="veriff-info">*/}
          {/*                <p>{Resource('VeriffInfoDesc')}</p>*/}
          {/*                <h3>{Resource('VeriffInfoQuest')}</h3>*/}
          {/*                <ul>*/}
          {/*                    <li>{Resource('VeriffInfoAsk1')}</li>*/}
          {/*                    <li>{Resource('VeriffInfoAsk2')}</li>*/}
          {/*                    <li>{Resource('VeriffInfoAsk3')}</li>*/}
          {/*                </ul>*/}
          {/*            </div>*/}
          {/*            <DepositVeriffForm*/}
          {/*              onSubmit={sumbitSaveVeriff.bind(this)}*/}
          {/*              disableVerifBtn={disableVerifBtn}*/}
          {/*              //toggleVeriffPanel={props.toggleVeriffPanel}*/}
          {/*              fields={props.fields}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*    </div>*/}
          
          {/*</Modal>*/}
    
        {/*<VeriffIframe modal={props.modal} />*/}
        {/*<div id="veriff-root"/>*/}
    </>
    )
}

class Strategy extends Component {

    state = {
        disableVerifBtn: false
    }
    
    getFirstPayDate = () => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 7);
        return currentDate.toLocaleDateString()
    }
    
    componentDidMount() {
        this.props.getProductList()
    }

    render() {
        return (
            <div className={["calculator", this.props.customClass?.calculator].join(' ')}>
                <div className={['calculator__columns', !this.props.infoDisabled ? 'columns_2' : ''].join(' ')}>
                    {
                        !this.props.infoDisabled && <div className='calculator__info'>
                            <h2>{Resource('CalculatorInfoTitle')}</h2>
                            <p>{Resource('CalculatorInfoDescription')}</p>
                            <List
                              title={Resource('CalculatorListTitle')}
                              marker='arrow'
                              items={[
                                  Resource('CalculatorListItem1'),
                                  Resource('CalculatorListItem2'),
                                  this.props.lang !== 'DE' && Resource('CalculatorListItem3')
                              ]}
                            />
                        </div>
                    }
                    <div className="calculator__column_right box card">
                        <Currency
                          order
                          small
                          primary
                          amount={currentFormatting(this.props.person.currentBalance.value)}
                          text={[Resource('AccountSumAvailable')]}
                          loadId={PERSON_DATA_SUCCESS}
                          className='calculator__balance'
                        />
                        <h1 className='calculator__title'>{Resource('StrategyTitle')}</h1>
                        <Loader loadId={STRATEGY_DATA_SUCCESS}>
                            <div className="tabs js-tabs">
                                <RenderTabs id='strategyTabs' dataProduct={this.props.strategys} idProduct={STRATEGY_ID}>
                                    <Information id={'classic'} dataProduct={this.props.strategys} idProduct={STRATEGY_ID} />
                                    <Information id={'balanced'} dataProduct={this.props.strategys} idProduct={STRATEGY_ID} />
                                    {/*<Information id={'special'} dataProduct={this.props.strategys} idProduct={STRATEGY_ID} />*/}
                                </RenderTabs>
                            </div>
                            <Calculator 
                              data={this.props.strategys}
                              idProduct={this.props.strategys.activeProductId}
                              fixedValues={this.props.fixedValues} 
                              setCurrentValue={this.props.setCurrentValue}
                            />
                        </Loader>
                        <div className="calculator-total">
                            <Profit 
                              data={this.props.strategys} 
                              idProduct={this.props.strategys.activeProductId}
                            />
                            <div className='columns'>
                                <div className="calculator-date">
                                    <span className='calculator-date__title'>{Resource('WeeklyInterestPay')}</span>
                                    <span className='calculator-date__value'>{Resource('FirstPayment')} {this.getFirstPayDate()}</span>
                                </div>
                                {
                                    this.props.person.veriffStatusId === VERIFY_CONFIRM
                                        ? <LoaderButton
                                        type='button'
                                        onClick={event => {
                                            if(this.props.isValidCalculator) {
                                                // Создание портфеля
                                                event.preventDefault();
                                                this.props.createPortfolio({
                                                    startSum: this.props.dynamicValues.customAmount ?? this.props.dynamicValues.startSum,
                                                    term: this.props.dynamicValues.customTerm ?? this.props.dynamicValues.term,
                                                    productId: this.props.strategys.activeProductId
                                                })
                                            } else {
                                                console.log('Валидация не прошла')
                                            }
                                        }}
                                        addClass='button_primary'
                                        disabled={true}
                                        loading={this.props.loading[CREATE_PORTFOLIO_SUCCESS]}
                                      >{Resource('CreatePortfolio')}</LoaderButton>
                                        : !this.props.isFullFields
                                            ? <ModalButton
                                                type="submit"
                                                isOpen={this.props.isValidCalculator && !this.props.modalDepositingVeriff.isOpen}
                                                id={this.props.modalDepositingVeriff.id}
                                                // disabled={!this.props.disableVerifBtn}
                                                // addClass='button_primary'
                                                addClass='button_primary'
                                                button
                                                >{Resource('IdentifyYourself')}</ModalButton>
                                            : <LoaderButton
                                                type="button"
                                                disabled={!this.props.disableVerifBtn}
                                                addClass={'button_primary'}
                                                onClick={() => {
                                                    this.props.setVerifBtnStatus(true)
                                                    this.props.getVeriffSession({
                                                        personId: this.props.person.id, 
                                                        firstName: this.props.person.firstName, 
                                                        lastName: this.props.person.lastName,
                                                        setVerifBtnStatus: this.props.setVerifBtnStatus
                                                    })
                                            }}>{Resource('IdentifyYourself')}</LoaderButton>
                                    }
                            </div>
                        </div>
                    </div>
                </div>

                {/*<CalculatorVeriff*/}
                {/*  isOpen={this.props.modalDepositingVeriff.isOpen}*/}
                {/*  id={this.props.modalDepositingVeriff.id}*/}
                {/*  setValue={this.props.fieldsVeriff}*/}
                {/*  modal={this.props.modalVeriff}*/}
                {/*  fields={this.props.fieldsVeriff}*/}
                {/*  saveRequiredVeriff={this.props.saveRequiredVeriff}*/}
                {/*  selects={this.props.selects}*/}
                {/*  person={this.props.person}*/}
                {/*/>*/}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const fields = state.veriff.fields
    const fieldsValues = [
        fields.verifyCountry.value,
        fields.verifyCity.value,
        fields.verifyAddress.value,
        fields.verifyZip.value,
        fields.prefix.value,
        fields.phone.value
    ]
    
    return {
        form: state.form,
        strategys: state.strategys,
        profile: state.profile,
        person: state.person,
        dynamicValues: state.strategys.dynamicValues,
        lang: state.language.langActive,
        loading: state.loading,
        modal: state.modals.modalCreatePortfolio,
        isValidCalculator: isValid('CalculatorForm')(state),
        modalVeriff: state.modals.modalVeriff,
        modalDepositingVeriff: state.modals.modalDepositingVeriff,
        fieldsVeriff: state.veriff.fields,
        selects: state.forms.selects,
        isFullFields: fieldsValues.every((value) => !!value),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fixedValues: (id, value, idSlider) => dispatch(fixedValues(id, value, idSlider)),
        setCurrentValue: (value, strategy) => dispatch(setCurrentValue(value, strategy)),
        createPortfolio: ({ ...params }) => dispatch(createPortfolio({ ...params })),
        saveRequiredVeriff: veriffModel => dispatch(saveRequiredVeriff(veriffModel)),
        getVeriffSession: (personData) => dispatch(getVeriffSession(personData)),
        getProductList: () => dispatch(getProductList())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Strategy)