import { PARTNER_DATA_FAILURE, PARTNER_DATA_SUCCESS } from "../../constants/partner/partner.constants"

const initialState = {
    socialLinks: {
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
        twitter: 'https://twitter.com/home?status=',
        linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
        telegram: 'https://telegram.me/share/url?url=',
        whatsapp: 'https://api.whatsapp.com/send?text='
    },
    partnerTables: {
        replenishmentInformation: {
            id: 'partner_deposit',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                deposit: {
                    title: 'Income', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-dep'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        },
        registrationInformation: {
            id: 'partner_registr',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                deposit: {
                    title: 'Income', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-dep'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        }
    }
}

const partner = (state = initialState, action) => {
    switch (action.type) {
        case PARTNER_DATA_SUCCESS:

            const getDataTable = (array) => {
                return array.map(({ id, operations, deposit, status, dateOperation }) => {
                    return {
                        id,
                        operations: operations === 8
                            ? 'CashbackForInvestments'
                            : operations === 9
                                ? 'CashbackForFriends'
                                : '',
                        deposit,
                        status: status === 64
                            ? 'STATUS_005'
                            : status === 63
                                ? 'STATUS_004'
                                : status === 65
                                    ? 'STATUS_006'
                                    : '',
                        dateOperation
                    }
                })
            }

            return {
                ...state,
                ...action.payload,
                partnerTables: {
                    ...state.partnerTables,
                    registrationInformation: {
                        ...state.partnerTables.registrationInformation,
                        body: getDataTable(action.dataTable.registrationInformation)
                    },
                    replenishmentInformation: {
                        ...state.partnerTables.replenishmentInformation,
                        body: getDataTable(action.dataTable.replenishmentInformation)
                    }
                }
            }
        case PARTNER_DATA_FAILURE:
            return {
                ...state,
                ...action.error
            }
        default:
            return state
    }
}

export default partner
