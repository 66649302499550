import { requestGet, errorHandler } from "../../api/request"
import { LOADING } from "../../constants/loading.constants"
import { OPERATIONS_TABLE_SUCCESS, OPERATIONS_TABLE_FAILURE } from "../../constants/operations/operations.constants"

export const getOperations = () => {
    return dispatch => {
        requestGet('Person/GetOperationsHistory')
            .then(resp => {
                dispatch({
                    type: OPERATIONS_TABLE_SUCCESS,
                    payload: {
                        all: resp.data.allOperations,
                        deposit: resp.data.depositOperations,
                        withdrawal: resp.data.withdrawOperations,
                        income: resp.data.incomeOperations,
                        investing: resp.data.investOperations
                    }
                })
                dispatch({ type: LOADING, id: OPERATIONS_TABLE_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: OPERATIONS_TABLE_SUCCESS, loading: false })
                dispatch({ type: OPERATIONS_TABLE_FAILURE, error })
            })
        dispatch({ type: LOADING, id: OPERATIONS_TABLE_SUCCESS, loading: true })
    }
}