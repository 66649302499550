import {
  DEPOSITING_DATA_SUCCESS,
  DEPOSITING_DATA_FAILURE,
  DEPOSITING_DATA_SAVE_SUCCESS,
  DEPOSITING_DATA_SAVE_FAILURE,
  DEPOSITING_GET_URL_SUCCESS,
  DEPOSITING_BANK_STATUS_SUCCESS,
  DEPOSITING_BANK_STATUS_FAILURE,
  DEPOSITING_CHANGE_STATUS,
  DEPOSITING_SET_VALUE, DEPOSITING_GET_URL_PROCESS,
} from "../../constants/depositing/depositing.constants";
import {requestPost, errorHandler, requestGet} from "../../api/request";
import { LOADING } from "../../constants/loading.constants";
import { reset } from "redux-form";
import { toast } from "react-toastify";
import { MODAL_IS_OPEN } from "../../constants/modals.constants";
import { Resource } from "../lang.actions";
import { getLayoutData } from "../../../update/redux/layout-reducer";
import { getPersonSettings } from "../settings/settings.actions";
import api from "../../api/api";
import axios from "axios";
import {history} from "../../history";

export const getDepositingData = () => {
  return (dispatch) => {
    requestPost("Payment/GetDepositingData")
      .then((resp) => {
        dispatch({
          type: DEPOSITING_DATA_SUCCESS,
          payload: resp.data,
          dataTable: resp.data.paymentsInformation,
        });
        dispatch({
          type: LOADING,
          id: DEPOSITING_DATA_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error);
        dispatch({ type: DEPOSITING_DATA_FAILURE, error });
        dispatch({
          type: LOADING,
          id: DEPOSITING_DATA_SUCCESS,
          loading: false,
        });
      });
    dispatch({ type: LOADING, id: DEPOSITING_DATA_SUCCESS, loading: true });
  };
};

export const saveRequiredSettings = ({ ...params }) => {
  const {
    depositingCountry,
    depositingCity,
    depositingAddress_1,
    zip,
    prefix,
    phone,
    depositValue,
  } = params;

  let settings = {
    Country: Number(depositingCountry) || null,
    City: depositingCity || "",
    Address: depositingAddress_1 || "",
    Zip: zip || "",
    PhonePrefix: String(prefix) || null,
    Phone: phone || "",
  };

  return (dispatch) => {
    requestPost("Payment/SaveRequiredSettings", settings)
      .then((resp) => {
        // dispatch(getDepositingUrl(depositValue))
        dispatch({ type: DEPOSITING_DATA_SAVE_SUCCESS });
        dispatch({ type: DEPOSITING_CHANGE_STATUS, payload: false });
        dispatch({
          type: LOADING,
          id: DEPOSITING_DATA_SAVE_SUCCESS,
          loading: false,
        });
        dispatch(getDepositingData());
      })
      .catch((error) => {
        toast.error(Resource("PhoneAlreadyReg"));
        errorHandler(dispatch, error);
        dispatch({ type: DEPOSITING_DATA_SAVE_FAILURE, error });
      });
    dispatch({
      type: LOADING,
      id: DEPOSITING_DATA_SAVE_SUCCESS,
      loading: true,
    });
  };
};

export const getDepositingUrl = (orderPrice) => {
  let amountFormat = orderPrice.replace(/[^0-9\.]/, "");
  return (dispatch) => {
    requestPost(`Payment/GetDepositingUrl?orderPrice=${amountFormat}`)
      .then((resp) => {
        dispatch({
          type: LOADING,
          id: DEPOSITING_GET_URL_SUCCESS,
          loading: false,
        });
        dispatch(reset("contentPayCard"));
        if (resp.data.success) {
          dispatch({ type: MODAL_IS_OPEN, isOpen: true, id: "modalPayment" });
          dispatch({ type: DEPOSITING_GET_URL_SUCCESS, payData: resp.data });
        } else {
          // Если сумма пополнений превышена за один месяц
          dispatch(getLayoutData(resp.data));
          dispatch({
            type: MODAL_IS_OPEN,
            isOpen: true,
            id: "modalLimitDeposit",
          });
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error);
        dispatch({ type: DEPOSITING_DATA_FAILURE, error });
        dispatch({
          type: LOADING,
          id: DEPOSITING_GET_URL_SUCCESS,
          loading: false,
        });
        dispatch(reset("contentPayCard"));
      });
    dispatch({ type: LOADING, id: DEPOSITING_GET_URL_SUCCESS, loading: true });
  };
};

export const checkPaymentStatus = () => {
  return (dispatch) => {
    requestPost(`Bank/CheckPaymentStatus`)
      .then((resp) => {
        dispatch({
          type: LOADING,
          id: DEPOSITING_BANK_STATUS_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error);
        dispatch({ type: DEPOSITING_BANK_STATUS_FAILURE, error });
      });
    dispatch({
      type: LOADING,
      id: DEPOSITING_BANK_STATUS_SUCCESS,
      loading: true,
    });
  };
};

{
  /* TODO: Отключил функционал новой оплаты */
}

export const sendPaymentFormData = (data) => {
  return (dispatch) => {
    return requestPost("Payment/PaymentCardDirectRequest", data)
        .then(response => {
          if(response.data.result === 'success') {
            // success
            // При успешной обработке - редиректим на страницу процесса обработки платежа
            // if(response.data?.message?.outputRedirectToUrl) {
            //   window.location.replace(response.data?.message?.outputRedirectToUrl);
            // } else {
            //   toast.error(Resource(response.data.message.errorMessage ?? Resource('ERROR_003')))
            // }
            
            // history.push('/paymentProcess')
            
            history.push(`/webform/result/${response.data?.message?.orderMerchantId}`);
          } else {
            // error
            // Показываем уведомление об ошибке
            // throw new Error(Resource(response.data.message.errorMessage))
            toast.error(Resource(response.data.message.errorMessage))
          }
        })
        .catch(error => {
          toast.error(Resource('ERROR_003'))
          dispatch({ type: DEPOSITING_BANK_STATUS_FAILURE, error });
        })
  };
};
  
  
export const checkPaymentStatusWithProvider = (orderId) => {
  return (dispatch) => {
    requestPost(`Payment/PaymentCheckStatus?merchantId=${orderId}`)
        .then((response) => {
          if(response.data.result === 'success') {
            
            // if(response.data?.message?.outputRedirectToUrl) {
            //   window.location.replace(response.data?.message?.outputRedirectToUrl);
            // } else {
            //   toast.error(Resource(response.data.message.errorMessage ?? Resource('ERROR_003')))
            // }
            
            switch (response.data?.message?.orderState) {
              case 'APPROVED': {
                history.push('/paymentSuccess');
                break;
              }
              case 'DECLINED': {
                history.push(`/paymentError`, response.data.message);
                break;
              }
              case 'ERROR': {
                history.push(`/paymentError`, response.data.message);
                break;
              }
              case 'PROCESSING': {
                if(response.data?.message?.outputRedirectToUrl) {
                  window.location.replace(response.data?.message?.outputRedirectToUrl);
                }
                break;
              }
              default: {
                break;
              }
            }
          } else {
            history.push(`/paymentError`, response.data.error)
          }
        })
        .catch((error) => {
          errorHandler(dispatch, error);
          history.push('/paymentError', error)
          dispatch({ type: DEPOSITING_BANK_STATUS_FAILURE, error });
        });
  };
};

export const depositingSetValue = (amountValue) => {
  return (dispatch) => {
    dispatch({ type: DEPOSITING_SET_VALUE, amountValue });
  };
};
