import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import RenderCheckbox from '../../formComponents/RenderCheckbox'
import RenderSelect from '../../formComponents/RenderSelect'
import { Resource } from '../../../../redux/actions/lang.actions'
import LoaderButton from '../../LoaderButton'
import { SETTINGS_SAVE_SUCCESS } from '../../../../redux/constants/settings/settings.constants'

const MainSettingsForm = props => {
    
    const fieldID = props.fields[props.form]

    useEffect(() => {
        for (let key in fieldID)
            props.change(fieldID[key].id, fieldID[key].value)
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <form id='main_button' onSubmit={props.handleSubmit} className="settings__form columns_2">
                {/* <div className="box card">
                    <h3 className="box__heading">{Resource('SystemSettings')}</h3>

                    <RenderSelect data={fieldID['settingsLang']} />
                </div> */}
                 
                <div className="box card">
                    <h3 className="box__heading">{Resource('Notifications')}</h3>

                    <RenderCheckbox data={fieldID['noticeNews']} />
                    <RenderCheckbox data={fieldID['noticeArticle']} />
                    <RenderCheckbox data={fieldID['noticeDays']} />
                    <RenderCheckbox data={fieldID['noticeWeek']} />
                    <RenderCheckbox data={fieldID['noticeMonth']} />
                </div>

            </form>

            <div className="fixed">
                <div className="container">
                    <LoaderButton
                        form="main_button"
                        disabled={true}
                        loading={props.loading[SETTINGS_SAVE_SUCCESS]}
                    >{Resource('Save')}</LoaderButton>
                </div>
            </div>
        </>
    )
}

export default reduxForm({ form: 'mainSettingsForm' })(MainSettingsForm)
