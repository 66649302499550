import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthPages from './Auth.pages';
import UnauthPages from './Unauth.pages';
import { sendRestorePassword, confirmEmailHash, redirectLoginHash } from '../../redux/actions/login/auth.actions';
import { getUrlParam } from '../../redux/utils/getUrlParam';
import {utmInit} from "../../redux/utils/utm";



const RoutingPage = (props) => {
    
    let history = useHistory()

    useEffect(() => {
        let query = props.location.search
        if (!!query && (!!getUrlParam('email', query) || getUrlParam('restore', query))) {
            !!getUrlParam('email', query)
                ? props.confirmEmailHash(getUrlParam('email', query))
                : props.sendRestorePassword(getUrlParam('restore', query))
        }
        if (!!query && (!!getUrlParam('loginHash', query))) {
            props.redirectLoginHash(getUrlParam('loginHash', query))
        }
        utmInit(props)
    }, [])

    return ( 
        <>
            {
                props.auth.auth.logged
                    ? <AuthPages />
                    : <UnauthPages />
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendRestorePassword: (hash) => dispatch(sendRestorePassword(hash)),
        confirmEmailHash: (hash) => dispatch(confirmEmailHash(hash)),
        redirectLoginHash: (hash) => dispatch(redirectLoginHash(hash))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoutingPage))