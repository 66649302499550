import React, {useState} from 'react';
import classes from './DropdownList.module.scss'

const DropdownList = props => {
  
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className={[classes.dropdown, isOpen ? [classes.open, props.customClass.open].join(' ') : ''].join(' ')}>
      <div className={[classes.button, props.customClass.button].join(' ')} onClick={() => {
        if(props.disabled) return false
          setIsOpen(!isOpen)
      }}>
        {props.icon && <img className={classes.icon} src={props.icon} alt={props.icon}/>}
        <span className={[classes.label, props.customClass.label].join(' ')}>{props.label}</span>
      </div>
      <div className={[classes.content, props.customClass.content].join(' ')}>
        {props.children}
      </div>
    </div>
  );
};

export default DropdownList;