import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { IconPlus } from "../../static/Icons/Icons";
import { Resource } from "../../../redux/actions/lang.actions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { checkProductList, checkIndividualReInvest } from "../../redux/strategys-reducer";
import "./StrategiesPanel.scss";
import { useDispatch, useSelector } from "react-redux";

const StrategiesPanel = (props) => {
  const dispatch = useDispatch();
  const joymoneyActive = useSelector(state => state.strategysReducer.status);

  useEffect(()=>{
    // checkProductList(dispatch);
    checkIndividualReInvest(dispatch, props.personId);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          swipe: true,
        }
      },
    ]
  };
    return(
    <div className="strategies">
        <h2 className="strategies__title">
        { Resource("JudicialPanelTitle") }
        </h2>
        <div className="strategies-swiper">
        <Slider {...settings}>
          <div className="strategies-swiper__slide">
            <Link className="strategies-legal" to="/judicial">
            {/* <div className="strategies-legal"> */}
                <div className="strategies-legal__boostr">
                </div>
                <div className="strategies-legal__info">
                  <span className="strategies-legal__percent">14.5%</span>
                  <span className="strategies-legal__name">{ Resource("StrategyJudicialPanelTitle") }</span>
                </div>
                <div className="strategies-legal__tagline">{ Resource("TaglineStrategyJudicialPanel") }</div>
                <div className="strategies-legal__strategy">{ Resource("JudicialSubTitle") }</div>
                <div className="strategies-legal__db">
                  <div className="strategies-legal__icon"></div>
                  <div className="strategies-legal__text">Deposit back</div>
                </div>
                {/* <div className="strategies-legal__db--disabled">
                  <div className="strategies-legal__icon--disabled"></div>
                  <div className="strategies-legal__text--disabled">No available loans</div>
                </div> */}

              <div className="strategies-legal__chart"></div>
              {/* </div> */}
            </Link>
            </div>
          <div className="strategies-swiper__slide">
            <div className={joymoneyActive ? "strategies-other" : "strategies-other strategies-other--disabled"}
                onClick={() => { joymoneyActive && props.onToggleModal(props.id, !props.isOpen)}}
            >
              <div className="strategies-other__joymoney"></div>
              <div className="strategies-other__info">
                <div className="strategies-other__percent">13%</div>
                <div className="strategies-other__name">
                  { Resource("StrategyTitleInvestClassic") }&nbsp;/ 
                  <br /> 
                  { Resource("StrategyTitleInvestBalanced")}</div>
              </div>
              <div className="strategies-other__tagline">{ Resource("TaglineStrategyOtherPanel") }</div>
              <div className="strategies-other__strategy">{ Resource("JudicialSubTitle") }</div>
              {!joymoneyActive ? 
              <div className="strategies-other__db">
                <div className="strategies-other__icon"></div>
                <div className="strategies-other__text">No available loans</div>
              </div>
              :
              <div className="strategies-legal__db">
                <div className="strategies-legal__icon-bb"></div>
                <div className="strategies-legal__text">Buy back</div>
              </div>
            }
            </div>
          </div>
          <div className="strategies-swiper__slide"></div>
        </Slider>
        </div>
      </div>
    )
};

export { StrategiesPanel };