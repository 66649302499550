import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Resource } from '../../../redux/actions/lang.actions';
import {connect} from "react-redux";
import {getPersonData} from "../../../redux/actions/person.action";
import Cookies from "js-cookie";

const VeriffSuccess = (props) => {
    
    useEffect(() => {
        // google
        window.gtag('event', 'conversion', {'send_to': 'AW-656305309/yhifCOrIofoBEJ3Z-bgC'});

        // YandexCounter
        window.ym(56965879,'reachGoal','veriff_success');

        // KTracking
        window.KTracking.reportConversion(0, 'lead', {sub_id_2: props.person.id, source: Cookies.get('utm_source') ?? null})
        
        console.log('conversion veriff');
    }, []);

    return (
        <main className="veriff-modal__iframe">
            <section>
                <div className="veriff-modal__wrapper">
                    <span className="veriff-modal__icon veriff-modal__icon--success" />


                    <img className="veriff-modal__logo" src="./image/veriff/veriff-logo.svg" alt="veriff-logo" />
                    <div className="veriff-modal__logo--site"></div>
                    <h1 className="veriff-modal__title">{Resource('ThankyouSuccessTitle')}</h1>
                    <p className="veriff-modal__description" dangerouslySetInnerHTML={{ __html: Resource('ThankyouSuccessText') }}></p>
                    <Link to="/depositing" target="_top" className="button button--small veriff-modal__button">{Resource('DepositAccount')}</Link>

                </div>
            </section>
        </main>
    );
}

const mapStateToProps = state => {
    return {
        person: state.person
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPersonData: () => dispatch(getPersonData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VeriffSuccess)