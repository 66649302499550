import React, { useEffect } from "react";
import { Resource } from "../../../redux/actions/lang.actions";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import IconButton from "../../components/Buttons/IconButton/IconButton";
import Currency from "../../components/сurrency/Currency";
import Panel from "../../components/Panel/Panel";
import iconPlus from "../../../static/image/icons/icon-plus-btn.svg";
import iconArrow from "../../../static/image/icons/icon-arrow-btn.svg";
import {
  PERSON_DATA_SUCCESS,
  VERIFY_CONFIRM,
} from "../../../redux/constants/person.constants";
import {
  currentFormatting,
  dateFormattingWithTime,
} from "../../../redux/utils/formatting";
import {
  IconInfo,
  IconStrategy,
  IconFilter,
  IconArrow,
  IconBack,
} from "../../static/Icons/Icons";
import "./JudicialPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { JudicialFilter } from "../../components/JudicialFilter/JudicialFilter";
import { PortfolioCardNew } from "../../components/PortfolioCardNew/PortfolioCardNew";
import { Link } from "react-router-dom";
import {
  filterPopupToggle,
  getJudicial,
  judicialInfoPopupToggle,
  judicialDbPopupToggle,
} from "../../redux/actions/actionsCreators";
import { FilterPopup } from "../../components/FilterPopup/FilterPopup";
import { getJudicialPortfolios } from "../../redux/judicial-reducer";
import { JudicialSlideInfo } from "../../components/JudicialSlideInfo/JudicialSlideInfo";
import { JudicialSlideDb } from "../../components/JudicialSlideDb/JudicialSlideDb";
import SpinnerLoad from "../../components/SpinnerLoading/SpinnerLoading";

const JudicialPage = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.person);
  const judicial = useSelector((state) => state.judicialReducer);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    getJudicialPortfolios(dispatch, person.id, 7);
    window.ym(56965879, "hit", "/judicial");
  }, []);

  useEffect(() => {}, [language]);

  const openFilterPopup = () => {
    dispatch(filterPopupToggle(true));
  };

  const openInfo = () => {
    dispatch(judicialInfoPopupToggle(true));
  };

  const openDb = () => {
    dispatch(judicialDbPopupToggle(true));
  };

  const setPortfolios = () => {
    return judicial.judicialInvestObjects.length > 0 ? (
      judicial.judicialInvestObjects.map(
        (portfolio) =>
          portfolio.investedObject.availableForInvestment && (
            <div key={portfolio.investedObject.id} className="portfolios__item">
              <PortfolioCardNew
                id={portfolio.investedObject.id}
                idJ={portfolio.investedObject.id}
                rulesId={7}
                percent={portfolio.investedObject.percentMax}
                monthCount={portfolio.investedObject.monthCount}
                availableForInvestment={
                  portfolio.investedObject.availableForInvestment
                }
                avgExpectedInterest={150 /* Уточнить у Володи */}
                endDate={portfolio.investedObject.endAvailableDate}
                remind={portfolio.investedObject.remind}
                totalAmount={portfolio.investedObject.totalAmount}
                currentInterest={0}
                amount={0}
                endAvailableDate={portfolio.investedObject.endAvailableDate}
                defValue={50}
                btnText={"Инвестировать"}
                label={"Сумма инвестиции"}
                judicial
                moreLink={`/judicial/${portfolio.investedObject.id}`}
                bonus={judicial.bonus}
              />
            </div>
          )
      )
    ) : (
      <SpinnerLoad />
    );
  };

  return (
    <>
      <main className="judicial">
        <DocumentTitle title={"JudicialTitle"} />

        <div className="judicial__sidebar">
          <Panel customClass="judicial__panel">
            <Currency
              column
              order
              className="judicial__currency"
              amount={currentFormatting(person.currentBalance.value)}
              text={[Resource("Balance")]}
              loadId={PERSON_DATA_SUCCESS}
            />

            <div className="judicial__action">
              <IconButton
                id={"depositing_link"}
                className="judicial__icon-button"
                column
                icon={iconPlus}
                link={"/depositing"}
              >
                {Resource("DepositAccountMobile")}
              </IconButton>
              <IconButton
                id={"withdrawal_link"}
                className="judicial__icon-button"
                column
                icon={iconArrow}
                link={"/withdrawal"}
              >
                {Resource("WithdrawFundsMobile")}
              </IconButton>
            </div>
          </Panel>

          {/* <div className="judicial-filter-sidebar">
                        <JudicialFilter />
                    </div> */}
        </div>

        <div className="judicial__content">
          <Link className="judicial__back" to={"/portfolio"}>
            <div className="judicial__back-icon">{IconBack()}</div>
            <div className="judicial__back-text">{Resource("BackButton")}</div>
          </Link>
          <h1 className="judicial__content-title">
            {Resource("JudicialTitle")}
          </h1>

          <div className="judicial__header">
            <div className="informer">
              <div className="informer__column-left">
                <div className="informer__icon-info">{IconInfo(24, 24)}</div>
                <div className="informer__text-info">
                  {Resource("JudicialGuarantee")}
                </div>
                <div className="informer__link-block">
                  <div className="informer__link-info" onClick={openDb}>
                    {Resource("JudicialGuaranteeDb")}
                  </div>
                </div>
              </div>

              <div
                id="Informer"
                className="informer__column-right"
                onClick={openInfo}
              >
                <div className="informer__icon-strategy">{IconStrategy()}</div>
                <div className="informer__link-block-strategy">
                  <div className="informer__link-strategy">
                    {Resource("JudicialMoreStrategy")}
                  </div>
                </div>
              </div>
            </div>

            {/* <div 
                            className="judicial__filter-btn"
                            onClick={ openFilterPopup }
                        >
                            <div className="filter-btn">
                                <div className="filter-btn__filter-icon">
                                    { IconFilter() }
                                </div>
                                <div className="filter-btn__title">
                                    Найти портфель
                                </div>
                                <div className="filter-btn__arrow-icon">
                                    { IconArrow() }
                                </div>
                            </div>
                        </div> */}
          </div>

          <div className="judicial__portfolios">
            <div className="portfolios">
              <div className="portfolios__list">
                {judicial.isLoadingPortfolios ? (
                  <SpinnerLoad />
                ) : (
                  setPortfolios()
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <FilterPopup /> */}
        <JudicialSlideInfo />
        <JudicialSlideDb />
      </main>
    </>
  );
};

export { JudicialPage };
