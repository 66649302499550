import React from 'react';
import classes from './LinkButton.module.scss';
import {Link} from "react-router-dom";

const LinkButton = (props) => {
  
  return (
    <>
      {
        <Link style={props.style} to={props.to} className={[classes.button, props.customClass].join(' ')} onClick={props.onClick}>
          {props.children}
        </Link>
      }
    </>
  );
};

export default LinkButton;