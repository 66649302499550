import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  baseURL: !!process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://localhost:44323/'
});

// const customAPI = {
//   // Шаблон api запроса
//   customAPINAME() {
//     return instance.get(`login/check`)
//       .then(response => response)
//       .catch(error => error.response)
//   },
// }

// export const getDateEndReinvestmentAsync = () => {
//   return instance.get('Portfolio/GetDateEndReinvestment', {
//       params: {
//           portfolioId: 1270

//       } })
//       .then(response => console.log(response))
//       .catch(error => console.log(error))
// }


export const DepositingAPI = {
  getDepositPayments() {
    return instance.post('Payment/GetDepositingData')
      .then(response => response)
      .catch(error => error.response)
  }
}


// console.log(instance.post('portfolio/delete/draft?portfolioId=1'))

