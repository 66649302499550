import React from 'react'
import Portal from './Portal'
import { connect } from 'react-redux'
import { MODAL_IS_OPEN } from '../../../redux/constants/modals.constants'
import { useEffect } from 'react'
import { Resource } from '../../../redux/actions/lang.actions'

const GraphLine = (props) => {
    return (
      <div className='graph'>
          <span className='graph__label'>{Resource('Risks')}</span>
          <ul>
              {
                  Object.keys(props.data.graph).map((cat, idx) => {
                      return <li key={idx} className={`graph__item graph__${cat}`}>{props.data.graph[cat]}% – {cat}</li>
                  })
              }
        </ul>
      </div>
    )
}

const Helper = ({ isOpen, children, onToggleModal, id, info }) => {
    
    const onClickOuterElement = event => {
        if (!event.target.closest('.helper__window') && isOpen) {
            onToggleModal(id, false)
        }
    }
    
    useEffect(() => {
        document.addEventListener('click', onClickOuterElement, false);
        isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
        return () => {
            document.removeEventListener('click', onClickOuterElement, false);
        }
    // eslint-disable-next-line
    }, [isOpen])

    return (
        <>
            {/*<i className='helper__text' onClick={() => onToggleModal(id, true)}>{children}</i>*/}
            {
                isOpen &&
                <Portal>
                    <div className={['helper', isOpen ? 'open' : null].join(' ')}>
                        <div className="helper__window">
                            <span className="helper__close" onClick={() => onToggleModal(id, false)}>×</span>
                            <span className="helper__title"><h2 dangerouslySetInnerHTML={{ __html: Resource(info.title) }}></h2></span>
                            <GraphLine data={info}/>
                            <section className="helper__text"><p dangerouslySetInnerHTML={{ __html: Resource(info.text) }}></p></section>
                        </div>
                    </div>
                </Portal>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        id: 'helper',
        isOpen: state.modals['helper'].isOpen,
        info: state.modals['helper'].activeInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Helper)
