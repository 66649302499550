export const localeMonthDate = {
	ru: {
		1: 'янв',
		2: 'фев',
		3: 'мар',
		4: 'апр',
		5: 'май',
		6: 'июн',
		7: 'июл',
		8: 'авг',
		9: 'сен',
		10: 'окт',
		11: 'ноя',
		12: 'дек'
	},
	en: {
		1: 'Jan',
		2: 'Feb',
		3: 'Mar',
		4: 'Apr',
		5: 'May',
		6: 'Jun',
		7: 'Jul',
		8: 'Aug',
		9: 'Sep',
		10: 'Oct',
		11: 'Nov',
		12: 'Dec'
	},
	es: {
		1: 'ene',
		2: 'feb',
		3: 'mar',
		4: 'abr',
		5: 'may',
		6: 'jun',
		7: 'jul',
		8: 'ago',
		9: 'sep',
		10: 'oct',
		11: 'nov',
		12: 'dic'
	},
	de: {
		1: 'Jan',
		2: 'Feb',
		3: 'Mrz',
		4: 'Apr',
		5: 'Mai',
		6: 'Jun',
		7: 'Jul',
		8: 'Aug',
		9: 'Sep',
		10: 'Okt',
		11: 'Nov',
		12: 'Dez'
	},
	it: {
		1: 'gen',
		2: 'feb',
		3: 'mar',
		4: 'apr',
		5: 'mag',
		6: 'giu',
		7: 'lug',
		8: 'ago',
		9: 'set',
		10: 'ott',
		11: 'nov',
		12: 'dic'
	}
}