import { LOGOUT_GOOGLE, AUTH_GOOGLE_SUCCESS } from "../../constants/login/auth.constants"
import jwt from 'jsonwebtoken';

export function loginGoogle(token) {
    console.log(token)
    return dispath => {
        dispath({
            type: AUTH_GOOGLE_SUCCESS,
            payload: token
        });
    }
}

export function logoutGoogle() {
    console.log("Logging out");
    return dispath => {
        dispath({
            type: LOGOUT_GOOGLE,
            payload: ""
        });
    };
}

export function UserIsValid(token) {
    console.log("the token is " + token.user);
    if (token.isAuthenticated) {
        var decodedToken = jwt.decode(token.user);
        var dateNow = new Date();
        if (decodedToken.exp > dateNow.getTime() / 1000) return true;
        else return false;
    }
    return false;
}