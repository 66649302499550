import React, { forwardRef } from 'react'
import { currentFormatting } from '../../redux/utils/formatting'
import Loader from '../UI/Loader'
import { Resource } from '../../redux/actions/lang.actions'

const Stats = forwardRef(({ className, addClass, id, data, load, reload }, ref) => {

    const cls = [
        className ? className : 'stat',
        addClass
    ]

    return (
        <li ref={ref} className={cls.join(' ')}>
            {data[id].icon ? <figure className={`stat-icon stat-icon_${data[id].icon} ${id}`}></figure> : null}
            <span className="stat-value">
                <Loader data={data[id].value} loadId={load} reload={reload}>
                    {
                        data[id].formatting
                            ? currentFormatting(data[id].value)
                            : data[id].value
                    }
                </Loader>
            </span>
            <span className={["stat-key", data[id].hiddenKey ? 'mobile-hidden' : null].join(' ')}>{Resource(data[id].valueKey)}</span>
        </li>
    )
})

export default Stats