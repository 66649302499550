import React, {useEffect, useState} from 'react'

import {reduxForm, getFormValues, isValid, change} from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import RenderSelect from '../../formComponents/RenderSelect'
import RenderPhone from '../../formComponents/RenderPhone'
import Button from '../../Button'
import { Resource } from '../../../../redux/actions/lang.actions'
import { toast } from 'react-toastify'
import {PERSON_DATA_SUCCESS, VERIFY_CONFIRM} from '../../../../redux/constants/person.constants'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { validationCurrency } from '../../../../redux/utils/formatting'
import {
  DEPOSITING_DATA_SUCCESS,
  DEPOSITING_GET_URL_SUCCESS
} from '../../../../redux/constants/depositing/depositing.constants'
import LoaderButton from '../../LoaderButton'
import logger from 'redux-logger'
import Loader from "../../Loader";
import VeriffIframe from "../../statusVeriff/VeriffIframe";
import {VERIFF_SESSION_SUCCESS} from "../../../../redux/constants/veriff/veriff.constants";
import Modal from "../../Modal/Modal";
import VeriffForm from "../veriff/veriff.form";
import ModalButton from "../../Modal/ModalButton";
import {saveRequiredSettings} from "../../../../redux/actions/depositing/depositing.actions";
import {getVeriffSession, saveRequiredVeriff, toggleVeriffPanel} from "../../../../redux/actions/veriff/veriff.actions";
import {MODAL_IS_OPEN} from "../../../../redux/constants/modals.constants";
import {CREATE_PORTFOLIO_SUCCESS} from "../../../../redux/constants/profile/strategys.constants";
import DepositVeriffForm from "../veriff/depositVeriff.form";
import {getPersonSettings} from "../../../../redux/actions/settings/settings.actions";
import {SETTINGS_SUCCESS} from "../../../../redux/constants/settings/settings.constants";

const ContentPayCard = ({ fields, fieldsVerify, handleSubmit, change, isFullFields, modal, modalVeriff, getVeriffSession, modalDepositingVeriff, getPersonSettings, valid, person, valuesForm, isValid, loading, saveRequiredVeriff, selects}) => {

  useEffect(() => {
    getPersonSettings()
  }, [])
  
  const [disableVerifBtn, setVerifBtnStatus] = useState(false)

  const sumbitSaveVeriff = (values) => {
    setVerifBtnStatus(true);
    const { prefix, verifyCountry } = selects
    const { verifyAddress, verifyCity, verifyZip, phone } = values
   
    saveRequiredVeriff({
      verifyAddress,
      verifyCity,
      verifyCountry: verifyCountry ? verifyCountry.id : fieldsVerify.verifyCountry.value,
      verifyZip,
      prefix: prefix !== undefined ? prefix.id : fieldsVerify.prefix.value,
      phone: fieldsVerify.phone.value !== phone
        ? !!phone ? (prefix !== undefined ? prefix.id : String(fieldsVerify.prefix.value)) + phone : null
        : !!phone ? (prefix !== undefined ? prefix.id : String(fieldsVerify.prefix.value)) + String(fieldsVerify.phone.value).substring(String(fieldsVerify.prefix.value).length) : null,
      personId: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      setVerifBtnStatus,
      verifyStatusId: person.veriffStatusId,
      depositValue: fields['depositValue'].value
    })
    
  }

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault()
          handleSubmit();

         !!valuesForm?.depositValue && validationCurrency(valuesForm.depositValue.replace(',', '.'), fields['depositValue'].validate.range)
        }}
        className="settings-columns columns_1"
      >
        <div className="box card">
          <h3 className="box__heading">{Resource('RefillWithCard')}</h3>
          <p className="box__text box__text_accent">{Resource('NoticeDepositingCard')}</p>
          {/*{*/}
          {/*  status*/}
          {/*    ? <div className="box__text_accent">*/}
          {/*      <div className="depositing__payment columns_3">*/}
          {/*        <RenderInput data={fields['depositingAddress_1']} />*/}
          {/*        <RenderInput data={fields['zip']} />*/}
          {/*        <RenderInput data={fields['depositingCity']} />*/}
          {/*        <RenderSelect data={fields['depositingCountry']} />*/}
          {/*        {*/}
          {/*          (!fields['phone'] || fields['phone'].value === '' || isNaN(Number(fields['phone'].value)) || !fields['prefix'] || fields['prefix'].value === '')*/}
          {/*            ? <div className={"form__group"}>*/}
          {/*              <div className="form__fields-group">*/}
          {/*                <RenderSelect data={fields['prefix']} />*/}
          {/*                <RenderInput data={fields['phone']} />*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*            : null*/}
          {/*        }*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    : !(fields['prefix'].value && fields['phone'].value && fields['depositingAddress_1'].value && fields['zip'].value && fields['depositingCity'].value && fields['depositingCountry'].value) ? <div className="spinner" /> : null*/}
          {/*}*/}

        <div className="form__group">
                <label htmlFor="depositValue" className="form__label">{Resource(fields['depositValue'].labelDown)}</label>
                <div className="withdrawal__form">
                  <RenderInput data={fields['depositValue']} />
                  {/* {
                  person.veriffStatusId === VERIFY_CONFIRM
                      ? <ModalButton
                              id={modal.id}
                              isOpen={valid}
                              button
                          >{Resource('DepositAccount')}</ModalButton>
                      : <Button type='button' onClick={() => toast.info(Resource('ProgressIdentifyDesc'))}>{Resource('DepositAccount')}</Button>
              } */}
                  
                  {
                    loading[DEPOSITING_DATA_SUCCESS]
                      ? <LoaderButton >{Resource('DepositAccount')}</LoaderButton>
                      : <>
                        {
                          person.veriffStatusId === VERIFY_CONFIRM
                            ? <LoaderButton
                              type="submit"
                              disabled={true}
                              loading={loading[DEPOSITING_GET_URL_SUCCESS]}
                            >{Resource('DepositAccount')}</LoaderButton>
                            // : <ModalButton
                            //   type="submit"
                            //   isOpen={isValid && !modalDepositingVeriff.isOpen}
                            //   id={modalDepositingVeriff.id}
                            //   // disabled={!isValid}
                            //   // addClass='button_primary'
                            //   button
                            // >{Resource('DepositAccount')}</ModalButton>

                            : !isFullFields
                              ? <ModalButton
                                type="button"
                                isOpen={isValid && !modalDepositingVeriff.isOpen}
                                id={modalDepositingVeriff.id}
                                button
                              >{Resource('DepositAccount')}</ModalButton>
                              : <LoaderButton
                                type="submit"
                                disabled={!disableVerifBtn}
                                onClick={() => {
                                  if(isValid) {
                                    setVerifBtnStatus(true)
                                    getVeriffSession({personId: person.id, setVerifBtnStatus, firstName: person.firstName, lastName: person.lastName})
                                  }
                                }}
                              >{Resource('DepositAccount')}</LoaderButton>
                        }
                      </>
                  }
                  
                </div>
              </div>
          
        </div>
        
      </form>

      <Modal
        isOpen={modalDepositingVeriff.isOpen}
        id={modalDepositingVeriff.id}
        isForm={false}
        addClass={'veriff__modal'}
        isOutside={true}
        onStart={() => {
          console.log('Open verify modal')
          window.ym(56965879,'reachGoal','Veriff_prelend_open')
        }}
      >
        <VeriffForm
          onSubmit={sumbitSaveVeriff.bind(this)}
          disableVerifBtn={disableVerifBtn}
          toggleVeriffPanel={toggleVeriffPanel}
          fields={fieldsVerify}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  const fields = state.veriff.fields
  const fieldsValues = [
    fields.verifyCountry.value,
    fields.verifyCity.value,
    fields.verifyAddress.value,
    fields.verifyZip.value,
    fields.prefix.value,
    fields.phone.value
  ]

  return {
    valuesForm: getFormValues('contentPayCard')(state),
    isValid: isValid('contentPayCard')(state),
    modalVeriff: state.modals.modalVeriff,
    modalDepositingVeriff: state.modals.modalDepositingVeriff,
    fieldsVerify: state.veriff.fields,
    selects: state.forms.selects,
    loading: state.loading,
    isFullFields: fieldsValues.every((value) => !!value),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveRequiredVeriff: veriffModel => dispatch(saveRequiredVeriff(veriffModel)),
    toggleVeriffPanel: () => dispatch(toggleVeriffPanel()),
    getPersonSettings: () => dispatch(getPersonSettings()),
    getVeriffSession: (personData) => dispatch(getVeriffSession(personData))
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm({ form: 'contentPayCard' }))(ContentPayCard)

