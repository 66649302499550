import React, { Component, createRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Resource } from '../redux/actions/lang.actions';
import ScrollToLink from '../redux/utils/scrollToLink';
import DocumentTitle from '../components/UI/DocumentTitle';
import { FixedScroll } from '../update/components/FixedScroll/FixedScroll';

class Information extends Component {

    constructor(props) {
        super(props)

        this.contentRef1 = createRef()
        this.contentRef2 = createRef()
        this.contentRef3 = createRef()
        this.contentRef4 = createRef()
        this.contentRef5 = createRef()
        this.contentRef6 = createRef()
        this.contentRef7 = createRef()
        this.contentRef8 = createRef()

    }

    createHtml = key => {
        return { __html: Resource(key) }
    }

    componentDidMount() {
        this.props.person.id && window.gtag('set', { 'user_id': this.props.person.id });
        window.ym(56965879,'hit','/information');
    }

    render() {
        return (
            <main className="article-page">
                <DocumentTitle title='StrategyTitle' />
                <article className="content-page">
                    <section className="article-wrap">
                        <h1>{Resource('StrategyTitle')}</h1>
                        <FixedScroll className="article__side" defaultPos={80}>
                            <ul>
                                <li><ScrollToLink contentRef={this.contentRef1}>{Resource('InformationTitleSection1')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef2}>{Resource('InformationTitleSection2')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef3}>{Resource('InformationTitleSection3')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef4}>{Resource('InformationTitleSection4')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef5}>{Resource('InformationTitleSection5')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef6}>{Resource('InformationTitleSection6')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef7}>{Resource('InformationTitleSection7')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={this.contentRef8}>{Resource('InformationTitleSection8')}</ScrollToLink></li>
                            </ul>
                        </FixedScroll>
                    </section>

                    <section ref={this.contentRef1} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection1')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection1')} />
                    </section>
                    <section ref={this.contentRef2} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection2')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection2')} />
                    </section>
                    <section ref={this.contentRef3} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection3')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection3')} />
                    </section>
                    <section ref={this.contentRef4} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection4')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection4')} />
                    </section>
                    <section ref={this.contentRef5} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection5')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection5')} />
                    </section>
                    <section ref={this.contentRef6} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection6')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection6')} />
                    </section>
                    <section ref={this.contentRef7} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection7')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection7')} />
                    </section>
                    <section ref={this.contentRef8} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={this.createHtml('InformationTitleSection8')} />
                        <div dangerouslySetInnerHTML={this.createHtml('InformationContentSection8')} />
                    </section>

                </article>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        person: state.person,
        lang: state.language.langActive
    }
}

export default connect(mapStateToProps, null)(Information)