import { OPERATIONS_TABLE_SUCCESS, OPERATIONS_TABLE_FAILURE } from "../../constants/operations/operations.constants"

const initialState = {
    operationsTable: {
        all: {
            id: 'operations_all',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                deposit: {
                    title: 'Income', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-dep'
                },
                withdrawal: {
                    title: 'Withdrawal', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-wit'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        },
        deposit: {
            id: 'operations_deposit',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                deposit: {
                    title: 'Income', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-dep'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        },
        withdrawal: {
            id: 'operations_withdrawal',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                withdrawal: {
                    title: 'Withdrawal', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-wit'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        },
        income: {
            id: 'operations_income',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                deposit: {
                    title: 'Income', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-dep'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        },
        investing: {
            id: 'operations_investing',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operations: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                withdrawal: {
                    title: 'Withdrawal', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-wit'
                },
                status: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateOperation: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
            },
            body: []
        }
    }
}

const operations = (state = initialState, action) => {
    switch (action.type) {
        case OPERATIONS_TABLE_SUCCESS:

            const getAllOperations = (element) => {
                return element.map(({ id, wayId, operations, deposit, withdrawal, status, dateOperation, type }) => {
                    return {
                        id,
                        operations: operations === 3
                            ? wayId === 334
                                ? 'PaymentsOpertions_334'
                                : wayId === 150
                                    ? 'PaymentsOpertions_150'
                                    : wayId === 355
                                        ? 'PaymentsOpertions_355'
                                        : ''
                            : operations === 6
                                ? (type === 550 ? 'BankTransferWithdrawal' : 'CardTransferWithdrawal')
                                : operations === 5
                                    ? 'PortfolioIncome'
                                    : operations === 4
                                        ? 'PortfolioCreated'
                                        : operations === 299
                                          ? 'PaymentsOpertions_355'
                                          : operations === 7
                                              ? 'WithdrawalOfBalances'
                                              : operations === 8 || operations === 9
                                                  ? 'ReferralTitle'
                                                  : '',
                        deposit,
                        withdrawal,
                        status: status === 64
                            ? 'STATUS_005'
                            : status === 63
                                ? 'STATUS_004'
                                : status === 65
                                    ? 'STATUS_006'
                                    : '',
                        dateOperation
                    }
                })
            }
            const getDepositOperations = (element) => {
                return element.map(({ id, wayId, operations, deposit, status, dateOperation, type }) => {
                    return {
                        id,
                        operations: operations === 3
                            ? wayId === 334
                                ? 'PaymentsOpertions_334'
                                : wayId === 150
                                    ? 'PaymentsOpertions_150'
                                    : wayId === 355
                                        ? 'PaymentsOpertions_355'
                                        : ''
                            : operations === 6
                                ? (type === 550 ? 'BankTransferWithdrawal' : 'CardTransferWithdrawal')
                                : operations === 5
                                    ? 'PortfolioIncome'
                                    : operations === 4
                                        ? 'PortfolioCreated'
                                        : operations === 299
                                            ? 'PaymentsOpertions_355'
                                            : operations === 7
                                                ? 'WithdrawalOfBalances'
                                                : operations === 8 || operations === 9
                                                  ? 'ReferralTitle'
                                                  : '',
                        deposit,
                        status: status === 64
                            ? 'STATUS_005'
                            : status === 63
                                ? 'STATUS_004'
                                : status === 65
                                    ? 'STATUS_006'
                                    : '',
                        dateOperation
                    }
                })
            }
            const getWithdrawalOperations = (element) => {
                return element.map(({ id, operations, withdrawal, status, dateOperation, type }) => {

                    return {
                        id,
                        operations: operations === 3
                            ? 'BankTransferReplenishment'
                            : operations === 6
                                ? (type === 550 ? 'BankTransferWithdrawal' : 'CardTransferWithdrawal')
                                : operations === 5
                                    ? 'PortfolioIncome'
                                    : operations === 4
                                        ? 'PortfolioCreated'
                                        : '',
                        withdrawal,
                        status: status === 64
                            ? 'STATUS_005'
                            : status === 63
                                ? 'STATUS_004'
                                : status === 65
                                    ? 'STATUS_006'
                                    : '',
                        dateOperation
                    }
                })
            }

            return {
                ...state,
                operationsTable: {
                    ...state.operationsTable,
                    all: {
                        ...state.operationsTable.all,
                        body: getAllOperations(action.payload.all)
                    },
                    deposit: {
                        ...state.operationsTable.deposit,
                        body: getDepositOperations(action.payload.deposit)
                    },
                    withdrawal: {
                        ...state.operationsTable.withdrawal,
                        body: getWithdrawalOperations(action.payload.withdrawal)
                    },
                    income: {
                        ...state.operationsTable.income,
                        body: getDepositOperations(action.payload.income)
                    },
                    investing: {
                        ...state.operationsTable.investing,
                        body: getWithdrawalOperations(action.payload.investing)
                    },
                },
            }
        case OPERATIONS_TABLE_FAILURE:
            return {
                ...state,
                ...action.error
            }
        default:
            return state
    }
}

export default operations