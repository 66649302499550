import { JUDICIAL } from "./actions/actionsTypes";
import {
  getJudicial,
  loadingJudicial,
  getJudicialOne,
  setBonus,
} from "./actions/actionsCreators";
import {
  errorHandler,
  requestGetParams,
  requestPost,
  requestGet,
} from "../../redux/api/request";
import { isCurrency, rangeNumbers } from "../components/tools";
import { history } from "../../redux/history";
import { LOADING } from "../../redux/constants/loading.constants";
import {
  CREATE_PORTFOLIO_SUCCESS,
  STRATEGY_DATA_FAILURE,
} from "../../redux/constants/profile/strategys.constants";
import { toast } from "react-toastify";
import { Resource } from "../../redux/actions/lang.actions";
import { getPersonData } from "../../redux/actions/person.action";
import { MODAL_IS_OPEN } from "../../redux/constants/modals.constants";
import { setDepositValueField } from "./balanced-reducer";
import { IS_LOADING_PORTFOLIO, JUDICIAL_SET_DATE_END, setData } from "./investment-reducer";

export const getJudicialPortfolios = (dispatch, personId, productId) => {
  dispatch(loadingJudicial(true));
  return requestGetParams("Product/GetJudicial", { params: { mfcId: 6 } })
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(getJudicial(resp.data));
        getBonus(dispatch, personId, productId);
        //dispatch(loadingJudicial(false));
      } else {
        console.log("Error: ", resp);
        // throw new Error()
      }
    })
    .catch((error) => {
      dispatch(loadingJudicial(false));
    });
};

const getBonus = (dispatch, personId, productId) => {
  return requestGet(
    `/Person/GetBonusInfo?personId=${personId}&productId=${productId}`
  )
    .then((resp) => {
      if (resp.status === 200) {
        if (resp.data.result === "success") {
          dispatch(setBonus(resp.data.message.additionalPercent));
          dispatch(loadingJudicial(false));
        } else {
          dispatch(loadingJudicial(false));
        }
      } else {
        console.log("Error: ", resp);
        // throw new Error()
      }
    })
    .catch((error) => {
      dispatch(loadingJudicial(false));
    });
};

export const getJudicialPortfolioOne = (dispatch, id, personId, productId) => {
  dispatch(loadingJudicial(true));
  let request;
  let actualId = id.replace("p_", "");
  if (id.includes("p_")) {
    request = requestPost(
      `Portfolio/GetPortfolioDetails?portfolioId=${actualId}`
    );
  } else {
    request = requestGetParams("Product/GetLoanInfoById", {
      params: { id: actualId },
    });
  }
  return request
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(getJudicialOne(resp.data));
        getBonus(dispatch, personId, productId);
        dispatch(setData(IS_LOADING_PORTFOLIO, false));
      } else {
        console.log("Error: ", resp);
        // throw new Error()
      }
    })
    .catch((error) => {
      dispatch(loadingJudicial(false));
    });
};

export const createJudicialPortfolio = (params) => (dispatch) => {
  const { startSum, term, productId = 7, loanId } = params;

  let portfolioSettings = {
    Sum: String(startSum.replaceAll(/[^0-9\.]/g, "")) || null,
    Range: term || null,
    ProductId: productId || null,
    LoanId: loanId || null,
  };

  dispatch({ type: LOADING, id: CREATE_PORTFOLIO_SUCCESS, loading: true });
  return requestPost("Portfolio/CreatePortfolio", portfolioSettings)
    .then((resp) => {
      if (resp.data.success) {
        history.push(`/portfolio`);
        toast.success(Resource("PortfolioCreated"));
        dispatch(getPersonData());
        dispatch({
          type: LOADING,
          id: CREATE_PORTFOLIO_SUCCESS,
          loading: false,
        });
        const startSumNumber = startSum.replaceAll(/[^0-9\.]/g, "");
        window.ym(56965879, "reachGoal", "create_judicial", {
          order_price: startSumNumber,
          currency: "EUR",
        });
        window.gtag("event", "CreatePorfolioJudicial", {
          event_category: "Calculator",
          event_label: "Judicial",
          value: String(startSumNumber),
        });
        window.gtag("event", "conversion", {
          send_to: "AW-656305309/2EAOCKjh8pEDEJ3Z-bgC",
          value: String(startSumNumber),
          currency: "EUR",
          transaction_id: resp.data.message,
        });
        //dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalCalculator' })
      } else {
        history.push("/depositing");
        // toast.error(Resource(resp.data.message))
        dispatch(setDepositValueField(startSum));
        //dispatch(change('contentPayCard', 'depositValue', String(startSum)))
        dispatch({
          type: LOADING,
          id: CREATE_PORTFOLIO_SUCCESS,
          loading: false,
        });

        //dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalCalculator' })
      }
    })
    .catch((error) => {
      errorHandler(dispatch, error);
      dispatch({ type: STRATEGY_DATA_FAILURE, error });
      dispatch({ type: LOADING, id: CREATE_PORTFOLIO_SUCCESS, loading: false });
      //dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalCalculator' })
    });
};

const initialState = {
  isLoadingPortfolios: true,
  bonus: 0,
  judicialPortfolios: [],
  judicialInvestObjects: [],
  fields: [
    {
      id: "depositValue",
      label: "Сумма инвестиции",
      maxLength: 9,
      validates: {
        required: "required",
        // validate: rangeNumbers.bind(this, [100, 10000]),
        onChange: isCurrency.bind(this, { prefix: "€" }),
      },
    },
  ],
};

const judicialReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUDICIAL.LOADING:
      return {
        ...state,
        isLoadingPortfolios: action.payload,
      };
    case JUDICIAL.BONUS:
      return {
        ...state,
        bonus: action.payload,
      };
    case JUDICIAL.GET:
      return {
        ...state,
        judicialInvestObjects: [...action.payload],
      };
    case JUDICIAL.GET_ONE:
      return {
        ...state,
        judicialPortfolios: [action.payload],
      };
    case JUDICIAL_SET_DATE_END:
      console.log();
      const newActive = state.judicialPortfolios.map((item) => {
        if (item.portfolioId == action.payload.id) {
          return { ...item,  dateEndReinvestment: action.payload.dateEnd };
        } else {
          return item;
        }
      });

      return {
        ...state,
        judicialPortfolios: [...newActive]
      }
    default:
      return state;
  }
};

export default judicialReducer;
