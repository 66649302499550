import { WITHDRAWAL_REQUEST_SUCCESS, WITHDRAWAL_REQUEST_FAILURE, WITHDRAWAL_TABLE_SUCCESS, WITHDRAWAL_TABLE_FAILURE} from "../../constants/withdrawal/withdrawal.constants"
import { LOADING } from "../../constants/loading.constants"
import { requestPost, requestGet, errorHandler } from "../../api/request"
import { getPersonData } from "../person.action"
import { toast } from "react-toastify"
import { Resource } from "../lang.actions"
import { reset } from "redux-form"
import { getWithdrawalNew } from "../../../update/redux/withdrawal-reducer"

export const getWithdrawal = () => {
    return dispatch => {
        requestGet('Transaction/GetWithdrawal')
            .then(resp => {
                dispatch({
                    type: WITHDRAWAL_TABLE_SUCCESS,
                    payload: resp.data.withdrawalTableModel
                })
                dispatch({ type: LOADING, id: WITHDRAWAL_TABLE_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: WITHDRAWAL_TABLE_FAILURE, error })
                dispatch({ type: LOADING, id: WITHDRAWAL_TABLE_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: WITHDRAWAL_TABLE_SUCCESS, loading: true })
    }
}


export const withdrawalRequest = (personId, amount, paytoolId) => {
    let amountFormat = amount.replace(',', '').replace(/[\s]/g, '')
    return dispatch => {
        requestPost(`Withdrawal/MakeWithdrawalRequest?personId=${personId}&amount=${amountFormat}&paytoolId=${paytoolId}` /*`Transaction/WithdrawalRequest?amount=`*/)
            .then(resp => {
                if (resp.data.codeResult === '200') {
                    dispatch({ type: WITHDRAWAL_REQUEST_SUCCESS })
                    dispatch({ type: LOADING, id: WITHDRAWAL_REQUEST_SUCCESS, loading: false })
                    toast.success(Resource('MessageWithdrawalSuccess'))
                    // dispatch(reset('withdrawalForm'))
                    dispatch(getPersonData())
                    dispatch(getWithdrawalNew())
                    window.ym(56965879,'reachGoal','Withdraw', {order_price: amountFormat, currency: "EUR"});
                } else {
                    // dispatch(reset('withdrawalForm'))
                    dispatch({ type: LOADING, id: WITHDRAWAL_REQUEST_SUCCESS, loading: false })
                    if(resp.data.error.errorCode === "ERROR_007"){
                        toast.error(Resource('NoBankAlert'))
                    }

                    if (resp.data.error.errorCode === "ERROR_003") {
                        toast.error(Resource('LowBalanceAlertCommon'))
                    }
                    
                }
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: WITHDRAWAL_REQUEST_FAILURE, error })
                if (error.response.status === 400) {
                    toast.error(Resource('OutputFail'))
                }
                dispatch({ type: LOADING, id: WITHDRAWAL_REQUEST_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: WITHDRAWAL_REQUEST_SUCCESS, loading: true })
    }
}