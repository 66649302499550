import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import { Resource } from '../../../../redux/actions/lang.actions'
import LoaderButton from '../../LoaderButton'


const LegalForm = props => {
    
    return (
        <form onSubmit={props.handleSubmit}>
            <div className="box panel">
                <RenderInput data={props.fields['legalCompany']} />
                <RenderInput data={props.fields['legalHead']} />
                <RenderInput data={props.fields['legalEmail']} />
                <p className="register-agreement">
                    {Resource('UserAgreement', 'text_1_legal')}
                    <a href={props.state.userAgreement.link_1[1]}>{Resource('UserAgreement', props.state.userAgreement.link_1[0])}</a>
                    {Resource('UserAgreement', 'text_2')}
                    <a href={props.state.userAgreement.link_2[1]}>{Resource('UserAgreement', props.state.userAgreement.link_2[0])}</a>
                </p>
                <LoaderButton loading={props.loading} disabled={props.valid}>{Resource('SendRequest')}</LoaderButton>
                <p className="centered"><Link className="register-link" to='/'>{Resource('AlreadyHaveAccount')}</Link></p>
            </div>
        </form>
    )
}

export default reduxForm({ form: 'legalForm' })(LegalForm)