import React, { useEffect, useRef, useState } from "react";
import { SideBarMenu } from "../../components/SideBarMenu/SideBarMenu";
import { ReactComponent as IconCopy } from "../../static/Icons/copy.svg";
import { MyInvitationsTabs } from "../../components/MyInvitationsTabs/MyInvitationsTabs";
import { Resource } from "../../../redux/actions/lang.actions";
import { VERIFY_CONFIRM } from "../../../redux/constants/person.constants";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import { myInvitationsRequest } from "../../redux/invitefriends-reducer";
import { currentFormatting } from "../../../redux/utils/formatting";
import { useDispatch, useSelector } from "react-redux";
import { setInviteRedirect } from "../../redux/actions/actionsCreators";
import { useLocation } from "react-router";
import "./MyInvitationsPage.scss";

const MyInvitationsPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const refLink = useRef(null);
  const [isCopyLink, serCopyLink] = useState(false);
  const person = useSelector((state) => state.person);
  const info = useSelector((state) => state.invitefriendsReducer);
  const language = useSelector((state) => state.language);
  useEffect(() => {}, [language]);

  useEffect(() => {
    myInvitationsRequest(dispatch, person.id);
    location.state?.redirect && dispatch(setInviteRedirect());
    window.ym(56965879, "hit", "/myinvitations");
  }, []);

  const copyRefLink = () => {
    navigator.clipboard
      .writeText(info.refLink)
      .then(() => serCopyLink(true))
      .catch((error) => {
        console.log("error copy ref lonk: ", error);
      });
  };

  return (
    <main className="my-invitations">
      <DocumentTitle title={Resource("refMyInvitationsTitle1")} />
      <div className="my-invitations__wrapper">
        <SideBarMenu />
        <div className="my-invitations__content">
          <h1 className="my-invitations__title">
            {Resource("refMyInvitationsTitle1")}
          </h1>

          <div className="stat-info">
            <div className="stat-info__money">
              <div className="stat-info__info">
                {Resource("refMyInvitationsTotal")}
              </div>
              <div className="stat-info__sum">
                {currentFormatting(info.totalInterest)}
              </div>
            </div>
            <div className="stat-info__bonus">
              <div className="stat-info__string-top">
                <div className="stat-info__label">
                  {Resource("refMyInvitationsFriends")}:
                </div>
                <div className="stat-info__value">{info.totalLinkCount}</div>
              </div>
              <div className="stat-info__string-bottom">
                <div className="stat-info__label">
                  {Resource("refMyInvitationsGetBonus")}:
                </div>
                <div className="stat-info__value">{info.gotBonusByFriend}</div>
              </div>
            </div>
          </div>

          {person.veriffStatusId === VERIFY_CONFIRM && (
            <div className="my-invitations__ref-box">
              <h2 className="my-invitations__sub-title">
                {Resource("refMyInvitationsTitle2")}
              </h2>
              <div
                className={!isCopyLink ? "ref-box" : "ref-box ref-box--active"}
              >
                <div className="ref-box__column">
                  <div className="ref-box__link" ref={refLink}>
                    {info.refLink}
                  </div>
                </div>
                <div className="ref-box__column">
                  <div className="ref-box__btn" onClick={copyRefLink}>
                    <IconCopy />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="my-invitations__box">
            <h2 className="my-invitations__sub-title">
              {Resource("refMyInvitationsTitle3")}
            </h2>
            <MyInvitationsTabs list={info.details} />
          </div>
        </div>
      </div>
    </main>
  );
};

export { MyInvitationsPage };
