import React from 'react';
import classes from './FieldsFixed.module.scss'
import Tooltip from "../../Tooltip/Tooltip";

import icon_copy from '../../../../static/image/icons/icon-copy.svg'
import icon_check from '../../../../static/image/check.svg'

const FieldsFixed = (props) => {
  
  const copyHandler = (clear, event) => {
    if(clear) {
      window.navigator.clipboard.writeText(event.target.title.replace(/\s/g, ''))
    } else {
      window.navigator.clipboard.writeText(event.target.title)
    }
  }
  
  return (
    <div className={classes.wrapper}>
      {
        props.data.map((field, index) => {
          return <div key={index} className={classes.fixed}>
            <div>
              <span className={classes.label}>{field.label}</span>
              <span className={classes.value}>{field.value}</span>
            </div>
            <Tooltip onClickChildren={copyHandler.bind(this, field.clear)} icon={icon_check}>
              <img src={icon_copy} alt="copy" title={field.value}/>
            </Tooltip>
          </div>
        })
      }
    </div>
  )
}

export default FieldsFixed