import React, { useEffect } from 'react'
import { Resource } from '../../redux/actions/lang.actions'
import { connect } from 'react-redux'

const DocumentTitle = ({ title, lang, dynamic }) => {
    useEffect(() => {
        document.title = `${Resource(title)} ${dynamic ? dynamic : ''} — Nibble Finance`
    }, [lang, title, dynamic])

    return <></>
}

const mapStateToProps = state => {
    return {
        lang: state.language.langActive
    }
}

export default connect(mapStateToProps, null)(DocumentTitle)