import React, {useEffect, useState} from 'react';
import DocumentTitle from "../../../components/UI/DocumentTitle";
import {connect} from "react-redux";

import classes from './PortfolioPage.module.scss'
import Panel from "../../components/Panel/Panel";
import Currency from "../../components/сurrency/Currency";
import {currentFormatting, dateFormatting, dateFormattingWithTime} from "../../../redux/utils/formatting";
import {PERSON_DATA_SUCCESS} from "../../../redux/constants/person.constants";
import IconButton from "../../components/Buttons/IconButton/IconButton";

import iconPlus from '../../../static/image/icons/icon-plus-btn.svg'
import iconArrow from '../../../static/image/icons/icon-arrow-btn.svg'
import iconArrowBig from '../../../static/image/icons/icon-arrow-big.svg'
import {Resource} from "../../../redux/actions/lang.actions";
import HistorySidebar from "../../components/History/HistorySidebar";
import {getIncomeHistory, getInvestmentData} from "../../redux/investment-reducer";
import {changeActiveId} from "../../../redux/actions/tabs.actions";
import PageContainer from "../PageConstructor/PageContainer";
import {MainContent, SideBar} from "../PageConstructor/Section";
import {useHistory, useParams} from "react-router";
import BackButton from "../../components/Buttons/BackButton/BackButton";
import {getPortfolioData} from "../../redux/portfolio-reducer";
import CardPortfolio from "../../components/CardPortfolio/CardPortfolio";
import DropdownList from "../../components/DropdownList/DropdownList";
import DropdownListItem from "../../components/DropdownList/DropdownListItem";

import iconChecked from '../../../static/image/icons/icon-checked-circle.svg'
import iconStats from '../../../static/image/icons/icon-stats.svg'
import iconWatch from '../../../static/image/icons/icon-stopwatch.svg'
import iconShield from '../../../static/image/icons/icon-shield.svg'
import CardLoan from "../../components/CardLoan/CardLoan";

const PortfolioPage = (props) => {
  
  const params = useParams();
  const history = useHistory();
    const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    window.ym(56965879,'hit','/portfolio/' + params.id);
    props.getPortfolioData(params.id).then(resp => {
      if(resp) {
        setLoading(false)
      } else {
        history.push('/portfolio')
      }
    });
  }, [])

  const getTextStatus = (status) => {
    if(status === 82) {
      return Resource('STATUS_004')
    } else {
      return null
    }
  }
  
  const outputIdWithDate = (params) => `ID ${params.id} – ${dateFormatting(params.dateCreate)}`

  return (
    <main className={classes.portfolio}>
      <DocumentTitle title={'PortfolioID'} />
      
      <div className={classes.sidebar}>
        <Panel customClass={classes.panel}>
          <Currency column order
            className={classes.currency}
            amount={currentFormatting(props.person.currentBalance.value)}
            text={[Resource('Balance')]}
            loadId={PERSON_DATA_SUCCESS}
          />
          <div className={classes.action}>
            <IconButton className={classes.iconButton} column icon={iconPlus} link={'/depositing'}>{Resource('DepositAccountMobile')}</IconButton>
            <IconButton className={classes.iconButton} column icon={iconArrow} link={'/withdrawal'}>{Resource('WithdrawFundsMobile')}</IconButton>
          </div>
          
        </Panel>

          <HistorySidebar
            title={Resource('RefillHistory')}
            customLoad={loading}
            data={props.portfolio.paymentsInformation}
            template={[
              { cls: 'name', text: Resource('PortfolioReplenishmentOperation') },
              { cls: 'amount', before: '+ ', key: 'amount', format: currentFormatting },
              { cls: 'date', bind: ['id', 'dateCreate'], bindFn: outputIdWithDate },
              { cls: 'status', key: 'statusId', format: getTextStatus }
            ]}
          />

      </div>
      <div className={classes.content}>
        <BackButton className={classes.iconButton} link={'/portfolio'}>{Resource('BackButton')}</BackButton>

        <h1 className={classes.title}>{Resource('PortfolioID')} #{params.id}</h1>

        {
          loading
            ? <span className='spinner'/>
            : <>
                <CardPortfolio id={props.portfolio.portfolioId} type={props.portfolio.ruleId}
                               percent={props.portfolio.percent} term={props.portfolio.monthCount} amount={props.portfolio.portfolioAmount}
                               currentIncome={props.portfolio.currentInterest?.toFixed(2)}
                               expectedIncome={props.portfolio.statusId === 172 ? props.portfolio.currentInterest?.toFixed(2) : props.portfolio.expectedInterest?.toFixed(2)}
                               endDate={props.portfolio.endDate} fields={props.balancedReducer.fields} closed={(props.portfolio.statusId === 172 || !props.portfolio.canFillBalance)} linkDisabled
                               disabledField={!props.portfolio.canFillBalance}
                />
              <div className={classes.loans}>

                <h3 className={classes.subtitle} data-count={props.portfolio?.loanCount}>{Resource('LoansInvesting')}</h3>

                <div className={classes.dropdown}>
                  <DropdownList icon={iconChecked} label={`${Resource('LoansClose')}: ${props.loans?.close?.length}`} disabled={props.loans?.close?.length <= 0} customClass={classes}>
                    {
                      props.loans?.close?.length > 0 && props.loans?.close.map(loan => {
                        return <CardLoan key={loan.dateCreate} data={loan}/>
                      })
                    }
                  </DropdownList>
                  
                  {/*<DropdownList icon={iconWatch} label={`${Resource('LoansDebt')}: ${props.loans?.expired?.length}`} disabled={props.loans?.expired?.length <= 0} customClass={classes}>*/}
                  {/*  {*/}
                  {/*    props.loans?.expired?.length > 0 && props.loans?.expired.map(loan => {*/}
                  {/*      return <CardLoan key={loan.dateCreate} data={loan}/>*/}
                  {/*    })*/}
                  {/*  }*/}
                  {/*</DropdownList>*/}
                  {
                    props.portfolio.statusId !== 172 &&
                      <DropdownList icon={iconStats} label={`${Resource('LoansActive')}: ${props.loans?.active?.length}`} disabled={props.loans?.active?.length <= 0} customClass={classes}>
                        {
                          props.loans?.active?.length > 0 && props.loans?.active.map(loan => {
                            return <CardLoan key={loan.dateCreate} data={loan}/>
                          })
                        }
                      </DropdownList>
                  }
                  
                  <DropdownList icon={iconShield} label={`${Resource('LoansBought')}: ${props.loans?.buyBack?.length}`} disabled={props.loans?.buyBack?.length <= 0} customClass={classes}>
                    {
                      props.loans?.buyBack?.length > 0 && props.loans?.buyBack.map(loan => {
                        return <CardLoan key={loan.dateCreate} data={loan}/>
                      })
                    }
                  </DropdownList>
                </div>

              </div>
            </>
            
            
          
        }
        
      </div>
    </main>
  );
}

const mapStateToProps = state => {
  return {
    person: state.person,
    language: state.language,
    portfolio: state.PortfolioReducer,
    loans: state.PortfolioReducer.loans,
    
    balancedReducer: state.BalancedReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPortfolioData: (id) => dispatch(getPortfolioData(id))
    // getInvestmentData: () => dispatch(getInvestmentData()),
    // getIncomeHistory: (count) => dispatch(getIncomeHistory(count)),
    // changeActiveId: (activeId, tabsId) => dispatch(changeActiveId(activeId, tabsId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPage);