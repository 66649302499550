import React from "react";
import classes from "../../../page/MyInvestment/MyInvestmentPage.module.scss";
import { currentFormatting } from "../../../../redux/utils/formatting";
import { PERSON_DATA_SUCCESS } from "../../../../redux/constants/person.constants";
import Currency from "../Currency";
import { NavLink } from "react-router-dom";
import { Resource } from "../../../../redux/actions/lang.actions";

const BalanceHeader = (props) => {
  const click = () => {
    window.ym(56965879, "reachGoal", "mob balance");
  };

  return (
    <div className={[props.className].join(" ")}>
      <Currency
        order
        className={classes.currency}
        amount={currentFormatting(props.balanceValue)}
        text={[Resource("Balance")]}
      />
      <NavLink
        to={"/depositing"}
        className={classes.link}
        id="mobileDepositing"
        onClick={click}
      />
    </div>
  );
};

export default BalanceHeader;
