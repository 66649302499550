import React from "react";
import classes from './SpinnerLoading.module.scss'

const SpinnerLoad = props => {

  const styledColor = {
    borderTop: `1.1em solid ${props.second ?? 'rgba(107, 208, 240, 0.2)'}`,
    borderRight: `1.1em solid ${props.primary ?? 'rgba(107, 208, 240, 0.7)'}`,
    borderBottom: `1.1em solid ${props.second ?? 'rgba(107, 208, 240, 0.2)'}`,
    borderLeft: `1.1em solid ${props.primary ?? 'rgba(107, 208, 240, 0.7)'}`,
    marginLeft: props.left ? '0' : '',
    marginRight: props.right ? '0' : ''
  }
  
  return (
    <span className={classes.spinner} style={styledColor}/>
  )
}

export default SpinnerLoad;