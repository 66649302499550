import React from 'react';
import classes from './List.module.scss';

const List = (props) => {
  return (
    <div className={[classes['list__wrapper'], props.customClass].join(' ')}>
      <h3 className={classes.title}>{props.title}</h3>
      <ul className={classes.list}>
        {props.items.map((item, index) => item && <li key={index} className={[classes[`list__item`], classes[props.marker]].join(' ')}>{item}</li>)}
      </ul>
    </div>
  );
};

export default List;