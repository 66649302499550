import {
  STRATEGY_DATA_SUCCESS,
  STRATEGY_DATA_FAILURE,
  STRATEGY_ACTIVE_PRODUCT_ID,
  STRATEGY_ID,
  STRATEGY_FIXED_VALUES,
  STRATEGY_SET_VALUE, STRATEGY_STATUS_CHECKBOX
} from "../../constants/profile/strategys.constants"
import {currencyFormatProfit} from "../../utils/formatting";

const initialState = {
  classic: {
    description: 'ClassicDescription',
    nameplate: 'BuyBack Guarantee',
    startSum: {
      id: 'startSum',
      label: 'RangeInvestmentAmount',
      formatting: currencyFormatProfit,
      defaultValue: 1000,
      values: [50, 100, 1000],
      customId: 'customAmount'
    },
    reinvestSum: {
      id: 'reinvestSum',
      min: 10,
      max: 10000,
      defaultValue: 1000,
      values: [100, 500, 1000],
      customId: 'customReinvest'
    },
    term: {
      id: 'term',
      label: 'RangeInvestmentTerm',
      defaultValue: 6,
      values: [3, 4, 6],
      customId: 'customTerm'
    },
    value: 50
  },
  balanced: {
    description: 'BalancedDescription',
    startSum: {
      id: 'startSum',
      label: 'RangeInvestmentAmount',
      formatting: currencyFormatProfit,
      defaultValue: 1000,
      values: [250, 500, 1000],
      customId: 'customAmount'
    },
    reinvestSum: {
      id: 'reinvestSum',
      min: 10,
      max: 10000,
      defaultValue: 500,
      values: [100, 500, 1000],
      customId: 'customReinvest'
    },
    term: {
      id: 'term',
      label: 'RangeInvestmentTerm',
      defaultValue: 12,
      values: [7, 9, 12],
      customId: 'customTerm'
    },
    helper: true,
    value: 100
  },
  special: {
    description: 'SpecialDescription',
    startSum: {
      id: 'startSum',
      label: 'RangeInvestmentAmount',
      formatting: currencyFormatProfit,
      defaultValue: 1000,
      values: [1000, 1500, 3000],
      customId: 'customAmount'
    },
    reinvestSum: {
      id: 'reinvestSum',
      min: 10,
      max: 10000,
      defaultValue: 1000,
      values: [100, 500, 1000],
      customId: 'customReinvest'
    },
    term: {
      id: 'term',
      label: 'RangeInvestmentTerm',
      defaultValue: 12,
      values: [12, 24, 36],
      customId: 'customTerm'
    },
    helper: true,
    value: 500
  },
  activeProductId: STRATEGY_ID.classic,
  dynamicValues: {
    startSum: 0,
    reinvestSum: 0,
    term: 0,
    customAmount: null,
    customTerm: null,
    customReinvest: null,
    activeReinvest: false
  },
  fields: {
    customAmount: {
      id: 'customAmount',
      name: 'customAmount',
      type: 'text',
      label: 'OtherLabel',
      maxLength: 6,
      validate: {
        rangeChecking: ['ValidationAmountMin', 'ValidationAmountMax']
      }
    },
    customTerm: {
      id: 'customTerm',
      name: 'customTerm',
      type: 'text',
      label: 'OtherLabel',
      maxLength: 2,
      validate: {
        rangeChecking: 'ValidationTermMinMax'
      }
    },
    customReinvest: {
      id: 'customReinvest',
      name: 'customReinvest',
      type: 'text',
      label: 'OtherLabel',
      maxLength: 6,
      validate: {
        rangeChecking: ['ValidationAmountMin', 'ValidationAmountMax']
      }
    },
    checkDeposit: {
      id: 'checkDeposit',
      name: 'checkDeposit',
      type: 'checkbox',
      label: 'MonthlyDeposit',
      checked: false
    }
  }
}

const strategys = (state = initialState, action) => {
  switch (action.type) {
    case STRATEGY_DATA_SUCCESS:
      return {
        ...state,
        classic: {
          ...state.classic,
          percent: action.payload.classic.percent,
          startSum: {
            ...state.classic.startSum,
            min: action.payload.classic.amountMin,
            max: action.payload.classic.amountMax
          },
          term: {
            ...state.classic.term,
            min: action.payload.classic.monthMin,
            max: action.payload.classic.monthMax
          }
        },
        balanced: {
          ...state.balanced,
          ...action.payload.balanced,
          percent: action.payload.balanced.percent,
          startSum: {
            ...state.balanced.startSum,
            min: action.payload.balanced.amountMin,
            max: action.payload.balanced.amountMax
          },
          term: {
            ...state.balanced.term,
            min: action.payload.balanced.monthMin,
            max: action.payload.balanced.monthMax
          }
        },
        // special: {
        //   ...state.special,
        //   ...action.payload.special,
        //   percent: action.payload.special.percent,
        //   startSum: {
        //     ...state.special.startSum,
        //     min: action.payload.special.amountMin,
        //     max: action.payload.special.amountMax
        //   },
        //   term: {
        //     ...state.special.term,
        //     min: action.payload.special.monthMin,
        //     max: action.payload.special.monthMax
        //   }
        // },
        loading: false
      }
    case STRATEGY_DATA_FAILURE:
      return { ...state, error: action.error }
    case STRATEGY_FIXED_VALUES:
      return {
        ...state,
        dynamicValues: {
          ...state.dynamicValues,
          [action.idSlider]: action.value
        }
      }
    case STRATEGY_ACTIVE_PRODUCT_ID:
      return {
        ...state,
        activeProductId: action.productId
      }
    case STRATEGY_SET_VALUE:
      return  {
        ...state,
        [action.strategy]: {
          ...state[action.strategy],
          value: action.value
        }
      }
    case STRATEGY_STATUS_CHECKBOX:
      return {
        ...state,
        dynamicValues: {
          ...state.dynamicValues,
          reinvestSum: 0,
          customReinvest: null
        },
        fields: {
          ...state.fields,
          checkDeposit: {
            ...state.fields.checkDeposit,
            checked: action.checked
          }
        }
      }
    default:
      return state
  }
}

export default strategys