import { MODAL_IS_OPEN, HELPER_GET_INFO } from "../../constants/modals.constants"

const initialState = {
    modalResetPassword: {
        id: 'modalResetPassword',
        title: 'ResetPassword',
        button: 'ResetPassword',
        fields: {
            loginResetEmail: {
                id: 'loginResetEmail',
                name: 'loginResetEmail',
                type: 'email',
                label: 'Email',
                edit: true,
                validate: {
                    required: true,
                    email: true
                }
            }
        },
        isOpen: false
    },
    modalPayment: {
        id: 'modalPayment',
        isOpen: false
    },
    modalVeriff: {
        id: 'modalVeriff',
        isOpen: false
    },
    modalCreatePortfolio: {
        id: 'modalCreatePortfolio',
        isOpen: false,
        title: 'AreYouSure'
    },
    modalDepositingVeriff: {
        id: 'modalDepositingVeriff',
        isOpen: false
    },
    modalCalculator: {
        id: 'modalCalculator',
        isOpen: false
    },
    helper: {
        id: 'helper',
        isOpen: false,
        info: {
            HelperAnnualRate: {
                title: 'HelperAnnualRateTitle',
                text: 'HelperAnnualRateText'
            },
            risks: {
                title: 'HelperRisksTitle',
                text: 'HelperRisksText'
            },
            classic: {
                title: 'HelperStrategyClassicTitle',
                text: 'HelperStrategyClassicText',
                graph: {
                    AA: 65,
                    A: 25,
                    BB: 10
                }
            },
            balanced: {
                title: 'HelperStrategyBalancedTitle',
                text: 'HelperStrategyBalancedText',
                graph: {
                    BB: 63,
                    B: 32,
                    CC: 5
                }
            },
            special: {
                title: 'HelperStrategySpecialTitle',
                text: 'HelperStrategySpecialText',
                graph: {
                    B: 54,
                    CC: 28,
                    C: 18
                }
            }
        }
    },
    modalLimitDeposit: {
        id: 'modalLimitDeposit',
        isOpen: false
    }
}

const modals = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_IS_OPEN:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    isOpen: action.isOpen
                }
            }
        case HELPER_GET_INFO:
            return {
                ...state,
                helper: {
                    ...state.helper,
                    activeInfo: {
                        ...action.payload
                    }
                }  
            }
        default:
            return state
    }
}

export default modals
