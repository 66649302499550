import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./SubNavigation.scss";
import { Resource } from "../../../redux/actions/lang.actions";

const SubNavigation = ({ nameItem, navItems, isDark, setIsOpen }) => {
  const [isOpen, setOpen] = useState(false);
  const openSub = () => {
    setOpen(!isOpen);
  };
  return (
    <div className={isDark ? "sub-navigation dark" : "sub-navigation"}>
      <span
        className={isOpen ? "navigator action" : "navigator"}
        onClick={openSub}
      >
        {Resource(nameItem)}
      </span>
      <div className={isOpen ? "sub-menu action" : "sub-menu"}>
        {navItems &&
          Object.keys(navItems).map((key) => {
            return (
              <NavLink
                key={key}
                to={navItems[key]}
                className="item"
                activeClassName="action"
                onClick={()=>{openSub(); setIsOpen()}}
              >
                {Resource(key)}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};

export { SubNavigation };
