// Отображение окна с просьбой оценить платформу, после пополнения портфеля
import React from "react";
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PopupSlide } from "../ui/PopupSlide/PopupSlide";
import { infoPopupToggle } from "../../redux/actions/actionsCreators";
// import { Locale } from "../../../locale/locale";
import "./InfoSlide.scss";

const InfoSlide = (props) => {
  const infoOpen = useSelector((state) => state.PopupReducer.infoOpen);
  const joymoneyActive = useSelector(state => state.strategysReducer.status);
  const dispatch = useDispatch();
  // const history = useHistory();

  const setActive = (status) => {
    dispatch(infoPopupToggle(status));
  };

  const goToJudicial = () => {
    props.onToggleModal(props.id, !props.isOpen);
    setActive(false);
  };

  return (
    <>
      {
      joymoneyActive &&
      <PopupSlide active={infoOpen} setActive={setActive}>
        <div className="info-slide">
          <div className="info-slide__content">
            <div className="info-slide__title">
              <div className="info-slide__title-head">
                Grow your income by investig in Classic and Balanced strategies!
              </div>
            </div>

            <div className="info-slide__title-sub">
              <p>
                Nibble opens up a great opportunity for you to benefit from Classic and Balanced strategies - the rates have become higher.
              </p>
              <p>
                Choose Classic Strategy with a guaranteed income of 9.7% or take advantage of our unique offer - Balanced Strategy with a BuyBack guarantee of 9.7% and a maximum income of up to 13%. You can invest in both strategies as well.
              </p>
              <p>
                Hurry up before someone else starts earning instead of you! Investment is only possible while there are available loans.
              </p>
            </div>

            <div className="info-slide__btn" onClick={goToJudicial}>
              Invest
            </div>
          </div>
        </div>
      </PopupSlide>
    }
    </>
  );
};

export { InfoSlide };
