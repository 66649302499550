import React from 'react';
import classes from './OnlyDevice.module.scss'

export const OnlyMobile = (props) => {
  return (
    <>{
      React.Children.map(props.children, child => {
        return React.cloneElement(child, {className: [child.props.className, classes.mobile, classes[`mobile__${props.point}`]].join(' ')})
      })
    }</>
  )
}

export const OnlyDesktop = (props) => {
  return (
    <>{
      React.Children.map(props.children, child => {
        return React.cloneElement(child, {className: [child.props.className, classes.desktop, classes[`desktop__${props.point}`]].join(' ')})
      })
    }</>
  )
}

// const OnlyDevice = (props) => {
//   return {
//     Mobile
//   }
// }
//
// export default OnlyDevice;