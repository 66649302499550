import { CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE, CREATE_ACCOUNT_CHANGE_FIELD, CREATE_ACCOUNT_STATUS_PASSWORD } from "../../constants/create-account/account.constants"

const initialState = {
    fields: {
        individualGender: {
            id: 'individualGender',
            name: 'individualGender',
            options: [
                {
                    id: 'M',
                    value: 'MrLabel'
                },
                {
                    id: 'W',
                    value: 'MsLabel'
                }
            ],
            value: 'M',
            labelHidden: 'Gender'
        },
        individualName: {
            id: 'individualName',
            name: 'individualName',
            type: 'text',
            label: 'FirstName',
            edit: true,
            validate: {
                required: true,
                only: 'letter'
            }
        },
        individualEmail: {
            id: 'individualEmail',
            name: 'individualEmail',
            type: 'email',
            label: 'Email',
            edit: true,
            validate: {
                required: true,
                email: true
            }
        },
        individualPassword: {
            id: 'individualPassword',
            name: 'individualPassword',
            type: 'password',
            label: 'Password',
            edit: true,
            validate: {
                required: true,
                minLength: 6
            },
            password: true,
            complexity: {
                show: false
            },
            autofill: true
        },
        individualPromocode: {
            id: 'individualPromocode',
            name: 'individualPromocode',
            type: 'text',
            placeholder: 'ApplyPromoCode',
            edit: true,
            show: 'IHavePromoCode'
        },
        legalCompany: {
            id: 'legalCompany',
            name: 'legalCompany',
            type: 'text',
            required: false,
            label: 'CompanyName',
            edit: true,
            validate: {
                required: true
            }
        },
        legalHead: {
            id: 'legalHead',
            name: 'legalHead',
            type: 'text',
            label: 'CompanyHeadName',
            edit: true,
            validate: {
                required: true
            }
        },
        legalEmail: {
            id: 'legalEmail',
            name: 'legalEmail',
            type: 'email',
            required: false,
            label: 'Email',
            edit: true,
            validate: {
                required: true,
                email: true
            }
        }
    },
    userAgreement: {
        link_1: ['text_link_1', 'https://nibble.finance/user-agreement.html'],
        link_2: ['text_link_2', 'https://nibble.finance/privacy-policy.html']
    },
    recaptcha: true
}

const createAccount = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ACCOUNT_REQUEST:
            return { ...state, loading: true }
        case CREATE_ACCOUNT_SUCCESS:
            return { ...state, loading: false }
        case CREATE_ACCOUNT_FAILURE:
            return { ...state, error: action.error, loading: false }
        case CREATE_ACCOUNT_CHANGE_FIELD:
            return {
                ...state, fields: {
                    ...state.fields,
                    individualPromocode: {
                        ...state.fields.individualPromocode,
                        show: action.payload
                    }
                }
            }
        case CREATE_ACCOUNT_STATUS_PASSWORD:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.id]: {
                        ...state.fields[action.payload.id],
                        complexity: {
                            ...state.fields[action.payload.id].complexity,
                            show: action.payload.show,
                            classPass: action.payload.classPass,
                            status: action.payload.status
                        }
                    }
                }
                
            }
        default:
            return state
    }
}

export default createAccount
