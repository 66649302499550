import React from "react";
import { Resource } from "../../../redux/actions/lang.actions"; 
import "./JudicialPortfolioDescription.scss";

const JudicialPortfolioDescription = ({info}) => {
    return(
        <div className="portfolio-description">
            <div className="portfolio-description__title">
                { Resource("NextMoreText") }
            </div>
            <div className="portfolio-description__text">
                { info }
            </div>
            {/* <div className="portfolio-description__dpd">
                DPD - 
            </div>
            <ul className="portfolio-description__list">
                <li className="portfolio-description__item">
                    Пункт 1
                </li>
                <li className="portfolio-description__item">
                    Пункт 2
                </li>
                <li className="portfolio-description__item">
                    Пункт 3
                </li>
            </ul> */}
        </div>
    )
};

export { JudicialPortfolioDescription };