import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { SEND_TOKEN_SUCCESS } from '../../../redux/constants/create-account/account.constants';
import { requestPost } from '../../../redux/api/request';

const ReCaptcha = props => {    

    useEffect(() => {
        requestPost(`/Google/GetSiteKey`).then(response => {
            const SITE_KEY = response.data.token;

            if (props.loading === undefined || props.loading) {
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.id = "root-recaptha";
                script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
                script.onload = function () {
                    window.grecaptcha.ready(() => {
                        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                            props.tokenVerify(token)
                        });
                    })
                };
                document.body.appendChild(script);
            } else {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                        props.tokenVerify(token)
                    });
                })
            }
        })

        
    // eslint-disable-next-line
    }, [props.tokenVerify])

    return <></>
}

const mapStateToProps = state => {
    return {
        logged: state.auth.logged,
        loading: state.loading[SEND_TOKEN_SUCCESS]
    }
}

export default connect(mapStateToProps, null)(ReCaptcha)