import {
  STRATEGY_DATA_SUCCESS,
  STRATEGY_DATA_FAILURE,
  STRATEGY_ACTIVE_PRODUCT_ID,
  STRATEGY_ID,
  STRATEGY_FIXED_VALUES,
  CREATE_PORTFOLIO_SUCCESS,
  STRATEGY_SET_VALUE,
  STRATEGY_STATUS_CHECKBOX,
} from "../../constants/profile/strategys.constants";
import { requestGet, requestPost, errorHandler } from "../../api/request";
import { LOADING } from "../../constants/loading.constants";
import { history } from "../../history";
import { toast } from "react-toastify";
import { Resource } from "../lang.actions";
import { getPersonData } from "../person.action";
import { change } from "redux-form";
import { MODAL_IS_OPEN } from "../../constants/modals.constants";
import { setDepositValueField } from "../../../update/redux/balanced-reducer";

export const getProductList = () => {
  return (dispatch) => {
    requestGet("Portfolio/GetProductList")
      .then((resp) => {
        let payload = {};
        // eslint-disable-next-line
        resp.data.map((item) => {
          if (item.product.id === STRATEGY_ID.classic) {
            return (payload = {
              ...payload,
              classic: {
                ...payload.classic,
                ...item,
              },
            });
          }
          if (item.product.id === STRATEGY_ID.balanced) {
            return (payload = {
              ...payload,
              balanced: {
                ...payload.balanced,
                ...item,
              },
            });
          }
          if (item.product.id === STRATEGY_ID.special) {
            return (payload = {
              ...payload,
              special: {
                ...payload.special,
                ...item,
              },
            });
          }
        });
        dispatch({ type: STRATEGY_DATA_SUCCESS, payload });
        dispatch({ type: LOADING, id: STRATEGY_DATA_SUCCESS, loading: false });
      })
      .catch((error) => {
        errorHandler(dispatch, error);
        dispatch({ type: STRATEGY_DATA_FAILURE, error });
      });
    dispatch({ type: LOADING, id: STRATEGY_DATA_SUCCESS, loading: true });
  };
};

export const addProductId = (productId) => {
  return {
    type: STRATEGY_ACTIVE_PRODUCT_ID,
    productId,
  };
};

export const fixedValues = (id, value, idSlider) => {
  return {
    type: STRATEGY_FIXED_VALUES,
    id,
    value,
    idSlider,
  };
};

export const setCurrentValue = (value, strategy) => {
  return {
    type: STRATEGY_SET_VALUE,
    value,
    strategy,
  };
};

export const createPortfolio = ({ ...params }) => {
  const { startSum, term, productId } = params;

  let portfolioSettings = {
    Sum: String(startSum) || null,
    Range: term || null,
    ProductId: productId || null,
  };

  return (dispatch) => {
    requestPost("Portfolio/CreatePortfolio", portfolioSettings)
      .then((resp) => {
        if (resp.data.success) {
          history.push(`/portfolio/${resp.data.message}`);
          dispatch({
            type: LOADING,
            id: CREATE_PORTFOLIO_SUCCESS,
            loading: false,
          });
          toast.success(Resource("PortfolioCreated"));
          dispatch(getPersonData());

          dispatch({
            type: MODAL_IS_OPEN,
            isOpen: false,
            id: "modalCalculator",
          });
          // YandexCounter
          switch (productId) {
            case 8: {
              window.ym(56965879, "reachGoal", "create_classic", {
                order_price: startSum,
                currency: "EUR",
              });
              // window.gtag('event', 'CreatePorfolioClassic', {
              //     'event_category': 'Calculator',
              //     'event_label': 'Classic',
              //     'value': String(startSum)
              // });
              break;
            }
            case 9: {
              window.ym(56965879, "reachGoal", "create_balanced", {
                order_price: startSum,
                currency: "EUR",
              });
              // window.gtag('event', 'CreatePorfolioBalanced', {
              //     'event_category': 'Calculator',
              //     'event_label': 'Balanced',
              //     'value': String(startSum)
              // });
              // window.gtag('event', 'conversion', {
              //     'send_to': 'AW-656305309/z3ucCNHX8pEDEJ3Z-bgC',
              //     'value': String(startSum),
              //     'currency': 'EUR',
              //     'transaction_id': resp.data.message
              // });
              break;
            }
            case 5: {
              window.ym(56965879, "reachGoal", "create_special", {
                order_price: startSum,
                currency: "EUR",
              });
              // window.gtag('event', 'CreatePorfolioSpecial', {
              //     'event_category': 'Calculator',
              //     'event_label': 'Special',
              //     'value': String(startSum)
              // });
              // window.gtag('event', 'conversion', {
              //     'send_to': 'AW-656305309/2EAOCKjh8pEDEJ3Z-bgC',
              //     'value': String(startSum),
              //     'currency': 'EUR',
              //     'transaction_id': resp.data.message
              // });
              break;
            }
          }
        } else {
          if (resp.data.message === "ERROR_004") {
            toast.error(Resource(resp.data.errorMessage));
          } else {
            history.push("/depositing");
          }
          // toast.error(Resource(resp.data.message))
          dispatch(setDepositValueField(startSum));
          // dispatch(change('contentPayCard', 'depositValue', String(startSum)))
          dispatch({
            type: LOADING,
            id: CREATE_PORTFOLIO_SUCCESS,
            loading: false,
          });

          dispatch({
            type: MODAL_IS_OPEN,
            isOpen: false,
            id: "modalCalculator",
          });
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error);
        dispatch({ type: STRATEGY_DATA_FAILURE, error });
        dispatch({
          type: LOADING,
          id: CREATE_PORTFOLIO_SUCCESS,
          loading: false,
        });
        dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: "modalCalculator" });
      });
    dispatch({ type: LOADING, id: CREATE_PORTFOLIO_SUCCESS, loading: true });
  };
};

export const changeStatusCheckbox = ({ target }) => {
  return (dispatch) => {
    dispatch({ type: STRATEGY_STATUS_CHECKBOX, checked: target.checked });
  };
};
