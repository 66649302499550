import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPersonSettings, savePersonalInformation, changePasswordSetting, saveMainSettings } from '../redux/actions/settings/settings.actions';
import RenderTabs from '../components/UI/RenderTabs';

import PersonInformationForm from '../components/UI/formControls/settings/personInformation.form';
import ChangePasswordForm from '../components/UI/formControls/settings/changePassword.form';
import DocsForm from '../components/UI/formControls/settings/docs.form';
import MainSettingsForm from '../components/UI/formControls/settings/mainSettings.form';
import Loader from '../components/UI/Loader';
import { SETTINGS_SUCCESS } from '../redux/constants/settings/settings.constants';
import { getPersonData } from '../redux/actions/person.action';
import { Resource } from '../redux/actions/lang.actions';
import DocumentTitle from '../components/UI/DocumentTitle';

class Settings extends Component {

    componentDidMount() {
        if (!this.props.person.id) {
            this.props.getPersonData()
        }
        this.props.getPersonSettings()
        window.ym(56965879,'hit','/settings');
    }

    submitPersonInformationForm(values) {
        const { settingsCountry, prefix } = this.props.selects
        const { settingsName, settingsSurname, settingsBirthday, settingsPassport, settingsCity, settingsAddress_1, zip, phone } = values

        this.props.savePersonalInformation({
                settingsName,
                settingsSurname,
                settingsBirthday,
                settingsPassport,
                settingsCountry: settingsCountry ? settingsCountry.id : this.props.state.fields.personInformationForm.settingsCountry.value,
                settingsCity,
                settingsAddress_1,
                zip,
                prefix: prefix !== undefined ? prefix.id : this.props.state.fields.personInformationForm.prefix.value,
                phone: this.props.state.fields.personInformationForm.phone.value !== phone
                    ? (prefix !== undefined ? prefix.id : String(this.props.state.fields.personInformationForm.prefix.value)) + phone
                    : this.props.state.fields.personInformationForm.phone.value
        })
    }

    submitChangePasswordForm(values) {
        const { settingsPasswordCurrent, settingsPassNew } = values
        this.props.changePasswordSetting({ settingsPasswordCurrent, settingsPassNew })
    }

    submitMainSettingsForm(values) {
        const { settingsLang } = this.props.selects
        const { noticeArticle, noticeDays, noticeMonth, noticeNews, noticeWeek } = values
        this.props.saveMainSettings({
            noticeArticle, noticeDays, noticeMonth, noticeNews, noticeWeek,
            settingsLang: settingsLang ? settingsLang.id : this.props.state.fields.mainSettingsForm.settingsLang.value
        })
    }

    render() {
        return (
            <main className="depositing">
                <DocumentTitle title="MyAccount" />
                <h1 className="container">{Resource('MyAccount')}</h1>
                {
                        <section className="tabs js-tabs">
                            <RenderTabs page={'settings'} id='settingsTabs'>
                                <Loader loadId={SETTINGS_SUCCESS} reload>
                                    <PersonInformationForm onSubmit={this.submitPersonInformationForm.bind(this)} fields={this.props.state.fields} loading={this.props.loading} />
                                </Loader>
                                <Loader loadId={SETTINGS_SUCCESS} reload>
                                    <ChangePasswordForm onSubmit={this.submitChangePasswordForm.bind(this)} fields={this.props.state.fields} loading={this.props.loading}/>
                                </Loader>
                                <Loader loadId={SETTINGS_SUCCESS} reload>
                                    <DocsForm fields={this.props.state.fields} docs={this.props.state.docs} />
                                </Loader>
                                <Loader loadId={SETTINGS_SUCCESS} reload>
                                    <MainSettingsForm onSubmit={this.submitMainSettingsForm.bind(this)} fields={this.props.state.fields} loading={this.props.loading}/>
                                </Loader>
                            </RenderTabs>
                        </section>
                }
            </main>
        )
    }
}

const MSTP = state => {
    return {
        state: state.settings,
        selects: state.forms.selects,
        person: state.person,
        lang: state.language.langActive,
        loading: state.loading
    }
}

const MDTP = dispatch => {
    return {
        getPersonData: () => dispatch(getPersonData()),
        getPersonSettings: () => dispatch(getPersonSettings()),
        changePasswordSetting: values => dispatch(changePasswordSetting(values)),
        savePersonalInformation: personInformation => dispatch(savePersonalInformation(personInformation)),
        saveMainSettings: settingsMainModel => dispatch(saveMainSettings(settingsMainModel))
    }
}

export default connect(MSTP, MDTP)(Settings)