import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { getPersonData } from '../../../redux/actions/person.action';
import VeriffError from "./VeriffError";
import VeriffSuccess from "./VeriffSuccess";

const VeriffProcess = (props) => {

    useEffect(() => {
        let updateStatus;
        if (props.status !== 191 && props.status !== 190) {
            updateStatus = setInterval(() => {
                props.getPersonData()
            }, 3000)
        }
        return () => {
            clearInterval(updateStatus)
        }
    // eslint-disable-next-line
    }, [props.status])
    
    return (<>
        {
            props.status !== 190 && props.status !== 191
              ? <main className="veriff-modal__iframe">
                  <section>
                      <div className="veriff-modal__wrapper">
                          <img className="veriff-modal__icon veriff-modal__icon--loader" src="./image/veriff/veriff_loader.gif" alt="veriff_loader" />
                      </div>
                  </section>
              </main>
              : props.status === 190
                ? <VeriffError />
                : <VeriffSuccess />
        }
    </>);

}

const mapStateToProps = state => {
    return {
        person: state.person,
        status: state.person.veriffStatusId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPersonData: () => dispatch(getPersonData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VeriffProcess)