import React from 'react'
import Portal from './Portal'
import { connect } from 'react-redux'
import { MODAL_IS_OPEN } from '../../../redux/constants/modals.constants'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { useEffect } from 'react'
import { Resource } from '../../../redux/actions/lang.actions'
import { SEND_RESTORE_PASSWORD_SUCCESS } from '../../../redux/constants/login/auth.constants'
import LoaderButton from '../LoaderButton'


const ModalForm = ({title, id, onToggleModal, children, loading, button, addClass, handleSubmit, onCloseHandler}) => {

    return (
      <form className={['modal__wrapper box', addClass].join(' ')} onSubmit={handleSubmit} id={id} name={id}>
          <div className="modal__header">
              {title && <h2>{Resource(title)}</h2>}
              <div className="modal__close" onClick={() => {
                  onCloseHandler && onCloseHandler();
                  onToggleModal(id, false);
              }}/>
          </div>
          {children}
          {
              button && <LoaderButton
                form={id}
                type="submit"
                disabled={true}
                loading={loading[SEND_RESTORE_PASSWORD_SUCCESS]}
              >{Resource(button)}</LoaderButton>
          }
      </form>
    )
}

const ModalFormRender = reduxForm({ form: 'modalForm' })(ModalForm)

const Modal = ({ isOpen, children, title, button, handleSubmit, onToggleModal, id, addClass, change, loading, setValue, isForm, onCloseHandler, isOutside, onStart }) => {
    
    useEffect(() => {
        if(isOpen) {
            onStart && onStart();
        }
    }, [isOpen])
    
    const onClickOutside = (event) => {
        if(isOutside) {
            if (!event.target.classList.contains('modal')) return;
            onCloseHandler && onCloseHandler();
            onToggleModal(id, false);
        }
    }

    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, { onToggleModal: () => onToggleModal(id, false) });
    });
    
    return (
        <>
            {
                isOpen &&
                <Portal>
                    <div className='modal' onClick={onClickOutside} name={id + 'Div'}>
                        {
                            isForm === undefined || isForm
                                ? <ModalFormRender children={children} title={title} button={button} onSubmit={handleSubmit} onToggleModal={onToggleModal} id={id} addClass={addClass} loading={loading} onCloseHandler={onCloseHandler}/>
                                : <div className={['modal__wrapper box', addClass].join(' ')} onSubmit={handleSubmit} id={'modalForm'}>
                                      <div className="modal__header">
                                          {title && <h2>{Resource(title)}</h2>}
                                          <div className="modal__close" onClick={() => {
                                              onCloseHandler && onCloseHandler();
                                              onToggleModal(id, false);
                                          }}/>
                                      </div>
                                        {childrenWithProps}
                                  </div>
                        }
                    </div>
               </Portal>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
