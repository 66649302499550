import React, { Component } from 'react';
import Cookies from 'js-cookie'
import { connect } from 'react-redux';
import { choiceLangClick, dropdownClick } from '../../redux/actions/lang.actions';

class Language extends Component {

    renderLangList = () => {
        return this.props.lang.langList.map((lang, i) => {
            if (this.props.lang.langActive !== lang && lang !== "RU" ) {
                return (
                    <li key={lang + i}
                        onClick={event => { this.props.choiceLangClick(event.target.closest('li').dataset.lang) }}
                        data-lang={lang}
                    >
                        <span className="language-dropdown__item">
                            <img className="language-img" src={`./image/header/lang-${lang}.png`} alt={lang} />
                            <b className="language-key">{lang}</b>
                        </span>
                    </li>    
                )
            }
            return null
        })
    }

    onClickOuterElement = event => {
        if (!event.target.closest(`.language`) && this.props.lang.dropdownActive) {
            this.props.dropdownClick(false)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOuterElement, false);
    }

    componentDidMount() {
        document.addEventListener('click', this.onClickOuterElement, false);
    }

    render() {
        Cookies.set('language', this.props.lang.langActive, { expires: 999 })
        const cls = [
            'language js-toggle-language',
            this.props.lang.dropdownActive ? 'language--expanded' : null,
            this.props.className
        ]

        return (
            <span
                className={cls.join(' ')}
                onClick={() => { this.props.dropdownClick(!this.props.lang.dropdownActive) }}
            >
                <span className="language-dropdown__item active">
                    <img
                        className="language-img active"
                        src={`./image/header/lang-${this.props.lang.langActive}.png`}
                        alt={this.props.lang.langActive}
                    />
                    <b className="language-key">{
                        this.props.lang.langActive
                    }</b>
                </span>
                <ul className="language-dropdown">
                    { this.renderLangList() }
                </ul>
            </span>
        );
    };
}

const mapStateToProps = state => {
    return {
        lang: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dropdownClick: active => dispatch(dropdownClick(active)),
        choiceLangClick: event => dispatch(choiceLangClick(event))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Language)