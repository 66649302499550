import { VERIFF_TOGGLE } from "../../constants/veriff/veriff.constants";
import { PERSON_DATA_SUCCESS, VERIFY_OPEN, VERIFY_PROCESS, VERIFY_REJECT } from "../../constants/person.constants";
import {SETTINGS_SUCCESS} from "../../constants/settings/settings.constants";

const initialState = {
    fields: {
        verifyCountry: {
            id: 'verifyCountry',
            name: 'verifyCountry',
            options: [
                { id: 208, value: 'c208' },
                { id: 206, value: 'c206' },
                { id: 11, value: 'c11' },
                { id: 12, value: 'c12' },
                { id: 242, value: 'c242' },
                { id: 243, value: 'c243' },
                { id: 244, value: 'c244' },
                { id: 246, value: 'c246' },
                { id: 247, value: 'c247' },
                { id: 248, value: 'c248' },
                { id: 249, value: 'c249' },
                { id: 250, value: 'c250' },
                { id: 251, value: 'c251' },
                { id: 252, value: 'c252' },
                { id: 253, value: 'c253' },
                { id: 254, value: 'c254' },
                { id: 255, value: 'c255' },
                { id: 256, value: 'c256' },
                { id: 257, value: 'c257' },
                { id: 258, value: 'c258' },
                { id: 259, value: 'c259' },
                { id: 260, value: 'c260' },
                { id: 261, value: 'c261' },
                { id: 262, value: 'c262' },
                { id: 245, value: 'c245' },
                { id: 263, value: 'c263' },
                { id: 264, value: 'c264' },
                { id: 265, value: 'c265' },
                { id: 266, value: 'c266' },
                { id: 267, value: 'c267' }
            ],
            orderValue: 'value',
            value: 208,
            // label: 'Country'
        },
        verifyCity: {
            id: 'verifyCity',
            name: 'verifyCity',
            type: 'text',
            // label: 'City',
            placeholder: 'City',
            edit: true,
            validate: {
                required: true
            },
            // saveOnBlur: true
        },
        verifyAddress: {
            id: 'verifyAddress',
            name: 'verifyAddress',
            type: 'text',
            // label: 'AddressLine1',
            placeholder: 'AddressLine1',
            edit: true,
            validate: {
                required: true
            }
        },
        verifyZip: {
            id: 'verifyZip',
            name: 'verifyZip',
            type: 'text',
            // label: 'ZIP',
            placeholder: 'ZIP',
            edit: true,
            validate: {
                required: true
            }
        },
        prefix: {
            id: 'prefix',
            name: 'prefix',
            options: [
                { id: 43, value: '+43 AT' },
                { id: 32, value: '+32 BE' },
                { id: 359, value: '+359 BG' },
                { id: 44, value: '+44 GB' },
                { id: 36, value: '+36 HU' },
                { id: 49, value: '+49 DE' },
                { id: 30, value: '+30 GR' },
                { id: 45, value: '+45 DK' },
                { id: 353, value: '+353 IE' },
                { id: 34, value: '+34 ES' },
                { id: 39, value: '+39 IT' },
                { id: 357, value: '+357 CY' },
                { id: 371, value: '+371 LV' },
                { id: 370, value: '+370 LT' },
                { id: 352, value: '+352 LU' },
                { id: 356, value: '+356 MT' },
                { id: 31, value: '+31 NL' },
                { id: 48, value: '+48 PL' },
                { id: 351, value: '+351 PT' },
                { id: 7, value: '+7 RU' },
                { id: 40, value: '+40 RO' },
                { id: 421, value: '+421 SK' },
                { id: 386, value: '+386 SI' },
                { id: 358, value: '+358 FI' },
                { id: 33, value: '+33 FR' },
                { id: 385, value: '+385 HR' },
                { id: 420, value: '+420 CZ' },
                { id: 46, value: '+46 SE' },
                { id: 41, value: '+41 CH' },
                { id: 372, value: '+372 EE' },
                { id: 1, value: '+1 US' }
            ],
            orderValue: 'id',
            value: 43,
            // labelHidden: 'Code'
        },
        phone: {
            id: 'phone',
            name: 'phone',
            type: 'phone',
            placeholder: 'Phone1',
            // label: 'Phone',
            edit: true,
            numberFormat: true,
            validate: {
                required: true
            }
        },
    },
    showVeriff: false,
    blockButton: false,
    veriffStatus: VERIFY_PROCESS
}

const veriff = (state = initialState, action) => {
    switch (action.type) {
        case VERIFF_TOGGLE:
            return {
                ...state,
                showVeriff: action.payload !== '' ? action.payload : !state.showVeriff
            }
        case SETTINGS_SUCCESS:
            
            const {
                firstName,
                lastName,
                settingsCountry,
                settingsCity,
                settingsAddress_1,
                zip,
                phone,
                prefix,
                veriffStatusId,
                verifyCode
            } = action.payload.personInformation

            let getVeriffStatusCode = ''

            switch (verifyCode) {
                case 274: getVeriffStatusCode = 'c274'
                    break;
                case 275: getVeriffStatusCode = 'c275'
                    break;
                case 276: getVeriffStatusCode = 'c276'
                    break;
                case 277: getVeriffStatusCode = 'c277'
                    break;
                case 278: getVeriffStatusCode = 'c278'
                    break;
                case 279: getVeriffStatusCode = 'c279'
                    break;
                case 280: getVeriffStatusCode = 'c280'
                    break;
                case 282: getVeriffStatusCode = 'c282'
                    break;
                default: getVeriffStatusCode = 'c283'
                    break;
            }
            
            return {
                ...state,
                fields: {
                    ...state.fields,
                    verifyCountry: {
                        ...state.fields.verifyCountry,
                        value: settingsCountry ? settingsCountry : 208
                    },
                    verifyCity: {
                        ...state.fields.verifyCity,
                        value: settingsCity ?? ''
                    },
                    verifyAddress: {
                        ...state.fields.verifyAddress,
                        value: settingsAddress_1 ?? ''
                    },
                    verifyZip: {
                        ...state.fields.verifyZip,
                        value: zip ?? ''
                    },
                    prefix: {
                        ...state.fields.prefix,
                        value: Number(prefix) || 43
                    },
                    phone: {
                        ...state.fields.phone,
                        value: phone ? phone : ''
                    },
                },
                veriffStatus: veriffStatusId,
                progressText: veriffStatusId === VERIFY_OPEN || veriffStatusId === VERIFY_PROCESS
                    ? 'VeriffInProcess'
                    : veriffStatusId === VERIFY_REJECT
                        ? 'IdentFailed'
                        : 'ProgressIdentifyDesc',
                expiredText: getVeriffStatusCode
            }
        default:
            return state
    }
}

export default veriff
