import React, { useEffect } from "react";
import Cookies from "js-cookie";
import "./DevPage.scss";


const DevPage = () => {
    useEffect(() => {
        Cookies.set('language', 'RU', { expires: 999 });
        window.history.back();
    }, [])

    return(
        <div className="dev-loading">
            Loading...
        </div>
    )
};

export { DevPage };