import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import RenderSelect from '../../formComponents/RenderSelect'
import ReCaptcha from '../../ReCaptcha/ReCaptcha'
import { Resource } from '../../../../redux/actions/lang.actions'
import RenderPassword from '../../formComponents/RenderPassword'
import LoaderButton from '../../LoaderButton'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

const IndividualForm = props => {

    const dispatch = useDispatch();

    useLayoutEffect(()=>{
        const promo = Cookies.get('ref_code');
        if(promo){
            dispatch({
                type: '@@redux-form/CHANGE', 
                meta:{form: "individualForm", field: "individualPromocode", touch: false, persistentSubmitErrors: false}, 
                payload: promo
            });
            dispatch({
                type: 'CREATE_ACCOUNT/CHANGE_VALUE_FIELD', 
                payload: false
            })
        }
    }, [])

    return (
        <form onSubmit={props.handleSubmit}>
            <div className="box panel">
                <div className="form__fields-group form__fields-group_phone">
                    <RenderSelect data={props.fields['individualGender']} />
                    <RenderInput data={props.fields['individualName']} />
                </div>
                    <RenderInput data={props.fields['individualEmail']} />
                    <RenderPassword data={props.fields['individualPassword']} />
                    <RenderInput showField={props.changeField} data={props.fields['individualPromocode']} />

                <p className="register-agreement">
                    {Resource('UserAgreement', 'text_1')}
                    <a href={props.state.userAgreement.link_1[1]}>{Resource('UserAgreement', props.state.userAgreement.link_1[0])}</a>
                    {Resource('UserAgreement', 'text_2')}
                    <a href={props.state.userAgreement.link_2[1]}>{Resource('UserAgreement', props.state.userAgreement.link_2[0])}</a>
                    {Resource('UserAgreement', 'text_3')}
                </p>
                <ReCaptcha tokenVerify={props.tokenVerify}/>
                <LoaderButton loading={props.loading} disabled={props.valid}>{Resource('Continue')}</LoaderButton>
                <p className="centered">
                    <Link className="register-link" to='/'>{Resource('AlreadyHaveAccount')}</Link>
                </p>
            </div>
        </form>
    )
}

export default reduxForm({ form: 'individualForm' })(IndividualForm)