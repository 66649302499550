import { requestGet } from "../../redux/api/request";
import { BAN } from "./actions/actionsTypes";
import { checkBanCards } from "./actions/actionsCreators";

export const checkCards = ( dispatch ) => {
    requestGet('Paytool/GetActiveCards')
        .then(resp => {
            if (resp.data.result === "success") {
                dispatch(checkBanCards(resp.data.message));
            }
        })
};


const initialState = {
    status: false,
  };
  
  const banReducer = (state = initialState, action) => {
      switch (action.type) {
        case BAN.CHECK_CARD:
          return {
              ...state, status: action.payload
          }
          default:
              return state
      }
  }
  
  export default  banReducer;