import { LANG_DROPLIST, LANG_CHANGE, TOGGLE_MENU } from "../../constants/lang.constants"
import Cookies from 'js-cookie'

const initialState = {
    langList: ['EN', 'RU', 'ES', 'DE', 'IT'],
    langActive: !!Cookies.get('language') ? Cookies.get('language') : 'EN',
    dropdownActive: false,
    burgerActive: false
}

const lang = (state = initialState, action) => {
    switch (action.type) {
        case LANG_DROPLIST:
            return {
                ...state,
                dropdownActive: action.payload
            }
        case LANG_CHANGE:
            return {
                ...state,
                langActive: action.payload
            }
        case TOGGLE_MENU:
            return {
                ...state,
                burgerActive: action.payload
            }
        default:
            return state
    }
}

export default lang
