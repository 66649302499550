import { SETTINGS_REQUEST, SETTINGS_SUCCESS, SETTINGS_FAILURE, SETTINGS_SAVE_SUCCESS } from '../../constants/settings/settings.constants'
import { FORMS_SHOW_PASSWORD } from '../../constants/forms.constants'
import { LOADING } from '../../constants/loading.constants'

const initialState = {
    fields: {
        personInformationForm: {
            settingsName: {
                id: 'settingsName',
                name: 'settingsName',
                type: 'text',
                required: true,
                label: 'PersonName',
                edit: true,
                validate: {
                    required: true
                },
            },
            settingsSurname: {
                id: 'settingsSurname',
                name: 'settingsSurname',
                type: 'text',
                required: true,
                label: 'PersonLastName',
                description: 'NameChangeWarning',
                edit: true,
            },
            email: {
                id: 'email',
                name: 'email',
                type: 'email',
                required: true,
                label: 'Email',
                description: 'EmailChangeInfo',
                edit: false
            },
            prefix: {
                id: 'prefix',
                name: 'prefix',
                options: [
                    { id: 43, value: '+43 AT' },
                    { id: 32, value: '+32 BE' },
                    { id: 359, value: '+359 BG' },
                    { id: 44, value: '+44 GB' },
                    { id: 36, value: '+36 HU' },
                    { id: 49, value: '+49 DE' },
                    { id: 30, value: '+30 GR' },
                    { id: 45, value: '+45 DK' },
                    { id: 353, value: '+353 IE' },
                    { id: 34, value: '+34 ES' },
                    { id: 39, value: '+39 IT' },
                    { id: 357, value: '+357 CY' },
                    { id: 371, value: '+371 LV' },
                    { id: 370, value: '+370 LT' },
                    { id: 352, value: '+352 LU' },
                    { id: 356, value: '+356 MT' },
                    { id: 31, value: '+31 NL' },
                    { id: 48, value: '+48 PL' },
                    { id: 351, value: '+351 PT' },
                    { id: 7, value: '+7 RU' },
                    { id: 40, value: '+40 RO' },
                    { id: 421, value: '+421 SK' },
                    { id: 386, value: '+386 SI' },
                    { id: 358, value: '+358 FI' },
                    { id: 33, value: '+33 FR' },
                    { id: 385, value: '+385 HR' },
                    { id: 420, value: '+420 CZ' },
                    { id: 46, value: '+46 SE' },
                    { id: 41, value: '+41 CH' },
                    { id: 372, value: '+372 EE' },
                    { id: 1, value: '+1 US' }
                ],
                orderValue: 'id',
                value: 43,
                labelHidden: 'Code'
            },
            phone: {
                id: 'phone',
                name: 'phone',
                type: 'phone',
                label: 'Phone',
                description: 'PhoneChangeInfo',
                edit: true,
                validate: {
                    required: true
                },
                numberFormat: true
            },
            settingsBirthday: {
                id: 'settingsBirthday',
                name: 'settingsBirthday',
                type: 'text',
                required: true,
                label: 'BirthDate',
                edit: true,
                placeholder: 'PlacehoderBirthDate',
                validate: {
                    date: true
                },
                dateFormat: true,
            },
            settingsPassport: {
                id: 'settingsPassport',
                name: 'settingsPassport',
                type: 'text',
                required: true,
                label: 'IDNumber',
                edit: true
            },
            settingsCountry: {
                id: 'settingsCountry',
                name: 'settingsCountry',
                options: [
                    { id: 208, value: 'c208' },
                    { id: 206, value: 'c206' },
                    { id: 11, value: 'c11' },
                    { id: 12, value: 'c12' },
                    { id: 242, value: 'c242' },
                    { id: 243, value: 'c243' },
                    { id: 244, value: 'c244' },
                    { id: 246, value: 'c246' },
                    { id: 247, value: 'c247' },
                    { id: 248, value: 'c248' },
                    { id: 249, value: 'c249' },
                    { id: 250, value: 'c250' },
                    { id: 251, value: 'c251' },
                    { id: 252, value: 'c252' },
                    { id: 253, value: 'c253' },
                    { id: 254, value: 'c254' },
                    { id: 255, value: 'c255' },
                    { id: 256, value: 'c256' },
                    { id: 257, value: 'c257' },
                    { id: 258, value: 'c258' },
                    { id: 259, value: 'c259' },
                    { id: 260, value: 'c260' },
                    { id: 261, value: 'c261' },
                    { id: 262, value: 'c262' },
                    { id: 245, value: 'c245' },
                    { id: 263, value: 'c263' },
                    { id: 264, value: 'c264' },
                    { id: 265, value: 'c265' },
                    { id: 266, value: 'c266' },
                    { id: 267, value: 'c267' }
                ],
                orderValue: 'value',
                value: 208,
                label: 'Country'
            },
            settingsCity: {
                id: 'settingsCity',
                name: 'settingsCity',
                type: 'text',
                required: false,
                label: 'City',
                edit: true
            },
            settingsAddress_1: {
                id: 'settingsAddress_1',
                name: 'settingsAddress_1',
                type: 'text',
                required: false,
                label: 'AddressLine1',
                edit: true
            },
            zip: {
                id: 'zip',
                name: 'zip',
                type: 'text',
                required: false,
                label: 'ZIP',
                edit: true,
                indexFormat: true
            }
        },
        changePasswordForm: {
            settingsPasswordCurrent: {
                id: 'settingsPasswordCurrent',
                name: 'settingsPasswordCurrent',
                type: 'password',
                required: true,
                label: 'CurrentPassword',
                edit: true
            },
            settingsPassNew: {
                id: 'settingsPassNew',
                name: 'settingsPassNew',
                type: 'password',
                required: true,
                label: 'NewPassword',
                description: 'NewPasswordInfo',
                edit: true,
                password: true
            },
            settingsPassRepeat: {
                id: 'settingsPassRepeat',
                name: 'settingsPassRepeat',
                type: 'password',
                required: true,
                label: 'RepeatPassword',
                description: 'PasswordsMakeSure',
                edit: true,
                password: true,
                validate: {
                    match: 'settingsPassNew'
                }
            }
        },
        docsForm: {
            settingsBank: {
                id: 'settingsBank',
                name: 'settingsBank',
                type: 'text',
                required: true,
                label: 'BankName',
                description: 'PersonalDataChangeInfo',
                edit: false
            },
            settingsBankNumber: {
                id: 'settingsBankNumber',
                name: 'settingsBankNumber',
                type: 'text',
                required: true,
                label: 'BankAccountNumber',
                description: 'PersonalDataChangeInfo',
                edit: false
            }
        },
        mainSettingsForm: {
            settingsLang: {
                id: 'settingsLang',
                name: 'settingsLang',
                options: [
                    { id: 183, value: 'English' },
                    { id: 182, value: 'Русский' },
                    { id: 216, value: 'Español' },
                    { id: 230, value: 'Deutsch' },
                    { id: 501, value: 'italiano' },
                ],
                orderValue: 'id',
                value: 183,
                label: 'PrefferedLanguage'
            },
            noticeNews: {
                id: 'noticeNews',
                name: 'noticeNews',
                type: 'checkbox',
                label: 'News'
            },
            noticeArticle: {
                id: 'noticeArticle',
                name: 'noticeArticle',
                type: 'checkbox',
                label: 'Articles'
            },
            noticeDays: {
                id: 'noticeDays',
                name: 'noticeDays',
                type: 'checkbox',
                label: 'DailyReport'
            },
            noticeWeek: {
                id: 'noticeWeek',
                name: 'noticeWeek',
                type: 'checkbox',
                label: 'WeeklyReport'
            },
            noticeMonth: {
                id: 'noticeMonth',
                name: 'noticeMonth',
                type: 'checkbox',
                label: 'MonthlyReport'
            }
        }
    }
}

const settings = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_REQUEST:
            return { ...state, loading: true }
        case SETTINGS_SUCCESS:
            const {
                settingsName,
                settingsSurname,
                email,
                prefix,
                phone,
                settingsBirthday,
                settingsPassport,
                settingsCountry,
                settingsCity,
                settingsAddress_1,
                zip,
                verif,

                settingsBank,
                settingsBankNumber,

                settingsLang,
                noticeNews,
                noticeArticle,
                noticeDays,
                noticeWeek,
                noticeMonth

            } = action.payload.personInformation
            return {
                ...state,
                fields: {
                    ...state.fields,
                    personInformationForm: {
                        ...state.fields.personInformationForm,
                        settingsName: {
                            ...state.fields.personInformationForm.settingsName,
                            value: settingsName,
                            verif
                        },
                        settingsSurname: {
                            ...state.fields.personInformationForm.settingsSurname,
                            value: settingsSurname,
                            verif
                        },
                        email: {
                            ...state.fields.personInformationForm.email,
                            value: email
                        },
                        prefix: {
                            ...state.fields.personInformationForm.prefix,
                            value: Number(prefix) || 43
                        },
                        phone: {
                            ...state.fields.personInformationForm.phone,
                            value: phone
                        },
                        settingsBirthday: {
                            ...state.fields.personInformationForm.settingsBirthday,
                            value: settingsBirthday
                        },
                        settingsPassport: {
                            ...state.fields.personInformationForm.settingsPassport,
                            value: settingsPassport
                        },
                        settingsCountry: {
                            ...state.fields.personInformationForm.settingsCountry,
                            value: Number(settingsCountry) || 208
                        },
                        settingsCity: {
                            ...state.fields.personInformationForm.settingsCity,
                            value: settingsCity
                        },
                        settingsAddress_1: {
                            ...state.fields.personInformationForm.settingsAddress_1,
                            value: settingsAddress_1
                        },
                        zip: {
                            ...state.fields.personInformationForm.zip,
                            value: zip
                        }
                    },
                    docsForm: {
                        ...state.fields.docsForm,
                        settingsBank: {
                            ...state.fields.docsForm.settingsBank,
                            value: settingsBank
                        },
                        settingsBankNumber: {
                            ...state.fields.docsForm.settingsBankNumber,
                            value: settingsBankNumber
                        }
                    },
                    mainSettingsForm: {
                        ...state.fields.mainSettingsForm,
                        settingsLang: {
                            ...state.fields.mainSettingsForm.settingsLang,
                            value: Number(settingsLang) || 183
                        },
                        noticeNews: {
                            ...state.fields.mainSettingsForm.noticeNews,
                            value: noticeNews
                        },
                        noticeArticle: {
                            ...state.fields.mainSettingsForm.noticeArticle,
                            value: noticeArticle
                        },
                        noticeDays: {
                            ...state.fields.mainSettingsForm.noticeDays,
                            value: noticeDays
                        },
                        noticeWeek: {
                            ...state.fields.mainSettingsForm.noticeWeek,
                            value: noticeWeek
                        },
                        noticeMonth: {
                            ...state.fields.mainSettingsForm.noticeMonth,
                            value: noticeMonth
                        }
                    }
                },
                docs: action.payload.portfolioPersons,
                loading: false
            }
        case SETTINGS_FAILURE:
            return { ...state, error: action.error, loading: false }
        case SETTINGS_SAVE_SUCCESS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    personInformationForm: {
                        ...state.fields.personInformationForm,
                        settingsName: {
                            ...state.fields.personInformationForm.settingsName,
                            value: action.payload.personInformation.settingsName
                        },
                        settingsSurname: {
                            ...state.fields.personInformationForm.settingsSurname,
                            value: action.payload.personInformation.settingsSurname
                        },
                        email: {
                            ...state.fields.personInformationForm.email,
                            value: action.payload.personInformation.email
                        },
                        prefix: {
                            ...state.fields.personInformationForm.prefix,
                            value: Number(action.payload.personInformation.prefix) || 43
                        },
                        phone: {
                            ...state.fields.personInformationForm.phone,
                            value: action.payload.personInformation.phone
                        },
                        settingsBirthday: {
                            ...state.fields.personInformationForm.settingsBirthday,
                            value: action.payload.personInformation.settingsBirthday
                        },
                        settingsPassport: {
                            ...state.fields.personInformationForm.settingsPassport,
                            value: action.payload.personInformation.settingsPassport
                        },
                        settingsCountry: {
                            ...state.fields.personInformationForm.settingsCountry,
                            value: Number(action.payload.personInformation.settingsCountry)
                        },
                        settingsCity: {
                            ...state.fields.personInformationForm.settingsCity,
                            value: action.payload.personInformation.settingsCity
                        },
                        settingsAddress_1: {
                            ...state.fields.personInformationForm.settingsAddress_1,
                            value: action.payload.personInformation.settingsAddress_1
                        },
                        zip: {
                            ...state.fields.personInformationForm.zip,
                            value: action.payload.personInformation.zip
                        }
                    },
                    mainSettingsForm: {
                        ...state.fields.mainSettingsForm,
                        settingsLang: {
                            ...state.fields.mainSettingsForm.settingsLang,
                            value: Number(action.payload.personInformation.settingsLang)
                        },
                        noticeNews: {
                            ...state.fields.mainSettingsForm.noticeNews,
                            value: action.payload.personInformation.noticeNews
                        },
                        noticeArticle: {
                            ...state.fields.mainSettingsForm.noticeArticle,
                            value: action.payload.personInformation.noticeArticle
                        },
                        noticeDays: {
                            ...state.fields.mainSettingsForm.noticeDays,
                            value: action.payload.personInformation.noticeDays
                        },
                        noticeWeek: {
                            ...state.fields.mainSettingsForm.noticeWeek,
                            value: action.payload.personInformation.noticeWeek
                        },
                        noticeMonth: {
                            ...state.fields.mainSettingsForm.noticeMonth,
                            value: action.payload.personInformation.noticeMonth
                        }
                    }
                },
                loading: false
            }
        case FORMS_SHOW_PASSWORD:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    changePasswordForm: {
                        ...state.fields.changePasswordForm,
                        [action.id]: {
                            ...state.fields.changePasswordForm[action.id],
                            password: action.show
                        }
                    }
                }
            }
        case LOADING:
            return {...state, loading: action.loading}
        default:
            return state
    }
}

export default settings
