import { POPUP_INFO } from "./actions/actionsTypes";

const initialState = {
    popupInfoOpen: false,
    popupInfoCheck: false,
};

const popupInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case POPUP_INFO.POPUP_INFO_TOGGLE:
            return {
                ...state,
                popupInfoOpen: action.payload
            }
        case POPUP_INFO.POPUP_INFO_CHECK:
        return {
            ...state,
            popupInfoCheck: action.payload
        }
        default:
            return state
    }
}

export default  popupInfoReducer;