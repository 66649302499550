import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { Resource } from "../../../redux/actions/lang.actions"; 
import { MyInvitations } from "../MyInvitations/MyInvitations";
import TabsComponent from "../Tabs/TabsComponent";
import TabComponentItem from "../Tabs/TabsComponentItem";
import classes from "./MyInvitationsTabs.module.scss";

const MyInvitationsTabs = ({ list }) => {
  const language = useSelector((state) => state.language);
  useEffect(() => {}, [language]);
  const pending = [];
  const bonusAccrued = [];
  const noBonus = [];

  list.length &&
    list.forEach((item) => {
      switch (item.status) {
        case "Waiting":
          pending.push(item);
          break;
        case "Bonus received":
          bonusAccrued.push(item);
          break;
        case "Bonus not received":
          noBonus.push(item);
          break;
      }
    });

  return (
    <div className={classes.invitations}>
      <TabsComponent id={"myInvitationsTabs"} customClasses={classes}>
        <TabComponentItem label={Resource("refLinkTab1")}>
          <MyInvitations list={pending} />
        </TabComponentItem>
        <TabComponentItem label={Resource("refLinkTab2")}>
          <MyInvitations list={bonusAccrued} />
        </TabComponentItem>
        <TabComponentItem label={Resource("refLinkTab3")}>
          <MyInvitations list={noBonus} />
        </TabComponentItem>
      </TabsComponent>
    </div>
  );
};

export { MyInvitationsTabs };
