import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Router, useLocation } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux'

import store from './redux/store'
import App from './App';
import { history } from './redux/history';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


export const ScrollToTopOnMount = () => {
    let { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

unregister();
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <Router history={history}>
                <ScrollToTopOnMount />
                <Route path="/" component={App} />
            </Router>
        </BrowserRouter>
    </Provider>,
  rootElement);

//registerServiceWorker();

