import React from 'react'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { loginGoogle } from '../../redux/actions/login/auth.google.actions'

const SocialAuth = (props) => {

    //const onFailure = error => {
    //    alert(error)
    //}

    const responseGoogle = (response) => {
        console.log(response);
        props.loginGoogle(response.tokenId);
        //const tokenBlob = new Blob([JSON.stringify({ tokenId: response.tokenId }, null, 2)], { type: 'application/json' });
        //const options = {
        //    method: 'POST',
        //    body: tokenBlob,
        //    mode: 'cors',
        //    cache: 'default'
        //};
        //fetch('281951027450-b7hp4277a882kvem0n416hqbr5chij52.apps.googleusercontent.com', options)
        //    .then(res => {
        //        res.json().then(user => {
        //            const token = user.token;
        //            console.log(token);
        //            props.login(token);
        //        });
        //    })
    }

    const responseFacebook = (response) => {
        console.log(response);
    }

    const responseTwitter = (response) => {
        console.log(response);
    }

    return (
        <>
            <nav className="register-socials">
                {/* <a className="register-socials__icon icon-oauth icon_facebook" href="#"></a>
                            <a className="register-socials__icon icon-oauth icon_twitter" href="#"></a> */}
                <GoogleLogin
                    clientId="281951027450-b7hp4277a882kvem0n416hqbr5chij52.apps.googleusercontent.com"
                    render={renderProps => <span disabled={renderProps.disabled} onClick={renderProps.onClick} className="register-socials__icon icon-oauth icon_google"></span>}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                <FacebookLogin
                    appId="123123123123123"
                    autoLoad={false}
                    render={renderProps => <span disabled={renderProps.disabled} onClick={renderProps.onClick} className="register-socials__icon icon-oauth icon_facebook"></span>}
                    onSuccess={responseFacebook}
                    onFailure={responseFacebook}
                />
            </nav>
            <h2 className="register-breaker"><span>или по электронной почте</span></h2>
        </>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth.googleAuth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginGoogle: (token) => dispatch(loginGoogle(token))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialAuth))