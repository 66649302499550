import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import Stats from '../components/Blocks/Stats';
import RenderTabs from '../components/UI/RenderTabs'
import Slider from '../components/Blocks/Slider';
import { connect } from 'react-redux';
import { getPortfolioDetails, getPortfolioDepositing } from '../redux/actions/portfolio/portfolio.actions';
import { PORTFOLIO_DETAILS_SUCCESS, PORTFOLIO_DEPOSITING_SUCCESS } from '../redux/constants/portfolio/portfolio.constants';
import DepositForm from '../components/UI/formControls/portfolio/deposit.form';
import GridTables from '../components/Blocks/GridTables/GridTables';
import { Resource } from '../redux/actions/lang.actions';
import DocumentTitle from '../components/UI/DocumentTitle';

class PortfolioDetails extends Component {
    static displayName = PortfolioDetails.name;
    myref = createRef()

    componentDidMount = () => {
        this.props.getPortfolioDetails(this.props.match.params.id)

    }

    onDepositingHandler = (values) => {
        const { depositValue } = values
        this.props.getPortfolioDepositing(this.props.portfolio.portfolioId, depositValue)
    }
    
    render() {
        return (
            <div>
                <main className="portfolio">
                    <DocumentTitle title='PortfolioID' dynamic={`#${this.props.match.params.id}`}/>
                    <div className="combo-title container">

                        <h1>{Resource('PortfolioID')} #{this.props.match.params.id} ({Resource(this.props.portfolio.ruleId)})</h1>
                        
                        {/* <ul className="combo-title__aside mobile-hidden">
                            {
                                this.props.portfolio.documentMainUrl === ''
                                    ? <li className="aside__warning">{Resource('DownloadWarning')} <Link to="/settings">{Resource('DownloadWarningNext')}</Link></li>
                                    : null
                            }

                            <a
                                className={[
                                    'button button--small button--stroke mobile-hidden',
                                    this.props.portfolio.documentMainUrl === '' ? 'button--disabled' : null
                                ].join(' ')}
                                disabled={this.props.portfolio.documentMainUrl === ''}
                                href={this.props.portfolio.documentMainUrl} download
                            >
                                <span>{Resource('DownloadAgreement')}</span>
                            </a>
                        </ul> */}
                    </div>

                    <section>
                        <div className="box panel panel_azure">
                            <Slider data={this.props.portfolio} className='widget--swiped' />
                        </div>
                    </section>

                    {
                        this.props.portfolio.aviableRepayment
                           ? <section>
                                <div className="box panel">
                                    <ul className="portfolio-refill columns_3">
                                        <li><h2 className="portfolio-refill__heading">{Resource('DepositPortfolio')}</h2></li>
                                        <Stats id='balance' data={this.props.portfolio} load={PORTFOLIO_DETAILS_SUCCESS} reload/>
                                        <li>
                                            <DepositForm
                                                fields={this.props.fields}
                                                onSubmit={this.onDepositingHandler.bind(this)}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            : null
                    }

                    <section className="tabs js-tabs">
                        <RenderTabs id='portfolioTabs'>
                            <GridTables paginationId='invest' data={this.props.tableInvest} loading={this.props.loading} />
                            <GridTables paginationId='deposit' data={this.props.tableDeposit} loading={this.props.loading} />
                        </RenderTabs>
                    </section>
                </main>
            </div>
        )
    };

}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        portfolio: state.portfolio,
        fields: state.portfolio.fields,
        loading: state.loading[PORTFOLIO_DETAILS_SUCCESS],

        tableInvest: state.portfolio.portfolioTable.invest,
        tableDeposit: state.portfolio.portfolioTable.deposit,

        lang: state.language.langActive,

        person: state.person
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPortfolioDetails: portfolioId => dispatch(getPortfolioDetails(portfolioId)),
        getPortfolioDepositing: (portfolioId, amount) => dispatch(getPortfolioDepositing(portfolioId, amount))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioDetails))