import { PERSON_DATA_SUCCESS, PERSON_DATA_FAILED } from "../../constants/person.constants"

const initialState = {
    currentBalanceDepositing: {
        valueKey: 'AccountSumAvailable',
        value: '',
        icon: 'wallet',
        formatting: true,
        hiddenKey: true
    },
    currentBalance: {
        valueKey: 'AccountSumAvailable',
        value: '',
        icon: 'wallet',
        formatting: true
    },
    investBalance: {
        valueKey: 'InvestmentsSumTotal',
        value: '',
        icon: 'briefcase',
        formatting: true
    },
    portfolioCount: {
        valueKey: 'InvestmentsCountTotal',
        value: '',
        icon: 'layers',
        formatting: false
    },
    interestSum: {
        valueKey: 'AccuredIncome',
        value: '',
        icon: 'percents',
        formatting: true
    }
}

const person = (state = initialState, action) => {
    switch (action.type) {
        case PERSON_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                currentBalanceDepositing: {
                    ...state.currentBalanceDepositing,
                    value: action.payload.currentBalance
                },
                currentBalance: {
                    ...state.currentBalance,
                    value: action.payload.currentBalance
                },
                investBalance: {
                    ...state.investBalance,
                    value: action.payload.investBalance
                },
                portfolioCount: {
                    ...state.portfolioCount,
                    value: action.payload.portfolioCount
                },
                interestSum: {
                    ...state.interestSum,
                    value: action.payload.interestSum
                }
            }
        case PERSON_DATA_FAILED:
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}

export default person