import {
    DEPOSITING_DATA_SUCCESS,
    DEPOSITING_DATA_FAILURE,
    DEPOSITING_CHANGE_STATUS,
    DEPOSITING_GET_URL_SUCCESS,
    DEPOSITING_SET_VALUE
} from "../../constants/depositing/depositing.constants"
import {addedSessionData} from "../../utils/different";

const initialState = {
    idPage: 'depositing',
    contentPayCard: {
        fields: {
            prefix: {
                id: 'prefix',
                name: 'prefix',
                options: [
                    { id: 43, value: '+43 AT' },
                    { id: 32, value: '+32 BE' },
                    { id: 359, value: '+359 BG' },
                    { id: 44, value: '+44 GB' },
                    { id: 36, value: '+36 HU' },
                    { id: 49, value: '+49 DE' },
                    { id: 30, value: '+30 GR' },
                    { id: 45, value: '+45 DK' },
                    { id: 353, value: '+353 IE' },
                    { id: 34, value: '+34 ES' },
                    { id: 39, value: '+39 IT' },
                    { id: 357, value: '+357 CY' },
                    { id: 371, value: '+371 LV' },
                    { id: 370, value: '+370 LT' },
                    { id: 352, value: '+352 LU' },
                    { id: 356, value: '+356 MT' },
                    { id: 31, value: '+31 NL' },
                    { id: 48, value: '+48 PL' },
                    { id: 351, value: '+351 PT' },
                    { id: 7, value: '+7 RU' },
                    { id: 40, value: '+40 RO' },
                    { id: 421, value: '+421 SK' },
                    { id: 386, value: '+386 SI' },
                    { id: 358, value: '+358 FI' },
                    { id: 33, value: '+33 FR' },
                    { id: 385, value: '+385 HR' },
                    { id: 420, value: '+420 CZ' },
                    { id: 46, value: '+46 SE' },
                    { id: 41, value: '+41 CH' },
                    { id: 372, value: '+372 EE' },
                    { id: 1, value: '+1 US' }
                ],
                orderValue: 'id',
                value: '+43 AT',
                labelHidden: 'Code'
            },
            phone: {
                id: 'phone',
                name: 'phone',
                type: 'phone',
                label: 'Phone',
                edit: true,
                validate: {
                    required: true
                },
                numberFormat: true
            },
            depositingCountry: {
                id: 'depositingCountry',
                name: 'depositingCountry',
                options: [
                    { id: 208, value: 'c208' },
                    { id: 206, value: 'c206' },
                    { id: 11, value: 'c11' },
                    { id: 12, value: 'c12' },
                    { id: 242, value: 'c242' },
                    { id: 243, value: 'c243' },
                    { id: 244, value: 'c244' },
                    { id: 246, value: 'c246' },
                    { id: 247, value: 'c247' },
                    { id: 248, value: 'c248' },
                    { id: 249, value: 'c249' },
                    { id: 250, value: 'c250' },
                    { id: 251, value: 'c251' },
                    { id: 252, value: 'c252' },
                    { id: 253, value: 'c253' },
                    { id: 254, value: 'c254' },
                    { id: 255, value: 'c255' },
                    { id: 256, value: 'c256' },
                    { id: 257, value: 'c257' },
                    { id: 258, value: 'c258' },
                    { id: 259, value: 'c259' },
                    { id: 260, value: 'c260' },
                    { id: 261, value: 'c261' },
                    { id: 262, value: 'c262' },
                    { id: 245, value: 'c245' },
                    { id: 263, value: 'c263' },
                    { id: 264, value: 'c264' },
                    { id: 265, value: 'c265' },
                    { id: 266, value: 'c266' },
                    { id: 267, value: 'c267' }
                ],
                orderValue: 'value',
                value: 208,
                label: 'Country'
            },
            depositingCity: {
                id: 'depositingCity',
                name: 'depositingCity',
                type: 'text',
                label: 'City',
                edit: true,
                validate: {
                    required: true
                },
                // saveOnBlur: true
            },
            depositingAddress_1: {
                id: 'depositingAddress_1',
                name: 'depositingAddress_1',
                type: 'text',
                label: 'AddressLine1',
                edit: true,
                validate: {
                    required: true
                }
            },
            zip: {
                id: 'zip',
                name: 'zip',
                type: 'text',
                label: 'ZIP',
                edit: true,
                validate: {
                    required: true
                }
            },
            depositValue: {
                id: 'depositValue',
                name: 'depositValue',
                type: 'text',
                placeholder: 'Sum',
                labelDown: 'SumAmount',
                container: false,
                edit: true,
                maxLength: 6,
                validate: {
                    required: true,
                    range: [10, 10000]
                },
                depositFormat: true,
                setValue: true,
                value: null
            }
        },
    },
    contentPayScore: {
        title: 'DepositViaBankAccount',
        descriptionInfo: 'PaymentPurposeInfo',
        descriptionSepa: 'SEPAInfo',
        requisites: {
            company: {
                title: 'CompanyName',
                text: 'OÜ NIBBLE ITSF'
            },
            bank: {
                title: 'Bank',
                text: '"Paysera LT", UAB'
            },
            swift: {
                title: 'SWIFT',
                text: 'EVIULT2VXXX'
            },
            number: {
                title: 'AccountNumber',
                text: 'LT55 3500 0100 0733 7093'
            },
            purpose: {
                title: 'PurposeOfPayment',
                text: 'Deposit for account'
            }
        },
        data: {
            status: false
        }
    },
    depositingTable: {
        id: 'depositing',
        displayLength: 8,
        columnsOptions: {
            id: {
                title: 'ID', flexGrow: 0.5, minWidth: 50, sort: true, align: 'center'
            },
            operations: {
                title: 'Operation', flexGrow: 3, minWidth: 200, sort: true, format: 'string'
            },
            deposit: {
                title: 'Income', flexGrow: 1, minWidth: 100, sort: true, format: 'currency'
            },
            status: {
                title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
            },
            dateOperation: {
                title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
            }
        },
        body: []
    },
    data: {
        status: true
    }
}

const depositing = (state = initialState, action) => {
    switch (action.type) {
        case DEPOSITING_DATA_SUCCESS:

            const getDataTable = () => {
                return action.dataTable.map(({ id, wayId, deposit, status, dateOperation }) => {
                    return {
                        id,
                        operations: wayId === 334
                            ? 'PaymentsOpertions_334'
                            : wayId === 150
                                ? 'PaymentsOpertions_150'
                                : wayId === 355
                                    ? 'PaymentsOpertions_355'
                                    : '',
                        deposit: deposit,
                        status: status === 64
                            ? 'STATUS_005'
                            : status === 63
                                ? 'STATUS_004'
                                : status === 65
                                    ? 'STATUS_006'
                                    : '',
                        dateOperation: dateOperation
                    }
                })
            }


            const {
                phonePrefix,
                phone,
                country,
                city,
                address,
                zip
            } = action.payload

            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                    status: !phonePrefix || !phone || !country || !city || !address || !zip
                },
                contentPayCard: {
                    ...state.contentPayCard,
                    fields: {
                        ...state.contentPayCard.fields,
                        prefix: {
                            ...state.contentPayCard.fields.prefix,
                            value: Number(phonePrefix) || 43
                        },
                        phone: {
                            ...state.contentPayCard.fields.phone,
                            value: phone ? phone : ''
                        },
                        depositingCountry: {
                            ...state.contentPayCard.fields.depositingCountry,
                            value: Number(country) || 208
                        },
                        depositingCity: {
                            ...state.contentPayCard.fields.depositingCity,
                            value: city
                        },
                        depositingAddress_1: {
                            ...state.contentPayCard.fields.depositingAddress_1,
                            value: address
                        },
                        zip: {
                            ...state.contentPayCard.fields.zip,
                            value: zip
                        }
                    }
                },
                contentPayScore: {
                    ...state.contentPayScore,
                    requisites: {
                        ...state.contentPayScore.requisites,
                        purpose: {
                            ...state.contentPayScore.requisites.purpose,
                            text: 'Deposit for account ' + action.payload.personId
                        }
                    }
                },
                depositingTable: {
                    ...state.depositingTable,
                    body: getDataTable()
                }
            }
        case DEPOSITING_DATA_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case DEPOSITING_CHANGE_STATUS:
            return {
                ...state,
                data: {
                    ...state.data,
                    status: action.payload
                }
            }
        case DEPOSITING_GET_URL_SUCCESS:
            return {
                ...state,
                payData: action.payData
            }
        case DEPOSITING_SET_VALUE:
            return {
                ...state,
                contentPayCard: {
                    ...state.contentPayCard,
                    fields: {
                        ...state.contentPayCard.fields,
                        depositValue: {
                            ...state.contentPayCard.fields.depositValue,
                            value: action.amountValue
                        }
                    }
                }
            }
        default:
            return state
    }
}

export default depositing
