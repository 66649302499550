import React from "react";
import Button from "../../Button";
import { reduxForm, change, initialize } from "redux-form";
import RenderInput from "../../formComponents/RenderInput";
import { useEffect } from "react";
import RenderPhone from "../../formComponents/RenderPhone";
import { Resource } from "../../../../redux/actions/lang.actions";
import RenderSelect from "../../formComponents/RenderSelect";
import RenderInputVerify from "../../formComponents/RenderInputVerify";

const VeriffForm = (props) => {
  useEffect(() => {
    Object.keys(props.fields).map((key) => {
      if (key === "phone") {
        props.dispatch(
          change(
            props.form,
            props.fields[key].id,
            String(props.fields[key].value).substring(
              String(props.fields["prefix"].value).length
            )
          )
        );
      } else {
        props.dispatch(
          change(
            props.form,
            props.fields[key].id,
            String(props.fields[key].value).trim()
          )
        );
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h3 className="veriff__title">{Resource("Identification")}</h3>
      <p className="veriff__subtitle">
        {Resource("IdentificationDescription")}
      </p>
      <form className="veriff-form" onSubmit={props.handleSubmit}>
        <RenderSelect data={props.fields["verifyCountry"]} />
        <RenderInput data={props.fields["verifyCity"]} />
        <RenderInput data={props.fields["verifyAddress"]} />
        <RenderInput data={props.fields["verifyZip"]} />

        {!props.fields["phone"] ||
        props.fields["phone"].value === "" ||
        Number(props.fields["phone"].value) === NaN ||
        !props.fields["prefix"] ||
        props.fields["prefix"].value === "" ? (
          <div className="form__group">
            <div className="form__fields-group">
              <RenderSelect data={props.fields["prefix"]} />
              <RenderInput data={props.fields["phone"]} />
            </div>
          </div>
        ) : null}

        <div className="veriff-form__buttons">
          <Button
            //disabled={props.submitSucceeded}
            disabled={props.disableVerifBtn}
            addClass="button_primary"
          >
            {Resource("Next")}
          </Button>
          <span className="veriff__close" onClick={props.onToggleModal}>
            {Resource("BackButton")}
          </span>
          {/*<Button*/}
          {/*    type='button'*/}
          {/*    onClick={props.toggleVeriffPanel}*/}
          {/*    stroke*/}
          {/*>{Resource('Cancel')}</Button>*/}
        </div>
        {/*<div id="veriff-root"/>*/}
      </form>
    </>
  );
};

export default reduxForm({ form: "veriffForm" })(VeriffForm);
