const GET_LAYOUT_DATA = 'GET/LAYOUT_DATA';

export const getLayoutData = (data) => dispatch => {
  dispatch({type: GET_LAYOUT_DATA, data})
}

const initialState = {}

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {  
    case GET_LAYOUT_DATA:
      return {
        ...action.data
      }
    default:
      return state
  }
}

export default LayoutReducer
