import {get as getCookie} from "js-cookie";
import {Resource} from "../../redux/actions/lang.actions";
import {currencyFormatProfit} from "../../redux/utils/formatting";

export const formattingData = (value) => {
  // console.log(value)
  return value
}

// Validation
export const rangeNumbers = (ranges, value) => {
  // console.log('rangeNumbers')
  let [min, max] = ranges
  let currentValue = value.replace(/[^0-9\.]/g, '')
  if(Number(currentValue) < min) return `NoticeMinSum ${min}`
  if(Number(currentValue) > max) return `NoticeMaxSum ${max}`
  return undefined
}

//Formatting
const currencyFormat = (value) => String(value).replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const isCurrency = (params, event) => {
  let {target} = event
  let {prefix} = params
  let formattingValue = '';
  
  if(target.value.toString().indexOf('.') >= 0) {
    let decimal = target.value.toString().indexOf(".");
    let left = target.value.toString().substring(0, decimal);
    let right = target.value.toString().substring(decimal);

    left = currencyFormat(left);
    right = currencyFormat(right);

    right = right.substring(0, 2);
    
    formattingValue = prefix + left + '.' + right
  } else {
    if(target.value === '' || target.value === prefix) {
      formattingValue = ''
    } else {
      formattingValue = prefix + currencyFormat(target.value)
    }
  }

  target.value = formattingValue
  return formattingValue
}

// Получить последнюю дату текущего месяца
export const getLastDayCurrentMonth = () => {
  let currentDate = new Date(),
    month = currentDate.getMonth(),
    year = currentDate.getFullYear();

  month = month + 1;
  let date = new Date(year, month, 0).getDate();
  return `${date < 10 ? '0' + date : date}.${month < 10 ? '0' + month : month}.${year}`;
}

// Получить первую дату следующего месяца
export const getFirstDayNextMonth = () => {
  let currentDate = new Date(),
      month = currentDate.getMonth(),
      year = currentDate.getFullYear();
  
  month = month + 1 === 12 ? 1 : month + 2;
  year = month === 1 ? year + 1 : year
  let date = new Date(year, month, 1).getDate();
  return `${date < 10 ? '0' + date : date}.${month < 10 ? '0' + month : month}.${year}`;
}

// Вывод шаблонов с динамическими данными
export const getTemplateOfValues = (string, params) => {
  return string.split('/').map((key, i) => {
    if(params[key]) {
      return params[key]
    } 
    return key
  })
}

// Сортировка данных по строке
export const sortBy = (data, key, type) =>  data.sort((a, b) => type === 'desc' ? b[key] - a[key] : a[key] - b[key])