import React, { Component } from 'react';
import Helper from '../../components/UI/Modal/Helper';

export class FooterPages extends Component {

    render() {    
        return (
            <>
                <footer className={`footer ${this.props.activeMenu ? 'footer--visible' : ''}`}>
                    <div className="container">
                        <nav className="footer-contacts">
                            <div>
                                <a className="footer-contacts__phone" href="tel:+3726093461">+3726093461</a>
                            </div>
                            <a className="footer-contacts__email" href="mailto:support@nibble.finance">support@nibble.finance</a>
                        </nav>
                    </div>
                </footer>
                <Helper />
            </>
        ); 
    };
}