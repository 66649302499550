import React from 'react'
import Button from '../../Button'
import { reduxForm } from 'redux-form'
import RenderTextarea from '../../formComponents/RenderTextarea'
import { Resource } from '../../../../redux/actions/lang.actions'
import LoaderButton from '../../LoaderButton'
import { SUPPORT_SEND_MESSAGE_SUCCESS } from '../../../../redux/constants/support/support.constants'


const SupportForm = ({ fields, handleSubmit, loading }) => {

    return (
        <>
            <form onSubmit={handleSubmit} className="chat__send">
                <RenderTextarea data={fields['supportMessage']} />
                <LoaderButton loading={loading[SUPPORT_SEND_MESSAGE_SUCCESS]} disabled>{Resource('SendMessageBtn')}</LoaderButton>
            </form>
        </>
    )
}

export default reduxForm({ form: 'supportForm' })(SupportForm)


