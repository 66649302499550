import React from 'react'
import { Resource } from '../../../../redux/actions/lang.actions'

const ContentPayScore = ({ data }) => {

    const renderRequisites = () => {
        const { requisites } = data
        return Object.keys(requisites).map((item, i) => {
            return (
                <li key={requisites[item] + i}>
                    <span className="depositing-props__key">{Resource(requisites[item].title)}</span>
                    <span className="depositing-props__value">{requisites[item].text}</span>
                </li>    
            )
            
        })
    }

    return (
        <div className="columns_1">
            <div className="box card">
                <h3 className="box__heading">{Resource(data.title)}</h3>
                <p className="box__text box__text_accent">{Resource(data.descriptionInfo)} <br /> {Resource(data.descriptionSepa)} </p>
                <ul className="depositing-props columns_3">
                    { renderRequisites() }
                </ul>
            </div>
        </div>
    )
}

export default ContentPayScore