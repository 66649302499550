import React from 'react'
import { connect } from 'react-redux'
import { MODAL_IS_OPEN, HELPER_GET_INFO } from '../../../redux/constants/modals.constants'

const HelperLink = ({ children, onToggleModal, getHelperInfo, id, keyHelper, info, className }) => {

    const onClickHelper = () => {
        getHelperInfo(info[keyHelper].title, info[keyHelper].text, info[keyHelper].graph)
        onToggleModal(id, true)
    }

    return (
        <i className={['helper__text', className].join(' ')} onClick={onClickHelper}>{children}</i>
    )
}

const mapStateToProps = state => {
    return {
        id: 'helper',
        info: state.modals['helper'].info
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHelperInfo: (title, text, graph) => dispatch({ type: HELPER_GET_INFO, payload: { title, text, graph } }),
        onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelperLink)
