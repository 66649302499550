import React, {useEffect, useRef, useState} from 'react'
import SliderRange from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Resource } from '../../../redux/actions/lang.actions';
import { calculateFormatting, onlyNumber } from '../../../redux/utils/formatting';
import {changeStatusCheckbox, setCurrentValue} from "../../../redux/actions/profile/strategys.actions";
import CalculatorForm from "../../UI/formControls/calculator.form";
import {Field, reduxForm, focus} from "redux-form";
import {renderValidation} from "../../UI/formComponents/Validation";
import RenderCustomField from "../../UI/formComponents/RenderCustomField";
import {compose} from "redux";
import {connect} from "react-redux";
import {switchId} from "../../../redux/constants/profile/strategys.constants";

const Calculator = (props) => {

  const nameProduct = switchId(props.idProduct)
  const { startSum, reinvestSum, term } = props.data[nameProduct]
  
  return (
    <form className='calculator__form'>

      <RenderCustomField 
        data={props.data.fields['customAmount']}
        strategys={props.data}
        idProduct={props.idProduct}
        fixedValues={props.fixedValues}
        setCurrentValue={props.setCurrentValue}
        dataValues={startSum}
        nameProduct={nameProduct}
        valuesId={'startSum'}
      />
      <RenderCustomField
        data={props.data.fields['customTerm']}
        strategys={props.data}
        idProduct={props.idProduct}
        fixedValues={props.fixedValues}
        setCurrentValue={props.setCurrentValue}
        dataValues={term}
        nameProduct={nameProduct}
        valuesId={'term'}
      />
      
      <div className="calculator__checkbox">
        {props.data.fields['checkDeposit'].label 
          && <span className="calculator__checkbox__label">
              {Resource(props.data.fields['checkDeposit'].label)}
            </span>}
        <div className="checkbox">
          <Field
            component='input'
            id={props.data.fields['checkDeposit'].id}
            name={props.data.fields['checkDeposit'].name}
            type={props.data.fields['checkDeposit'].type}
            onChange={event => {
              props.dispatch(changeStatusCheckbox(event))
            }}
          />
          <label className="checkbox__body" htmlFor={props.data.fields['checkDeposit'].id} />
        </div>
      </div>

      {
        props.data.fields['checkDeposit'].checked
          ? <RenderCustomField
            data={props.data.fields['customReinvest']}
            strategys={props.data}
            idProduct={props.idProduct}
            fixedValues={props.fixedValues}
            setCurrentValue={props.setCurrentValue}
            dataValues={reinvestSum}
            nameProduct={nameProduct}
            valuesId={'reinvestSum'}
          />
          : null
      }
    </form>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.langActive
  }
}

export default compose(connect(mapStateToProps, null), reduxForm({ form: 'CalculatorForm' }))(Calculator);