import { LOADING } from "../../constants/loading.constants"
import { requestGet, errorHandler } from "../../api/request"
import { PARTNER_DATA_SUCCESS, PARTNER_DATA_FAILURE } from "../../constants/partner/partner.constants"

export const getPartnerInformation = () => {
    return dispatch => {
        requestGet('Partner/GetPartnerInformation')
            .then(resp => {
                const { cashGroupList, link, promocode, referalUsers, registrationInformation, replenishmentInformation } = resp.data
                
                dispatch({
                    type: PARTNER_DATA_SUCCESS,
                    payload: {
                        cashGroupList,
                        link,
                        promocode: promocode.toUpperCase(),
                        referalUsers
                    },
                    dataTable: {
                        registrationInformation: registrationInformation,
                        replenishmentInformation: replenishmentInformation
                    }
                })
                dispatch({ type: LOADING, id: PARTNER_DATA_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: PARTNER_DATA_SUCCESS, loading: false })
                dispatch({ type: PARTNER_DATA_FAILURE, error })
            })
        dispatch({ type: LOADING, id: PARTNER_DATA_SUCCESS, loading: true })
    }
}