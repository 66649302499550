import React, {useState} from 'react';
import Panel from "../Panel/Panel";
import classes from './CardPortfolio.module.scss'
import {DEPOSITING_GET_URL_SUCCESS} from "../../../redux/constants/depositing/depositing.constants";
import {VERIFF_SESSION_SUCCESS} from "../../../redux/constants/veriff/veriff.constants";
import FieldsWithButton from "../FormsController/FieldsWithButton";
import {Resource} from "../../../redux/actions/lang.actions";
import {localeMonthDate} from "../../../locale/tables.locale";
import {get as getCookie} from "js-cookie";
import LinkButton from "../Buttons/LinkButton/LinkButton";
import {currentFormatting} from "../../../redux/utils/formatting";
import {VERIFY_CONFIRM} from "../../../redux/constants/person.constants";
import {connect} from "react-redux";
import {MODAL_IS_OPEN} from "../../../redux/constants/modals.constants";
import {getVeriffSession} from "../../../redux/actions/veriff/veriff.actions";
import {depositCurrentPortfolio} from "../../redux/portfolio-reducer";
import IconButton from "../Buttons/IconButton/IconButton";
import iconArrow from "../../../static/image/icons/icon-arrow-btn.svg";
import {OnlyMobile} from "../Tools/OnlyDevice/OnlyDevice";

const CardInfo = props => {
  
  return (
    <div className={[classes.info, props.className].join(' ')}>
      <span className={classes.label}>{props.label}</span>
      <span className={classes.value}>{props.value}</span>
    </div>
  )
}

const Progress = props => {
  
  const getWidthProgress = (current, full) => (current * 100) / full + '%'
  
  return (
    <div className={classes.progress}>
      <span style={{width: getWidthProgress(props.current, props.full)}}/>
    </div>
  )
}

const CardPortfolio = props => {
  
  const getNamePortfolio = (type) => {
    switch (type) {
      case 1:
        return Resource('LongTerm');
      case 2:
        return Resource('Test');
      case 3: // Старая Классическая стратегия
        return Resource('StrategyClassicTitle');
      case 4:
        return Resource('StrategyBalancedTitle');
      case 5:
        return Resource('StrategySpecialTitle');
      case 6:
          return "BayBack";
      case 7:
          return Resource('StrategyJudicialTitle');
      case 8: // Новая Классическая стратегия
        return Resource('StrategyClassicTitle');
      case 9: // Новая Сбалансированная стратегия
        return Resource('StrategyBalancedTitle');
      default:
        return null
    }
  }
  
  const addedZeroForDate = (date) => date < 10 ? '0' + date : date
  
  const getCountDays = (endDate, disabled) => {
    const currentDateDay = new Date();
    currentDateDay.setHours(0, 0, 0, 0);
    const endDateParse = new Date(Date.parse(endDate)),
          day = addedZeroForDate(endDateParse.getDate()),
          month = addedZeroForDate(endDateParse.getMonth() + 1),
          year = endDateParse.getFullYear();
    const deltaDays = Math.floor((endDateParse.getTime() - currentDateDay.getTime())/(1000*60*60*24));
    return disabled || deltaDays < 0 ? `${day}.${month}.${year}` : `${deltaDays} ${Resource('DaysLabel')} / ${day}.${month}.${year}`
  }

  const [disableVerifBtn, setVerifBtnStatus] = useState(false)
  
  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false)

  const submitDepositingPayment = (values, errors) => {
    // setCurrentDepostiValue(values.depositValue.replace(/[^0-9\.]/, ''))
    if(props.person.veriffStatusId === VERIFY_CONFIRM) {
      console.log('Верификация пройдена. Провести пополнение портфеля')
      setIsLoadingDeposit(true)
      props.depositCurrentPortfolio(props.id, values[`depositValue${props.id}`].replace(/[^0-9/./,]/g, ''))
        .then(() => {setIsLoadingDeposit(false)})
    } else {
      if(!props.isFullFields) {
        console.log('Обязательные поля не заполнены. Открыть модальное окно с полями для заполнения')
        props.onToggleModal('modalDepositingVeriff', true)
        setIsLoadingDeposit(false)
      } else {
        console.log('Верификация не пройдена. Открыть верификацию')
        setVerifBtnStatus(true)
        props.getVeriffSession({personId: props.person.id, setVerifBtnStatus, firstName: props.person.firstName, lastName: props.person.lastName})
        setIsLoadingDeposit(false)
      }
    }
  }

  const declOfNum = (n, text_forms) => {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];
  }
  
  return (
    <Panel customClass={classes.card}>
      
      <h4 className={classes.title}>{getNamePortfolio(props.type)} {props.percent}%</h4>
      <span className={classes.term}>{props.term} {declOfNum(props.term, [Resource('MonthOne'), Resource('MonthSeveral'), Resource('MonthMany')])}</span>
      
      <CardInfo 
        label={Resource('InvestmentAmount')}
        value={props.amount ? currentFormatting(props.amount) : '–'}
        className={classes.invest}
      />

      <CardInfo
        label={Resource('UntilTheEnd') + ':'}
        value={props.endDate ? getCountDays(props.endDate, props.closed) : '–'}
        className={classes.date}
      />
      
      <div className={classes.income}>
        <div className={classes.label}>
          <span>{Resource('CurrentIncome')}: {props.currentIncome ? currentFormatting(props.currentIncome) + ` ${Resource('Out')} ` + currentFormatting(props.expectedIncome) : '–'}</span>
          {/*Mobile*/}
          {/*<LinkButton to={`/portfolio/${props.id}`}>Подробнее</LinkButton>*/}
        </div>
        <Progress current={props.currentIncome} full={props.expectedIncome}/>
      </div>
        
        {/*Desktop*/}

        {
          props.deleteLink
            ? <button className={classes.delete} onClick={props.onDelete ? props.onDelete : () => {}}>{Resource('Delete')}</button>
            : <LinkButton to={`/portfolio/${props.id}`} style={{display: !props.linkDisabled ? 'block' : 'none'}}>{Resource('NextMoreText')}</LinkButton>
        }
        <FieldsWithButton
          unicId={props.id}
          fields={props.fields}
          className={classes.deposit}
          btnText={Resource('DepositFunds')}
          disabledField={props.disabledField}
          offline={props.closed}
          onSubmit={props.onSubmitDeposit ? props.onSubmitDeposit : submitDepositingPayment}
          defValue={props.defValue}
          loading={isLoadingDeposit}
          padding={'36px var(--maxWidthBtn) 8px var(--leftOffset)'}
          // loading={props.loading[DEPOSITING_GET_URL_SUCCESS] || props.loading[VERIFF_SESSION_SUCCESS]}
        />
      
    </Panel>
  )
}

const mapStateToProps = state => {
  
  return {
    person: state.person,
    balancedReducer: state.BalancedReducer,
    language: state.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVeriffSession: params => dispatch(getVeriffSession(params)),
    onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
    depositCurrentPortfolio: (portfolioId, amount) => dispatch(depositCurrentPortfolio(portfolioId, amount))
  } 
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPortfolio)