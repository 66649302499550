// Отображение окна с просьбой оценить платформу, после пополнения портфеля
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupSlide } from "../ui/PopupSlide/PopupSlide";
import { judicialDbPopupToggle } from "../../redux/actions/actionsCreators";
import { Locale } from "../../../locale/locale";
import { Resource } from "../../../redux/actions/lang.actions";
import "./JudicialSlideDb.scss";

const JudicialSlideDb = () => {

    const judicialDbOpen = useSelector(state => state.PopupReducer.judicialDbOpen);
    const dispatch = useDispatch();


    const setActive = status => {
        dispatch(judicialDbPopupToggle(status));
    };


    return(
        <PopupSlide 
            active={judicialDbOpen}
            setActive={setActive}
        >
           <div className="judicial-slide">
                <div className="judicial-slide__content">
                    <div className="judicial-slide__informer">
                        <div className="judicial-slide__informer">
                            Info
                        </div>
                    </div>
                        <div dangerouslySetInnerHTML={{__html: Resource("JudiciaDb")}} ></div>
              </div>
           </div>
            
        </PopupSlide>
    )
};

export { JudicialSlideDb };