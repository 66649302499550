import React from 'react';
import classes from './Panel.module.scss';

const Panel = (props) => {
  return (
    <div className={[classes.panel, props.customClass].join(' ')} style={props.style}>
      {props.children}
    </div>
  )
}

export default Panel;