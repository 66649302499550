import {errorHandler, requestGet, requestPost} from "../../redux/api/request";
import {setDepositPayments, setDepositPersonData, setDepositValueField} from "./balanced-reducer";
import {sortBy, SortBy} from "../components/tools";
import {getPersonData} from "../../redux/actions/person.action";
import {history} from "../../redux/history";

const INVESTMENT_SET_ACTIVE = 'INVESTMENT/SET_ACTIVE'
const INVESTMENT_SET_PLAN = 'INVESTMENT/SET_PLAN'
const INVESTMENT_SET_CLOSED = 'INVESTMENT/SET_CLOSED'
export const IS_LOADING_PORTFOLIO = 'IS_LOADING_PORTFOLIO'
const INVESTMENT_INCOME_HISTORY = 'INVESTMENT/SET_INCOME_HISTORY'
const INVESTMENT_SET_DATE_END = 'INVESTMENT/INVESTMENT_SET_DATE_END'
export const JUDICIAL_SET_DATE_END = 'JUDICIAL/JUDICIAL_SET_DATE_END'

export const setData = (type, payload) => dispatch => {
  dispatch({ type, payload })
}

// PLAN = 170,
// ACTIVE = 171,
// CLOSED = 172

export const getInvestmentData = () => dispatch => {
    dispatch(setData(IS_LOADING_PORTFOLIO, true))
    return requestGet('Portfolio/GetInvestmetns')
      .then(resp => {
        if(resp.status === 200) {
          let data = resp.data
          let activePortfolio = data.filter(portfolio => portfolio.statusId === 171).sort((a, b) => b.id - a.id);
          let planPortfolio = data.filter(portfolio => portfolio.statusId === 170).sort((a, b) => b.id - a.id);
          let closedPortfolio = data.filter(portfolio => portfolio.statusId === 172).sort((a, b) => {
            return Date.parse(b.endDate) - Date.parse(a.endDate)
          });
          
          dispatch(setData(INVESTMENT_SET_ACTIVE, activePortfolio))
          dispatch(setData(INVESTMENT_SET_PLAN, planPortfolio))
          dispatch(setData(INVESTMENT_SET_CLOSED, closedPortfolio))
          dispatch(setData(IS_LOADING_PORTFOLIO, false))
        } else {
          console.log('Error: ', resp)
          // throw new Error()
        }
      })
      .catch(error => {
        dispatch(setData(IS_LOADING_PORTFOLIO, false))
      })
}

export const getIncomeHistory = (count) => dispatch => {
  return requestGet(`Person/GetIncomeOperationsHistory?count=${count}`)
    .then(resp => {
      if(resp.status === 200) {
        dispatch(setData(INVESTMENT_INCOME_HISTORY, resp.data))
      }
    })
    .catch(error => {

    })
}

export const activatePortfolioDraft = ({portfolioId, amount = null}) => {
  return dispatch => {
    dispatch(setData(IS_LOADING_PORTFOLIO, true))
    return requestPost(`Portfolio/PortfolioDraftActivate?portfolioId=${portfolioId}`)
      .then(resp => {
        if(resp.status === 200) {
          if(resp.data.error) {
            throw new Error(resp.data.error.errorCode)
          }
          dispatch(getInvestmentData());
          dispatch(getPersonData());
        }
      })
      .catch(error => {
        if(error.message === 'ERROR_008') {
          dispatch(setDepositValueField(amount))
          history.push('/depositing');
        }
        errorHandler(dispatch, error)
      })
  }
}

export const deletePortfolioDraft = (portfolioId) => {
  return dispatch => {
    dispatch(setData(IS_LOADING_PORTFOLIO, true))
    return requestPost(`Portfolio/PortfolioDraftDelete?portfolioId=${portfolioId}`)
      .then(resp => {
        if(resp.status === 200) {
          dispatch(getInvestmentData());
        }
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getDateEndReinvestmentAsync = (portfolioId, dispatch) => {
  requestGet("Portfolio/GetDateEndReinvestment?portfolioId=" + portfolioId).then(
    (resp) => {
      // console.log(resp);
      if (resp.data.result === "success") {
        dispatch(setData(INVESTMENT_SET_DATE_END, { id: portfolioId, dateEnd: resp.data.message.dateEndReinvestment }))
        dispatch(setData(JUDICIAL_SET_DATE_END, { id: portfolioId, dateEnd: resp.data.message.dateEndReinvestment }))
      }
    })
    .catch(error => {
      console.log(error);
    })
};

const initialState = {
    active: [],
    closed: [],
    plan: [],
    historyIncome: [],
    isLoadingPortfolios: true
}

const InvestmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case INVESTMENT_SET_ACTIVE:
        return {
          ...state,
          active: [...action.payload]
        }
      case INVESTMENT_SET_PLAN:
        return {
          ...state,
          plan: [...action.payload]
        }
      case INVESTMENT_SET_CLOSED:
        return {
          ...state,
          closed: [...action.payload]
        }
      case INVESTMENT_INCOME_HISTORY:
        return {
          ...state,
          historyIncome: [...action.payload]
        }
      case IS_LOADING_PORTFOLIO:
        return {
          ...state,
          isLoadingPortfolios: action.payload
        }
      case INVESTMENT_SET_DATE_END:
        const newActive = state.active.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item,  dateEndReinvestment: action.payload.dateEnd };
          } else {
            return item;
          }
        });

        return {
          ...state,
          active: [...newActive]
        }
      default:
          return state
    }
}

export default InvestmentReducer
