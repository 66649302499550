import { requestPost, requestGet } from "../../redux/api/request";
import { INVITE } from "./actions/actionsTypes";
import { setInviteFriends, setMyInvitations } from "./actions/actionsCreators";

export const invitefriendsRequest = (dispatch, personId) => {
  const request = requestGet(
    `Referral/GetBringFriendMainInfo?personId=${personId}`
  );
  return request
    .then((resp) => {
      if (resp.data.result === "success") {
        if (resp.data.message.promoCodeId) {
          dispatch(
            setInviteFriends(
              resp.data.message.promoCodeId,
              resp.data.message.refLink,
              resp.data.message.totalLinkCount,
              resp.data.message.readLinkCount
            )
          );
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const myInvitationsRequest = (dispatch, personId) => {
  const request = requestGet(
    `Referral/GetBringFriendMainInfoDetails?personId=${personId}`
  );
  return request
    .then((resp) => {
      if (resp.data.result === "success") {
        if (resp.data.message.promoCodeId) {
          dispatch(
            setMyInvitations(
              resp.data.message.promoCodeId,
              resp.data.message.refLink,
              resp.data.message.totalLinkCount,
              resp.data.message.readLinkCount,
              resp.data.message.totalInterest,
              resp.data.message.gotBonusByFriend,
              resp.data.message.details
            )
          );
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const initialState = {
  promoCodeId: "",
  refLink: "",
  totalLinkCount: 0,
  readLinkCount: 0,
  totalInterest: 0,
  gotBonusByFriend: 0,
  details: [],
  isLoading: true,
  redirect: false,
};

const invitefriendsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE.INVITE_FRIENDS:
      return {
        ...state,
        promoCodeId: action.payload.promoCodeId,
        refLink: action.payload.refLink,
        totalLinkCount: action.payload.totalLinkCount,
        readLinkCount: action.payload.readLinkCount,
        isLoading: false,
      };
    case INVITE.MY_INVITE:
      return {
        ...state,
        promoCodeId: action.payload.promoCodeId,
        refLink: action.payload.refLink,
        totalLinkCount: action.payload.totalLinkCount,
        readLinkCount: action.payload.readLinkCount,
        totalInterest: action.payload.totalInterest,
        gotBonusByFriend: action.payload.gotBonusByFriend,
        details: action.payload.details,
      };
    case INVITE.REDIRECT:
      return {
        ...state,
        redirect: true,
      };
    default:
      return state;
  }
};
export default invitefriendsReducer;
