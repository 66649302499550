import React from 'react';
import classes from './IconLinks.module.scss'
import {Link} from "react-router-dom";

const IconLink = props => {
  return (
    <div className={[classes.link, props.className['document'], !props.link ? props.className['disabled'] : ''].join(' ')}>
      {
        props.to
          ? <Link to={props.to}>{props.children}</Link>
          : props.link
              ? <a download href={props.link}>{props.children}</a>
              : <span>{props.children}</span>
      }
      <img src={props.icon} alt={props.icon}/>
    </div>
  )
}

export default IconLink;