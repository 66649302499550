import React from 'react'
import Button from './Button'
import SpinnerButton from './SpinnerButton'

const LoaderButton = ({ children, loading, disabled, form, addClass, type, onClick }) => {

    return (
        <>
            {
                !!loading
                    ? <Button onClick={onClick} type={type} addClass={['button--loader', addClass].join(' ')} form={form} disabled={!!loading}><SpinnerButton /></Button>
                    : <Button onClick={onClick} type={type} addClass={addClass} form={form} disabled={!disabled}>{children}</Button>
            }
        </>
    )
}

export default LoaderButton