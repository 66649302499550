import React, { useEffect } from "react";
import { Resource } from "../../../redux/actions/lang.actions";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import IconButton from "../../components/Buttons/IconButton/IconButton";
import { currentFormatting } from "../../../redux/utils/formatting";
import iconPlus from "../../../static/image/icons/icon-plus-btn.svg";
import iconArrow from "../../../static/image/icons/icon-arrow-btn.svg";
import {
  PERSON_DATA_SUCCESS,
  VERIFY_CONFIRM,
} from "../../../redux/constants/person.constants";
import {
  IconInfo,
  IconStrategy,
  IconFilter,
  IconArrow,
  IconBack,
  IconPeople,
} from "../../static/Icons/Icons";
import Currency from "../../components/сurrency/Currency";
import Panel from "../../components/Panel/Panel";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import "./JudicialPortfolioPage.scss";
import { JudicialPortfolioStatus } from "../../components/JudicialPortfolioStatus/JudicialPortfolioStatus";
import { JudicialPortfolioDescription } from "../../components/JudicialPortfolioDescription/JudicialPortfolioDescription";
import { PortfolioCardNew } from "../../components/PortfolioCardNew/PortfolioCardNew";
import { getJudicialPortfolioOne } from "../../redux/judicial-reducer";
import BackButton from "../../components/Buttons/BackButton/BackButton";
import { IconDoc } from "../../static/Icons/Icons";
import classes from "../Portfolio/PortfolioPage.module.scss";
import { getPortfolioData } from "../../redux/portfolio-reducer";
import iconDocument from "../../static/Icons/document.svg";
import DropdownList from "../../components/DropdownList/DropdownList";
import SpinnerLoad from "../../components/SpinnerLoading/SpinnerLoading";
import { PortfoliosInfoSlide } from "../../components/PortfoliosInfoSlide/PortfoliosInfoSlide";
import { PortfoliosInfo2Slide } from "../../components/PortfoliosInfo2Slide/PortfoliosInfo2Slide";

const JudicialPortfolioPage = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();
  const person = useSelector((state) => state.person);
  const language = useSelector((state) => state.language);
  const judicial = useSelector((state) => state.judicialReducer);
  const isPortfolio = param.id.includes("p_");

  useEffect(() => {}, [language]);

  useEffect(() => {
    getJudicialPortfolioOne(dispatch, param.id, person.id, 7);
    window.ym(56965879, "hit", "/judicial/" + param.id);
  }, []);

  // const docInfo = () =>{
  //     return judicial.judicialPortfolios.length > 0  ? true : false;
  // }
  const addedZeroForDate = (date) => (date < 10 ? "0" + date : date);

  const formatDate = (date) => {
    const currentDateDay = new Date();
    currentDateDay.setHours(0, 0, 0, 0);
    const endDateParse = new Date(Date.parse(date)),
      day = addedZeroForDate(endDateParse.getDate()),
      month = addedZeroForDate(endDateParse.getMonth() + 1),
      year = endDateParse.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const setPortfolios = () => {
    return judicial.judicialPortfolios.length > 0 ? (
      judicial.judicialPortfolios.map((portfolio) => (
        <div
          key={portfolio.investedObject.id + "trn"}
          className="portfolios__item"
        >
          <PortfolioCardNew
            id={
              isPortfolio ? portfolio.portfolioId : portfolio.investedObject.id
            }
            rulesId={7}
            percent={
              isPortfolio
                ? portfolio.percent
                : portfolio.investedObject.percentMax
            }
            monthCount={portfolio.investedObject.monthCount}
            availableForInvestment={
              portfolio.investedObject.availableForInvestment
            }
            avgExpectedInterest={isPortfolio ? portfolio.expectedInterest : 0}
            endDate={portfolio.endDate}
            remind={portfolio.investedObject.remind}
            totalAmount={portfolio.investedObject.totalAmount}
            currentInterest={isPortfolio ? portfolio.currentInterest : 0}
            amount={isPortfolio ? portfolio.portfolioAmount : 0}
            endAvailableDate={portfolio.investedObject.endAvailableDate}
            defValue={100}
            btnText={isPortfolio ? "DepositFunds" : "Инвестировать"}
            label={isPortfolio ? "SumAmount" : "Сумма инвестиции"}
            judicial
            isPortfolio={isPortfolio}
            more
            bonus={judicial.bonus}
          />
        </div>
      ))
    ) : (
      <SpinnerLoad />
    );
  };

  return (
    <main className="judicial-portfolio">
      <DocumentTitle title={"PortfolioID"} />

      <div className="judicial-portfolio__sidebar">
        <Panel customClass="judicial-portfolio__panel">
          <Currency
            column
            order
            className="judicial-portfolio__currency"
            amount={currentFormatting(person.currentBalance.value)}
            text={[Resource("Balance")]}
            loadId={PERSON_DATA_SUCCESS}
          />

          <div className="judicial-portfolio__action">
            <IconButton
              id={"depositing_link"}
              className="judicial-portfolio__icon-button"
              column
              icon={iconPlus}
              link={"/depositing"}
            >
              {Resource("DepositAccountMobile")}
            </IconButton>
            <IconButton
              id={"withdrawal_link"}
              className="judicial-portfolio__icon-button"
              column
              icon={iconArrow}
              link={"/withdrawal"}
            >
              {Resource("WithdrawFundsMobile")}
            </IconButton>
          </div>
        </Panel>
      </div>

      <div className="judicial-portfolio__content">
        <BackButton
          className={"judicial-portfolio__back"}
          onClickHandler={() => {
            history.go(-1);
          }}
        />
        <h1 className="judicial-portfolio__content-title">
          {Resource("PortfolioID")} #{param.id.replace("p_", "")}
        </h1>

        <div className="portfolio-judicial-card">{setPortfolios()}</div>
        <div className="portfolio-info">
          {judicial.judicialPortfolios.length > 0 && (
            <JudicialPortfolioStatus
              statusId={judicial.judicialPortfolios[0].mfcLoanInfo.statusId}
            />
          )}
          {judicial.judicialPortfolios.length > 0 && (
            <JudicialPortfolioDescription
              info={judicial.judicialPortfolios[0].mfcLoanInfo.info}
            />
          )}
        </div>

        <div className="doc-info">
          {isPortfolio &&
          judicial.judicialPortfolios[0]?.investmentsInformation?.length > 0 ? (
            <DropdownList
              icon={iconDocument}
              label={`${Resource("JudicialAgreement")}: ${
                judicial.judicialPortfolios[0].investmentsInformation.length
              }`}
              disabled={false}
              customClass={""}
            >
              {
                <div className="doc-list">
                  {judicial.judicialPortfolios[0].investmentsInformation
                    .length > 0 &&
                    judicial.judicialPortfolios[0].investmentsInformation.map(
                      (item) => {
                        return item.documentUrl ? (
                          <a
                            key={item.id}
                            className="doc-list__item"
                            href={item.documentUrl}
                            download
                          >
                            {Resource("JudicialAgreement") +
                              " №" +
                              item.id +
                              ", " +
                              formatDate(item.dateCreate)}
                          </a>
                        ) : (
                          <span
                            key={item.id}
                            className="doc-list__item doc-list__item--disable"
                          >
                            {Resource("JudicialAgreement") +
                              " №" +
                              item.id +
                              ", " +
                              formatDate(item.dateCreate)}
                          </span>
                        );
                      }
                    )}
                </div>
              }
            </DropdownList>
          ) : (
            isPortfolio && <SpinnerLoad />
          )}
        </div>
      </div>
      {/* <JudicialSlide /> */}
      <PortfoliosInfoSlide />
      <PortfoliosInfo2Slide />
    </main>
  );
};

export { JudicialPortfolioPage };
