import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import RenderInput from '../../formComponents/RenderInput'
import { Resource } from '../../../../redux/actions/lang.actions'

const DocsForm = props => {

    const fieldID = props.fields[props.form]
    // eslint-disable-next-line
    {/* const docsID = props.docs */ }

    useEffect(() => {
        for (let key in fieldID)
            props.change(fieldID[key].id, fieldID[key].value)
    // eslint-disable-next-line
    }, [])

    return (
        <form id='docs_button' className="settings__form columns_2">
            <div className="box card">
                <h3 className="box__heading">{Resource('PaymentOnformation')}</h3>

                <RenderInput notValue data={fieldID['settingsBank']} />
                <RenderInput notValue data={fieldID['settingsBankNumber']} />

            </div>

            {/* <div className="box card">
                <h3 className="box__heading">{Resource('ContractsAndDocs')}</h3>

                <RenderDocs docs={docsID}  />
            </div> */}

        </form>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.language.langActive
    }
}

export default compose(reduxForm({ form: 'docsForm' }), connect(mapStateToProps, null))(DocsForm)
