import React from 'react';
import classes from './IconButton.module.scss';
import {Link} from "react-router-dom";

const IconButton = (props) => {
  
  return (
    <>
      {
        props.link
          ? <Link id={props.id} to={props.link} className={[classes.link, props.className, props.column ? classes.column : ''].join(' ')}>
              {props.children}
              <div className={classes.icon}><img src={props.icon} alt="icon"/></div>
            </Link>
          : <button className={[classes.button, props.className, props.column ? classes.column : ''].join(' ')} onClick={props.onClick}>
              {props.children}
              <div className={classes.icon}><img src={props.icon} alt="icon"/></div>
            </button>
      }
      
    </>
  );
};

export default IconButton;