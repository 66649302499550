import React from 'react'
import { Table } from 'rsuite'
import { history } from '../../../redux/history';
import { dateFormatting, currentFormatting } from '../../../redux/utils/formatting';
import Spinner from '../../UI/Spinner';
import { Resource } from '../../../redux/actions/lang.actions';
import { isArray } from 'jquery';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PAGINATION_CHANGE_ACTIVE_PAGE } from '../../../redux/constants/table.constants';
const { Column, HeaderCell, Cell, Pagination } = Table

const CustomCell = ({ rowData, dataKey, ...props }) => {
    if (dataKey === 'namePortfolio') {
        return (
            <Cell {...props} className={rowData.options?.closed ? props.className + ' closed' : props.className}>
                {Resource(rowData[dataKey][0]) + ' (' + Resource(rowData[dataKey][1]) + ')'}
            </Cell>
        )
    } else if (dataKey === 'link' && rowData.options) {
        if (rowData.options.urlLink === '') {
            return (
                <Cell {...props} className={rowData.options?.closed ? props.className + ' closed' : props.className}>
                    <span onClick={() => toast.error(Resource('NoLinkDocsAlert'))} className={'table__no-link'}>{Resource(rowData[dataKey])}</span>
                </Cell>
            )
        } else {
            return (
                <Cell {...props} className={rowData.options?.closed ? props.className + ' closed' : props.className}>
                    {
                        rowData.options.tagLink
                            ? <Link to={rowData.options.urlLink}>{Resource(rowData[dataKey])}</Link>
                            : <a download href={rowData.options.urlLink}>{Resource(rowData[dataKey])}</a>
                    }
                </Cell>
            )
        }
        
    } else {
        return (
            <Cell {...props} className={rowData.options?.closed ? props.className + ' closed' : props.className}>
                {
                    !!props.format
                        ? props.format === 'currency-wit'
                            ? '-' + currentFormatting(rowData[dataKey])
                            : props.format === 'currency-dep'
                                ? '+' + currentFormatting(rowData[dataKey])
                                : props.format === 'currency'
                                    ? currentFormatting(rowData[dataKey])
                                    : props.format === 'date'
                                        ? dateFormatting(rowData[dataKey])
                                        : Resource(rowData[dataKey])
                        : Resource(rowData[dataKey])
                }
            </Cell>
        )
    }
};

class GridTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addColumn: false,
            data: props.data.body,
            displayLength: 8,
            loading: props.loading,
            page: 1,
            sortColumn: props.data.sortColumn
        };
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            page: 1
        })
    }

    getData() {
        const { sortColumn, sortType, displayLength, page } = this.state;

        let { body, columnsOptions } = this.props.data
        if (sortColumn && sortType) {
            if (!!columnsOptions[sortColumn]) {
                if (columnsOptions[sortColumn].format === 'date') {
                    return body.sort((a, b) => {
                        let x = a[sortColumn];
                        let y = b[sortColumn];

                        if (typeof x === 'string') {
                            x = Date.parse(x)
                        }
                        if (typeof y === 'string') {
                            y = Date.parse(y)
                        }
                        if (sortType === 'asc') {
                            return x - y;
                        } else {
                            return y - x;
                        }
                    }).filter((v, i) => {
                        const start = displayLength * (page - 1);
                        const end = start + displayLength;
                        return i >= start && i < end;
                    })
                } else if (columnsOptions[sortColumn].format === 'string') {
                    return body.sort((a, b) => {

                        let x = isArray(a[sortColumn]) ? Resource(a[sortColumn][1]) : Resource(a[sortColumn]);
                        let y = isArray(b[sortColumn]) ? Resource(b[sortColumn][1]) : Resource(b[sortColumn]);
                        if (typeof x === 'string') {
                            x = x.charCodeAt();
                        }
                        if (typeof y === 'string') {
                            y = y.charCodeAt();
                        }
                        if (sortType === 'asc') {
                            return x - y;
                        } else {
                            return y - x;
                        }
                    }).filter((v, i) => {
                        const start = displayLength * (page - 1);
                        const end = start + displayLength;
                        return i >= start && i < end;
                    })
                } else {

                    return body.sort((a, b) => {

                        let x = a[sortColumn];
                        let y = b[sortColumn];
                        if (typeof x === 'string') {
                            x = x.charCodeAt();
                        }
                        if (typeof y === 'string') {
                            y = y.charCodeAt();
                        }
                        if (sortType === 'asc') {
                            return x - y;
                        } else {
                            return y - x;
                        }
                    }).filter((v, i) => {
                        const start = displayLength * (page - 1);
                        const end = start + displayLength;
                        return i >= start && i < end;
                    })
                }
            }
        }

        return body.sort((a, b) => {

            let x = a[sortColumn];
            let y = b[sortColumn];
            if (typeof x === 'string') {
                x = x.charCodeAt();
            }
            if (typeof y === 'string') {
                y = y.charCodeAt();
            }
            if (sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        }).filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            sortColumn,
            sortType,
            loading: this.props.loading
        });
    }

    handleChangePage(dataKey) {
        this.setState({
            page: dataKey
        });
    }
    handleChangeLength(dataKey) {
        this.setState({
            page: 2,
            displayLength: dataKey
        });
    }
    

    renderTables = () => {

        let bodyItem = this.props.data.body["0"];
                        
        let obj = this.props.data.columnsOptions;

        return Object.keys(obj).map((item, ind) => {
            let column = obj[item]
            if (bodyItem && item === 'link' && !("options" in bodyItem)) {
                return;
            } else {
                return (
                    <Column
                        key={item + ind}
                        flexGrow={column.flexGrow}
                        minWidth={column.minWidth}
                        align={column.align !== undefined ? column.align : "left"}
                        className={column.className}
                        style={this.props.data.rowClick ? { cursor: 'pointer' } : null}
                        sortable={column.sort ? true : false}
                        format={column.format}
                    >
                        <HeaderCell>{column.title !== undefined ? Resource(column.title).toUpperCase() : null}</HeaderCell>
                        <CustomCell dataKey={item} />
                    </Column>
                )
            }
        })
        
    }


    render() {
        return (
            <div className="init-table">
                <Table
                    autoHeight
                    data={this.getData()}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    onSortColumn={this.handleSortColumn}
                    loading={this.props.loading}
                    onRowClick={data => {
                        if (!!this.props.data.rowClick) {
                            if (data.options.urlLink) {
                                history.push(data.options.urlLink)
                            }
                        }

                    }}
                    renderLoading={(e) => {
                        if (this.props.loading) {
                            return <Spinner container />
                        }
                        return <></>
                    }}
                >
                    { this.renderTables() }
                </Table>

                <Pagination
                    activePage={this.state.page}
                    displayLength={this.state.displayLength}
                    total={this.props.data.body.length}
                    onChangePage={this.handleChangePage}
                    onChangeLength={this.handleChangeLength}
                    showLengthMenu={false}
                    showInfo={false}
                    boundaryLinks={true}
                />

            </div>
        );
    }
}

export default GridTables