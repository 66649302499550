import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { TUTORIAL_CHANGE_STEP, TUTORIAL_SHOW } from '../../redux/constants/profile/strategys.constants'
import Cookies from 'js-cookie'
import { ScrollTo } from 'react-scroll-to'
import { Resource } from '../../redux/actions/lang.actions'

const Tutorial = ({ showTutorial, step, tutorial, dicrementStep, incrementStep, children }) => {

    useEffect(() => {
        if (Cookies.get('tutorial') && Cookies.get('tutorial') !== 'true') {
            showTutorial(false)
        }
    }, [showTutorial]);

    function anim(duration) {
        var temp;
        return function (sel) {
            cancelAnimationFrame(temp);
            var start = performance.now();
            var from = window.pageYOffset || document.documentElement.scrollTop,
                to = document.querySelector(sel).getBoundingClientRect().top - 20;
            requestAnimationFrame(function step(timestamp) {
                var progress = (timestamp - start) / duration;
                1 <= progress && (progress = 1);
                window.scrollTo(0, from + to * progress | 0);
                1 > progress && (temp = requestAnimationFrame(step))
            })
        }
    };
    const scrollPage = anim(400)

    const nextStep = (scrollClass) => {
        let currentStep = step;
        currentStep++;
        
        let selector = (tutorial.steps[step + 1].pos === 'tl' || tutorial.steps[step + 1].pos === 'tr') ? '.tutorial-step__desc' : `.${scrollClass}`;
        // console.log(selector);

        let element = document.querySelector('.tutorial-step--active');
        element.classList.add('tutorial-step--inactive')
        
        setTimeout(() => {
            incrementStep(currentStep);
            scrollPage(selector);
        }, 500);
    }

    const prevStep = (scroll, scrollClass) => {
        let currentStep = step
        currentStep--

        let selector = (tutorial.steps[step - 1].pos === 'tl' || tutorial.steps[step - 1].pos === 'tr') ? '.tutorial-step__desc' : `.${scrollClass}`;

        let element = document.querySelector('.tutorial-step--active');
        element.classList.add('tutorial-step--inactive')

        setTimeout(() => {
            dicrementStep(currentStep);
            scrollPage(selector);
        }, 500)
    }

    const closeTutorial = () => {
        Cookies.set('tutorial', false, { expires: 999 })
        showTutorial(false)
    }

    return (
        <>{
            tutorial.activeStep === step && tutorial.show ?

                <ScrollTo>
                    {
                        ({ scroll }) => {

                            if (+step === 0) {
                                scroll({ x: 0, y: 0, smooth: true })
                            }

                            return (
                                <div className='tutorial-step--active' id='tutorial_init'>

                                    {children}

                                    <div className={`tutorial-step__desc tutorial-step__desc_${tutorial.steps[step].pos}`}>
                                        <div>
                                            <span>{(step + 1) + ' / ' + (Object.keys(tutorial.steps).length)}<i className="tutorial__cancel" onClick={closeTutorial}></i></span>
                                            {
                                                Resource(tutorial.steps[step].text)
                                            }
                                        </div>
                                        <div>
                                            {
                                                step > 0 && <span className="button button--small button--stroke tutorial-step__prev" onClick={() => {
                                                    prevStep(scroll, tutorial.steps[step - 1].scrollClass)
                                                }}>{Resource('TutorialPrev')}</span>
                                            }
                                            {
                                                step === (Object.keys(tutorial.steps).length - 1)
                                                    ? <span className="button button--small tutorial-step__next" onClick={closeTutorial}>{Resource('TutorialFinal')}</span>
                                                    : <span className="button button--small tutorial-step__next" onClick={() => {
                                                        nextStep(tutorial.steps[step + 1].scrollClass)
                                                    }
                                                    }>{Resource('TutorialNext')}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                </ScrollTo>
            : children
        }</>    
    )
}

const mapStateToProps = state => {
    return {
        tutorial: state.profile.tutorial
    }
}

const mapDispatchToProps = dispatch => {
    return {
        incrementStep: currentStep => dispatch({ type: TUTORIAL_CHANGE_STEP, currentStep }),
        dicrementStep: currentStep => dispatch({ type: TUTORIAL_CHANGE_STEP, currentStep }),
        showTutorial: status => dispatch({ type: TUTORIAL_SHOW, status })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Tutorial)