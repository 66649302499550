import React, { Component } from 'react';
import Stats from '../components/Blocks/Stats';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PERSON_DATA_SUCCESS } from '../redux/constants/person.constants';
import { getPersonData } from '../redux/actions/person.action';
import { getInvestments } from '../redux/actions/my-investments/investments.actions';
import { MYINVESTMENTS_TABLE_SUCCESS } from '../redux/constants/my-investments/investments.constants';
import GridTables from '../components/Blocks/GridTables/GridTables';
import { Resource } from '../redux/actions/lang.actions';
import DocumentTitle from '../components/UI/DocumentTitle';


class MyInvestment extends Component {
	static displayName = MyInvestment.name;

	componentDidMount = () => {
		this.props.getInvestments()
		// this.props.getPersonData()

    }

	render() {
		return (
			<main className="investments">
				<DocumentTitle title='MyInvestments' />
				<section>
					<div className="box panel panel_azure">
						<ul className="widget widget_panel">
							<Stats id='investBalance' data={this.props.person} reload load={PERSON_DATA_SUCCESS} />
							<Stats id='portfolioCount' data={this.props.person} reload load={PERSON_DATA_SUCCESS} />
							<Stats id='interestSum' data={this.props.person} reload load={PERSON_DATA_SUCCESS} />
						</ul>
					</div>
				</section>

				<h1 className="container">{Resource('MyInvestments')}</h1>

				<section>
					<GridTables data={this.props.table} loading={this.props.loading[MYINVESTMENTS_TABLE_SUCCESS]} />
					<div className="fixed">
						<div className="container">
							<Link to="/profile" className="button">{Resource('Invest')}</Link>
						</div>
					</div>
				</section>

            </main>
        )
    };

}

const mapStateToProps = state => {
	return {
		person: state.person,
		profile: state.profile,
		loading: state.loading,
		table: state.myInvestments.myInvestmentsTable,
		lang: state.language.langActive
    }
}

const mapDispatchToProps = dispatch => {
	return {
		getInvestments: () => dispatch(getInvestments()),
		getPersonData: () => dispatch(getPersonData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyInvestment)