import { LOADING } from "../../constants/loading.constants"
import { requestPost, requestGet, errorHandler } from "../../api/request"
import { SUPPORT_GET_MESSAGE_SUCCESS, SUPPORT_GET_MESSAGE_FAILURE, SUPPORT_SEND_MESSAGE_SUCCESS, SUPPORT_SEND_MESSAGE_FAILURE } from "../../constants/support/support.constants"
import { reset } from "redux-form"

export const getSupportMessages = () => {
    return dispatch => {
        requestGet('Support/GetSupportMessages')
            .then(resp => {
                dispatch({ type: SUPPORT_GET_MESSAGE_SUCCESS, payload: resp.data })
                dispatch({ type: LOADING, id: SUPPORT_GET_MESSAGE_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: SUPPORT_GET_MESSAGE_FAILURE, error })
                dispatch({ type: LOADING, id: SUPPORT_GET_MESSAGE_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: SUPPORT_GET_MESSAGE_SUCCESS, loading: true })
    }
}

export const sendSupportMessage = (message) => {
    return dispatch => {
        requestPost(`Support/SendSupportMessage?message=${message}`)
            .then(resp => {
                dispatch(getSupportMessages())
                dispatch({ type: SUPPORT_SEND_MESSAGE_SUCCESS, payload: resp.data.message })
                dispatch({ type: LOADING, id: SUPPORT_SEND_MESSAGE_SUCCESS, loading: false })
                dispatch(reset('supportForm'))
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: SUPPORT_SEND_MESSAGE_FAILURE, error })
                dispatch({ type: LOADING, id: SUPPORT_SEND_MESSAGE_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: SUPPORT_SEND_MESSAGE_SUCCESS, loading: true })
    }
}