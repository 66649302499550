import React from "react";
import { IconPeople, IconPortfolio, IconСlock, IconDocFind, IconDocCheсk, IconLod, IconDone } from "../../static/Icons/Icons";
import { Resource } from "../../../redux/actions/lang.actions";
import "./JudicialPortfolioStatus.scss";

const JudicialPortfolioStatus = ({statusId}) => {
    return(
        <div className="portfolio-stages">
            {/* <div className="portfolio-stages__title">
                    Прогресс портфеля
                </div> */}
                <div className="portfolio-stages__list">
                    <div className={statusId && statusId === 620 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId && statusId === 620 ? IconPeople("#1F242D") : IconPeople("#C4C4C4") }
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState1") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">

                            </div>
                        </div>
                    </div>

                    <div className={statusId && statusId === 620 ? "portfolio-stages__item portfolio-stages__item--ready" :(
                        statusId === 621 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"
                    )}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId === 620 ? IconPortfolio("#1F242D") : ( 
                                        statusId === 621 ? IconPortfolio("#1F242D") : IconPortfolio("#C4C4C4")
                                    )}
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState2") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">

                            </div>
                        </div>
                    </div>

                    <div className={statusId && statusId === 621 ? "portfolio-stages__item portfolio-stages__item--ready" :(
                        statusId === 622 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"
                    )}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId === 621 ? IconСlock("#1F242D") : ( 
                                        statusId === 622 ? IconСlock("#1F242D") : IconСlock("#C4C4C4")
                                    )}
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState3") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">
                                { Resource("JudicialStateSub3") }
                            </div>
                        </div>
                    </div>

                    <div className={statusId && statusId === 622 ? "portfolio-stages__item portfolio-stages__item--ready" :(
                        statusId === 623 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"
                    )}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId === 622 ? IconDocFind("#1F242D") : ( 
                                        statusId === 623 ? IconDocFind("#1F242D") : IconDocFind("#C4C4C4")
                                    )}
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState4") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">
                                
                            </div>
                        </div>
                    </div>

                    <div className={statusId && statusId === 623 ? "portfolio-stages__item portfolio-stages__item--ready" :(
                        statusId === 624 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"
                    )}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId === 623 ? IconDocCheсk("#1F242D") : ( 
                                        statusId === 624 ? IconDocCheсk("#1F242D") : IconDocCheсk("#C4C4C4")
                                    )}
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState5") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">
                                
                            </div>
                        </div>
                    </div>

                    <div className={statusId && statusId === 624 ? "portfolio-stages__item portfolio-stages__item--ready" :(
                        statusId === 625 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"
                    )}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId === 624 ? IconLod("#1F242D") : ( 
                                        statusId === 625 ? IconLod("#1F242D") : IconLod("#C4C4C4")
                                    )}
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState6") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">
                                
                            </div>
                        </div>
                    </div>

                    <div className={statusId && statusId === 625 ? "portfolio-stages__item portfolio-stages__item--ready" :(
                        statusId === 626 ? "portfolio-stages__item portfolio-stages__item--active" : "portfolio-stages__item"
                    )}>
                        <div className="portfolio-stages__item-info">
                            <div className="portfolio-stages__item-title">
                                <div className="portfolio-stages__item-icon">
                                    { statusId === 625 ? IconDone("#1F242D") : ( 
                                        statusId === 626 ? IconDone("#1F242D") : IconDone("#C4C4C4")
                                    )}
                                </div>
                                <div className="portfolio-stages__item-text">
                                    { Resource("JudicialState7") }
                                </div>
                            </div>
                            <div className="portfolio-stages__item-description">
                                
                            </div>
                        </div>
                    </div>

                </div>

        </div>
    )
};

export { JudicialPortfolioStatus };