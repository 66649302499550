import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Resource } from "../../../redux/actions/lang.actions";
import "./SideBarMenu.scss";

const SideBarMenu = () => {
  const info = useSelector((state) => state.invitefriendsReducer);
  const language = useSelector((state) => state.language);
  useEffect(() => {}, [language]);
  const newFriends = info.totalLinkCount - info.readLinkCount;
  return (
    <div className="invite-sidebar">
      <NavLink className="invite-sidebar__link" to="/invitefriends">
        {Resource("refLinkMenu1")}
      </NavLink>
      <NavLink className="invite-sidebar__link" to="/myinvitations">
        {Resource("refLinkMenu2")}
        {!!newFriends && (
          <div className="invite-sidebar__info">{newFriends}</div>
        )}
      </NavLink>
    </div>
  );
};

export { SideBarMenu };
