import React from 'react';
import classes from "./VerifyBanner.module.scss";
import iconVerifyLogo from "../../../static/image/veriff/veriff-logo.svg";
import {Resource} from "../../../redux/actions/lang.actions";
import SpinnerLoad from "../SpinnerLoading/SpinnerLoading";
import iconArrowLong from "../../../static/image/icons/icon-arrow-long.svg";
import IconButton from "../Buttons/IconButton/IconButton";

const VerifyBanner = props => {
  return (
    <div className={[classes.banner, props.loading ? classes.isLoading : '', props.className['banner']].join(' ')} onClick={props.onClickBanner ? props.onClickBanner : props.onClick}>
      {props.id}
      <img className={[classes.logo, props.className['logo']].join(' ')} src={iconVerifyLogo} alt="veriff_logo"/>
      <h5 className={[classes.title, props.className['title']].join(' ')}>{Resource('Hello')} <br/> {props.name}!</h5>
      <p className={classes.description}>{Resource('ProgressIdentifyDesc')}</p>
      {
        props.loading
          ? <SpinnerLoad primary={'rgba(106, 90, 205, 0.7)'} second={'rgba(106, 90, 205, 0.2)'} right/>
          : <IconButton icon={iconArrowLong} className={classes.button}>{Resource('Continue')}</IconButton>
      }
    </div>
  )
}

export default VerifyBanner;