import Helper from "../../../components/UI/Modal/Helper";
import classes from './FooterComponent.module.scss'
import React from "react";

export const FooterComponent = props => {
  return (
    <>
      <footer className={[classes.footer, props.activeMenu ? classes.visible : ''].join(' ')}>
        <div className={classes.footer__container}>
          <a className={classes.footer__container_email} href="mailto:support@nibble.finance">support@nibble.finance</a>
          <div>
            <a className={classes.footer__container_phone} href="tel:+3726093461">+3726093461</a>
          </div>
        </div>
      </footer>
    </>
  );
}