import Cookies from 'js-cookie'

export const utmInit = (history) => {
  Cookies.set('url_link', document.URL, { expires: 30 });
  getURLParameter('utm_id') != null && Cookies.set('utm_id', getURLParameter('utm_id'), { expires: 30 });
  getURLParameter('utm_source') != null && Cookies.set('utm_source', getURLParameter('utm_source'), { expires: 30 });
  getURLParameter('utm_medium') != null && Cookies.set('utm_medium', getURLParameter('utm_medium'), { expires: 30 });
  getURLParameter('utm_campaign') != null && Cookies.set('utm_campaign', getURLParameter('utm_campaign'), { expires: 30 });
  getURLParameter('utm_term') != null && Cookies.set('utm_term', getURLParameter('utm_term'), { expires: 30 });
  getURLParameter('utm_content') != null && Cookies.set('utm_content', getURLParameter('utm_content'), { expires: 30 });

  function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(history.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
  }
}