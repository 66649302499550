import {DepositingAPI} from "../api/REST-API";
import {currentFormatting, dateFormatting} from "../../redux/utils/formatting";
import {Resource} from "../../redux/actions/lang.actions";
import {errorHandler, requestPost} from "../../redux/api/request";
import {isCurrency, isNumber, rangeNumbers} from "../components/tools";
import {history} from "../../redux/history";

const DEPOSITING_DATA = 'BALANCED/GET_DATA';
const DEPOSITING_PERSON_DATA = 'BALANCED/GET_PERSON_DATA';
const DEPOSITING_SET_VALUE_FIELD = 'BALANCED/SET_VALUE_FIELD';
const CODE_STATUS = {
    64: 'STATUS_005',
    63: 'STATUS_004',
    65: 'STATUS_006',
}
const CODE_OPERATIONS = {
    334: 'PaymentsOpertions_334',
    150: 'PaymentsOpertions_150',
    355: 'PaymentsOpertions_355',
}

export const setDepositPayments = (payload) => dispatch => {
    let data = payload.map(payment => {
        const {id, wayId, deposit, status, dateOperation} = payment
        return {
            id,
            operations: CODE_OPERATIONS[wayId],
            deposit,
            status: CODE_STATUS[status],
            dateOperation
        }
    })
    
    dispatch({ type: DEPOSITING_DATA, data })
}

export const setDepositPersonData = (payload) => dispatch => {
    dispatch({ type: DEPOSITING_PERSON_DATA, payload })
}

export const setDepositValueField = (payload) => dispatch => {
    dispatch({ type: DEPOSITING_SET_VALUE_FIELD, payload })
}

export const getDepositPayments = () => dispatch => {
    return requestPost('Payment/GetDepositingData')
      .then(resp => {
          if(resp.status === 200) {
            dispatch(setDepositPayments(resp.data.paymentsInformation))
            dispatch(setDepositPersonData(resp.data))
          } else {
              throw new Error();
          }
      })
      .catch(error => {
          errorHandler(dispatch, error)
      })
}

const initialState = {
    depositTable: {
        headers: [
            {
                Header: 'Operation',
                accessor: 'operations',
                Cell: props => props.value ? Resource(props.value) : ''
            },
            {
                Header: 'Income',
                accessor: 'deposit',
                Cell: props => props.value ? '+ ' + currentFormatting(props.value) : ''
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: props => props.value ? Resource(props.value) : ''
            },
            {
                Header: 'OperationDate',
                accessor: 'dateOperation',
                Cell: props => props.value ? dateFormatting(props.value) : ''
            },
        ],
        depositPayments: []
    },
    fields: [{
        id: 'depositValue',
        label: 'SumAmount',
        maxLength: 9,
        validates: {
            required: 'required',
            validate: rangeNumbers.bind(this, [10, 15000]),
            onChange: isCurrency.bind(this, {prefix: '€'})
        },
    }],
    valueField: null
}

const BalancedReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEPOSITING_DATA:
            return {
                ...state,
                depositTable: {
                    ...state.depositTable,
                    depositPayments: [...action.data]
                }
            }
        case DEPOSITING_PERSON_DATA:

            const {
                phonePrefix,
                phone,
                country,
                city,
                address,
                zip
            } = action.payload
            
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                    status: !phonePrefix || !phone || !country || !city || !address || !zip
                }
            }
        case DEPOSITING_SET_VALUE_FIELD:
            return {
                ...state,
                valueField: String(action.payload)
            }
        default:
            return state
    }
}

export default BalancedReducer
