import { PERSON_DATA_SUCCESS, PERSON_DATA_FAILED } from "../../constants/person.constants"
import { TUTORIAL_CHANGE_STEP, TUTORIAL_SHOW } from "../../constants/profile/strategys.constants"
import Cookies from 'js-cookie'

const initialState = {
    fields: {
        veriffFirstName: {
            id: 'veriffFirstName',
            name: 'veriffFirstName',
            type: 'text',
            label: 'PersonName',
            edit: true,
            validate: {
                required: true,
                only: 'letter'
            }
        },
        veriffLastName: {
            id: 'veriffLastName',
            name: 'veriffLastName',
            type: 'text',
            label: 'PersonLastName',
            edit: true,
            validate: {
                required: true,
                only: 'letter'
            }
        },
        prefix: {
            id: 'prefix',
            name: 'prefix',
            options: [
                { id: 43, value: '+43 AT' },
                { id: 32, value: '+32 BE' },
                { id: 359, value: '+359 BG' },
                { id: 44, value: '+44 GB' },
                { id: 36, value: '+36 HU' },
                { id: 49, value: '+49 DE' },
                { id: 30, value: '+30 GR' },
                { id: 45, value: '+45 DK' },
                { id: 353, value: '+353 IE' },
                { id: 34, value: '+34 ES' },
                { id: 39, value: '+39 IT' },
                { id: 357, value: '+357 CY' },
                { id: 371, value: '+371 LV' },
                { id: 370, value: '+370 LT' },
                { id: 352, value: '+352 LU' },
                { id: 356, value: '+356 MT' },
                { id: 31, value: '+31 NL' },
                { id: 48, value: '+48 PL' },
                { id: 351, value: '+351 PT' },
                { id: 7, value: '+7 RU' },
                { id: 40, value: '+40 RO' },
                { id: 421, value: '+421 SK' },
                { id: 386, value: '+386 SI' },
                { id: 358, value: '+358 FI' },
                { id: 33, value: '+33 FR' },
                { id: 385, value: '+385 HR' },
                { id: 420, value: '+420 CZ' },
                { id: 46, value: '+46 SE' },
                { id: 41, value: '+41 CH' },
                { id: 372, value: '+372 EE' },
                { id: 1, value: '+1 US' }
            ],
            value: '+43 AT',
            labelHidden: true
        },
        phone: {
            id: 'phone',
            name: 'phone',
            type: 'phone',
            label: 'Phone',
            description: 'PhoneChangeInfo',
            edit: true,
            validate: {
                required: true
            }
        },
    },
    tutorial: {
        steps: {
            0: {
                pos: 'bl',
                text: 'step0',
                scrollClass: 'veriff'
            },
            1: {
                pos: 'bl',
                text: 'step1',
                scrollClass: 'panel_azure'
            },
            2: {
                pos: 'tl',
                text: 'step2',
                scrollClass: 'calculator'
            },
            //3: {
            //    pos: 'tl',
            //    text: 'step3',
            //    scrollClass: 'index-widget'
            //},
            //4: {
            //    pos: 'tl',
            //    text: 'step4',
            //    scrollClass: 'index-widget'
            //},
            //5: {
            //    pos: 'tl',
            //    text: 'step5',
            //    scrollClass: 'index-widget'
            //},
            //5: {
            //    pos: 'tl',
            //    text: 'step6',
            //    scrollClass: 'index-widget'
            //}
        },
        activeStep: 0,
        show: Cookies.get('tutorial') !== undefined ? Cookies.get('tutorial') : true
    }
}

const profile = (state = initialState, action) => {
    switch (action.type) {
        case PERSON_DATA_SUCCESS:
            return {
                ...state
            }
        case PERSON_DATA_FAILED:
            return {
                ...state,
                error: action.error
            }
        case TUTORIAL_CHANGE_STEP:
            return {
                ...state,
                tutorial: {
                    ...state.tutorial,
                    activeStep: action.currentStep
                }
            }
        case TUTORIAL_SHOW:
            return {
                ...state,
                tutorial: {
                    ...state.tutorial,
                    show: action.status
                }
            }
        default:
            return state
    }
}

export default profile