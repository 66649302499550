
export const getUrlParam = (sParam, query) => {
    let sPageURL = query.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
        
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split(sParam + '=');
        if (sParameterName[0] === "") {
            return sParameterName[1] === undefined ? true : encodeURIComponent(sParameterName[1]);
        }
    }
};