// Универсальный компонент для вывода данных во всплывающем окне
import React from "react";
import "./PopupSlide.scss";

const PopupSlide = ({active, setActive, children}) => {
    return(
        <div className={active ? "popup-slide popup-slide--active" : "popup-slide"} onClick={() => setActive(false)}>
            <div className="popup-slide__window" onClick={e => e.stopPropagation()}>
                <div className="popup-slide__header">
                    <div 
                        className="popup-slide__close"
                        onClick={() => setActive(false)}
                    ></div>
                </div>
                {children}
            </div>
        </div>
    )
};

export { PopupSlide };