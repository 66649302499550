import {requestGet, errorHandler, requestPost} from "../api/request"
import { LOADING } from "../constants/loading.constants"
import { PERSON_DATA_SUCCESS, PERSON_DATA_FAILED } from "../constants/person.constants"
import { toggleVeriffPanel } from '../actions/veriff/veriff.actions'
import {checkFreshChatId, saveFreshChatId} from "./login/auth.actions";

export const getPersonData = () => {
    return dispatch => {
        return requestGet('Person/GetPersonData')
            .then(resp => {
              const {id, firstName, email, phone} = resp.data;
              /* FreshChat */
              if (window.fcWidget.isInitialized()) {
                window.fcWidget.destroy();
              }
              checkFreshChatId()
                .then(response => {
                  // Проверили есть ли в БД идентификатор чата
                  // console.log('Ответ на запрос id чата');
                  // console.log(response.data);

                  // Инициализируем чат
                  const freshId = response.data.success ? String(response.data.freshId) : null;

                  window.fcWidget.init({
                    token: "a4d6d1fb-19f3-440f-a7d6-7585903fc180",
                    host: "https://wchat.freshchat.com",
                    externalId: String(id),
                    restoreId: freshId
                  });
                  window.fcWidget.user.get(function(resp) {
                    var status = resp && resp.status,
                      data = resp && resp.data;
                    if (status !== 200) {
                      window.fcWidget.user.setProperties({
                        firstName: String(firstName),   // user's first name
                        email: String(email),           // user's email address
                        phone: String(phone),           // phone number
                        personId: String(id),           // user's id
                        freshId: String(freshId),       // user's id for FreshChat
                      });
                      window.fcWidget.on('user:created', function(resp) {
                        var status = resp && resp.status,
                          data = resp && resp.data;
                        if (status === 200) {
                          if (data.restoreId) {
                            console.log(data.restoreId);
                            saveFreshChatId(data.restoreId);
                          }
                        }
                      });
                    }
                  });
                })
                .catch(error => {
                  console.log(error);
                }
              )
              /* FreshChat */
                          
              dispatch({ type: PERSON_DATA_SUCCESS, payload: resp.data })
              dispatch({ type: LOADING, id: PERSON_DATA_SUCCESS, loading: false })
              dispatch(toggleVeriffPanel(false))
  
              //window.gtag('set', { 'user_id': resp.data.id }); // Set the user ID using signed-in user_id.
              return resp.data
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: PERSON_DATA_FAILED, error })
            })
        dispatch({ type: LOADING, id: PERSON_DATA_SUCCESS, loading: true })
    }
}