import React from 'react';
import classes from './Currency.module.scss';
import CountUp from "react-countup";
import Loader from "../../../components/UI/Loader";
import {PERSON_DATA_SUCCESS} from "../../../redux/constants/person.constants";

const Currency = (props) => {
  
  const classWrp = [
    classes.currency, 
    props.className,
    props.order ? classes.order : '',
    props.column ? classes.column : '',
    props.primary ? classes.primary : ''
  ].join(' ')
  
  return (
      <div className={classWrp}>
        <Loader reload loadId={props.loadId}>
          <span className={classes.amount}>{props.amount}</span>
        </Loader>
        <p className={[classes.text, props.small && classes.small].join(' ')} dangerouslySetInnerHTML={{ __html: props.text.join(' ') }} />
      </div>
  );
};

export default Currency;