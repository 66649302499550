import React from 'react'
import { Field } from 'redux-form'
import { Resource } from '../../../redux/actions/lang.actions'

const RenderCheckbox = ({ data: { id, name, type, label } }) => {

    return (
        <div className="settings__checkbox">
            {label && <span className="settings__checkbox__label">{Resource(label)}</span>}
            <div className="checkbox">
                <Field
                    component='input'
                    id={id}
                    name={name}
                    type={type}
                />
                <label className="checkbox__body" htmlFor={id}></label>
            </div>
        </div>
    )
}

export default RenderCheckbox