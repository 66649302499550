import axios from "axios";
//import 'react-toastify/dist/ReactToastify.css';
import { history } from "../history";
import { LOGOUT } from "../constants/login/auth.constants";
import {toast} from "react-toastify";
import {errorLocale} from "../../locale/messages/errors.locale";
import {Resource} from "../actions/lang.actions";

export const requestPost = async (url, params) => {
    return await axios.post(url, params)
}

export const requestGet = async (url) => {
    return await axios.get(url)
}

export const requestAll = async ([...url]) => {
    return await axios.all([
        axios.get(url[0]),
        axios.post(url[1])
    ])
}

export const requestGetParams = async (url, params) => {
    return await axios.get(url, params)
}

//ERROR HANDLER
export const errorHandler = (dispatch, error) => {
    switch (error.response?.status) {
        case 401:
            localStorage.removeItem('auth')
            dispatch({ type: LOGOUT })
            history.push('/')
            break
        //case 503:
        //    //localStorage.removeItem('auth')
        //    //dispatch({ type: LOGOUT })
        //    //history.push('/')
        //    toast.error(errorLocale.ERROR_500)
        //    break
        case 500:
           toast.error(Resource('ERROR_003'))
           break
        default:
            break
    }
    //if ([401, 403, 500].indexOf(error.response.status) !== -1) {
    //   localStorage.removeItem('auth')
    //   dispatch({ type: LOGOUT })
    //   history.push('/')
    //}
}