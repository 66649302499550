import React from 'react'
import { ScrollTo } from 'react-scroll-to/dist'

const ScrollToLink = ({ contentRef, children }) => {

    return (
        <ScrollTo>
            {({ scroll }) => (
                <button className='linkScroll' onClick={() => {
                    scroll({ x: 0, y: +contentRef.current.offsetTop - 100, smooth: true })
                }} dangerouslySetInnerHTML={{ __html: children }}>
                </button>
            )}
        </ScrollTo>
    )
}

export default ScrollToLink