import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import classes from './statusPayment.module.scss'
import {Resource} from "../../../redux/actions/lang.actions";
import success from '../../../static/image/icons/icon-payment-success.svg'
import DefaultButton from "../../../update/components/Buttons/DefaultButton/DefaultButton";
import LinkButton from "../../../update/components/Buttons/LinkButton/LinkButton";
import {MODAL_IS_OPEN} from "../../../redux/constants/modals.constants";

const PaymentSuccess = (props) => {
 
    useEffect(() => {   
        // YandexCounter
        window.ym(56965879,'reachGoal','baclance_refill_success');
        window.fcWidget.hide()
    }, []);
    
    return (
        <main>
            <section className={[classes.status, classes.success].join(' ')}>
                <img className={classes.icon} src={success} alt='success'/>
                <span className={classes.title}>The balance has been successfully replenished!</span>
                <DefaultButton onClick={() => {
                    // props.isToggleModal(false, 'modalPayment')
                  window.parent.location.reload()
               }} customClass={classes.button}>OK</DefaultButton>
            </section>
        </main>
    );
}

const mapStateToProps = state => {
  return {
    language: state.language,
    amountValue: state.depositing.contentPayCard.fields.depositValue.value
  }
}

const mapDispatchToProps = dispatch => {
    return {
        isToggleModal: (isOpen, id) => dispatch({ type: MODAL_IS_OPEN, isOpen, id})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)