import React, { Component } from 'react';
import { connect } from 'react-redux'
import { login, resetPassword, sendRestorePassword, confirmEmailHash } from '../redux/actions/login/auth.actions';
import LoginForm from '../components/UI/formControls/login/login.form';
import { AUTH_SUCCESS, CONFIRM_RESTORE_PASSWORD_SUCCESS } from '../redux/constants/login/auth.constants';
import { Resource } from '../redux/actions/lang.actions';
import DocumentTitle from '../components/UI/DocumentTitle';

class Login extends Component {

    onAuthHandler = (values) => {
        const { loginEmail, loginPassword } = values
        this.props.login(loginEmail, loginPassword);
    }

    handleResetPassword = (values) => {
        const { loginResetEmail } = values
        this.props.resetPassword(loginResetEmail, false, this.props.modal.id)
    }

    //componentDidMount() {
    //    if (!!window.location.search && (!!getUrlParam('email') || getUrlParam('restore'))) {
    //        !!getUrlParam('email')
    //            ? this.props.confirmEmailHash(getUrlParam('email'))
    //            : this.props.sendRestorePassword(getUrlParam('restore'))
    //    }
    //}

    render() {
        return (
            <main className="login">
                <DocumentTitle title='LoginHead' />
                <section>
                    <div className="register-wrapper">
                        <h1>{Resource('LoginHead')}</h1>
                        {
                            this.props.showResetMessage !== undefined && !this.props.showResetMessage && <p className="toast-notice">{Resource('NewPasswordSent')}</p>
                        }
                        <LoginForm
                            fields={this.props.fields}
                            loading={this.props.loading}
                            modal={this.props.modal}
                            onSubmit={this.onAuthHandler.bind(this)}
                            handleResetPassword={this.handleResetPassword.bind(this)}
                        />
                    </div>
                </section>
            </main>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading[AUTH_SUCCESS],
        fields: state.auth.fields,
        modal: state.modals.modalResetPassword,
        lang: state.language.langActive,
        showResetMessage: state.loading[CONFIRM_RESTORE_PASSWORD_SUCCESS]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => dispatch(login(email, password)),
        resetPassword: (email, isOpen, id) => dispatch(resetPassword(email, isOpen, id)),
        sendRestorePassword: (hash) => dispatch(sendRestorePassword(hash)),
        confirmEmailHash: (hash) => dispatch(confirmEmailHash(hash))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)