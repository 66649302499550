import { PORTFOLIO_DETAILS_FAILED, PORTFOLIO_DETAILS_SUCCESS, PORTFOLIO_DEPOSITING_FAILED } from "../../constants/portfolio/portfolio.constants"
import { requestPost, errorHandler } from "../../api/request"
import { LOADING } from "../../constants/loading.constants"
import { PORTFOLIO_DEPOSITING_SUCCESS } from "../../constants/table.constants"
import { history } from "../../history"
import { reset } from "redux-form"
import { toast } from 'react-toastify'
import { Resource } from '../../actions/lang.actions'
import { changeActiveId } from '../../actions/tabs.actions'
import { currentFormatting } from '../../utils/formatting'
import {getPersonData} from "../person.action";
import {getInvestments} from "../my-investments/investments.actions";
import {getInvestmentData} from "../../../update/redux/investment-reducer";

export const getPortfolioDetails = (portfolioId) => {
    return dispatch => {
        requestPost(`Portfolio/GetPortfolioDetails?portfolioId=${portfolioId}`)
            .then(resp => {
                dispatch({
                    type: PORTFOLIO_DETAILS_SUCCESS,
                    payload: resp.data,
                    tableData: {
                        invest: resp.data.investmentsInformation,
                        deposit: resp.data.paymentsInformation
                    }
                })
                dispatch({ type: LOADING, id: PORTFOLIO_DETAILS_SUCCESS, loading: false })
            })
            .catch(error => {
                if (error.response.status === 400) {
                    history.push('/my-investment')
                }
                dispatch({ type: LOADING, id: PORTFOLIO_DETAILS_SUCCESS, loading: false })
                dispatch({ type: PORTFOLIO_DETAILS_FAILED, error })
                errorHandler(dispatch, error)
            })
        dispatch({ type: LOADING, id: PORTFOLIO_DETAILS_SUCCESS, loading: true })
    }
}

export const getPortfolioDepositing = (portfolioId, amount) => {
    let amountFormat = amount.replace(',', '.').replace(/[\s]/g, '')
    return dispatch => {
        requestPost(`Portfolio/PortfolioPayment?portfolioId=${Number(portfolioId)}&amount=${amountFormat}`)
            .then(resp => {
                dispatch({ type: PORTFOLIO_DEPOSITING_SUCCESS })
                dispatch({ type: LOADING, id: PORTFOLIO_DEPOSITING_SUCCESS, loading: false })
                dispatch(reset('depositForm'))
                if (resp.data.success) {
                    dispatch(getPortfolioDetails(portfolioId))
                    dispatch(changeActiveId(1, 'portfolioTabs'))
                    toast.success(Resource('PortfolioReplenishmentOperation') + ' - ' + currentFormatting(amountFormat));
                    dispatch(getPersonData())
                    // YandexCounter;
                    window.ym(56965879,'reachGoal','refill_portfolio');
                } else {
                    toast.error(Resource('ERROR_006'))
                }
            })
            .catch(error => {
                dispatch({ type: PORTFOLIO_DEPOSITING_FAILED, error })
                dispatch({ type: LOADING, id: PORTFOLIO_DEPOSITING_SUCCESS, loading: false })
                errorHandler(dispatch, error)
                dispatch(reset('depositForm'))
            })
        dispatch({ type: LOADING, id: PORTFOLIO_DEPOSITING_SUCCESS, loading: true })
    }
}
