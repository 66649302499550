import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';

class Chart extends Component {

    constructor(props) {
        super(props)

        this.canvasRef = createRef()
    }

    drawChartPart(ctx, centerX, centerY, radius, startAngle, endAngle, color) {
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.closePath();
        ctx.fill();
    }

    generateCanvas(total) {
        const canvasRef = this.canvasRef.current
        if (canvasRef !== null) {
            const params = {
                percent: this.props.data[this.props.id][this.props.type].percent,
                colors: this.props.data[this.props.id][this.props.type].colors,

                width: this.props.base.width,
                height: this.props.base.height,
                donut: this.props.base.donut,

                donutColor: this.props.base.donutColor || "#e9f3f6",
                start_angle: this.props.base.start_angle || 0.7,
                duration: this.props.base.duration || 1000
            }

            canvasRef.width = params.width
            canvasRef.height = params.height

            const ctx = canvasRef.getContext("2d");
            let total_value = total * params.duration / 5;
            let color_index = 0;
            for (let categ in params.percent) {
                let val = params.percent[categ];
                total_value += val;
            }
            let start_angle = params.start_angle;
            for (let categ in params.percent) {
                let val = params.percent[categ];
                let slice_angle = 2 * Math.PI * val / total_value;
                this.drawChartPart(
                    ctx,
                    params.width / 2,
                    params.height / 2,
                    Math.min(params.width / 2, params.height / 2),
                    start_angle,
                    start_angle + slice_angle,
                    params.colors[color_index % params.colors.length]
                )
                start_angle += slice_angle;
                color_index++;
            }

            if (params.donut) {
                this.drawChartPart(
                    ctx,
                    params.width / 2,
                    params.height / 2,
                    params.donut * Math.min(params.width / 2, params.height / 2),
                    0,
                    2 * Math.PI,
                    params.donutColor
                );
            }
        }
    }

    animate = ({ timing, draw, duration }) => {
        let start = performance.now();
        requestAnimationFrame(function animate(time) {
            let timeFraction = (time - start) / duration;
            if (timeFraction > 1) timeFraction = 1;
            let progress = timing(timeFraction);

            draw(progress);

            if (timeFraction < 1) {
                requestAnimationFrame(animate);
            }

        });
    }

    componentDidMount() {
        this.animate({
            duration: this.props.base.duration || 1000,
            draw: progress => {
                this.generateCanvas(progress)
            },
            timing(timeFraction) {
                return Math.pow(Math.acos(timeFraction), 2)
            }
        })
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.data.activeProductId !== nextProps.data.activeProductId) {
            this.animate({
                duration: this.props.base.duration || 1000,
                draw: progress => {
                    this.generateCanvas(progress)
                },
                timing(timeFraction) {
                    return Math.pow(Math.acos(timeFraction), 2)
                }
            })
            return true
        }
        return false 
    }

    render() {
        return (
            <canvas ref={this.canvasRef}></canvas>
        )
    }
}

const mapStateToProps = state => {
    return {
        base: state.strategys.chart,
        data: state.strategys
    }
}

export default connect(mapStateToProps, null)(Chart)