import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import Modal from '../../Modal/Modal'
import ModalButton from '../../Modal/ModalButton'
import { Resource } from '../../../../redux/actions/lang.actions'
import RenderPassword from '../../formComponents/RenderPassword'
import LoaderButton from '../../LoaderButton'
import SocialAuth from '../../SocialAuth'

const LoginForm = props => {
    return (
        <>
            <form onSubmit={props.handleSubmit}>
                <div className="box panel">
                    {/* <SocialAuth /> */}
                    <RenderInput data={props.fields['email']} disabled={!!props.loading} />
                    <RenderPassword data={props.fields['password']} disabled={!!props.loading} />
                </div>
                <LoaderButton loading={props.loading} disabled={props.valid}>{Resource('LoginBtn')}</LoaderButton>
                <p className="centered">
                    <Link className="register-link" to='/create-account'>{Resource('CreateAccount')}</Link>
                    <ModalButton
                        isOpen={!props.modal.isOpen}
                        id={props.modal.id}
                        link
                    >
                        {Resource('ForgotPassword')}
                    </ModalButton>
                </p>
            </form>

            <Modal
                id={props.modal.id}
                isOpen={props.modal.isOpen}
                title={props.modal.title}
                button={props.modal.button}
                handleSubmit={props.handleResetPassword}
                addClass='reset'
            >
                <RenderInput data={props.modal.fields['loginResetEmail']} />
            </Modal>
        </>
    )
}

export default reduxForm({ form: 'loginForm' })(LoginForm)


