import React, { useEffect, useState } from "react";
import classes from "./AuthHeader.module.scss";
import { Link, useHistory } from "react-router-dom";
import Language from "../../../components/UI/Language";
import Navigation from "../Navigation/Navigation";
import BalanceHeader from "../сurrency/Balance/BalanceHeader";

const AuthHeader = (props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isDark, setIsDark] = useState(false);
  // const [isBalance, setIsBalance] = useState(true)
  const history = useHistory();

  const handlerIsOpenMenu = (value) => {
    setIsOpenMenu(value);
    props.setHandlerValue({ isOpenMenu: value });
  };

  const isDarkAsLinks = (location) => {
    setIsDark(false);
    props.isLinks &&
      props.isLinks.map((link) => {
        if (location.pathname.split("/")[1] !== link) return;
        setIsDark(true);
      });
  };

  useEffect(() => {
    isDarkAsLinks(history.location);
    history.listen((location) => {
      isDarkAsLinks(location);
    });
  }, []);

  return (
    <header className={[classes.header, isDark ? classes.dark : ""].join(" ")}>
      <div className={classes.interface}>
        <Link className={classes.logo} to="/" />
        {/*<div className={classes.action}>*/}
        <Language
          className={[
            classes.language,
            isOpenMenu ? classes.isOpen : "",
            isDark ? classes.dark : "",
          ].join(" ")}
        />
        {/*{isBalance && <BalanceHeader className={classes.balance}/>}*/}
        <span
          className={[classes.burger, isOpenMenu ? classes.isOpen : ""].join(
            " "
          )}
          onClick={handlerIsOpenMenu.bind(this, !isOpenMenu)}
        />
        {/*</div>*/}
      </div>
      <Navigation
        isOpen={isOpenMenu}
        setIsOpen={handlerIsOpenMenu}
        isDark={isDark}
        navItems={{
          MyInvestments: "/portfolio",
          Balance: "/depositing",
          StrategyTitle: "/information",
          InviteFriends: "/invitefriends",
        }}
      />
    </header>
  );
};

export default AuthHeader;
