import React from 'react'
import Button from '../../Button'
import { reduxForm } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import { useEffect } from 'react'
import RenderPhone from '../../formComponents/RenderPhone'
import { Resource } from '../../../../redux/actions/lang.actions'

const DepositVeriffForm = props => {
  
  useEffect(() => {
    for (let key in props.fields) {
      props.change(props.fields[key].id, String(props.fields[key].value).trim())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <form className="veriff-form" onSubmit={props.handleSubmit}>
        <RenderInput data={props.fields['veriffFirstName']} />
        <RenderInput data={props.fields['veriffLastName']} />
        
        <div className="veriff-form__buttons">
          <Button
            type='submit'
            disabled={props.submitSucceeded}
            // disabled={props.disableVerifBtn}
            addClass='button_primary'>{Resource('VeriffStartButton')}</Button>
        </div>
      </form>
    </>
  )
}

export default reduxForm({ form: 'DepositVeriffForm' })(DepositVeriffForm)