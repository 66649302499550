export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT/REQUEST'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT/SUCCESS'
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT/FAILURE'

export const CREATE_ACCOUNT_CHANGE_VALUE_SELECT = 'CREATE_ACCOUNT/CHANGE_VALUE_SELECT'
export const CREATE_ACCOUNT_CHANGE_FIELD = 'CREATE_ACCOUNT/CHANGE_VALUE_FIELD'

export const SEND_LEGAL_SUCCESS = 'SEND_LEGAL/SUCCESS'
export const SEND_LEGAL_FAILURE = 'SEND_LEGAL/FAILURE'

export const SEND_TOKEN_SUCCESS = 'SEND_TOKEN/SUCCESS'
export const SEND_TOKEN_FAILURE = 'SEND_TOKEN/FAILURE'

export const CREATE_ACCOUNT_STATUS_PASSWORD = 'CREATE_ACCOUNT/STATUS_PASSWORD'