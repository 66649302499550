import React, {useEffect} from 'react';
import {checkPaymentStatus, checkPaymentStatusWithProvider} from '../../../redux/actions/depositing/depositing.actions';
import { connect } from 'react-redux';
import {history} from "../../../redux/history";
import {useParams} from "react-router";

let intervalProcessId;

const PaymentProcess = (props) => {
    const historyState = history.location.state
    const params = useParams();
    // setInterval(() => {
    //     // props.checkPaymentStatus()
    // }, 3000)
    
    useEffect(() => {
        intervalProcessId = setInterval(props.checkPaymentStatusWithProvider, 3000, params.id)
        return () => {
            clearInterval(intervalProcessId);
        }
    }, [])

    return (
        <main>
            <section className="box status">
                <div className="status__icon processing"></div>
                <span className="status__text">Transaction in processing</span>
            </section>
        </main>
    );

}

const mapDispatchToProps = dispatch => {
    return {
        checkPaymentStatus: () => dispatch(checkPaymentStatus()),
        checkPaymentStatusWithProvider: (...arg) => dispatch(checkPaymentStatusWithProvider(...arg))
    }
}

export default connect(null, mapDispatchToProps)(PaymentProcess)