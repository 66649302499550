import React from 'react';
import {reduxForm} from "redux-form";

const CalculatorForm = (props) => {
  return (
    <form className='calculator__form'>
      {props.children}
    </form>
  );
};

export default reduxForm({ form: 'CalculatorForm' })(CalculatorForm);