import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSupportMessages, sendSupportMessage } from '../redux/actions/support/support.actions'
import SupportChat from '../components/Blocks/SupportChat'
import Loader from '../components/UI/Loader'
import { SUPPORT_GET_MESSAGE_SUCCESS, SUPPORT_SEND_MESSAGE_SUCCESS } from '../redux/constants/support/support.constants'
import SupportForm from '../components/UI/formControls/support/support.form'
import { Resource } from '../redux/actions/lang.actions'
import DocumentTitle from '../components/UI/DocumentTitle'

class Support extends Component {

    componentDidMount() {
        /*if (this.props.support.message === undefined) {*/
        this.props.getSupportMessages()
        /*}*/
        window.ym(56965879,'hit','/support');
    }

    onMessageSubmit = values => {
        const { supportMessage } = values;
        const pattern = /^[\s]+$/;
        !pattern.test(supportMessage) && this.props.sendSupportMessage(supportMessage.trim());
    }

    render() {
        
        return (
            <main>
                <DocumentTitle title="Support" />
                <section>
                    <div className="box panel">
                        <h3 className="box__heading">{Resource('Support')}</h3>
                        <p className="box__text">{Resource('SupportTitle')}</p>
                        <div className="chat">
                            {
                                !!this.props.support.message
                                    ? <SupportChat messages={this.props.support.message} loading={this.props.loading} />
                                    : <div className="spinner"></div>
                            }
                            <SupportForm
                                fields={this.props.fields}
                                onSubmit={this.onMessageSubmit.bind(this)}
                                messages={this.props.support.message}
                                loading={this.props.loading}
                            />
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        person: state.person,
        support: state.support,
        fields: state.support.fields,
        lang: state.language.langActive,
        loading: state.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSupportMessages: () => dispatch(getSupportMessages()),
        sendSupportMessage: message => dispatch(sendSupportMessage(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support)