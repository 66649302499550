import React from 'react';
import { Link } from 'react-router-dom';
import { Resource } from '../../../redux/actions/lang.actions';
import { connect } from 'react-redux';
import { getPersonData } from '../../../redux/actions/person.action';
import { useEffect } from 'react';

const VeriffError = (props) => {

    useEffect(() => {
        props.getPersonData()
    }, [])

    return (
        <main className="veriff-modal__iframe">
            <section>
                <div className="veriff-modal__wrapper">
                    <span className="veriff-modal__icon veriff-modal__icon--error" />


                    <img className="veriff-modal__logo" src="./image/veriff/veriff-logo.svg" alt="veriff-logo" />
                    <div className="veriff-modal__logo--site"></div>
                    <h1 className="veriff-modal__title">{Resource('ThankyouErrorTitle')}</h1>
                    <p className="veriff-modal__description" dangerouslySetInnerHTML={{ __html: Resource('ThankyouErrorText') }}></p>
                    <p className="veriff-modal__error">{Resource(props.veriff.expiredText)}</p>
                    <Link to="/portfolio" target="_top" className="button button--small veriff-modal__button">{Resource('GoToAccount')}</Link>

                </div>
            </section>
        </main>
    );
    
}

const mapStateToProps = state => {
    return {
        veriff: state.veriff
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPersonData: () => dispatch(getPersonData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VeriffError)