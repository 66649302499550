import { FORMS_DEFAULT_VALUE_SELECT, FORMS_SHOW_PASSWORD } from "../../constants/forms.constants"

const initialState = {
    selects: {},
    passowrdShow: {}
}

const forms = (state = initialState, action) => {
    switch (action.type) {
        case FORMS_DEFAULT_VALUE_SELECT:
            return { ...state, selects: { ...state.selects, [action.id]: action.value } }
        case FORMS_SHOW_PASSWORD:
            return {
                ...state,
                passowrdShow: {
                    [action.id]: action.show
                }
            }
        default:
            return state
    }
}

export default forms