import React from 'react'
import DropdownList from 'react-widgets/lib/DropdownList'
import { connect } from 'react-redux'
import { changeValueSelect } from '../../../redux/actions/forms.actions'
import { Resource } from '../../../redux/actions/lang.actions'

const RenderSelect = ({ data: { id, name, options, value, orderValue, label, labelHidden }, changeValueSelect }) => {


    const renderDefaultValue = () => {
        for (let i = 0; i < Object.keys(options).length; i++) {
            if (String(options[i].id) === String(value)) {
                return options[i]
            }
        }
    }

    options.sort((a, b) => {
        if (orderValue !== 'id') {
            if (Resource(a[orderValue]) > Resource(b[orderValue])) return 1
            if (Resource(a[orderValue]) < Resource(b[orderValue])) return -1
            return 0
        }
        if (a[orderValue] > b[orderValue]) return 1
        if (a[orderValue] < b[orderValue]) return -1
        return 0
    })

    return (
        <div className='form__group'>
            {
                labelHidden
                    ? <label htmlFor={name} className="form__label" style={{ visibility: 'hidden' }}>{Resource(labelHidden)}</label>
                    : label && <label htmlFor={name} className="form__label">{Resource(label)}</label>
            }
            <DropdownList
                id={id}
                name={name}
                textField={item => Resource(item.value)}
                defaultValue={renderDefaultValue()}
                data={options}
                onChange={value => {
                    changeValueSelect(id, value)
                }}
                className={'field field__select'}
                selectIcon={false}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.language.langActive,
        selects: state.forms.selects
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeValueSelect: (id, value) => dispatch(changeValueSelect(id, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderSelect)