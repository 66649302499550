// Здесь хранятся типы событий
export const POPUP = {
  GRADE_TOGGLE: "INVESTMENT/GRADE_TOGGLE",
  STOPWAR_TOGGLE: "INVESTMENT/STOPWAR_TOGGLE",
  JUDICIAL_TOGGLE: "INVESTMENT/JUDICIAL_TOGGLE",
  JUDICIAL_INFO_TOGGLE: "INVESTMENT/JUDICIAL_INFO_TOGGLE",
  JUDICIAL_DB_TOGGLE: "INVESTMENT/JUDICIAL_DB_TOGGLE",
  INFO_TOGGLE: "INVESTMENT/INFO_TOGGLE",
  PORTFOLIOS_INFO_TOGGLE: "INVESTMENT/PORTFOLIOS_INFO_TOGGLE",
  PORTFOLIOS_INFO2_TOGGLE: "INVESTMENT/PORTFOLIOS_INFO2_TOGGLE",
};

export const FILTER = {
  FILTER_TOGGLE: "JUDICIAL/FILTER_TOGGLE",
};

export const POPUP_INFO = {
  POPUP_INFO_TOGGLE: "POPUP/POPUP_INFO_TOGGLE",
  POPUP_INFO_CHECK: "POPUP/POPUP_INFO_CHECK",
};

export const STRATEGY = {
  STATUS: "STRATEGY/STATUS",
};

export const JUDICIAL = {
  GET: "JUDICIAL/GET",
  GET_ONE: "JUDICIAL/GET_ONE",
  LOADING: "JUDICIAL/LOADING",
  BONUS: "JUDICIAL/BONUS",
};

export const PAYTOOLS = {
  SET_CARD: "PAYTOOLS/SET_CARD",
  SET_BANK: "PAYTOOLS/SET_BANK",
};

export const BAN = {
  CHECK_CARD: "BAN/CHECK_CARD",
};

export const INVITE = {
  INVITE_FRIENDS: "INVITE/INVITE_FRIENDS",
  MY_INVITE: "INVITE/MY_INVITE",
  REDIRECT: "INVITE/REDIRECT",
};
