import React, {useState} from 'react';
import classes from './TabsComponent.module.scss'
import {connect} from "react-redux";
import {setActiveTabs} from "../../redux/tabs-reducer";

const TabsComponent = ({ onTabClick, children, id, stateTabs, setActiveTabs, customClasses }) => {
  
  const [bindIndex, setBindIndex] = useState(stateTabs[id].activeId);

  const changeTab = newIndex => {
    if(bindIndex !== newIndex) {
      if (typeof onTabClick === 'function') onTabClick(newIndex);
      setBindIndex(newIndex);
      setActiveTabs(id, newIndex)
    }
  };

  const items = React.Children
    .map(children, (child) => child && React.cloneElement(child, {key: 'TabComponentItem'}))
    .filter(item => item.key.split('/')[0] === 'TabComponentItem')

  return (
    <div className={classes.tab}>
      <div className={[classes.menu, customClasses.menu].join(' ')}>
        {items.map(({ props: { label, order, disabled, count } }, index) =>{
          return (
            <button
              key={`tab-btn-${index + 1}`}
              onClick={() => changeTab(index + 1)}
              className={bindIndex === index + 1 ? [classes.focus, customClasses.focus].join(' ') : ''}
              disabled={disabled === undefined ? false : !disabled}
            >
              {label}
              {count > 0 && <span className={classes.count}>{count}</span>}
            </button>
          )
        })}
      </div>
      <div className={[classes.view, customClasses.view].join(' ')}>
        {items.map(({ props }, index) => {
          return (
            <div
              {...props}
              className={[classes.content, bindIndex === index + 1 ? [classes.selected, customClasses.selected].join(' ') : '', customClasses.content].join(' ')}
              key={`tab-content-${index + 1}`}
            />
          )
        })}
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    stateTabs: state.tabsComponentReducer
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setActiveTabs: (id, activeId) => dispatch(setActiveTabs(id, activeId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsComponent)