export const AUTH_REQUEST = 'AUTH/REQUEST'
export const AUTH_SUCCESS = 'AUTH/SUCCESS'
export const AUTH_FAILURE = 'AUTH/FAILURE'
export const LOGOUT = 'LOGOUT'

export const AUTH_GOOGLE_SUCCESS = 'AUTH/GOOGLE_SUCCESS'
export const AUTH_GOOGLE_FAILURE = 'AUTH/GOOGLE_FAILURE'
export const LOGOUT_GOOGLE = 'LOGOUT/GOOGLE'

export const LOGIN_HASH_SUCCESS = 'LOGIN/HASH_SUCCESS'
export const LOGIN_HASH_FAILURE = 'LOGIN/HASH_FAILURE'

export const LOGIN_CONFIRM_EMAIL_SUCCESS = 'LOGIN/CONFIRM_EMAIL_SUCCESS'
export const LOGIN_CONFIRM_EMAIL_FAILURE = 'LOGIN/CONFIRM_EMAIL_FAILURE'

export const SEND_RESTORE_EMAIL_SUCCESS = 'SEND_RESTORE/EMAIL_SUCCESS'
export const SEND_RESTORE_EMAIL_FAILURE = 'SEND_RESTORE/EMAIL_FAILURE'

export const SEND_RESTORE_PASSWORD_SUCCESS = 'SEND_RESTORE/PASSWORD_SUCCESS'
export const CONFIRM_RESTORE_PASSWORD_SUCCESS = 'CONFIRM_RESTORE/PASSWORD_SUCCESS'
