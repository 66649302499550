import React, { Component } from 'react';
import { Resource } from '../redux/actions/lang.actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class NotFound extends Component {
    static displayName = NotFound.name;

    render() {
        return (
            <main>
                <section className="not-page">
                    <div className="container">
                        <div className="not-page-block">
                            <h1 className="not-page__title">{Resource('PageNotFound')}</h1>
                            <p className="not-page__description">{Resource('PageNotExist')}</p>
                            <span className="not-page__number">404</span>
                            <Link to="/" className="button">{Resource('ReturnMainPage')}</Link>
                        </div>
                    </div>
                </section>
            </main>
        );
    };
    
}

const mapStateToProps = state => {
    return {
        lang: state.language.langActive,
    }
}


export default connect(mapStateToProps, null)(NotFound)