export const SETTINGS_REQUEST = 'SETTINGS/REQUEST'
export const SETTINGS_SUCCESS = 'SETTINGS/SUCCESS'
export const SETTINGS_FAILURE = 'SETTINGS/FAILURE'
export const SETTINGS_SAVE_REQUEST = 'SETTINGS/SAVE_SUCCESS'
export const SETTINGS_SAVE_SUCCESS = 'SETTINGS/SAVE_SUCCESS'
export const SETTINGS_SAVE_FAILURE = 'SETTINGS/SAVE_SUCCESS'

export const SETTINGS_CHANGE_VALUE_STORE = 'SETTINGS/CHANGE_VALUE_STORE'

export const SETTINGS_DROPDOWN_SELECT = 'SETTINGS/DROPDOWN_SELECT'
export const SETTINGS_DEFAULT_VALUE_SELECT = 'SETTINGS/DEFAULT_VALUE_SELECT'
