import React, { Component } from 'react';
import Button from '../components/UI/Button'
import Stats from '../components/Blocks/Stats';
import NavCards from '../components/Blocks/NavCards';
import { connect } from 'react-redux';
import { getPersonData } from '../redux/actions/person.action';
import { VERIFY_CONFIRM, PERSON_DATA_SUCCESS, EMAIL_CONFIRM } from '../redux/constants/person.constants';
import Strategys from '../components/Blocks/Strategys/Strategys.container';
import { getProductList } from '../redux/actions/profile/strategys.actions';
import ProgressBar from '../components/Blocks/ProgressBar/ProgressBar';
import Loader from '../components/UI/Loader';
import { sendRestorePassword, confirmEmailHash, restoreEmailLink } from '../redux/actions/login/auth.actions';
import { Resource } from '../redux/actions/lang.actions';
import VeriffIframe from '../components/UI/statusVeriff/VeriffIframe';
import Tutorial from '../components/UI/Tutorial';
import DocumentTitle from '../components/UI/DocumentTitle';
import Banner from '../components/UI/Banner';

import Section from "../update/components/Section/Section";
import List from "../update/components/List/List";
import classes from '../update/page/Profile/profile.module.scss'


class Profile extends Component {

    componentDidMount() {
        // this.props.getPersonData()
        // this.props.getProductList()

        window.gtag('set', 'page_path', '/profile');
        window.gtag('event', 'page_view');
    }

    render() {
        return (
            <main className='index'>
                <DocumentTitle title="MyAccount" />

                {/*<Banner />*/}
                <Loader loadId={PERSON_DATA_SUCCESS} reload={this.props.person.veriffStatusId !== VERIFY_CONFIRM ? true : false}>
                    {
                        this.props.person.veriffStatusId !== VERIFY_CONFIRM && <ProgressBar loading={this.props.loading} params={this.props.person} progress={this.props.progress} restoreEmailLink={this.props.restoreEmailLink} />
                    }
                </Loader>

                <section>
                  <div className="box panel panel_azure">
                      <ul className="widget widget_panel index-control columns_3">
                          <Stats id='currentBalance' data={this.props.person} load={PERSON_DATA_SUCCESS} />
                          <li className="index-control__form mobile-hidden">
                              <Button
                                  link={'/depositing'}
                                  small
                              >{Resource('DepositAccount')}</Button>
                              <Button
                                  link={'/withdrawal'}
                                  small
                                  stroke
                                  disabled={this.props.person.veriffStatusId !== VERIFY_CONFIRM || this.props.person.statusId !== EMAIL_CONFIRM}
                              >{Resource('WithdrawFunds')}</Button>                     
                          </li>
                          <Stats id='investBalance' data={this.props.person} load={PERSON_DATA_SUCCESS} />
                      </ul>
                  </div>
                </section >
              
                <section>
                  <Strategys />
                </section>

                <section>
                    <NavCards
                        cards={[
                            {
                                title: 'MyInvestments',
                                text: 'WidgetInvestLead',
                                icon: 'briefcase',
                                url: '/my-investment',
                                disabled: false,
                                //step: 3
                            },
                            {
                                title: 'History',
                                text: 'WidgetHistoryLead',
                                icon: 'history',
                                url: '/operations-history',
                                disabled: false,
                                //step: 4
                            },
                            /* Block Partner*/
                            {
                                title: 'Support',
                                text: 'WidgetSupportLead',
                                icon: 'support',
                                url: '/support',
                                disabled: false,
                                //step: 5
                            },
                            {
                                title: 'BringFriend',
                                text: 'WidgetBringFriendLead',
                                icon: 'friend',
                                url: '/partner',
                                disabled: true,
                                //disabled: this.props.person.veriffStatusId !== VERIFY_CONFIRM || this.props.person.statusId !== EMAIL_CONFIRM,
                                //step: 5,
                                blocked: true
                            }
                        ]}
                    />
                </section>

                <div className="fixed desktop-hidden">
                    <div className="container">
                        <div className="index-control__buttons">
                            <Button
                                link={'/depositing'}
                            >{Resource('DepositAccountMobile')}</Button>
                            <Button
                                link={'/withdrawal'}
                                disabled={this.props.person.veriffStatusId !== VERIFY_CONFIRM || this.props.person.statusId !== EMAIL_CONFIRM}
                            >{Resource('WithdrawFundsMobile')}</Button>
                        </div>
                    </div>
                </div>

                <VeriffIframe modal={this.props.modal['modalVeriff']} />
                {/*<div id="veriff-root"/>*/}
            </main>
        );
    };
}

const mapStateToProps = state => {
    return {
        person: state.person,
        auth: state.auth.logged,
        profile: state.profile,
        loading: state.loading,
        progress: state.profile.progress,
        lang: state.language.langActive,
        modal: state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPersonData: () => dispatch(getPersonData()),
        getProductList: () => dispatch(getProductList()),
        sendRestorePassword: (hash) => dispatch(sendRestorePassword(hash)),
        confirmEmailHash: (hash) => dispatch(confirmEmailHash(hash)),
        restoreEmailLink: () => dispatch(restoreEmailLink())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)