import React, {useEffect} from 'react';
import classes from "./statusPayment.module.scss";
import error from "../../../static/image/icons/icon-payment-error.svg";
import company from '../../../static/image/logos-company.svg'
import DefaultButton from "../../../update/components/Buttons/DefaultButton/DefaultButton";
import {connect} from "react-redux";
import {Resource} from "../../../redux/actions/lang.actions";
import {history} from "../../../redux/history";

const PaymentError = (props) => {
    
    useEffect(()=> {
        // YandexCounter
        window.ym(56965879,'reachGoal','balance_refill_fail');
        window.fcWidget.hide()
    })

    const getQueryMessage = () => {
        if (window.location.search.replace('?error=', '') !== '') {
            return window.location.search.replace('?error=', '').replace(/[%20]/g, ' ')
        } else if(history.location.state){
            return history.location.state.errorMessage
        } else {
            return 'Unknown error'
        }
    }

    return (
      <main>
          <section className={[classes.status, classes.error].join(' ')}>
              <img className={classes.icon} src={error} alt='error'/>
              <span className={classes.title}>{Resource('ResultPaymentErrorTitle')} <br/> {getQueryMessage()}</span>
              <div className={classes.info}>
                  <p>{Resource('ResultPaymentErrorInfo')}</p>
                  <img src={company} alt="company logos"/>
              </div>
              <DefaultButton onClick={() => {
                  window.parent.location.reload()
              }} customClass={classes.button}>OK</DefaultButton>
          </section>
      </main>
    );
}

const mapStateToProps = state => {
    return {
        language: state.language
    }
}

export default connect(mapStateToProps, null)(PaymentError)