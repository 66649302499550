import { PORTFOLIO_DETAILS_SUCCESS, PORTFOLIO_DETAILS_FAILED } from "../../constants/portfolio/portfolio.constants"
import { PAGINATION_CHANGE_ACTIVE_PAGE } from "../../constants/table.constants"

const initialState = {
    balance: {
        valueKey: 'AccountSumAvailable',
        value: '',
        icon: 'wallet',
        formatting: true
    },
    portfolioAmount: {
        valueKey: 'ActivePortfolio',
        value: '',
        icon: 'briefcase',
        formatting: true
    },
    interest: {
        valueKey: 'IncomeReceived',
        value: '',
        icon: 'sack',
        formatting: true
    },
    expectedInterest: {
        valueKey: 'ExpectedIncome',
        value: '',
        icon: 'timer',
        formatting: true
    },
    loanCount: {
        valueKey: 'XInvestedLoans',
        value: '',
        icon: 'layers',
        formatting: false
    },
    loanCountActive: {
        valueKey: 'ActiveLoans',
        value: '',
        icon: 'active',
        formatting: false
    },
    loanCountExpired: {
        valueKey: 'XOverdueLoans',
        value: '',
        icon: 'shield',
        formatting: false
    },
    loanCountClosed: {
        valueKey: 'XClosedLoans',
        value: '',
        icon: 'closed',
        formatting: false
    },
    fields: {
        depositValue: {
            id: 'depositValue',
            name: 'depositValue',
            type: 'text',
            placeholder: 'Sum',
            container: false,
            edit: true,
            validate: {
                required: true,
                range: [10, 10000]
            },
            depositFormat: true
        },
    },
    documentMainUrl: '',
    ruleId: '',
    portfolioTable: {
        invest: {
            id: 'portfolio_invest',
            columnsOptions: {
                dateCreate: {
                    title: 'Investment_date', flexGrow: 1, minWidth: 130, sort: true, format: 'date'
                },
                amount: {
                    title: 'Investment_amount', flexGrow: 1.5, minWidth: 150, sort: true, format: 'currency'
                },
                dateClose: {
                    title: 'ClosingDate', flexGrow: 1, minWidth: 130, sort: true, format: 'date'
                },
                interest: {
                    title: 'ExpectedIncome', flexGrow: 1, minWidth: 150, sort: true, format: 'currency'
                },
                mfcName: {
                    title: 'Country', flexGrow: 1.5, minWidth: 150, sort: true, format: 'string'
                },
                statusId: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                link: {
                    title: 'Document', flexGrow: 1, minWidth: 100, sort: false
                },
            },
            body: [],
            sortColumn: 'id'
        },
        deposit: {
            id: 'portfolio_deposit',
            columnsOptions: {
                id: {
                    title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
                },
                operation: {
                    title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
                },
                amount: {
                    title: 'Income', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-dep'
                },
                statusId: {
                    title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
                },
                dateCreate: {
                    title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
                },
                link: {
                    title: 'Document', flexGrow: 1, minWidth: 100, sort: false
                },
            },
            body: []
        }
    }
}

const portfolio = (state = initialState, action) => {

    const getRuleId = () => {
        switch (action.payload.ruleId) {
            case 1:
                return 'LongTerm'
            case 2:
                return 'Test'
            case 3:
                return 'StrategyClassicTitle'
            case 4:
                return 'StrategyBalancedTitle'
            case 5:
                return 'StrategySpecialTitle'
            default:
                return null
        }
    }

    const getPortfolioInvest = () => {
        return action.tableData.invest.map(({ dateCreate, amount, dateClose, interest, documentUrl, mfcLoanStatus, statusId, mfcName }) => {
            let returnObj = {
                dateCreate,
                amount,
                dateClose,
                interest,
                mfcName,
                statusId: (statusId === 181 || statusId === 179)
                    ? 'STATUS_001'
                    : (mfcLoanStatus === 160
                        ? 'STATUS_002'
                        : (mfcLoanStatus === 161
                            ? 'STATUS_001'
                            : (mfcLoanStatus === 162
                                ? 'STATUS_002'
                                : (mfcLoanStatus === 163
                                    ? 'STATUS_002'
                                    : (mfcLoanStatus === 167
                                        ? 'STATUS_003'
                                        : ""
                                    )
                                )
                            )
                        )
                    ),
            }

            if (action.payload.ruleId > 2) {
                const addObj = {
                    link: 'Agreement',
                    options: { urlLink: documentUrl }
                };
                returnObj = { ...returnObj, ...addObj }
            }

            return returnObj
        })
    }

    const getPortfolioDeposit = () => {
        return action.tableData.deposit.map(({ id, amount, statusId, dateCreate, documentUrl }) => {

            let returnObj = {
                id,
                operation: 'PortfolioReplenishmentOperation',
                amount,
                statusId: statusId === 80
                    ? 'STATUS_005'
                    : (statusId === 81
                        ? 'STATUS_006'
                        : (statusId === 82
                            ? 'STATUS_004'
                            : ""
                        )
                    ),
                dateCreate,
            }

            let specialDocs = {
                link: 'Agreement',
                options: { urlLink: documentUrl }
            };

            if (action.payload.ruleId < 3) {
                returnObj = { ...returnObj, ...specialDocs }
            }

            return returnObj;
        })
    }

    switch (action.type) {
        case PORTFOLIO_DETAILS_SUCCESS:
            return {
                ...state,
                portfolioId: action.payload.portfolioId,
                aviableRepayment: action.payload.aviableRepayment,
                documentMainUrl: action.payload.documentMainUrl,
                ruleId: getRuleId(),
                balance: {
                    ...state.balance,
                    value: action.payload.balance
                },
                portfolioAmount: {
                    ...state.portfolioAmount,
                    value: action.payload.portfolioAmount
                },
                interest: {
                    ...state.interest,
                    value: action.payload.interest
                },
                expectedInterest: {
                    ...state.expectedInterest,
                    value: action.payload.expectedInterest
                },
                loanCount: {
                    ...state.loanCount,
                    value: action.payload.loanCount
                },
                loanCountActive: {
                    ...state.loanCountActive,
                    value: action.payload.loanCountActive
                },
                loanCountExpired: {
                    ...state.loanCountExpired,
                    value: action.payload.loanCountExpired
                },
                loanCountClosed: {
                    ...state.loanCountClosed,
                    value: action.payload.loanCountClosed
                },
                portfolioTable: {
                    ...state.portfolioTable,
                    invest: {
                        ...state.portfolioTable.invest,
                        body: getPortfolioInvest()
                    },
                    deposit: {
                        ...state.portfolioTable.deposit,
                        body: getPortfolioDeposit()
                    }
                }

            }
        case PORTFOLIO_DETAILS_FAILED:
            return { ...state, error: action.payload }
        default:
            return state
    }
}

export default portfolio
