import React from 'react'
import { currencyFormatProfit } from '../../../redux/utils/formatting'
import Loader from '../../UI/Loader'
import {STRATEGY_DATA_SUCCESS, switchId} from '../../../redux/constants/profile/strategys.constants'
import { Resource } from '../../../redux/actions/lang.actions'
import CountUp from 'react-countup'
import Currency from "../../../update/components/сurrency/Currency";

const Profit = ({ data, idProduct }) => {

    const nameProduct = switchId(idProduct)
    const { startSum, reinvestSum, term, customAmount, customTerm, customReinvest } = data.dynamicValues
    const { percent } = data[nameProduct]

    let amountMin = data[nameProduct].startSum.min
    let amountMax = data[nameProduct].startSum.max
    let monthMin = data[nameProduct].term.min
    let monthMax = data[nameProduct].term.max
    let reinvestMin = data[nameProduct].reinvestSum.min
    let reinvestMax = data[nameProduct].reinvestSum.max
    
    const getReinvets = (amount, monthAmount, term, perc) => {
        let mA = monthAmount,
          a = amount,
          n = 0,
          m = 0

        for (let i = 1; i <= term; i++) {
            m = i
            if (m <= term) {
                n = n + (a * perc / 12) / 100
                a = a + mA
                m = m + 1
            }
        }
        return Math.floor(n) === 0 ? n : Math.floor(n);
    }

    const outputInvestments = (startSum, reinvestSum, term) => {
        if (startSum < amountMin) {
            return reinvestSum * (term - 2) + reinvestSum + amountMin
        } else if (startSum > amountMax) {
            return reinvestSum * (term - 2) + reinvestSum + amountMax
        } else if (term < monthMin) {
            return reinvestSum * (monthMin - 2) + reinvestSum + startSum
        } else if (term > monthMax) {
            return reinvestSum * (monthMax - 2) + reinvestSum + startSum
        } else if (reinvestSum > reinvestMax) {
            return reinvestMax * (term - 2) + reinvestMax + startSum
        } else {
            return reinvestSum * (term - 2) + reinvestSum + startSum
        }
    }

    const outputProfit = (startSum, reinvestSum, term) => {
        if (startSum < amountMin) {
            return getReinvets(amountMin, reinvestSum, term, percent)
        } else if (startSum > amountMax) {
            return getReinvets(amountMax, reinvestSum, term, percent)
        } else if (term < monthMin) {
            return getReinvets(startSum, reinvestSum, monthMin, percent)
        } else if (term > monthMax) {
            return getReinvets(startSum, reinvestSum, monthMax, percent)
        } else if (reinvestSum > reinvestMax) {
            return getReinvets(startSum, reinvestMax, term, percent)
        } else {
            return getReinvets(startSum, reinvestSum, term, percent)
        }

    }

    const outputTerm = (term) => {

        if (term < monthMin) {
            return monthMin
        } else if (term > monthMax) {
            return monthMax
        } else {
            return term
        }
    }

    return (
        <div className='columns'>
            <Currency
                amount={currencyFormatProfit(outputInvestments(customAmount ? customAmount : startSum, customReinvest !== null ? customReinvest : reinvestSum, customTerm !== null ? customTerm : term))}
                text={[Resource('WillAddXMonth1'), outputTerm(customTerm !== null ? customTerm : term), Resource('WillAddXMonth2')]}
                className={!data.fields['checkDeposit'].checked ? 'hidden--mobile' : ''}
            />
            <Currency 
                primary
                amount={currencyFormatProfit(outputProfit(customAmount ? customAmount : startSum, customReinvest !== null ? customReinvest : reinvestSum,customTerm !== null ? customTerm : term))}
                text={[Resource('PotentialProfit')]}
            />
        </div>
    )
}

export default Profit