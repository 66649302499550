import React, { Component } from "react";
import Modal from "../Modal/Modal";

class PaymentIframe extends Component {
  render() {
      if(!this.props.pay?.url) {
          return null
      }
      
    const url = new URL(this.props.pay?.url).pathname.indexOf('paymentCardForm') !== -1
        ? new URL(this.props.pay?.url).pathname + new URL(this.props.pay?.url).search 
        : this.props.pay?.url;
      
      // const h = 'https://api-preprod.nibble.finance/webform/paymentCardForm?customerKey=664&requestKey=3171&orderPrice=50';
      // const p = 'https://sandbox.payneteasy.eu/paynet/form/init/BB6C5A48726969446231413178616E6E505A62514A384E4466787934494B506B564E642F2F305551396E76383D'
      // console.log(new URL(p).pathname.indexOf('paymentCardForm'))

    return (
      <>
        <Modal
          isOpen={this.props.modal.isOpen}
          title={this.props.modal.title}
          id={this.props.modal.id}
          addClass="modal__wrapper--payment"
          onCloseHandler={this.props.closeHandler}
        >
          <iframe
            title="payment"
            frameBorder="0"
            // src={this.props.pay?.url}
            src={url}
            width="100%"
            height="100%"
          />
        </Modal>
      </>
    );
  }
}

export default PaymentIframe;
