import React from 'react'
import { get as getCookie } from 'js-cookie'
import { localeMonthDate } from '../../locale/tables.locale'
import { toast } from 'react-toastify'
import { Resource } from '../actions/lang.actions'
import depositing from '../reducers/depositing/depositing.reducer'

export const currentFormatting = number => {
	return new Intl.NumberFormat(
		getCookie('language'),
        {
            style: 'currency',
            currency: 'EUR',
			//minimumSignificantDigits: msd
        }
    ).format(number)
}

export const currencyFormatProfit = number => {
	return new Intl.NumberFormat(
		getCookie('language'),
		{
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: 2
		}
	).format(number)
}


export const percentFormatting = number => {
    return new Intl.NumberFormat(
		getCookie('language'),
        {
            minimumFractionDigits: 2,
            minimumSignificantDigits: 2
        }
    ).format(number)
}

export const dateFormatting = date => {
	const monthNames = localeMonthDate[getCookie('language')?.toLowerCase()],
		dateParse = new Date(Date.parse(date)),
		day = dateParse.getDate() < 10 ? '0' + dateParse.getDate() : dateParse.getDate(),
		monthIndex = dateParse.getMonth(),
		year = dateParse.getFullYear()
	
	return day + ' ' + monthNames[monthIndex + 1] + ' ' + year;
}

export const dateFormattingWithTime = date => {
	const monthNames = localeMonthDate[getCookie('language')?.toLowerCase()],
		dateParse = new Date(Date.parse(date)),
		day = dateParse.getDate() < 10 ? '0' + dateParse.getDate() : dateParse.getDate(),
		monthIndex = dateParse.getMonth(),
		hours = dateParse.getHours() < 10 ? '0' + dateParse.getHours() : dateParse.getHours(),
		minutes = dateParse.getMinutes() < 10 ? '0' + dateParse.getMinutes() : dateParse.getMinutes(),
		year = dateParse.getFullYear()

	return `${day} ${monthNames[monthIndex + 1]} ${hours}:${minutes}`;
}

export const tranferFormatting = (text) => {
	const arr = text.split('<br>')

	if (arr.length > 1) {
		return (
			<> {arr[0]} <br /> {arr[1]} </>
		)
	}else {
		return text
    }
}

export const calculateFormatting = number => {
	let getNumber = String(number).replace(/[^0-9]/gi, '')
	return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(+getNumber)
}

export const depositingFormatting = number => {
	let getNumber = String(number).replace('.', ',').replace(/[^0-9,]/gi, '')
	return getNumber
	//return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(getNumber)
	//switch (getCookie('language')?.toLowerCase()) {
	//	case 'ru':
	//		getNumber = String(number).replace(/[\.]/gi, ',').replace(/[^0-9\,\s]/gi, '')
	//		if (isNaN(getNumber)) {
	//			console.log('1')
	//			console.log(getNumber)
	//			return getNumber
	//		}
	//		console.log('2')
	//		return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(getNumber)
	//	case 'en':
	//		getNumber = String(number).replace(/[^0-9\.\s\,]/gi, '')
	//		return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(getNumber)
	//	case 'es':
	//		getNumber = String(number).replace(/[^0-9\.\s\,]/gi, '')
	//		return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(getNumber)
	//	case 'de':
	//		getNumber = String(number).replace(/[^0-9\.\s\,]/gi, '')
	//		return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(getNumber)
	//	case 'it':
	//		getNumber = String(number).replace(/[^0-9\.\s\,]/gi, '')
	//		return new Intl.NumberFormat(getCookie('language')?.toLowerCase()).format(getNumber)
	//	default:
	//		return number
 //   }

}

export const onlyNumber = value => {
	// eslint-disable-next-line
	return String(value).replace(/[^0-9\.]/gi, '')
}

export const onlyIndex = value => {
	// eslint-disable-next-line
	return String(value).replace(/[^0-9A-Za-z]/gi, '')
}

export const validationCurrency = (value, range) => {
	let numberRevers = String(value).replace(/[,#!$%&;:{}=\-_`~()\s]/g, "")
	console.log(+numberRevers, range[0])
	if (+numberRevers < range[0]) {
		toast.error(Resource('NoticeMinSum') + ' ' + currentFormatting(range[0]))
	}
	if (+numberRevers > range[1]) {
		toast.error(Resource('NoticeMaxSum') + ' ' + currentFormatting(range[1]))
	}
}

export const renderTemplateMinMax = (string, value) => {
	const stringArray = string.split('/')
	console.log(stringArray)
	// const minmax = {
	// 	min: value,
	// 	max: value
	// }
	// stringArray.map((item, idx) => {
	// 	if(minmax[item]) {
	// 		stringArray[idx] = minmax[item]
	// 	}
	// })
	return string.replaceAll('/min/', value)
}