import React, { useState, useEffect } from "react";
const FixedScroll = ({ defaultPos, children, className }) => {

    const [topValue, setTopValue] = useState(defaultPos)

    const moveScrollWindow = () => {
        setTopValue(window.pageYOffset < defaultPos ? defaultPos + 'px' : window.pageYOffset + 'px')
    }

    useEffect(() => {
        document.addEventListener('scroll', moveScrollWindow, false);
        return () => {
            document.removeEventListener('scroll', moveScrollWindow, false);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <aside className={className} style={{ top: topValue }}>{children}</aside>    
    )
};

export { FixedScroll };