import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({current, full}) => {

    const getWidthProgress = (current, full) => {
        let result = (current * 100) / full;
        if (result > 100) {
            result = 100;
        }
        return result + '%'
    };

    return(
        <div className="progressbar-nibble">
            <div className="progressbar-nibble__slider" style={{width: getWidthProgress(Number(current), Number(full))}} ></div>
        </div>
    )
};

export { ProgressBar };