import React from 'react'
import { connect } from 'react-redux'
import { changeActiveId } from '../../redux/actions/tabs.actions'
import { Resource } from '../../redux/actions/lang.actions'
import {STRATEGY_DATA_SUCCESS} from "../../redux/constants/profile/strategys.constants";
import CountUp from "react-countup";
import Loader from "./Loader";

const RenderTabs = (props) => {

    const { id, children, changeActiveId, tabs, disabled, percent, dataProduct } = props

    const state = props.tabs[id]
    const changeTabHundler = event => {
        let target = event.target
        target.closest('.tabs__wrapper').scrollTo(target.offsetLeft - target.closest('.tabs__wrapper').offsetLeft, 0)
        if (+target.closest('.tab').dataset.index !== tabs[id].activeId) {
            changeActiveId(+target.closest('.tab').dataset.index, id)
        }
    }
    
    const renderTabsAdditional = () => {
        return state.tabs.map((tabLabel, i) => {
          return (
            <li key={tabLabel + Math.random()} data-index={i}
                className={`tab ${state.activeId === i ? 'active' : ''}  ${state.additional ? 'tab__additional' : ''}`}
                onClick={changeTabHundler}>
                    {Resource(tabLabel)}
                    <span>{String(state.additional.percent[i]).replace('.', ',')}%</span>
            </li>
          )  
        })
    }

    const renderTabs = () => {
        return state.tabs.map((tabLabel, i) => {
            if(disabled){
                let {indexTab, value} = disabled
                if (i === indexTab) {
                    if (value) {
                        return (
                          <li key={tabLabel + Math.random()} data-index={i}
                              className={`tab ${state.activeId === i ? 'active' : ''}`}
                              onClick={changeTabHundler}>{Resource(tabLabel)}</li>
                        )
                    }
                } else {
                    return (
                      <li key={tabLabel + Math.random()} data-index={i} 
                          className={`tab ${state.activeId === i ? 'active' : ''}`} 
                          onClick={changeTabHundler}>{Resource(tabLabel)}</li>
                    )
                }
            } else {
                return (
                  <li key={tabLabel + Math.random()} data-index={i} 
                      className={`tab ${state.activeId === i ? 'active' : ''}`} 
                      onClick={changeTabHundler}>{Resource(tabLabel)}</li>
                )
            }
            
        })
    }

    return (
        <>
            {
                state.additional
                  ? <>
                      <ul className="tabs__wrapper tabs-calculator">
                          { renderTabsAdditional() }
                      </ul>
                      <div className={`tabs__content active calculator`}>
                          { children[state.activeId] }
                      </div>
                  </>
                  : <>
                      <ul className="tabs__wrapper">
                          { renderTabs() }
                      </ul>
                      <div className={`tabs__content active`}>
                          { children[state.activeId] }
                      </div>
                  </>
            }
            
        </>
    )
}

const MSTP = state => {
    return {
        tabs: state.tabs
    }
}

const MDTP = {
    changeActiveId
}


export default connect(MSTP, MDTP)(RenderTabs)