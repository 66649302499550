import {DepositingAPI} from "../api/REST-API";
import {currentFormatting, dateFormatting} from "../../redux/utils/formatting";
import {Resource} from "../../redux/actions/lang.actions";

// const DEPOSITING_DATA = 'BALANCED/GET_DATA';

// export const setDepositPayments = (payload) => dispatch => {
//     let data = payload.map(payment => {
//         const {id, wayId, deposit, status, dateOperation} = payment
//         return {
//             id,
//             operations: CODE_OPERATIONS[wayId],
//             deposit,
//             status: CODE_STATUS[status],
//             dateOperation
//         }
//     })
//     dispatch({ type: DEPOSITING_DATA, data })
// }
//
// export const getDepositPayments = () => dispatch => {
//     return DepositingAPI.getDepositPayments()
//       .then(resp => {
//         dispatch(setDepositPayments(resp.data.paymentsInformation))
//       })
//       .catch(error => {
//          
//       })
// }


const initialState = {
  fields: [
    {
      id: 'verifyCountry',
      type: 'select',
      options: [
        {id: 208, value: 'c208'},
        {id: 206, value: 'c206'},
        {id: 11, value: 'c11'},
        {id: 12, value: 'c12'},
        {id: 242, value: 'c242'},
        {id: 243, value: 'c243'},
        {id: 244, value: 'c244'},
        {id: 246, value: 'c246'},
        {id: 247, value: 'c247'},
        {id: 248, value: 'c248'},
        {id: 249, value: 'c249'},
        {id: 250, value: 'c250'},
        {id: 251, value: 'c251'},
        {id: 252, value: 'c252'},
        {id: 253, value: 'c253'},
        {id: 254, value: 'c254'},
        {id: 255, value: 'c255'},
        {id: 256, value: 'c256'},
        {id: 257, value: 'c257'},
        {id: 258, value: 'c258'},
        {id: 259, value: 'c259'},
        {id: 260, value: 'c260'},
        {id: 261, value: 'c261'},
        {id: 262, value: 'c262'},
        {id: 245, value: 'c245'},
        {id: 263, value: 'c263'},
        {id: 264, value: 'c264'},
        {id: 265, value: 'c265'},
        {id: 266, value: 'c266'},
        {id: 267, value: 'c267'}
      ],
      orderValue: 'value',
      value: 208,
    },
    {
      id: 'verifyCity',
      type: 'text',
      // label: 'City',
      placeholder: 'City',
      edit: true,
      validate: {
        required: true
      },
    },
    {
      id: 'verifyAddress',
      type: 'text',
      placeholder: 'AddressLine1',
      edit: true,
      validate: {
        required: true
      }
    },
    {
      id: 'verifyZip',
      type: 'text',
      placeholder: 'ZIP',
      edit: true,
      validate: {
        required: true
      }
    },
    {
      id: 'prefix',
      type: 'select',
      options: [
        {id: 43, value: '+43 AT'},
        {id: 32, value: '+32 BE'},
        {id: 359, value: '+359 BG'},
        {id: 44, value: '+44 GB'},
        {id: 36, value: '+36 HU'},
        {id: 49, value: '+49 DE'},
        {id: 30, value: '+30 GR'},
        {id: 45, value: '+45 DK'},
        {id: 353, value: '+353 IE'},
        {id: 34, value: '+34 ES'},
        {id: 39, value: '+39 IT'},
        {id: 357, value: '+357 CY'},
        {id: 371, value: '+371 LV'},
        {id: 370, value: '+370 LT'},
        {id: 352, value: '+352 LU'},
        {id: 356, value: '+356 MT'},
        {id: 31, value: '+31 NL'},
        {id: 48, value: '+48 PL'},
        {id: 351, value: '+351 PT'},
        {id: 7, value: '+7 RU'},
        {id: 40, value: '+40 RO'},
        {id: 421, value: '+421 SK'},
        {id: 386, value: '+386 SI'},
        {id: 358, value: '+358 FI'},
        {id: 33, value: '+33 FR'},
        {id: 385, value: '+385 HR'},
        {id: 420, value: '+420 CZ'},
        {id: 46, value: '+46 SE'},
        {id: 41, value: '+41 CH'},
        {id: 372, value: '+372 EE'},
        {id: 1, value: '+1 US'}
      ],
      orderValue: 'id',
      value: 43,
      // labelHidden: 'Code'
    },
    {
      id: 'phone',
      type: 'phone',
      placeholder: 'Phone1',
      // label: 'Phone',
      edit: true,
      numberFormat: true,
      validate: {
        required: true
      }
    }
  ]
}

const VerifyReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default VerifyReducer
