import React, { useRef, useEffect } from "react";
import "./LegalPage.scss";
import ScrollToLink from "../../../redux/utils/scrollToLink";
import { FixedScroll } from "../../components/FixedScroll/FixedScroll";
import { Resource } from "../../../redux/actions/lang.actions";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import { useSelector } from "react-redux";

const LegalPage = () =>{

    const language = useSelector(state => state.language);
    useEffect(() => {}, [language]);

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
    const contentRef4 = useRef(null);
    const contentRef5 = useRef(null);
    const contentRef6 = useRef(null);
    const contentRef7 = useRef(null);
    const contentRef8 = useRef(null);

    const createHtml = key => {
        return { __html: Resource(key) }
    }

    return(
        <main className="article-page">
            <DocumentTitle title={'legalTitle'} />
            <article className="content-page">
                    <section className="article-wrap">
                        <h1>{Resource('legalTitle')}</h1>
                        <div className="line"></div>
                        <FixedScroll className="article__side" defaultPos={80}>
                            <ul>
                                <li><ScrollToLink contentRef={contentRef1}>{Resource('legalInfoTitle1')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={contentRef2}>{Resource('legalInfoTitle2')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={contentRef3}>{Resource('legalInfoTitle4')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={contentRef4}>{Resource('legalInfoTitle5')}</ScrollToLink></li>
                                <li><ScrollToLink contentRef={contentRef4}>{Resource('legalInfoTitle6')}</ScrollToLink></li>
                            </ul>
                        </FixedScroll>
                    </section>


                    <section ref={contentRef1} className="article-wrap">
                        <div dangerouslySetInnerHTML={createHtml('legalInfoText1')} />
                    </section>
                    <section ref={contentRef2} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={createHtml('legalInfoTitle2')} />
                        <div dangerouslySetInnerHTML={createHtml('legalInfoText2')} />
                    </section>
                    <section ref={contentRef3} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={createHtml('legalInfoTitle3')} />
                        <div dangerouslySetInnerHTML={createHtml('legalInfoText3')} />
                    </section>
                    <section ref={contentRef3} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={createHtml('legalInfoTitle4')} />
                        <div dangerouslySetInnerHTML={createHtml('legalInfoText4')} />
                    </section>
                    <section ref={contentRef4} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={createHtml('legalInfoTitle5')} />
                        <div dangerouslySetInnerHTML={createHtml('legalInfoText5')} />
                    </section>
                    <section ref={contentRef4} className="article-wrap">
                        <h2 dangerouslySetInnerHTML={createHtml('legalInfoTitle6')} />
                        <div dangerouslySetInnerHTML={createHtml('legalInfoText6')} />
                    </section>
            </article>

        </main>
    )
};

export { LegalPage };