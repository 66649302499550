import { requestGet } from "../../redux/api/request";
import { STRATEGY } from "./actions/actionsTypes";
import { strategysStatus } from "./actions/actionsCreators";

// export const checkProductList = (dispatch) => {
//   const flag = [];
//   requestGet("Portfolio/GetProductList").then((resp) => {
//     resp.data.forEach((item) => {
//       (item.product.id === 8 || item.product.id === 9) &&
//         flag.push(item.product.id);
//     });
//     dispatch(strategysStatus(flag.length === 2));
//   });
// };

export const checkIndividualReInvest = (dispatch, personId) => {
  requestGet("person/CheckClassicStrategyAllowed?personId=" + personId).then(
    (resp) => {
      console.log(resp);
      if (resp.data.result === "success") {
        dispatch(strategysStatus(true));
      } else {
        dispatch(strategysStatus(false));
      }
    }
  );
};

const initialState = {
  status: false,
};

const strategysReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRATEGY.STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export default strategysReducer;
