import React, {useEffect} from "react";
import classes from './ModalComponent.module.scss'
import Portal from "../../../components/UI/Modal/Portal";
import {MODAL_IS_OPEN} from "../../../redux/constants/modals.constants";
import {connect} from "react-redux";

const ModalComponent = ({data, title = '', description = '', content = '', footer = '', onToggleModal, onCloseHandler, customClass, closeDisabled, children}) => {
  
  const onClose = () => {
    onToggleModal(data.id, false)
    onCloseHandler && onCloseHandler();
  }
  
  const onKeyDown = ({key}) => {
    switch (key) {
      case 'Escape':
        onClose()
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  })
  
  if (!data.isOpen) return null;
  
  return (
    <Portal>
      <div className={[classes.modal, customClass].join(' ')} onClick={onClose}>
        <div className={classes.dialog} onClick={e => e.stopPropagation()}>
          { (!closeDisabled && title) && <div className={classes.header}>
            {title && <h3 className={classes.title}>{title}</h3>}
            {!closeDisabled && <span className={classes.close} onClick={onClose} />}
          </div> }
          {description && <p className={classes.description}>{description}</p>}
          <div className={classes.body}>
            {content}
          </div>
          {footer && <div className={classes.footer}>{
            React.Children.map(footer, (child) => {
              if(child.props.close) {
                return React.cloneElement(child, {onClick: onClose})
              } else {
                return React.cloneElement(child)
              }
            })
          }</div>}
        </div>
      </div>
    </Portal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
  }
}

export default connect(null, mapDispatchToProps)(ModalComponent);