import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import NavBar from "../../components/UI/NavBar";
import { logout } from "../../redux/actions/login/auth.actions";
import { getPersonData } from "../../redux/actions/person.action";
import { connect } from "react-redux";
import {
  PERSON_DATA_SUCCESS,
  EMAIL_CONFIRM,
  VERIFY_CONFIRM,
} from "../../redux/constants/person.constants";
import Language from "../../components/UI/Language";
import Profile from "../Profile";
import Settings from "../Settings";
import NotFound from "../NotFound";
import Support from "../Support";
import PortfolioDetails from "../PortfolioDetails";
import MyInvestment from "../MyInvestment";
import Operations from "../Operations";
import Depositing from "../Depositing";
import Withdrawal from "../Withdrawal";
import Partner from "../Partner";
import { useEffect } from "react";
import { FooterPages } from "./Footer.pages";
import { toggleMenu } from "../../redux/actions/lang.actions";
import Information from "../Information";
import Loader from "../../components/UI/Loader";
import VeriffError from "../../components/UI/statusVeriff/VeriffError";
import VeriffProcess from "../../components/UI/statusVeriff/VeriffProcess";
import VeriffSuccess from "../../components/UI/statusVeriff/VeriffSuccess";
import BalancePage from "../../update/page/Balanced/BalancePage";
import MyInvestmentPage from "../../update/page/MyInvestment/MyInvestmentPage";
import PortfolioPage from "../../update/page/Portfolio/PortfolioPage";
import AuthHeader from "../../update/components/AuthHeader/AuthHeader";
import { setHandlerValue } from "../../update/redux/handler-reducer";
import { JudicialPage } from "../../update/page/Judicial/JudicialPage";
import { JudicialPortfolioPage } from "../../update/page/JudicialPortfolioPage/JudicialPortfolioPage";
import { FooterComponent } from "../../update/components/Footer/FooterComponent";
import { DevPage } from "../../update/page/DevPage/DevPage";
import { LegalPage } from "../../update/page/LegalPage/LegalPage";
import WithdrawalPage from "../../update/page/Withdrawal/WithdrawalPage";
import { InviteFriendsPage } from "../../update/page/InviteFriendsPage/InviteFriendsPage";
import { MyInvitationsPage } from "../../update/page/MyInvitationsPage/MyInvitationsPage";

const AuthPages = (props) => {
  useEffect(() => {
    props.getPersonData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.burgerActive
      ? document.body.classList.add("navigation--visible")
      : document.body.classList.remove("navigation--visible");
  }, [props.burgerActive]);

  return (
    <>
      {props.loading === undefined ? (
        <div className="spinner" style={{ margin: "auto" }} />
      ) : (
        <>
          <AuthHeader
            isLinks={[
              "portfolio",
              "judicial",
              "invitefriends",
              "myinvitations",
            ]}
            setHandlerValue={props.setHandlerValue}
          />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/portfolio" />} />
            {/*<Route exact path='/profile'><Profile /></Route>*/}
            <Route
              exact
              path="/profile"
              render={() => <Redirect to="/portfolio" />}
            />
            <Route path="/my-investment">
              <Redirect to="/portfolio" />
            </Route>
            {/*<Route path='/my-investment'><MyInvestment/></Route>*/}
            <Route path="/operations-history">
              <Operations />
            </Route>
            {/*<Route path='/depositing'><Depositing /></Route>*/}
            <Route path="/depositing">
              <BalancePage />
            </Route>{" "}
            {/*New page*/}
            <Route path="/settings">
              <Settings />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route path="/information">
              <Information />
            </Route>
            <Route path="/legal">
              <LegalPage />
            </Route>{" "}
            {/*New page*/}
            <Route exact path="/portfolio">
              <MyInvestmentPage />
            </Route>{" "}
            {/*New page*/}
            <Route exact path="/dev">
              <DevPage />
            </Route>{" "}
            {/*New page*/}
            <Route path="/portfolio/:id">
              {props.person.statusId === EMAIL_CONFIRM &&
              props.person.veriffStatusId === VERIFY_CONFIRM ? (
                // ? <PortfolioDetails />
                <PortfolioPage />
              ) : (
                <Route path="/portfolio/:id" />
              )}
            </Route>
            <Route exact path="/judicial">
              <JudicialPage />
            </Route>{" "}
            {/*New page*/}
            <Route path="/judicial/:id">
              {props.person.statusId === EMAIL_CONFIRM &&
              props.person.veriffStatusId === VERIFY_CONFIRM ? (
                <JudicialPortfolioPage />
              ) : (
                <Route path="/judicial/:id" />
              )}
            </Route>
            <Route exact path="/invitefriends">
              <InviteFriendsPage />
            </Route>{" "}
            {/*New page*/}
            <Route exact path="/myinvitations">
              <MyInvitationsPage />
            </Route>{" "}
            {/*New page*/}
            <Route path="/partner">
              <NotFound />
              {/*
                            Блок временно отключен
                            props.person.statusId === EMAIL_CONFIRM && props.person.veriffStatusId === VERIFY_CONFIRM
                                ? <Partner />
                                : <Route path='/partner' />
                        */}
            </Route>
            <Route path="/withdrawal">
              {props.person.statusId === EMAIL_CONFIRM &&
              props.person.veriffStatusId === VERIFY_CONFIRM ? (
                <WithdrawalPage /> /*New page*/
              ) : (
                <Route path="/withdrawal" />
              )}
            </Route>
            <Route path="/login" render={() => <Redirect to="/portfolio" />} />
            <Route
              path="/create-account"
              render={() => <Redirect to="/portfolio" />}
            />
            <Route component={NotFound} />
          </Switch>
          {/*<FooterComponent activeMenu={props.burgerActive} />*/}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    person: state.person,
    loading: state.loading[PERSON_DATA_SUCCESS],
    burgerActive: state.language.burgerActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonData: () => dispatch(getPersonData()),
    logout: () => dispatch(logout()),
    toggleMenu: (toggle) => dispatch(toggleMenu(toggle)),
    setHandlerValue: (payload) => dispatch(setHandlerValue(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthPages);
