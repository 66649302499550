import { VERIFF_SESSION_SUCCESS, VERIFF_KEY_SUCCESS, VERIFF_TOGGLE, VERIFF_SAVE_DATA_SUCCESS, VERIFF_SET_START_SESSION_SUCCESS} from "../../constants/veriff/veriff.constants"
import { requestPost, errorHandler } from "../../api/request";
import { LOADING } from "../../constants/loading.constants";
import { MODAL_IS_OPEN } from "../../constants/modals.constants";
import { toast } from 'react-toastify';
import { getPersonData } from "../person.action";

import Veriff from '@veriff/js-sdk'
import { createVeriffFrame } from '@veriff/incontext-sdk'
import { Resource } from "../lang.actions";
import {VERIFY_CONFIRM} from "../../constants/person.constants";
import {getDepositingUrl} from "../depositing/depositing.actions";
import {DEPOSITING_CHANGE_STATUS} from "../../constants/depositing/depositing.constants";
import {getDepositPayments} from "../../../update/redux/balanced-reducer";
import {getPersonSettings} from "../settings/settings.actions";

export const saveRequiredVeriff = ({ ...params }) => {

    const { verifyAddress, verifyCity, verifyCountry, verifyZip, phone, prefix, setVerifBtnStatus, verifyStatusId, depositValue } = params

    let veriffModel = {
        Country: Number(verifyCountry) || null,
        City: verifyCity || '',
        Address: verifyAddress || '',
        Zip: verifyZip || '',
        Phone: phone !== null ? String(phone) : null,
        PhonePrefix: String(prefix)
    }
    
    return dispatch => {
        requestPost('Verification/SaveRequiredVeriff', veriffModel)
            .then(resp => {
                //toast.success('Настройки сохранены')
                if(resp.data.success) {
                    if(verifyStatusId === VERIFY_CONFIRM) {
                        dispatch(getDepositingUrl(depositValue))
                        dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalDepositingVeriff' })
                        dispatch({ type: DEPOSITING_CHANGE_STATUS, payload: false })
                    } else {
                        dispatch(getVeriffSession(params))
                    }
                    dispatch(getPersonSettings())
                } else {
                    toast.error(Resource('PhoneAlreadyReg'))
                    setVerifBtnStatus(false)
                }
                dispatch({ type: LOADING, id: VERIFF_SAVE_DATA_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                setVerifBtnStatus(false)
                dispatch({ type: LOADING, id: VERIFF_SAVE_DATA_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: VERIFF_SAVE_DATA_SUCCESS, loading: true })
    }
}

export const setSessionVerif = ({ ...params }) => {
    const { verification } = params

    let sessionModel = {
        AlchemyUrl: verification.url || null,
        Id: verification.id || null
    }

    return dispatch => {
        requestPost('Verification/SetSessionVerif', sessionModel)
            .then(resp => {
                dispatch({ type: LOADING, id: VERIFF_SET_START_SESSION_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: VERIFF_SET_START_SESSION_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: VERIFF_SET_START_SESSION_SUCCESS, loading: true })
    }
}

export const getVeriffKey = ({ personId, firstName, lastName, setVerifBtnStatus }) => {
    
    return dispatch => {
        requestPost('Verification/GetVeriffKey')
            .then(resp => {
                let veriff = Veriff({
                    host: 'https://stationapi.veriff.com',
                    apiKey: resp.data,
                    parentId: 'veriff-root',
                    onSession: (error, response) => {
                        dispatch(setSessionVerif(response))
                        dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalDepositingVeriff' })

                        console.log('Start veriffication {getVeriffKey}')
                        window.ym(56965879,'reachGoal','Veriff_start')
                        
                        createVeriffFrame({
                            url: response.verification.url,
                            onEvent: msg => {
                                console.log('msg_key', msg);
                                switch (msg) {
                                    case 'STARTED':
                                        console.log(msg)
                                        break;
                                    case 'CANCELED':
                                        dispatch(toggleVeriffPanel())
                                        dispatch(getPersonData())
                                        dispatch(getDepositPayments())
                                        setVerifBtnStatus && setVerifBtnStatus(false)
                                        console.log(msg)
                                        break;
                                    case 'FINISHED':
                                        console.log(msg)
                                        setVerifBtnStatus && setVerifBtnStatus(false)
                                        dispatch({ type: MODAL_IS_OPEN, isOpen: true, id: 'modalVeriff' })
                                        break;
                                    default:
                                        console.log('error')
                                        break;
                                }
                            }
                        })
                    }
                })
                veriff.setParams({
                    person: {
                        givenName: firstName ?? ' ',
                        lastName: lastName ?? ' '
                    },
                    vendorData: 'autocreated_' + personId 
                });
                veriff.mount()

                document.getElementById('veriff-submit-btn').click()

                dispatch({ type: LOADING, id: VERIFF_KEY_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: VERIFF_KEY_SUCCESS, loading: false })
                
            })
        dispatch({ type: LOADING, id: VERIFF_KEY_SUCCESS, loading: true })
    }
}

export const getVeriffSession = personData => {
    return dispatch => {
        requestPost('Verification/GetVeriffSession')
            .then(resp => {
                    if(resp.data.result === 'success') {
                        console.log('Start veriffication {getVeriffSession}')
                        window.ym(56965879,'reachGoal','Veriff_start')
                        dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalCalculator' })
                        createVeriffFrame({
                            url: resp.data.message,
                            onEvent: msg => {
                                console.log('msg', msg);
                                switch (msg) {
                                    case 'STARTED':
                                        console.log(msg)
                                        break;
                                    case 'CANCELED':
                                        dispatch(toggleVeriffPanel())
                                        dispatch(getPersonData())
                                        dispatch(getDepositPayments())
                                        personData.setVerifBtnStatus && personData.setVerifBtnStatus(false)
                                        console.log(msg)
                                        dispatch({ type: MODAL_IS_OPEN, isOpen: false, id: 'modalDepositingVeriff' })
                                        break;
                                    case 'FINISHED':
                                        console.log(msg)
                                        personData.setVerifBtnStatus && personData.setVerifBtnStatus(false)
                                        dispatch({ type: MODAL_IS_OPEN, isOpen: true, id: 'modalVeriff' })
                                        break;
                                    default:
                                        console.log('error')
                                        break;
                                }
                            }
                        })
                    } else {
                        console.log('getVeriffKey');
                        dispatch(getVeriffKey(personData))
                    }
                dispatch({ type: LOADING, id: VERIFF_SESSION_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                personData.setVerifBtnStatus && personData.setVerifBtnStatus(false)
                dispatch({ type: LOADING, id: VERIFF_SESSION_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: VERIFF_SESSION_SUCCESS, loading: true })
    }
}

export const toggleVeriffPanel = (status = '') => {
    return dispatch => {
        dispatch({ type: VERIFF_TOGGLE, payload: status })
    }
}
