import { requestPost, requestGet } from "../../redux/api/request";
import { PAYTOOLS } from "./actions/actionsTypes";
import { setBankPaytools, setCardPaytools } from "./actions/actionsCreators";

export const paytoolsRequest = (dispatch, personId) => {
    const request = requestGet(`Paytool/GetPaytools?personId=${personId}`);
    return request.then(resp => {
        if (resp.data.result === "success") {
            resp.data.message.forEach(item => {
                if(item.toolType === 'bank'){
                    dispatch(setBankPaytools(item.toolId, item.toolName));
                }else{
                    dispatch(setCardPaytools(item.toolId, item.toolName));
                }
            })
        }else{
            throw new Error();
        }
    })
    .catch(error => {
        console.log(error);
    })
}

const initialState = {
    bank:{
        toolId: null,
        toolName: null,
    },
    card:{
        toolId: null,
        toolName: null,
    }
};

const paytoolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYTOOLS.SET_BANK:
    return {
            ...state, bank: {toolId: action.payload.toolId, toolName: action.payload.toolName}
        }
    case PAYTOOLS.SET_CARD:
        return {
                ...state, card: {toolId: action.payload.toolId, toolName: action.payload.toolName}
            }
        default:
            return state
    }
}
export default  paytoolsReducer;