// Отображение окна с просьбой оценить платформу, после пополнения портфеля
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupSlide } from "../ui/PopupSlide/PopupSlide";
import { portfoliosInfoToggle } from "../../redux/actions/actionsCreators";
import { Locale } from "../../../locale/locale";
import { Resource } from "../../../redux/actions/lang.actions";
import "./PortfoliosInfoSlide.scss";

const PortfoliosInfoSlide = () => {

    const portfoliosInfoOpen = useSelector(state => state.PopupReducer.portfoliosInfoOpen);
    const dispatch = useDispatch();


    const setActive = status => {
        dispatch(portfoliosInfoToggle(status));
    };


    return(
        <PopupSlide 
            active={portfoliosInfoOpen}
            setActive={setActive}
        >
           <div className="portfolios-info-slide">
                <div className="portfolios-info-slide__content">
                    <div className="portfolios-info-slide__informer">
                        <div className="portfolios-info-slide__informer">
                            Info
                        </div>
                    </div>
                    <p>Please note that the specified date marks the end of the investment period, including automatic re-investment. However, the option to withdraw funds remains available in accordance with the initially determined investment period and the terms of the contract.</p>
                    <p>The automatic re-investment has been implemented to ensure that your funds do not remain idle and to prevent the loss of liquidity of unused funds.</p>
              </div>
           </div>
            
        </PopupSlide>
    )
};

export { PortfoliosInfoSlide };