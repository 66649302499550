import React from 'react';
import classes from './DefaultButton.module.scss';
import SpinnerButton from "../../../../components/UI/SpinnerButton";
import {Link} from "react-router-dom";

const DefaultButton = (props) => {
  
  const {type = 'button', to} = props
  
  const clsButton = [
    classes.button,
    props.customClass
  ].join(' ');
  
  return (
    <>
      {
        to
          ? <Link className={clsButton} to={to}>{props.children}</Link>
          : <button type={type} className={clsButton} onClick={props.onClick} disabled={props.disabled || props.load}>
            {
              props.load
                ? <SpinnerButton />
                : props.children
            }
          </button>
      }
    </>
  );
};

export default DefaultButton;