import { errorHandler, requestGet, requestPost } from "../../redux/api/request";
import { toast } from "react-toastify";
import { Resource } from "../../redux/actions/lang.actions";
import { currentFormatting } from "../../redux/utils/formatting";
import { getPersonData } from "../../redux/actions/person.action";
import { getInvestmentData } from "./investment-reducer";
import { gradePopupToggle } from "./actions/actionsCreators";
import Cookies from "js-cookie";
import { history } from "../../redux/history";
import { setDepositValueField } from "./balanced-reducer";
import { JUDICIAL } from "./actions/actionsTypes";
import { getJudicialPortfolioOne } from "./judicial-reducer";

const PORTFOLIO_SET_DATA = "PORTFOLIO/SET_DATA";
const PORTFOLIO_SET_LOANS_ACTIVE = "PORTFOLIO/SET_LOANS_ACTIVE";
const PORTFOLIO_SET_LOANS_CLOSE = "PORTFOLIO/SET_LOANS_CLOSE";
const PORTFOLIO_SET_LOANS_EXPIRED = "PORTFOLIO/SET_LOANS_EXPIRED";
const PORTFOLIO_SET_LOANS_REDEEMED = "PORTFOLIO/SET_LOANS_REDEEMED";

// const loanStatus = {
//   active: 162,
//   close: 161,
//   expired: 167,
//   buyBack: 163,
// };

const setData = (type, payload) => (dispatch) => {
  dispatch({ type, payload });
};

const setDataLoans = (data) => (dispatch) => {
  const result = {
    active: [],
    close: [],
    buyBack: [],
    expired: [],
  };

  data.forEach((element) => {
    if (element.statusId === 180) {
      result.active.push(element);
    } else {
      result.close.push(element);
    }
  });

  // let result = {};
  // Object.keys(loanStatus).map((status) => {
  //   result[status] = data.filter(
  //     (loan) => loan.mfcLoanStatus === loanStatus[status]
  //   );
  // });
  // console.log(result);
  dispatch({ type: PORTFOLIO_SET_LOANS_ACTIVE, payload: result });
};

const gradePopupOpen = (dispatch) => {
  if (!Cookies.get("gradePopup")) {
    dispatch(gradePopupToggle(true));
    Cookies.set("gradePopup", true, { expires: 30 });
  }
};

export const getPortfolioData = (portfolioId) => (dispatch) => {
  return requestPost(`Portfolio/GetPortfolioDetails?portfolioId=${portfolioId}`)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(setData(PORTFOLIO_SET_DATA, resp.data));
        dispatch(setDataLoans(resp.data.investmentsInformation));
      } else {
        throw new Error();
      }
      return resp;
    })
    .catch((error) => {
      errorHandler(dispatch, error);
    });
};

export const depositCurrentPortfolio = (portfolioId, amount) => {
  let amountFormat = amount.replace(/[^0-9\.]/, "");
  return (dispatch) => {
    return requestPost(
      `Portfolio/PortfolioPayment?portfolioId=${Number(
        portfolioId
      )}&amount=${amountFormat}`
    )
      .then((resp) => {
        if (resp.status === 200) {
          window.clearDepositValue(`depositValue${portfolioId}`, "");
          if (resp.data.success) {
            dispatch(getPortfolioData(portfolioId));
            getJudicialPortfolioOne(dispatch, "p_" + portfolioId);
            dispatch(getInvestmentData());
            dispatch(getPersonData());
            getJudicialPortfolioOne(dispatch, "p_" + portfolioId);
            toast.success(
              Resource("PortfolioReplenishmentOperation") +
                " - " +
                currentFormatting(amountFormat)
            );
            // YandexCounter;
            window.ym(56965879, "reachGoal", "refill_portfolio", {
              order_price: amountFormat,
              currency: "EUR",
            });
            gradePopupOpen(dispatch);
          } else {
            // toast.error(Resource('ERROR_006'))
            dispatch(setDepositValueField(amount));
            history.push("/depositing");
          }
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error);
      });
  };
};

const initialState = {
  loans: {},
};

const PortfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case PORTFOLIO_SET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case PORTFOLIO_SET_LOANS_ACTIVE:
      return {
        ...state,
        loans: {
          ...state.loans,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default PortfolioReducer;
