import React from 'react';
import classes from './TabsComponent.module.scss'
import {connect} from "react-redux";

const TabsComponentPortal = ({ children, id, stateTabs }) => {

  const items = React.Children
    .map(children, (child) => React.cloneElement(child, {key: 'TabComponentItem'}))
    .filter(item => item.key.split('/')[0] === 'TabComponentItem')

  return (
    <div className={classes.tab}> 
      <div className={classes.view}>
        {items.map(({ props }, index) => {
          return (
            <div
              {...props}
              className={[classes.content, stateTabs[id].activeId === index + 1 ? classes.selected : ''].join(' ')}
              key={`tab-content-${index + 1}`}
            />
          )
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stateTabs: state.tabsComponentReducer
  }
}

export default connect(mapStateToProps, null)(TabsComponentPortal)