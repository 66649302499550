import React from 'react'
import { Field } from 'redux-form'
import { renderValidation } from './Validation'
import { Resource } from '../../../redux/actions/lang.actions'
import { connect } from 'react-redux'
import { onlyNumber, calculateFormatting, onlyIndex, depositingFormatting } from '../../../redux/utils/formatting'
import { createTextMask } from 'redux-form-input-masks'
import {depositingSetValue, saveRequiredSettings} from "../../../redux/actions/depositing/depositing.actions";

const renderField = ({ input, id, type, className, placeholder, disabled, maxLength, meta: { touched, error }, onInputHandler, saveOnBlur, setValue, depositingSetValue }) => {
    return (
        <>
            <input
              {...input}
              maxLength={maxLength}
              id={id}
              type={type}
              className={[className, touched && (error ? 'invalid' : null)].join(' ')}
              placeholder={Resource(placeholder)}
              disabled={disabled}
              onChange={event => {
                input.onChange(event)
                if(setValue) {
                  depositingSetValue(event.target.value)
                }
              }}
            />
            {touched && (error && <div className='invalid'>{error}</div>)}
        </>
    )
}

const RenderContainer = ({ children, container, addClass }) => {

    return (
        <>
            {
                container || container === undefined
                    ? <div className={`form__group ${!!addClass ? addClass : ''}`}>
                        {children && children}
                    </div>
                    : children && children
            }
        </>

    )
}


const RenderInput = ({
    data: {
        id, name, type, label, placeholder, value, edit, description, verif, validate, container, addClass, show, indexFormat, maxLength, numberFormat, dateFormat, currencyFormat, depositFormat, onInputHandler, saveOnBlur, setValue
    },
    disabled,
    showField,
    notValue,
    depositingSetValue
}) => {
  
    let validOptions = {
        required: validate && validate.required ? true : false,
        email: validate && validate.email ? true : false,
        only: validate && validate.only ? true : false,
        minLength: validate && validate.minLength ? true : false,
        maxLength: validate && validate.maxLength ? true : false,
        date: validate && validate.date ? true : false,
        range: validate && validate.range ? validate.range : false
    }

    const dateMask = !!dateFormat
        ? createTextMask({
            pattern: '99.99.9999',
            stripMask: false,
            guide: false
        }) : null

    return (
        <RenderContainer container={container} addClass={addClass}>
            {label && <label htmlFor={name} className="form__label">{Resource(label)}</label>}
            {
                !value && notValue && description
                && <>
                    <span className={'form__value form__value--disabled'} />
                    <span className="form__description">{Resource(description)}</span>
                </>
            }
            {
                edit && !verif
                    ? !!show
                        ? <span className='pseudofield__label' onClick={showField}>{Resource(show)}</span>
                        : <Field
                            component={renderField}
                            id={id}
                            name={name}
                            type={type}
                            className={'field field__input'}
                            placeholder={Resource(placeholder)}
                            disabled={disabled}
                            validate={renderValidation(validOptions)}
                            maxLength={maxLength}
                            parse={value => {
                                if (!!numberFormat) {
                                    return onlyNumber(value)
                                } else if (!!currencyFormat) {
                                    return calculateFormatting(value)
                                } else if (!!depositFormat) {
                                    return depositingFormatting(value)
                                } else if (!!indexFormat) {
                                    return onlyIndex(value)
                                } else {
                                    return value
                                }
                            }}
                            {...dateMask}
                            setValue={setValue}
                            depositingSetValue={depositingSetValue}
                            saveOnBlur={saveOnBlur}
                        />
                    : value && <span className={'form__value'}>{value}</span>
            }
            {description && value && <span className="form__description">{Resource(description)}</span>}

        </RenderContainer>
    )
}

const mstp = state => {
    return {
        lang: state.language.langActive
    }
}

const mdtp = dispatch => {
  return {
    saveRequiredSettings: settings => dispatch(saveRequiredSettings(settings)),
    depositingSetValue: amountValue => dispatch(depositingSetValue(amountValue))
  }
}

export default connect(mstp, mdtp)(RenderInput)