import React from 'react';
import classes from './BackButton.module.scss';
import {Link} from "react-router-dom";
import arrow from '../../../../static/image/icons/icon-arrow-big.svg'

const BackButton = (props) => {
  if(props.onClickHandler) {
    return (
      <div onClick={props.onClickHandler} className={[classes.button, props.className].join(' ')}>
        <div className={classes.icon}><img src={arrow} alt="icon"/></div>
        {props.children}
      </div>
    );
  } else {
    return (
      <Link to={props.link} className={[classes.button, props.className].join(' ')}>
        <div className={classes.icon}><img src={arrow} alt="icon"/></div>
        {props.children}
      </Link>
    );
  }
  
};

export default BackButton;