export const STRATEGY_DATA_SUCCESS = 'STRATEGY/DATA_SUCCESS'
export const STRATEGY_DATA_FAILURE = 'STRATEGY/DATA_FAILURE'

export const STRATEGY_FIXED_VALUES = 'STRATEGY/FIXED_VALUES'
export const STRATEGY_SET_VALUE = 'STRATEGY/SET_VALUE'
export const STRATEGY_ACTIVE_PRODUCT_ID = 'STRATEGY/ACTIVE_PRODUCT_ID'
export const STRATEGY_STATUS_CHECKBOX = 'STRATEGY/STRATEGY_STATUS_CHECKBOX'

export const STRATEGY_ID = { classic: 8, balanced: 9, special: 5 }

export const switchId = (id) => {
	switch (id) {
		case 8:  // раньше был 3
			return 'classic'
		case 9: // раньше был 4
			return 'balanced'
		case 5:
			return 'special'
		default:
			return null
	}
}

export const PORTFOLIO_ERROR = {
	ERROR_001: 'На вашем балансе не хватает средств для создания портфеля с выбранными параметрами',
	ERROR_002: 'Не выбраны страны',
	ERROR_003: 'Неизвестная ошибка',
	ERROR_004: 'Неверная сумма',
	ERROR_005: 'Можно создать только один пробный портфель',
	ERROR_006: 'Выбранная сумма инвестиций не соответствует балансу	'
}

export const CREATE_PORTFOLIO_SUCCESS = 'CREATE_PORTFOLIO/SUCCESS'

export const TUTORIAL_CHANGE_STEP = 'TUTORIAL/CHANGE_STEP'

export const TUTORIAL_SHOW = 'TUTORIAL/SHOW'