import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconFriend } from "../../static/Icons/friend.svg";
import { ReactComponent as IconPortfolio } from "../../static/Icons/portfolio.svg";
import { ReactComponent as IconGift } from "../../static/Icons/gift.svg";
import { ReactComponent as IconLinkedIn } from "../../static/Icons/linkedin.svg";
import { ReactComponent as IconDiscord } from "../../static/Icons/discord.svg";
import { ReactComponent as IconTwitter } from "../../static/Icons/twitter.svg";
import { ReactComponent as IconTelegram } from "../../static/Icons/telegram.svg";
import { ReactComponent as IconFacebook } from "../../static/Icons/facebook.svg";
import { ReactComponent as IconWhatsApp } from "../../static/Icons/whatsapp.svg";
import Modal from "../../../components/UI/Modal/Modal";
import { VERIFY_CONFIRM } from "../../../redux/constants/person.constants";
import { MODAL_IS_OPEN } from "../../../redux/constants/modals.constants";
import { Resource } from "../../../redux/actions/lang.actions";
import VeriffForm from "../../../components/UI/formControls/veriff/veriff.form";
import { ScrollTo } from "react-scroll-to/dist";
import { SideBarMenu } from "../../components/SideBarMenu/SideBarMenu";
import { getPersonSettings } from "../../../redux/actions/settings/settings.actions";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import { invitefriendsRequest } from "../../redux/invitefriends-reducer";
import {
  getVeriffSession,
  saveRequiredVeriff,
  toggleVeriffPanel,
} from "../../../redux/actions/veriff/veriff.actions";
import { Redirect } from "react-router";
import "./InviteFriendsPage.scss";

const InviteFriendsPage = () => {
  const invite = useRef(null);
  const language = useSelector((state) => state.language);
  useEffect(() => {}, [language]);

  const dispatch = useDispatch();
  const selects = useSelector((state) => state.forms.selects);
  const veriff = useSelector((state) => state.veriff);
  const person = useSelector((state) => state.person);
  const info = useSelector((state) => state.invitefriendsReducer);
  const modalDepositingVeriff = useSelector(
    (state) => state.modals.modalDepositingVeriff
  );

  const fields = veriff.fields;
  const fieldsValues = [
    fields.verifyCountry && fields.verifyCountry.value,
    fields.verifyCity && fields.verifyCity.value,
    fields.verifyAddress && fields.verifyAddress.value,
    fields.verifyZip && fields.verifyZip.value,
    fields.prefix && fields.prefix.value,
    fields.phone && fields.phone.value,
  ];

  const isFullFields = fieldsValues.every((value) => !!value);

  const [disableVerifBtn, setVerifBtnStatus] = useState(false);
  const [currentDepositValue, setCurrentDepostiValue] = useState(null);
  const [isCopyLink, serCopyLink] = useState(false);

  useEffect(() => {
    dispatch(getPersonSettings());
    invitefriendsRequest(dispatch, person.id);
    window.ym(56965879, "hit", "/invitefriends");
  }, []);

  const sumbitSaveVeriff = (values) => {
    setVerifBtnStatus(true);
    const { prefix, verifyCountry } = selects;
    const { verifyAddress, verifyCity, verifyZip, phone } = values;

    dispatch(
      saveRequiredVeriff({
        verifyAddress,
        verifyCity,
        verifyCountry: verifyCountry
          ? verifyCountry.id
          : veriff.fields.verifyCountry.value,
        verifyZip,
        prefix: prefix !== undefined ? prefix.id : veriff.fields.prefix.value,
        phone:
          veriff.fields.phone.value !== phone
            ? !!phone
              ? (prefix !== undefined
                  ? prefix.id
                  : String(veriff.fields.prefix.value)) + phone
              : null
            : !!phone
            ? (prefix !== undefined
                ? prefix.id
                : String(veriff.fields.prefix.value)) +
              String(veriff.fields.phone.value).substring(
                String(veriff.fields.prefix.value).length
              )
            : null,
        personId: person.id,
        firstName: person.firstName,
        lastName: person.lastName,
        setVerifBtnStatus,
        verifyStatusId: person.veriffStatusId,
        depositValue: currentDepositValue,
      })
    );
  };

  const onToggleModal = (id, isOpen) => {
    dispatch({ type: MODAL_IS_OPEN, isOpen, id });
  };

  const toggleVeriffPanelStart = () => dispatch(toggleVeriffPanel());
  const getVeriffSessionStart = (params) => dispatch(getVeriffSession(params));

  const copyRefLink = () => {
    navigator.clipboard
      .writeText(info.refLink)
      .then(() => serCopyLink(true))
      .catch((error) => {
        console.log("error copy ref lonk: ", error);
      });
  };

  return (
    <main className="invite-friends">
      <DocumentTitle title={Resource("refInviteFriendsTitle1")} />
      {info.isLoading ? (
        <div className="spinner"></div>
      ) : (
        <>
          {!!info.totalLinkCount && !info.redirect && (
            <Redirect
              to={{ pathname: "/myinvitations", state: { redirect: true } }}
            />
          )}
          <div className="invite-friends__wrapper">
            <SideBarMenu />
            <div className="invite-friends__content">
              <div className="invite-friends__banner">
                <div className="invite-friends__card">
                  <h1 className="invite-friends__title">
                    {Resource("refInviteFriendsTitle1")}
                  </h1>
                  <div className="invite-friends__sub-placeholder">
                    {Resource("refInviteFriendsSubTitle1")}
                  </div>
                  <div className="invite-friends__sub-title">
                    {Resource("refInviteFriendsText1")}
                  </div>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div
                        className="invite-friends__btn"
                        onClick={() => {
                          scroll({
                            x: 0,
                            y: +invite.current.offsetTop - 100,
                            smooth: true,
                          });
                        }}
                      >
                        {Resource("refInviteFriendsBTN1")}
                      </div>
                    )}
                  </ScrollTo>
                </div>
                <div className="invite-friends__wrapper-img">
                  <img
                    className="invite-friends__img"
                    src="image/overview/girl.png"
                  />
                </div>
              </div>
              <div className="invite-friends__it-work">
                <div className="steps-invite">
                  <h2 className="steps-invite__title">
                    {Resource("refInviteFriendsSubTitle2")}
                  </h2>
                  <div className="steps-invite__list">
                    <div className="steps-invite__item">
                      <div className="steps-invite__icon">
                        <IconFriend />
                      </div>
                      <div className="steps-invite__wrapper">
                        <div className="steps-invite__text">
                          {Resource("refInviteFriendsStepTitle1")}
                        </div>
                        <div className="steps-invite__description">
                          {Resource("refInviteFriendsStepText1")}
                        </div>
                      </div>
                    </div>

                    <div className="steps-invite__item">
                      <div className="steps-invite__icon">
                        <IconPortfolio />
                      </div>
                      <div className="steps-invite__wrapper">
                        <div className="steps-invite__text">
                          {Resource("refInviteFriendsStepTitle2")}
                        </div>
                        <div className="steps-invite__description">
                          {Resource("refInviteFriendsStepText2")}
                        </div>
                      </div>
                    </div>

                    <div className="steps-invite__item">
                      <div className="steps-invite__icon">
                        <IconGift />
                      </div>
                      <div className="steps-invite__wrapper">
                        <div className="steps-invite__text">
                          {Resource("refInviteFriendsStepTitle3")}
                        </div>
                        <div className="steps-invite__description">
                          {Resource("refInviteFriendsStepText3")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="invite-graph">
            <div className="invite-graph__wrapper">
              <div className="invite-graph__sidebar"></div>
              <div className="invite-graph__content">
                <div className="reward-schedule">
                  <div className="reward-schedule__info">
                    <div className="reward-schedule__column">
                      <div className="reward-schedule__title">
                        {Resource("refInviteFriendsTitle2")}
                      </div>
                      <div className="reward-schedule__description">
                        {Resource("refInviteFriendsText2")}
                      </div>
                    </div>
                    <div className="reward-schedule__column"></div>
                  </div>

                  <div className="graph">
                    <div className="graph__description">
                      <div className="graph__bonus-text">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: Resource("refInviteFriendsBonus"),
                          }}
                        />
                      </div>
                      <div className="graph__sum-text">
                        {Resource("refInviteFriendsSum")}
                      </div>
                    </div>
                    <div className="graph__render">
                      <div className="graph__bonus">
                        <div className="graph__bonus-item graph__bonus-item-10">
                          €&nbsp;10
                        </div>
                        <div className="graph__bonus-item graph__bonus-item-20">
                          €&nbsp;20
                        </div>
                        <div className="graph__bonus-item graph__bonus-item-30">
                          €&nbsp;50
                        </div>
                        <div className="graph__bonus-item graph__bonus-item-60">
                          €&nbsp;100
                        </div>
                        <div className="graph__bonus-item graph__bonus-item-1000">
                          2%, max. €&nbsp;1&nbsp;000
                        </div>
                      </div>
                      <div className="graph__sum">
                        <div className="graph__sum-item-500">€ 500</div>
                        <div className="graph__sum-item-1000">€ 1 000</div>
                        <div className="graph__sum-item-2500">€ 2 500</div>
                        <div className="graph__sum-item-5000">€ 5 000</div>
                        <div className="graph__sum-item-10000">€ 10 000+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="invite-fotter">
            <div className="invite-fotter__sidebar"></div>
            <div className="invite-fotter__fotter">
              <div className="fotter-informer">
                <div className="fotter-informer__title">
                  {Resource("refInviteFriendsTitle3")}
                </div>
                <div className="fotter-informer__list">
                  <div className="fotter-informer__item">
                    <div className="fotter-informer__sub-title">
                      {Resource("refInviteFriendsInfoTitle1")}
                    </div>
                    <div className="fotter-informer__text">
                      {Resource("refInviteFriendsInfoText1")}
                    </div>
                  </div>
                  <div className="fotter-informer__item">
                    <div className="fotter-informer__sub-title">
                      {Resource("refInviteFriendsInfoTitle2")}
                    </div>
                    <div className="fotter-informer__text">
                      {Resource("refInviteFriendsInfoText2")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="invite" ref={invite}>
                <div className="invite__column">
                  <div className="invite__title">
                    {Resource("refInviteFriendsBoxTitle1")}
                  </div>
                  <div className="invite__text">
                    {Resource("refInviteFriendsBoxText1")}
                  </div>
                </div>
                <div className="invite__column">
                  <div
                    className={
                      !isCopyLink ? "link-box" : "link-box link-box--active"
                    }
                  >
                    <div className="link-box__column">
                      <div className="link-box__description">
                        {Resource("yourReferralLink")}
                      </div>
                      {person.veriffStatusId === VERIFY_CONFIRM ? (
                        <div className="link-box__ref">{info.refLink}</div>
                      ) : (
                        <div className="link-box__ref">*******</div>
                      )}
                    </div>
                    <div className="link-box__column link-box__column-r">
                      {person.veriffStatusId === VERIFY_CONFIRM ? (
                        <div className="link-box__btn" onClick={copyRefLink}>
                          {Resource("refInviteFriendsBTN2")}
                        </div>
                      ) : (
                        <div
                          className="link-box__btn"
                          onClick={
                            !isFullFields
                              ? () =>
                                  onToggleModal("modalDepositingVeriff", true)
                              : () => {
                                  setVerifBtnStatus(true);
                                  getVeriffSessionStart({
                                    personId: person.id,
                                    setVerifBtnStatus,
                                    firstName: person.firstName,
                                    lastName: person.lastName,
                                  });
                                }
                          }
                        >
                          {Resource("IdentifyYourself")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="soc-list">
                    <a
                      className="soc-list__link"
                      href="https://www.linkedin.com/"
                      target="_blank"
                    >
                      <IconLinkedIn />
                    </a>
                    <a
                      className="soc-list__link"
                      href="https://discord.com/"
                      target="_blank"
                    >
                      <IconDiscord />
                    </a>
                    <a
                      className="soc-list__link"
                      href="https://twitter.com/"
                      target="_blank"
                    >
                      <IconTwitter />
                    </a>
                    <a
                      className="soc-list__link"
                      href="https://web.telegram.org/"
                      target="_blank"
                    >
                      <IconTelegram />
                    </a>
                    <a
                      className="soc-list__link"
                      href="https://www.facebook.com/"
                      target="_blank"
                    >
                      <IconFacebook />
                    </a>
                    <a
                      className="soc-list__link"
                      href="https://web.whatsapp.com/"
                      target="_blank"
                    >
                      <IconWhatsApp />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalDepositingVeriff.isOpen}
            id={modalDepositingVeriff.id}
            isForm={false}
            addClass={"veriff__modal"}
            isOutside={true}
            onStart={() => {
              console.log("Open verify modal");
              window.ym(56965879, "reachGoal", "Veriff_prelend_open");
            }}
          >
            <VeriffForm
              onSubmit={sumbitSaveVeriff.bind(this)}
              disableVerifBtn={disableVerifBtn}
              toggleVeriffPanel={toggleVeriffPanelStart}
              fields={veriff.fields}
            />
          </Modal>
        </>
      )}
    </main>
  );
};

export { InviteFriendsPage };
