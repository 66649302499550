import React from 'react'
import Portal from './Portal'
import { connect } from 'react-redux'
import { MODAL_IS_OPEN } from '../../../redux/constants/modals.constants'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { useEffect } from 'react'
import { Resource } from '../../../redux/actions/lang.actions'
import { SEND_RESTORE_PASSWORD_SUCCESS } from '../../../redux/constants/login/auth.constants'
import LoaderButton from '../LoaderButton'

const ModalConfirm = ({ isOpen, children, title, button, handleSubmit, onClick, onToggleModal, id, addClass, change, loading }) => {

    const onClickOuterElement = event => {
        if (!event.target.closest('.modal__wrapper') && isOpen) {
            onToggleModal(id, false)
        }
    }
    
    useEffect(() => {
        document.addEventListener('click', onClickOuterElement, false);
        isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
        return () => {
            document.removeEventListener('click', onClickOuterElement, false);
        }
    // eslint-disable-next-line
    }, [isOpen])

    return (
        <>
            {
                isOpen &&
                <Portal>
                    <div className='modal'>
                        <form className={['modal__wrapper box', addClass].join(' ')} onSubmit={handleSubmit}>
                            <div className="modal__header">
                                <h2>{Resource(title)}</h2>
                                <div className="modal__close" onClick={() => onToggleModal(id, false)}></div>
                            </div>
                            {children}
                            <div className="index-control__form confirm__buttons">
                                <a onClick={() => {
                                    onClick()
                                    onToggleModal(id, false)
                                }} className="button button--small button__close">{Resource('Yes')}</a>
                                <a className="button button--small button--stroke button__close" onClick={() => onToggleModal(id, false)}>{Resource('No')}</a>
                            </div>
                            {/* {
                                button && <LoaderButton
                                    type="submit"
                                    disabled={true}
                                    loading={loading[SEND_RESTORE_PASSWORD_SUCCESS]}
                                >{Resource(button)}</LoaderButton>
                            } */}
                        </form>
                    </div>
                </Portal>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirm)
