import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ id, addClass, onClick, children, link, small, disabled, stroke, dataAttr, type, form }) => {

    const cls = [
        'button',
        addClass, 
        small ? 'button--small' : '',
        disabled ? 'button--disabled' : '',
        stroke ? 'button--stroke' : ''
    ]

    return (
        <>
            {
                link
                    ? <Link id={id} className={cls.join(' ')} to={link}>
                        {children}
                    </Link>
                    : <button form={form} disabled={disabled} id={id} className={cls.join(' ')} {...dataAttr} type={type} onClick={onClick}>
                        {children}
                    </button>
            }
           
        </>    
    )
}

export default Button