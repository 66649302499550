import React, { useReducer, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  IconLogout,
  IconHelp,
  IconSettings,
  IconUser,
  IconTax,
} from "../../static/Icons/Icons";
import { Resource } from "../../../redux/actions/lang.actions";
import "./ProfileMenu.scss";

const ProfileMenu = ({ person, logout, toggleMenu, isDark, setIsOpen }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={isDark ? "dark" : ""}>
      <div className="profile-menu">
        <div
          className={
            !isOpen ? "user-button" : "user-button user-button--active"
          }
          onClick={toggle}
        >
          <IconUser color="#1F242D" />
        </div>
        <div
          className={
            !isOpen
              ? "profile-menu__window"
              : "profile-menu__window profile-menu__window--active"
          }
        >
          <div className="profile-menu__user-info">
            <div className="profile-menu__name">
              {person.firstName} {person?.lastName}
            </div>
            <div className="profile-menu__email">{person.email}</div>
          </div>
          <nav className="profile-menu__list">
            <NavLink
              className="profile-menu__link-account"
              onClick={() => {
                toggle();
                setIsOpen();
              }}
              to="/settings"
            >
              <div className="profile-menu__account-icon">
                <IconSettings color="#1F242D" />
              </div>
              <div className="profile-menu__account-text">
                {Resource("MyAccount")}
              </div>
            </NavLink>
            <NavLink
              className="profile-menu__link"
              onClick={() => {
                toggle();
                setIsOpen();
              }}
              to="/support"
            >
              <div className="profile-menu__help-icon">
                <IconHelp color="#1F242D" />
              </div>
              <div className="profile-menu__link-text">{Resource("Help")}</div>
            </NavLink>
            <a
              className="profile-menu__link"
              onClick={() => {
                toggle();
                setIsOpen();
              }}
              href={"/Document/DocumentTaxReturn?personId=" + person.id}
              download
            >
              <div className="profile-menu__tax-icon">
                <IconTax color="#1F242D" />
              </div>
              <div className="profile-menu__link-text">Tax statement</div>
            </a>
          </nav>
          <div className="profile-menu__line">
            <Link
              to="/"
              className="profile-menu__logout"
              onClick={() => {
                toggleMenu(false);
                logout();
              }}
            >
              {Resource("Exit")}
              <IconLogout color="#1F242D" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProfileMenu };
