import React, { Component } from 'react';
import RenderTabs from '../components/UI/RenderTabs';
import IndividualForm from '../components/UI/formControls/create-account/individual.form';
import LegalForm from '../components/UI/formControls/create-account/legal.form';
import { connect } from 'react-redux';
import { onCreateAccount, changeField, createCompanyApplication, tokenVerify } from '../redux/actions/create-account/account.actions'
import { Resource } from '../redux/actions/lang.actions';
import { CREATE_ACCOUNT_SUCCESS, SEND_LEGAL_SUCCESS } from '../redux/constants/create-account/account.constants';
import DocumentTitle from '../components/UI/DocumentTitle';

class CreateAccount extends Component {
    static displayName = CreateAccount.name;

    constructor(props) {
        super(props)
        this.defaultValue = props.state.fields.individualGender.value
    }

    onCreateAccount = (values) => {
        const { individualName, individualEmail, individualPromocode, individualPassword } = values
        const { individualGender } = this.props.selects
        this.props.onCreateAccount(individualName, individualGender ? individualGender.id : this.defaultValue, individualEmail, individualPromocode, individualPassword)
    }

    onSendOrder = (values) => {
        const { legalCompany, legalEmail, legalHead } = values
        this.props.createCompanyApplication({ legalCompany, legalEmail, legalHead })
    }

    componentDidMount() {
        console.log('conversion create')
    }

    render() {
        return (
            <main className='register'>
                <DocumentTitle title='CreateAccount' />
                <section>
                    <div className="register-wrapper">
                        <h1>{Resource('CreateAccount')}</h1>
                        <section className="tabs js-tabs">
                            <RenderTabs id='createAccountTabs'>
                                <IndividualForm tokenVerify={this.props.tokenVerify} loading={this.props.loading[CREATE_ACCOUNT_SUCCESS]} changeField={this.props.changeField} state={this.props.state} individualForm={this.props.individualForm} fields={this.props.state.fields} onSubmit={this.onCreateAccount.bind(this)} />
                                <LegalForm loading={this.props.loading[SEND_LEGAL_SUCCESS]} state={this.props.state} fields={this.props.state.fields} onSubmit={this.onSendOrder.bind(this)} />
                            </RenderTabs>
                        </section>
                    </div>
                </section>
            </main>
        );
    };
}

const mapStateToProps = state => {
    return {
        selects: state.forms.selects,
        state: state.createAccount,
        individualForm: state.form.individualForm,
        loading: state.loading,
        lang: state.language.langActive
    }
}

const MapDispatchToProps = dispatch => {
    return {
        onCreateAccount: (name, gender, email, promocode, password) => dispatch(onCreateAccount(name, gender, email, promocode, password)),
        createCompanyApplication: ({ ...params }) => dispatch(createCompanyApplication(params)),
        changeField: () => dispatch(changeField()),
        tokenVerify: token => dispatch(tokenVerify(token))
    }
}

export default connect(mapStateToProps, MapDispatchToProps)(CreateAccount)