import React, { useEffect } from 'react';
import Modal from '../Modal/Modal';
import { getPersonData } from '../../../redux/actions/person.action';
import { connect } from 'react-redux';
import VeriffProcess from "./VeriffProcess";
import VeriffError from "./VeriffError";
import VeriffSuccess from "./VeriffSuccess";

const VeriffIframe = props => {

    let { status, modal, getPersonData } = props

    const setStatus = () => {
        switch (status) {
            case 190:
                return '/veriffError'
            case 191:
                return '/veriffSuccess'
            default:
                return '/veriffProcess'
        }
    }

    // useEffect(() => {
    //     let updateStatus;
    //     if (modal.isOpen && (status !== 191 && status !== 190)) {
    //         updateStatus = setInterval(() => {
    //             getPersonData()
    //             //setStatus()
    //         }, 3000)
    //     }
    //     return () => {
    //         clearInterval(updateStatus)
    //     }
    // // eslint-disable-next-line
    // }, [status])


    return (
        <>
            <Modal
                isOpen={modal.isOpen}
                title={modal.title}
                id={modal.id}
                addClass='modal__wrapper--veriff'
            >
               <VeriffProcess />
                {/*<iframe title='veriffy' frameBorder="0" src={window.parent.location.origin + setStatus()} width="100%" height="95%"></iframe>*/}
            </Modal>
            <div id="veriff-root"/>
        </>
    );
}

const mapStateToProps = state => {
    return {
        status: state.veriff.veriffStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPersonData: () => dispatch(getPersonData())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VeriffIframe)