import React from 'react'
import { Link } from 'react-router-dom'
import { Resource } from '../../redux/actions/lang.actions'
import Tutorial from '../UI/Tutorial'

const NavCards = ({ className, addClass, cards }) => {

    const cls = [
        className ? className : 'cards',
        addClass
    ]

    return (
        <nav className={cls.join(' ')}>
            {
                cards.map(({ title, text, icon, url, disabled, step, blocked }, index) => {
                    if (step) {
                        return (
                            <Tutorial key={title + index} step={step} >
                                <Link
                                    to={url}
                                    className={["box card index-widget", disabled ? 'card--disabled' : ''].join(' ')}
                                >
                                    <figure className={`stat-icon stat-icon_${icon}`}></figure>
                                    <div className="index-widget__wrapper">
                                        <h3 className="index-widget__title">{Resource(title)}</h3>
                                        <p className="index-widget__lead">{Resource(text)}</p>
                                    </div>
                                </Link>
                            </Tutorial>
                        )
                    } else {
                        if (blocked) {
                            return (
                                <div key={title + index} className={["box card index-widget", 'card--blocked', disabled ? 'card--disabled' : ''].join(' ')}></div>
                            )
                        } else {
                            return (
                                <Link
                                    key={title + index}
                                    to={url}
                                    className={["box card index-widget", disabled ? 'card--disabled' : ''].join(' ')}
                                >
                                    <figure className={`stat-icon stat-icon_${icon}`}></figure>
                                    <div className="index-widget__wrapper">
                                        <h3 className="index-widget__title">{Resource(title)}</h3>
                                        <p className="index-widget__lead">{Resource(text)}</p>
                                    </div>
                                </Link>
                            )
                        }
                        
                    }
                })
            }
        </nav>
    )
}

export default NavCards