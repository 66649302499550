import React, { Component } from "react";
import Stats from "../components/Blocks/Stats";
import { Link } from "react-router-dom";
import RenderTabs from "../components/UI/RenderTabs";
import ContentPayCardForm from "../components/UI/formControls/depositing/ContentPayCard.form";
import ContentPayScore from "../components/UI/formControls/depositing/ContentPayScore.block";
import { connect } from "react-redux";
import { getPersonData } from "../redux/actions/person.action";
import {
  PERSON_DATA_SUCCESS,
  VERIFY_CONFIRM,
} from "../redux/constants/person.constants";
import {
  getDepositingData,
  saveRequiredSettings,
  getDepositingUrl,
} from "../redux/actions/depositing/depositing.actions";
import Loader from "../components/UI/Loader";
import {
  DEPOSITING_DATA_SUCCESS,
  DEPOSITING_CHANGE_STATUS,
} from "../redux/constants/depositing/depositing.constants";
import PaymentIframe from "../components/UI/statusPayment/PaymentIframe";
import GridTables from "../components/Blocks/GridTables/GridTables";
import { Resource } from "../redux/actions/lang.actions";
import DocumentTitle from "../components/UI/DocumentTitle";
import {
  getVeriffSession,
  toggleVeriffPanel,
} from "../redux/actions/veriff/veriff.actions";
import VeriffIframe from "../components/UI/statusVeriff/VeriffIframe";
import { MODAL_IS_OPEN } from "../redux/constants/modals.constants";

class Depositing extends Component {
  submitDepositingPayment = (values) => {
    sessionStorage.removeItem("depositValue");

    if (this.props.data.status) {
      this.props.onToggleModal("modalDepositingVeriff", true);
      //   const { depositingCountry, prefix } = this.props.selects
      //   const { depositingCity, depositingAddress_1, zip, phone } = values
      //   this.props.saveRequiredSettings({
      //     depositingCountry: depositingCountry ? depositingCountry.id : this.props.fields.depositingCountry.value,
      //     depositingCity,
      //     depositingAddress_1,
      //     zip,
      //     prefix: prefix !== undefined ? prefix.id : this.props.fields.prefix.value,
      //     phone: this.props.fields.phone.value !== phone
      //       ? (prefix !== undefined ? prefix.id : String(this.props.fields.prefix.value)) + phone
      //       : (prefix !== undefined ? prefix.id : String(this.props.fields.prefix.value)) + String(this.props.fields.phone.value).substring(String(this.props.fields.prefix.value).length),
      //     depositValue: values.depositValue
      //   })
      //   if(this.props.person.veriffStatusId === VERIFY_CONFIRM) {
      //     this.props.getDepositingUrl(values.depositValue)
      //   }
    } else {
      if (this.props.person.veriffStatusId === VERIFY_CONFIRM) {
        this.props.getDepositingUrl(values.depositValue);
      }
    }
  };

  componentDidMount() {
    this.props.getDepositingData();

    window.gtag("set", "page_path", "/depositing");
    window.gtag("event", "page_view");
  }

  render() {
    return (
      <main className="depositing">
        <DocumentTitle title="Balance" />
        <div className="combo-title container">
          <h1>{Resource("Balance")}</h1>
          <ul className="combo-title__aside">
            <Stats
              id="currentBalanceDepositing"
              data={this.props.person}
              load={PERSON_DATA_SUCCESS}
              reload
            />
            <li>
              <Link
                to="/operations-history"
                className="button button--small button--stroke mobile-hidden"
              >
                {Resource("History")}
              </Link>
            </li>
          </ul>
        </div>

        <section className="tabs js-tabs">
          <RenderTabs
            id="depositingTabs"
            disabled={{
              indexTab: 1,
              value: this.props.person.veriffStatusId === VERIFY_CONFIRM,
            }}
          >
            <Loader
              loadId={DEPOSITING_DATA_SUCCESS}
              reload={this.props.data?.status}
            >
              <ContentPayCardForm
                loading={this.props.loading}
                person={this.props.person}
                modal={this.props.modal["modalPayment"]}
                status={this.props.data?.status}
                fields={this.props.fields}
                onSubmit={this.submitDepositingPayment.bind(this)}
              />
            </Loader>
            <ContentPayScore data={this.props.contentPayScore} />
          </RenderTabs>
        </section>
        <section>
          <h2>{Resource("DepositHistory")}</h2>
          <GridTables
            data={this.props.table}
            loading={this.props.loading[DEPOSITING_DATA_SUCCESS]}
          />
        </section>

        <div className="fixed mobile-visible">
          <div className="container">
            <Link to="/operations-history" className="button">
              {Resource("History")}
            </Link>
          </div>
        </div>

        <PaymentIframe
          modal={this.props.modal["modalPayment"]}
          pay={this.props.payData}
          closeHandler={() => {
            console.log("pressed close modal");
            this.props.getPersonData();
          }}
        />
        <VeriffIframe modal={this.props.modal["modalVeriff"]} />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modals,
    contentPayScore: state.depositing.contentPayScore,
    fields: state.depositing.contentPayCard.fields,
    data: state.depositing.data,
    payData: state.depositing.payData,
    person: state.person,
    selects: state.forms.selects,
    table: state.depositing.depositingTable,
    loading: state.loading,
    lang: state.language.langActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonData: () => dispatch(getPersonData()),
    getDepositingData: () => dispatch(getDepositingData()),
    saveRequiredSettings: (settings) =>
      dispatch(saveRequiredSettings(settings)),
    changeStatusFull: (status) =>
      dispatch({ type: DEPOSITING_CHANGE_STATUS, payload: status }),
    getDepositingUrl: (orderPrice) => dispatch(getDepositingUrl(orderPrice)),
    getVeriffSession: (params) => dispatch(getVeriffSession(params)),
    onToggleModal: (id, isOpen) =>
      dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Depositing);
