import { AUTH_REQUEST, AUTH_SUCCESS, LOGOUT, LOGIN_CONFIRM_EMAIL_SUCCESS, SEND_RESTORE_EMAIL_SUCCESS, SEND_RESTORE_PASSWORD_SUCCESS, CONFIRM_RESTORE_PASSWORD_SUCCESS } from "../../constants/login/auth.constants"
import { history } from "../../history"
import { requestPost, requestGet, errorHandler } from "../../api/request";
import { LOADING } from "../../constants/loading.constants";
import { MODAL_IS_OPEN } from "../../constants/modals.constants";
import { toast } from 'react-toastify';

import { errorLocale } from '../../../locale/messages/errors.locale'
import { getPersonData } from "../person.action";
import { Resource } from "../lang.actions";

const loginRequest = (email, password) => {
    return requestPost('authorize', { email, password })
}

export const login = (email, password) => {
    return dispatch => {
        loginRequest(email, password)
            .then(resp => {
              dispatch({ type: LOADING, id: AUTH_SUCCESS, loading: false })
                localStorage.setItem('auth', JSON.stringify({ time: +new Date(), totalMilliseconds: resp.data.totalMilliseconds }))
                dispatch({ type: AUTH_SUCCESS, email })
                history.push('/portfolio')
                !!document.body.querySelector('.grecaptcha-badge') && document.body.querySelector('.grecaptcha-badge').remove();
            })
            .catch(error => {
                //errorHandler(dispatch, error)
                toast.error(Resource('ErrorEmailPassLogin'))
                dispatch({ type: LOADING, id: AUTH_SUCCESS, loading: false })
            })
        dispatch({ type: AUTH_REQUEST, email })
        dispatch({ type: LOADING, id: AUTH_SUCCESS, loading: true })
    }
}

export const logout = () => {
    return dispatch => {
        requestGet('Person/Unauthorize')
            .then(resp => {
                localStorage.removeItem('auth')
                dispatch({ type: LOGOUT })
                sessionStorage.removeItem('sale')
                history.push('/login');
                
                // FreshChat
                window.fcWidget.user.clear();
                // window.fcWidget.destroy();
                window.fcWidget.init({
                  token: "a4d6d1fb-19f3-440f-a7d6-7585903fc180",
                  host: "https://wchat.freshchat.com",
                  restoreId: null
                });
                // FreshChat
            })
            .catch(error => {
                errorHandler(dispatch, error)
            })
    }
}

export const resetPassword = (email, isOpen, id) => {
    return dispatch => {
        requestPost('Account/RestorePassword?email=' + email)
            .then(resp => {
                dispatch({ type: MODAL_IS_OPEN, isOpen, id })
                dispatch({ type: LOADING, id: SEND_RESTORE_PASSWORD_SUCCESS, loading: false });
                //dispatch({ type: LOADING, id: MODAL_IS_OPEN, loading: false })
                toast.success(Resource('RestorePasswordEmail'))
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: MODAL_IS_OPEN, isOpen, id })
                dispatch({ type: LOADING, id: SEND_RESTORE_PASSWORD_SUCCESS, loading: false });
            })
        //dispatch({ type: LOADING, id: MODAL_IS_OPEN, loading: true })
        dispatch({ type: LOADING, id: SEND_RESTORE_PASSWORD_SUCCESS, loading: true });
    }
}

export const sendRestorePassword = (hash) => {
    return dispatch => {
        requestPost(`Account/SendRestorePassword?hash=${hash}`)
            .then(resp => {
                //if (resp.data.success) {
                //   toast.success(Resource('NewPasswordSent'))
                //   history.push('/')
                //} else {
                //   toast.error(errorLocale.ERROR_500)
                //}
                dispatch({ type: LOADING, id: CONFIRM_RESTORE_PASSWORD_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: CONFIRM_RESTORE_PASSWORD_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: CONFIRM_RESTORE_PASSWORD_SUCCESS, loading: true })
    }
}

export const confirmEmailHash = (hash) => {
    return dispatch => {
        requestPost(`Account/ConfirmEmailHash?hash=${hash}`)
            .then(resp => {
                if (resp.data.success) {
                    //toast.success(successLocale.SuccessEmail)
                    dispatch({ type: LOADING, id: AUTH_SUCCESS, loading: false })
                    localStorage.setItem('auth', JSON.stringify({ time: +new Date() }))
                    dispatch({ type: AUTH_SUCCESS })
                    history.push('/portfolio')

                    dispatch(getPersonData())
                } else {
                    toast.error(errorLocale.ERROR_500)
                }
                dispatch({ type: LOADING, id: LOGIN_CONFIRM_EMAIL_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: LOGIN_CONFIRM_EMAIL_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: LOGIN_CONFIRM_EMAIL_SUCCESS, loading: true })
    }
}

export const restoreEmailLink = () => {
    return dispatch => {
        requestPost(`Person/RestoreEmailLink`)
            .then(resp => {
                toast.success(Resource('ConfirmationLinkSent'))
                dispatch({ type: LOADING, id: SEND_RESTORE_EMAIL_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: SEND_RESTORE_EMAIL_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: SEND_RESTORE_EMAIL_SUCCESS, loading: true })
    }
}

export const redirectLoginHash = (hash) => {
    return dispatch => {
        requestPost(`Account/RedirectLoginHash?hash=${hash}`)
            .then(resp => {
                if (resp.data.success) {
                    localStorage.setItem('auth', JSON.stringify({ time: +new Date(), totalMilliseconds: resp.data.totalMilliseconds }))
                    dispatch({ type: AUTH_SUCCESS, email: hash + '@mail.ru' })
                    history.push('/portfolio')
                } else {
                    history.push('/')
                }
            })
            .catch(error => {
                errorHandler(dispatch, error)
            })
    }
}

// FreshChat
export const checkFreshChatId = () => {  
    return requestGet('/Person/CheckFreshChat')
      .then(response => response)
      .catch(error => error.response);
}
export const saveFreshChatId = (freshChatId) => {
  return requestPost(`/Person/SaveFreshChat?freshId=${freshChatId}`)
    .then(response => response)
    .catch(error => error.response)
}
// FreshChat