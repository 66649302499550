import { LOADING } from "../../constants/loading.constants"

const loading = (state = {}, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, [action.id]: action.loading }
        default:
            return state
    }
}

export default loading
