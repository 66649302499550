import { WITHDRAWAL_REQUEST_FAILURE, WITHDRAWAL_TABLE_SUCCESS, WITHDRAWAL_TABLE_FAILURE } from "../../constants/withdrawal/withdrawal.constants"

const initialState = {
    title: 'WithdrawalViaBankAccount',
    description: ['WithdrawalInfo1', 'WithdrawalInfo2', 'WithdrawalInfo3'],
    fields: {
        withdrawalValue: {
            id: 'withdrawalValue',
            name: 'withdrawalValue',
            type: 'text',
            placeholder: 'Sum',
            labelDown: 'WithdrawalAmount',
            container: false,
            edit: true,
            maxLength: 7,
            validate: {
                required: true,
                range: [50, 10000]
            },
            depositFormat: true
        }
    },
    withdrawalTable: {
        id: 'withdrawal',
        columnsOptions: {
            id: {
                title: 'ID', flexGrow: 0.5, minWidth: 70, sort: true
            },
            operations: {
                title: 'Operation', flexGrow: 3, minWidth: 150, sort: true, format: 'string'
            },
            withdrawal: {
                title: 'Withdrawal', flexGrow: 1, minWidth: 120, sort: true, format: 'currency-wit'
            },
            status: {
                title: 'Status', flexGrow: 1, minWidth: 100, sort: true, format: 'string'
            },
            dateOperation: {
                title: 'OperationDate', flexGrow: 1, minWidth: 100, sort: true, format: 'date'
            },
        },
        body: [],
        sortColumn: 'id'

    }
}

const withdrawal = (state = initialState, action) => {
    switch (action.type) {
        case WITHDRAWAL_TABLE_SUCCESS:

            const getDataTable = () => {
                return action.payload.map(({ id, operations, withdrawal, status, dateOperation }) => {
                    return {
                        id,
                        operations: operations === 3
                            ? 'BankTransferReplenishment'
                            : operations === 6
                                ? 'BankTransferWithdrawal'
                                : operations === 5
                                    ? 'PortfolioIncome'
                                    : operations === 4
                                        ? 'PortfolioCreated'
                                        : '',
                        withdrawal,
                        status: status === 64
                            ? 'STATUS_005'
                            : status === 63
                                ? 'STATUS_004'
                                : status === 65
                                    ? 'STATUS_006'
                                    : '',
                        dateOperation
                    }
                })
            }

            return {
                ...state,
                withdrawalTable: {
                    ...state.withdrawalTable,
                    body: getDataTable()
                }
            }
        case WITHDRAWAL_TABLE_FAILURE:
            return {
                ...state,
                withdrawalTable: {
                    ...state.withdrawalTable,
                    erorr: action.error
                }
            }
        case WITHDRAWAL_REQUEST_FAILURE:
            return {
                ...state,
                ...action.error
            }
        default:
            return state
    }
}

export default withdrawal
