import {
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SETTINGS_FAILURE,
    SETTINGS_SAVE_SUCCESS,
} from "../../constants/settings/settings.constants"
import { requestPost, requestGet, errorHandler } from "../../api/request"
import { FORMS_SHOW_PASSWORD } from "../../constants/forms.constants"
import { LOADING } from "../../constants/loading.constants"
import { toast } from 'react-toastify'
import { getPersonData } from "../person.action"
import { reset } from "redux-form"
import { Resource } from "../lang.actions"

export const getPersonSettings = () => {

    return dispatch => {
        requestGet('Settings/GetPersonSettings')
            .then(resp => {
                if (resp.status === 200) {
                    dispatch({ type: SETTINGS_SUCCESS, payload: resp.data })
                    dispatch({ type: LOADING, id: SETTINGS_SUCCESS, loading: false })
                }
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: SETTINGS_FAILURE, error })
            })
        dispatch({ type: LOADING, id: SETTINGS_SUCCESS, loading: true })
        dispatch({ type: SETTINGS_REQUEST })
    }
}

export const savePersonalInformation = ({ ...params}) => {
    const { settingsName, settingsSurname, settingsBirthday, settingsPassport, settingsCountry, settingsCity, settingsAddress_1, zip, prefix, phone } = params

    let personalInformation = {
        FirstName: settingsName || null,
        LastName: settingsSurname || null,
        BirthDateStr: settingsBirthday || '',
        PersonalDocument: settingsPassport || '',
        DictionaryCountryId: Number(settingsCountry) || null,
        City: settingsCity || '',
        Address: settingsAddress_1 || '',
        Zip: zip || '',
        PhonePrefix: String(prefix) || null,
        Phone: phone || ''
    }

    return dispatch => {
        requestPost('Settings/ChangePersonalInformation', personalInformation)
            .then(resp => {
                if (resp.status === 200) {
                    toast.success(Resource('SuccessfullySaved'))
                    dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
                    dispatch({ type: SETTINGS_SAVE_SUCCESS, payload: resp.data })
                    dispatch(getPersonData())
                }
            })
            .catch(error => {
                if (error.request.status === 500) {
                    toast.error(Resource('PhoneAlreadyReg'))
                }
                dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
                dispatch({ type: SETTINGS_FAILURE, error })
            })
        dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: true })
    }
}

export const changePasswordSetting = ({...params}) => {
    const { settingsPasswordCurrent, settingsPassNew } = params
     
    let passwordSetting = {
        CurrentPassword: settingsPasswordCurrent || null,
        NewPassword: settingsPassNew || null
    }

    return dispatch => {
        requestPost('Settings/ChangePasswordSetting', passwordSetting)
            .then(resp => {
                if (resp.status === 200) {
                    toast.success(Resource('SuccessfullySaved'))
                    dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
                    dispatch(reset('changePasswordForm'))
                }
            })
            .catch(error => {
                //errorHandler(dispatch, error)
                toast.error(Resource('CurrentPassError'))
                dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
                dispatch({ type: SETTINGS_FAILURE, error })
            })
        dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: true })
    }
}

export const changeShowPassword = (id, show) => {
    return {
        type: FORMS_SHOW_PASSWORD,
        show: !show,
        id
    }
}

export const saveMainSettings = ({ ...params }) => {
    const { noticeArticle, noticeDays, noticeMonth, noticeNews, noticeWeek, settingsLang } = params

    let settingsMainModel = {
        Lang: Number(settingsLang) || null,
        NoticeArticle: noticeArticle || false,
        NoticeNews: noticeNews || false,
        NoticeDays: noticeDays || false,
        NoticeWeek: noticeWeek || false,
        NoticeMounth: noticeMonth || false
    }

    return dispatch => {
        requestPost('Settings/ChangeMainSetting', settingsMainModel)
            .then(resp => {
                if (resp.status === 200) {
                    toast.success(Resource('SuccessfullySaved'))
                    dispatch({ type: SETTINGS_SAVE_SUCCESS, payload: resp.data })
                    dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
                } else {
                    dispatch({ type: SETTINGS_FAILURE, error: resp.status })
                    dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
                }
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: SETTINGS_FAILURE, error })
                dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: SETTINGS_SAVE_SUCCESS, loading: true })
    }
}