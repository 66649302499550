import React from 'react'

const SpinnerButton = props => {

    return (
        <>
            {
                props.container
                    ? <div className='spinner__container'>
                        <div className="spinner--button">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    : <div className="spinner--button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
            }
        </>
    )
}

export default SpinnerButton