import React from 'react'
import Loader from '../UI/Loader'
import { SUPPORT_SEND_MESSAGE_SUCCESS, SUPPORT_GET_MESSAGE_SUCCESS } from '../../redux/constants/support/support.constants'
import { useRef } from 'react'
import { useEffect } from 'react'
import { scrollBottom } from '../../redux/utils/scrollBottom'
import { Resource } from '../../redux/actions/lang.actions'
import { set } from 'js-cookie'
import { useState } from 'react'


const SupportChat = ({ messages, loading }) => {
    const chatWindowRef = useRef(null)

    useEffect(() => {
        scrollBottom(chatWindowRef.current)
    }, [messages])

    const renderQuestMessages = () => {
        return Object.keys(messages).map((key, ind) => {
            const msg = messages[key]

            const cls = [
                "chat__message",
                msg.name === 'person' ? 'chat--quest' : 'chat--answer'
            ]

            return (
                <div key={msg.value + ind + Math.random()} className={cls.join(' ')}>
                    <p>{msg.value}</p>
                </div>
            )
        })
    }

    return (
        <div ref={chatWindowRef} className="chat__window">
            {renderQuestMessages()}
        </div>
    )
}

export default SupportChat