import React, { Component, useEffect, useMemo, useState } from "react";
import DocumentTitle from "../../../components/UI/DocumentTitle";
import { Resource } from "../../../redux/actions/lang.actions";
import Stats from "../../../components/Blocks/Stats";
import {
  PERSON_DATA_SUCCESS,
  VERIFY_CONFIRM,
} from "../../../redux/constants/person.constants";
import { Link } from "react-router-dom";
import RenderTabs from "../../../components/UI/RenderTabs";
import Loader from "../../../components/UI/Loader";
import {
  DEPOSITING_CHANGE_STATUS,
  DEPOSITING_DATA_SUCCESS,
  DEPOSITING_GET_URL_SUCCESS,
} from "../../../redux/constants/depositing/depositing.constants";
import PaymentIframe from "../../../components/UI/statusPayment/PaymentIframe";
import VeriffIframe from "../../../components/UI/statusVeriff/VeriffIframe";

import classes from "./BalancePage.module.scss";
import List from "../../components/List/List";
import Panel from "../../components/Panel/Panel";
import {
  currencyFormatProfit,
  currentFormatting,
} from "../../../redux/utils/formatting";
import Currency from "../../components/сurrency/Currency";
import { connect } from "react-redux";
import TabsComponent from "../../components/Tabs/TabsComponent";
import TabComponentItem from "../../components/Tabs/TabsComponentItem";
import TabComponentPortal from "../../components/Tabs/TabComponentPortal";
import logo_mastercard from "../../../static/image/icons/logo-mastercard.svg";
import logo_visa from "../../../static/image/icons/logo-visa.svg";
import FieldsWithButton from "../../components/FormsController/FieldsWithButton";
import FieldsFixed from "../../components/FormsController/FieldsFixed/FieldsFixed";
import LinkButton from "../../components/Buttons/LinkButton/LinkButton";
import TableComponent from "../../components/TableComponent/TableComponent";
import Heading from "../../components/Heading/Heading";
import { getDepositPayments } from "../../redux/balanced-reducer";
import {
  OnlyDesktop,
  OnlyMobile,
} from "../../components/Tools/OnlyDevice/OnlyDevice";
import VeriffForm from "../../../components/UI/formControls/veriff/veriff.form";
import {
  getVeriffSession,
  saveRequiredVeriff,
  toggleVeriffPanel,
} from "../../../redux/actions/veriff/veriff.actions";
import ModalComponent from "../../components/Modals/ModalComponent";
import FormVerify from "../../components/FormsController/FormVerify/FormVerify";
import Modal from "../../../components/UI/Modal/Modal";
import {
  getDepositingData,
  getDepositingUrl,
  saveRequiredSettings,
} from "../../../redux/actions/depositing/depositing.actions";
import { getFormValues, isValid } from "redux-form";
import { getPersonData } from "../../../redux/actions/person.action";
import { MODAL_IS_OPEN } from "../../../redux/constants/modals.constants";
import { getPersonSettings } from "../../../redux/actions/settings/settings.actions";
import { VERIFF_SESSION_SUCCESS } from "../../../redux/constants/veriff/veriff.constants";
import LayoutWithData from "../../components/LayoutWithData/LayoutWithData";
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton";
import { useForm } from "react-hook-form";
import {
  getFirstDayNextMonth,
  getLastDayCurrentMonth,
  getTemplateOfValues,
  isCurrency, rangeNumbers,
} from "../../components/tools";
import iconArrow from "../../../static/image/icons/icon-arrow-btn.svg";
import IconButton from "../../components/Buttons/IconButton/IconButton";
import NoticeComponent from "../../components/NoticeComponent/NoticeComponent";
import noticeArrow from "../../../static/image/arrow-stroke.svg";
import VerifyBanner from "../../components/VerifyBanner/VerifyBanner";
import ModalButton from "../../../components/UI/Modal/ModalButton";
import { BankOff } from "../../components/BankOff/BankOff";

const BalancePage = (props) => {
  const [disableVerifBtn, setVerifBtnStatus] = useState(false);

  const [currentDepositValue, setCurrentDepostiValue] = useState(null);

  const [isNotice, setIsNotice] = useState(false);

  useEffect(() => {
    props.getPersonSettings();
    window.ym(56965879, "hit", "/depositing");
  }, []);

  const sumbitSaveVeriff = (values) => {
    setVerifBtnStatus(true);
    const { prefix, verifyCountry } = props.selects;
    const { verifyAddress, verifyCity, verifyZip, phone } = values;

    props.saveRequiredVeriff({
      verifyAddress,
      verifyCity,
      verifyCountry: verifyCountry
        ? verifyCountry.id
        : props.veriff.fields.verifyCountry.value,
      verifyZip,
      prefix:
        prefix !== undefined ? prefix.id : props.veriff.fields.prefix.value,
      phone:
        props.veriff.fields.phone.value !== phone
          ? !!phone
            ? (prefix !== undefined
                ? prefix.id
                : String(props.veriff.fields.prefix.value)) + phone
            : null
          : !!phone
          ? (prefix !== undefined
              ? prefix.id
              : String(props.veriff.fields.prefix.value)) +
            String(props.veriff.fields.phone.value).substring(
              String(props.veriff.fields.prefix.value).length
            )
          : null,
      personId: props.person.id,
      firstName: props.person.firstName,
      lastName: props.person.lastName,
      setVerifBtnStatus,
      verifyStatusId: props.person.veriffStatusId,
      depositValue: currentDepositValue,
    });
  };

  const submitDepositingPayment = (values) => {
    setCurrentDepostiValue(values.depositValue.replace(/[^0-9\.]/, ""));
    if (props.balancedReducer.data?.status) {
      console.log(
        "Обязательные поля не заполнены. Открыть модальное окно с полями для заполнения"
      );
      props.onToggleModal("modalDepositingVeriff", true);
    } else {
      if (props.person.veriffStatusId === VERIFY_CONFIRM) {
        console.log("Верификация пройдена. Открыть Iframe банка");
        props.getDepositingUrl(values.depositValue);
      } else {
        console.log("Верификация не пройдена. Открыть верификацию");
        if (props.isValid) {
          setVerifBtnStatus(true);
          props.getVeriffSession({
            personId: props.person.id,
            setVerifBtnStatus,
            firstName: props.person.firstName,
            lastName: props.person.lastName,
          });
        }
      }
    }
  };

  return (
    <main className={classes.balance}>
      <DocumentTitle title={Resource("Balance")} />
      {/*<div className="container">*/}
      <div className={classes.info}>
        {/*<h2>Please keep in mind</h2>*/}
        {/*<p>Your bank may set limits on transactions made by card.</p>*/}
        {/*<List*/}
        {/*  marker='arrow'*/}
        {/*  items={[*/}
        {/*    'You can contact your bank to solve the problem',*/}
        {/*    'Deposit your account by bank transfer'*/}
        {/*  ]}*/}
        {/*  customClass={classes.list}*/}
        {/*/>*/}
      </div>
      <div className={classes.content}>
        <h1>{Resource("Balance")}</h1>
        <Panel customClass={classes.panel}>
          <div className={classes.action}>
            {/*action*/}
            <Currency
              column
              order
              className={classes.currency}
              amount={currentFormatting(props.person.currentBalance.value)}
              text={[Resource("AccountSumAvailable")]}
              loadId={PERSON_DATA_SUCCESS}
            />
            <IconButton icon={iconArrow} link={"/withdrawal"}>
              {Resource("WithdrawFundsMobile")}
            </IconButton>
          </div>

          <div className={classes.tabs}>
            <TabsComponent
              id={"balancedTabs"}
              customClasses={classes}
              onTabClick={() => console.log("Click handler to button for tabs")}
            >
              <TabComponentItem
                label={
                  <>
                    <span
                      style={{
                        order:
                          props.language.langActive === "EN" ||
                          props.language.langActive === "DE"
                            ? 1
                            : 0,
                      }}
                    >
                      {Resource("DepositTabsTitle1")}
                    </span>
                    <i>
                      <img src={logo_visa} alt="visa" /> /{" "}
                      <img src={logo_mastercard} alt="mastercard" />
                    </i>
                  </>
                }
              >
                {isNotice && (
                  <NoticeComponent
                    mainText={[
                      "Sorry, card payments are temporarily unavailable =(",
                      "We do our best to fix it",
                    ]}
                    descriptionText="Meantime, you can to-up your account by bank transfer. We apologize for the inconvenience"
                    image={noticeArrow}
                    imageStyle={{
                      position: "absolute",
                      right: "-25px",
                      bottom: "calc(100% - 160px)",
                    }}
                  />
                )}

                <FieldsWithButton
                  fields={props.balancedReducer.fields}
                  onSubmit={submitDepositingPayment}
                  loading={
                    props.loading[DEPOSITING_GET_URL_SUCCESS] ||
                    props.loading[VERIFF_SESSION_SUCCESS]
                  }
                  btnText={Resource("DepositAccount")}
                  defValue={props.balancedReducer.valueField}
                  offline={isNotice}
                  height={isNotice ? "64px" : "auto"}
                  validateCustom={props.person.id === 5442 && ((value) => rangeNumbers([5, 15000], value))}
                />

                <Modal
                  isOpen={props.modalDepositingVeriff.isOpen}
                  id={props.modalDepositingVeriff.id}
                  isForm={false}
                  addClass={"veriff__modal"}
                  isOutside={true}
                  onStart={() => {
                    console.log("Open verify modal");
                    window.ym(56965879, "reachGoal", "Veriff_prelend_open");
                  }}
                >
                  <VeriffForm
                    onSubmit={sumbitSaveVeriff.bind(this)}
                    disableVerifBtn={disableVerifBtn}
                    toggleVeriffPanel={props.toggleVeriffPanel}
                    fields={props.veriff.fields}
                  />
                </Modal>

                <ModalComponent
                  data={props.modal.modalLimitDeposit}
                  // title=''
                  // description=''
                  onCloseHandler={() => {
                    window.clearDepositValue(
                      "depositValue",
                      `€${props.layoutData.message?.remainBalance}`
                    );
                  }}
                  closeDisabled={true}
                  content={
                    <div className={classes.limit}>
                      {props.modal.modalLimitDeposit.isOpen ? (
                        <>
                          <h4 className={classes.title}>
                            {getTemplateOfValues(
                              Resource("LimitContentTitle"),
                              {
                                1: currentFormatting(
                                  props.layoutData.message?.currentBalance
                                ),
                              }
                            )}
                          </h4>
                          <p className={classes.description}>
                            {getTemplateOfValues(
                              Resource("LimitContentDescription1"),
                              { 1: currentFormatting(15000) }
                            )}
                          </p>
                          <ul className={classes.list}>
                            <li>
                              {getTemplateOfValues(
                                Resource("LimitContentList1"),
                                {
                                  1: getLastDayCurrentMonth(),
                                  2: currentFormatting(
                                    props.layoutData.message?.remainBalance
                                  ),
                                }
                              )}
                            </li>
                            <li>
                              {getTemplateOfValues(
                                Resource("LimitContentList2"),
                                {
                                  1: getFirstDayNextMonth(),
                                  2: currentFormatting(15000),
                                }
                              )}
                            </li>
                          </ul>
                          <p className={classes.description}>
                            {getTemplateOfValues(
                              Resource("LimitContentDescription2"),
                              { 1: currentFormatting(15000) }
                            )}
                          </p>
                        </>
                      ) : null}
                    </div>
                  }
                  footer={
                    <DefaultButton close customClass={classes.closed}>
                      OК
                    </DefaultButton>
                  }
                  customClass={classes.modal}
                />
                </TabComponentItem>
                

              {
                <TabComponentItem
                  label={Resource("DepositTabsTitle2")}
                  // disabled={props.person.veriffStatusId === VERIFY_CONFIRM}
                >
                  {props.person.veriffStatusId === VERIFY_CONFIRM ? (
                      // <FieldsFixed data={[
                      //       {label: Resource('CompanyName'), value: 'OÜ NIBBLE ITSF'},
                      //       {label: Resource('Bank'), value: 'Paysera LT, UAB'},
                      //       {label: Resource('AccountNumber'), value: 'LT55 3500 0100 0733 7093', clear: true},
                      //       {label: Resource('PurposeOfPayment'), value: `Deposit for account ${props.person.id}`},
                      //       {label: Resource('SWIFT'), value: `EVIULT2VXXX`}
                      //     ]}/>
                          <FieldsFixed data={[
                            {label: Resource('CompanyName'), value: 'OU Nibble itsf'},
                            {label: Resource('Bank'), value: '3S Money Club Limited'},
                            {label: Resource('AccountNumber'), value: 'NL62MOLU0738075078', clear: true},
                            {label: Resource('PurposeOfPayment'), value: `Deposit for account ${props.person.id}`},
                            {label: Resource('SWIFT'), value: `MOLUNL22`}
                          ]}/>
                      //<BankOff /> 
                  ) : !props.isFullFields ? (
                    <ModalButton
                      isOpen={!props.modalDepositingVeriff.isOpen}
                      id={props.modalDepositingVeriff.id}
                      layout={
                        <VerifyBanner
                          name={props.person.firstName}
                          loading={disableVerifBtn}
                          className={classes}
                        />
                      }
                      addClass={classes.button}
                    />
                  ) : (
                    <VerifyBanner
                      name={props.person.firstName}
                      loading={disableVerifBtn}
                      className={classes}
                      onClickBanner={() => {
                        setVerifBtnStatus(true);
                        props.getVeriffSession({
                          personId: props.person.id,
                          setVerifBtnStatus,
                          firstName: props.person.firstName,
                          lastName: props.person.lastName,
                        });
                      }}
                    />
                  )}

                  {/* <OnlyMobile point={768}>
                        <span className={classes.description} dangerouslySetInnerHTML={{__html: Resource('DepositDescriptionBankMobile')}}/>
                      </OnlyMobile> */}
                </TabComponentItem>
              }
            </TabsComponent>
          </div>

          <div className={classes.footer}>
            <TabComponentPortal id={"balancedTabs"}>
              <TabComponentItem>
                <OnlyDesktop point={768}>
                  <div className={classes.description}>
                    {Resource("DepositDescriptionCardDesk")}
                  </div>
                </OnlyDesktop>
                <OnlyMobile point={768}>
                  <div className={classes.description}>
                    {Resource("DepositDescriptionCardMobile")}
                  </div>
                </OnlyMobile>
              </TabComponentItem>
            
              <TabComponentItem>
                <OnlyDesktop point={768}>
                  <div className={classes.description}>
                    {Resource("DepositDescriptionBankDesk")}
                  </div>
                </OnlyDesktop>
              </TabComponentItem>
            </TabComponentPortal>
          </div>
        </Panel>

        <div>
          {props.tableData.depositPayments.length > 0 && (
            <Heading
              title={Resource("DepositHistory")}
              link={Resource("NextMoreText")}
              to="/operations-history"
              className={classes.heading}
            />
          )}

          <TableComponent
            headers={props.tableData.headers}
            body={props.tableData.depositPayments}
            gridLayout={[0.8, 0.8, 0.3, 0.3]}
            tableMediaPoint={768}
            tableMediaGrid={[1, 0.3]}
            getDataHandler={props.getDepositPayments}
            renderRows={10}
            desc={"dateOperation"} // По возрастанию
            // asc={'dateOperation'} // По убыванию
            light
            classValue={classes.value}
          />

        </div>
      </div>

      <PaymentIframe
        modal={props.modal["modalPayment"]}
        pay={props.payData}
        closeHandler={() => {
          console.log("pressed close modal");
          props.getPersonData();
          props.getDepositPayments();
        }}
      />
      <VeriffIframe modal={props.modal["modalVeriff"]} />
    </main>
  );
};

const mapStateToProps = (state) => {
  const fields = state.veriff.fields;
  const fieldsValues = [
    fields.verifyCountry.value,
    fields.verifyCity.value,
    fields.verifyAddress.value,
    fields.verifyZip.value,
    fields.prefix.value,
    fields.phone.value,
  ];

  return {
    person: state.person,
    language: state.language,
    balancedReducer: state.BalancedReducer,
    verifyReducer: state.VerifyReducer,
    tableData: state.BalancedReducer.depositTable,

    modal: state.modals,
    payData: state.depositing.payData,
    depositingData: state.depositing.data,
    valuesForm: getFormValues("DepositingFormCard")(state),
    isValid: isValid("DepositingFormCard")(state),
    modalVeriff: state.modals.modalVeriff,
    modalDepositingVeriff: state.modals.modalDepositingVeriff,
    fieldsVerify: state.veriff.fields,
    selects: state.forms.selects,
    loading: state.loading,
    veriff: state.veriff,
    isFullFields: fieldsValues.every((value) => !!value),
    layoutData: state.LayoutReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDepositPayments: () => dispatch(getDepositPayments()),

    getPersonData: () => dispatch(getPersonData()),
    saveRequiredSettings: (settings) =>
      dispatch(saveRequiredSettings(settings)),
    changeStatusFull: (status) =>
      dispatch({ type: DEPOSITING_CHANGE_STATUS, payload: status }),
    getVeriffSession: (params) => dispatch(getVeriffSession(params)),
    onToggleModal: (id, isOpen) =>
      dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
    toggleVeriffPanel: () => dispatch(toggleVeriffPanel()),
    saveRequiredVeriff: (veriffModel) =>
      dispatch(saveRequiredVeriff(veriffModel)),
    getDepositingUrl: (orderPrice) => dispatch(getDepositingUrl(orderPrice)),
    getPersonSettings: () => dispatch(getPersonSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BalancePage);
