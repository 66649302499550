import React, { useEffect, useRef, useState } from "react";
import { Field } from "redux-form";
import { renderValidation } from "./Validation";
import { Resource } from "../../../redux/actions/lang.actions";
import { connect } from "react-redux";
import {
  calculateFormatting,
  currentFormatting,
} from "../../../redux/utils/formatting";

const RenderField = ({
  input,
  label,
  id,
  meta,
  strategys,
  fixedValues,
  setCurrentValue,
  dataValues,
  nameProduct,
  valuesId,
  maxLength,
  validate,
}) => {
  useEffect(() => {
    fixedValues(nameProduct, dataValues.defaultValue, valuesId);
  }, [dataValues.defaultValue, nameProduct]);

  const selectedHandler = (event, id, customId) => {
    fixedValues(nameProduct, +event.target.textContent, id);
    fixedValues(nameProduct, null, customId);
    setCurrentValue(+event.target.textContent, nameProduct);
  };

  const renderTemplateLabel = (dataValues) => {
    const stringArray = Resource(dataValues.label).split("/");
    const minmax = {
      min: dataValues.formatting
        ? dataValues.formatting(parseInt(dataValues.min))
        : parseInt(dataValues.min),
      max: dataValues.formatting
        ? dataValues.formatting(parseInt(dataValues.max))
        : parseInt(dataValues.max),
    };
    stringArray.map((item, idx) => {
      if (minmax[item]) {
        stringArray[idx] = minmax[item];
      }
    });
    return stringArray.join("");
  };

  const [showField, setShowField] = useState(false);
  const [showError, setShowError] = useState(false);
  const inputRef = useRef(null);

  const checkNumber = (event) => {
    event.target.value.replace(/[^0-9]/g, (e) => {
      if (e === ",") {
        event.currentTarget.value = event.currentTarget.value.replace(",", ".");
      }
      if (!/[^a-zA-Z]/g.test(e) && e !== ".") {
        event.target.value = event.currentTarget.value.substring(
          0,
          event.currentTarget.value.length - 1
        );
      } else if (
        e === "." &&
        (event.currentTarget.value.match(/[.]/g) || []).length > 1
      ) {
        event.target.value = event.currentTarget.value.substring(
          0,
          event.currentTarget.value.length - 1
        );
      }
    });
  };

  return (
    <>
      <div
        className={["calculator__range", showField ? "focus" : ""].join(" ")}
      >
        {dataValues.label && (
          <span className="calculator__range-title">
            {renderTemplateLabel(dataValues)}
          </span>
        )}
        <div className="calculator-values">
          {dataValues.values.map((item, idx) => {
            return (
              <span
                key={idx}
                className={`calculator-values__button ${
                  Number(strategys.dynamicValues[dataValues.id]) ===
                    Number(item) && strategys.dynamicValues[id] === null
                    ? "active"
                    : ""
                }`}
                onClick={(event) => selectedHandler(event, dataValues.id, id)}
              >
                {item}
              </span>
            );
          })}

          <span
            className={[
              "calculator-field--mobile",
              strategys.dynamicValues[id] !== null && input.value !== ""
                ? "calculator-field__value"
                : "",
            ].join(" ")}
            onClick={() => {
              setShowField(true);
              setTimeout(() => {
                inputRef.current.focus();
              }, 100);
            }}
          >
            {input.value !== "" ? input.value : Resource("OtherLabel")}
          </span>

          <div
            className={[
              "calculator-field",
              strategys.dynamicValues[id] !== null && input.value !== ""
                ? "calculator-field__value"
                : "",
            ].join(" ")}
          >
            <input
              {...input}
              ref={inputRef}
              id={id}
              name={id}
              type="text"
              placeholder=" "
              autoComplete="off"
              onChange={(event) => {
                checkNumber(event);
                input.onChange(event);
                fixedValues(
                  nameProduct,
                  event.target.value !== "" ? Number(event.target.value) : null,
                  event.target.id
                );
              }}
              onFocus={(event) => {
                input.onFocus(event);
                fixedValues(
                  nameProduct,
                  event.target.value !== "" ? Number(event.target.value) : null,
                  event.target.id
                );
                setShowError(true);
              }}
              onBlur={(event) => {
                input.onBlur(event);
                setShowError(false);
              }}
              maxLength={maxLength}
            />
            <label htmlFor={id}>{Resource(label)}</label>
            <span
              className="close"
              onClick={() => {
                setShowField(false);
              }}
            />
          </div>
          {!showError && meta.touched && meta.error && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      </div>
    </>
  );
};

const RenderCustomField = ({
  data: {
    id,
    name,
    label,
    type,
    placeholder,
    validate,
    maxLength,
    onInputHandler,
    saveOnBlur,
  },
  disabled,
  strategys,
  idProduct,
  fixedValues,
  setCurrentValue,
  dataValues,
  nameProduct,
  valuesId,
}) => {
  let validOptions = {
    rangeChecking: validate.rangeChecking,
  };

  return (
    <>
      <Field
        component={RenderField}
        id={id}
        name={name}
        type={type}
        label={label}
        className={"field field__input"}
        placeholder={Resource(placeholder)}
        disabled={disabled}
        validate={renderValidation(validOptions)}
        maxLength={maxLength}
        onInputHandler={onInputHandler}
        saveOnBlur={saveOnBlur}
        strategys={strategys}
        idProduct={idProduct}
        fixedValues={fixedValues}
        setCurrentValue={setCurrentValue}
        dataValues={dataValues}
        nameProduct={nameProduct}
        valuesId={valuesId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.language.langActive,
  };
};

export default connect(mapStateToProps, null)(RenderCustomField);
