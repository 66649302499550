import React from 'react'
import Button from '../../Button'
import { reduxForm, getFormValues } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import { Resource } from '../../../../redux/actions/lang.actions'
import { validationCurrency } from '../../../../redux/utils/formatting'
import { compose } from 'redux'
import { connect } from 'react-redux'
import LoaderButton from '../../LoaderButton'
import { PORTFOLIO_DEPOSITING_SUCCESS } from '../../../../redux/constants/table.constants'

const DepositForm = props => {
    return (
        <>
            <form onSubmit={event => {
                event.preventDefault()
                !!props.valuesForm?.depositValue && validationCurrency(props.valuesForm.depositValue.replace(',', '.'), props.fields['depositValue'].validate.range)
                props.handleSubmit()
            }} className="portfolio-refill__form">
                <RenderInput
                    data={props.fields['depositValue']}
                    disabled={!!props.loading}
                />
                <LoaderButton
                    type="submit"
                    disabled={!props.loading}
                    loading={props.loading}
                >{Resource('DepositFunds')}</LoaderButton>
                
            </form>
        </>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading[PORTFOLIO_DEPOSITING_SUCCESS],
        valuesForm: getFormValues('depositForm')(state)
    }
}

export default compose(connect(mapStateToProps, null), reduxForm({ form: 'depositForm' }))(DepositForm)


    //< Button disabled = {!!props.loading}> { Resource('DepositFunds') }</Button >