import React, {useState} from 'react';
import classes from './Tooltip.module.scss'

const Tooltip = (props) => {
  
  const [isShowTooltip, setIsShowTooltip] = useState(false)

  const onClickHandler = (event) => {
    props.onClickChildren(event);
    setIsShowTooltip(true)
    
    setTimeout(() => {
      setIsShowTooltip(false)
    }, 1500)
  }
  
  const renderChildren = () => {
    return React.Children.map(props.children, child => {
      return React.cloneElement(child, {onClick: onClickHandler, className: classes.element})
    })
  }
  
  return (
    <div className={[classes.tooltip, isShowTooltip ? classes.active : ''].join(' ')}>
      {renderChildren()}
      <span className={classes.content}>
        {
          props.icon ? <img src={props.icon} alt="copy"/> : props.value
        }
      </span>
      
    </div>
  )
}

export default Tooltip;