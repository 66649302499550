import { Resource } from "../../../redux/actions/lang.actions"
import {currencyFormatProfit, renderTemplateMinMax} from "../../../redux/utils/formatting";
import {isArray} from "jquery";
import {switchId} from "../../../redux/constants/profile/strategys.constants";

export const renderValidation = ({ ...params }) => {
    const { required, email, only, recaptcha, date, range, match, rangeChecking } = params
    
    let array = []

    const requiredField = value => {
        return value ? undefined : Resource('required')
    }

    const recaptchaField = value => value ? undefined : ' '

    const emailField = value =>
        value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)
            ? Resource('regexNot')
            : undefined

    const letterField = value =>
        value && !/[a-zA-ZА-Яа-я]+/g.test(value)
            ? Resource('regexNot')
            : undefined

    const dateField = value => {
        
        let d, m, y;
        if (value === undefined) {
            value = ''
        } else {
            d = !!value.split('.')[0] ? value.split('.')[0] : ''
            m = !!value.split('.')[1] ? value.split('.')[1] : ''
            y = !!value.split('.')[2] ? value.split('.')[2] : ''

            let dateString = `${m}/${d}/${y}`

            if (dateString.length < 10 || y < '1900') {
                return Resource('regexNot')
            } else if (isNaN(Date.parse(dateString))) {
                return Resource('regexNot')
            } else if (Math.sign(Date.now() - Date.parse(dateString)) === -1) {
                return Resource('regexNot')
            }
        }
    }

    const valueMinMax = value => {
        let numberRevers = String(value).replace(/[.#!$%&;:{}=\-_`~()\s]/g, "")
        if (+numberRevers.replace(',', '.') < range[0] || +numberRevers.replace(',', '.') > range[1]) {
            return Resource('regexNot')
        }
    }
    
    const rangeCheckingValue = rangeChecking => (value, values, props, id) => {
        let ranges = []

        const nameProduct = switchId(props.data.activeProductId)
        
        Object.keys(props.data[nameProduct]).map(item => {
            if(props.data[nameProduct][item].customId && props.data[nameProduct][item].customId === id) {
                ranges.push(props.data[nameProduct][item].min)
                ranges.push(props.data[nameProduct][item].max)
            }
        })
        if(props.data.dynamicValues[id] === null) return undefined
        if(isArray(rangeChecking)) {
            if(Number(props.data.dynamicValues[id]) < Number(ranges[0]))
                return Resource(rangeChecking[0]).replaceAll('/min/', currencyFormatProfit(ranges[0]))
            if(Number(props.data.dynamicValues[id]) > Number(ranges[1]))
                return Resource(rangeChecking[1]).replaceAll('/max/', currencyFormatProfit(ranges[1]))
        } else {
            if(Number(props.data.dynamicValues[id]) < Number(ranges[0]) || Number(props.data.dynamicValues[id]) > Number(ranges[1]))
                return Resource(rangeChecking).replaceAll('/min/', ranges[0]).replaceAll('/max/', ranges[1])
        }
        return undefined
    }

    const matchValues = matchName => (value, allValues) => 
        value !== allValues[matchName]
            ? Resource('NewRepeatPasChange')
            : undefined
    
    if (required) array.push(requiredField)
    if (email) array.push(emailField)
    if (only === 'letter') array.push(letterField)
    if (recaptcha) array.push(recaptchaField)
    if (!!range) array.push(valueMinMax)
    if (match) array.push(matchValues(match))
    if (date) array.push(dateField)
    if (rangeChecking) array.push(rangeCheckingValue(rangeChecking))
    return array
}
