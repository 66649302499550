import { CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE, CREATE_ACCOUNT_CHANGE_FIELD, CREATE_ACCOUNT_STATUS_PASSWORD, SEND_LEGAL_SUCCESS, SEND_TOKEN_SUCCESS } from "../../constants/create-account/account.constants"
import { requestPost, errorHandler, requestGet } from "../../api/request"
import {  AUTH_SUCCESS } from "../../constants/login/auth.constants"
import { history } from "../../history"
import { toast } from "react-toastify"
import { LOADING } from "../../constants/loading.constants"
import { Resource } from "../lang.actions"
import { reset } from 'redux-form'
import Cookies from "js-cookie";

const b64EncodeUnicode = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
}

export const onCreateAccount = (individualName, individualGender, individualEmail, individualPromocode, individualPassword) => {

    let accountModel = {
        FirstName: individualName,
        Email: individualEmail,
        Gender: individualGender,
        CodePromo: individualPromocode ? individualPromocode : null,
        Password: b64EncodeUnicode(individualPassword)
    }

    return dispatch => {
        requestPost('Account/CreateAccount', accountModel)
            .then(resp => {
                if (resp.data.success) {
                    dispatch({ type: CREATE_ACCOUNT_SUCCESS })
                    dispatch({ type: AUTH_SUCCESS })
                    localStorage.setItem('auth', resp.data.success)
                    history.push('/portfolio')
                    !!document.body.querySelector('.grecaptcha-badge') && document.body.querySelector('.grecaptcha-badge').remove()
                    //toast.success(successLocale.AccountCreated);

                    window.gtag('event', 'conversion', { 'send_to': 'AW-656305309/AS9RCJnL1tgBEJ3Z-bgC' });

                    // YandexCounter
                    window.ym(56965879,'reachGoal','registred');

                    // KTracking
                    window.KTracking.reportConversion(0, 'rejected', {sub_id_2: resp.data.id, source: Cookies.get('utm_source') ?? null})
                } else {
                    toast.error(Resource(resp.data.message))
                }
                dispatch({ type: LOADING, id: CREATE_ACCOUNT_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: CREATE_ACCOUNT_FAILURE, error })
                dispatch({ type: LOADING, id: CREATE_ACCOUNT_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: CREATE_ACCOUNT_SUCCESS, loading: true })
    }
}

export const changeField = () => {
    return dispatch => {
        dispatch({ type: CREATE_ACCOUNT_CHANGE_FIELD, payload: false })
    }
}

export const statusPassword = (id, show, value, classPass, status) => {

    let params = { id, show, classPass, status }

    let s_letters = "qwertyuiopasdfghjklzxcvbnm",
        b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM",
        digits = "0123456789",
        // eslint-disable-next-line
        specials = "!@#$%^&*()_-+=\|/.,:;[]{}";

    let password = value,
        rating = 0;

    let is_s = false,
        is_b = false,
        is_d = false,
        is_sp = false;

    if (!!value) {
        params = { ...params, show: true }

        for (let i = 0; i < password.length; i++) {
          
            if (!is_s && s_letters.indexOf(password[i]) !== -1) is_s = true;
            else if (!is_b && b_letters.indexOf(password[i]) !== -1) is_b = true;
            else if (!is_d && digits.indexOf(password[i]) !== -1) is_d = true;
            else if (!is_sp && specials.indexOf(password[i]) !== -1) is_sp = true;
        }

        if (is_s) rating++;
        if (is_b) rating++;
        if (is_d) rating++;
        if (is_sp) rating++;

        if (password.length < 6 && rating === 1) {
            params.classPass = 'junior'
            params.status = 'simple'
        } else if (password.length < 6 && rating === 4) {
            params.classPass = 'junior'
            params.status = 'simple'
        } else if (password.length >= 6 && rating === 1) {
            params.classPass = 'junior'
            params.status = 'simple'
        } else if (password.length < 8 && rating === 2) {
            params.classPass = 'junior'
            params.status = 'simple'
        } else if (password.length < 8 && rating === 3) {
            params.classPass = 'junior'
            params.status = 'simple'
        } else if (password.length >= 8 && rating === 2) {
            params.classPass = 'middle'
            params.status = 'normal'
        } else if (password.length >= 6 && password.length < 8 && rating === 4) {
            params.classPass = 'middle'
            params.status = 'normal'
        } else if (password.length >= 8 && password.length < 10 && rating === 3) {
            params.classPass = 'middle'
            params.status = 'normal'
        } else if (password.length > 6 && password.length < 8 && rating === 3) {
            params.classPass = 'middle'
            params.status = 'normal'
        } else if (password.length >= 10 && rating === 3) {
            params.classPass = 'middle'
            params.status = 'normal'
        } else if (password.length >= 8 && rating === 4) {
            params.classPass = 'senior'
            params.status = 'hard'
        }
    } else {
        params = { ...params, show: false }
    }

    return dispatch => {
        dispatch({ type: CREATE_ACCOUNT_STATUS_PASSWORD, payload: { ...params } })
    }
}

export const createCompanyApplication = ({ legalCompany, legalEmail, legalHead }) => {

    let companyModel = {
        Email: legalEmail,
        ManagerName: legalHead,
        CompanyName: legalCompany
    }
    
    return dispatch => {
        requestPost('Company/CreateCompanyApplication', companyModel)
            .then(resp => {
                if (String(resp.status) === '200') {
                    toast.success(Resource('ApplicationReceivedMessage'))
                    dispatch(reset('legalForm'))
                } else {
                    toast.error('error')
                }
                dispatch({ type: LOADING, id: SEND_LEGAL_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: SEND_LEGAL_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: SEND_LEGAL_SUCCESS, loading: true })
    }
}

export const tokenVerify = token => {
    return dispatch => {
        requestGet(`Account/TokenVerify?token=${token}`)
            .then(resp => {
                if (resp.data) {
                    //каптча пройдена
                } else {
                   //каптча не пройдена
                }
                dispatch({ type: LOADING, id: SEND_TOKEN_SUCCESS, loading: false })
            })
            .catch(error => {
                errorHandler(dispatch, error)
                dispatch({ type: LOADING, id: SEND_TOKEN_SUCCESS, loading: false })
            })
        dispatch({ type: LOADING, id: SEND_TOKEN_SUCCESS, loading: true })
    }
}