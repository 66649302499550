const TABS_SET_ACTIVID = "TABS/SET_ACTIVID";

export const setActiveTabs = (tabsId, activeId) => ({
  type: TABS_SET_ACTIVID,
  activeId,
  tabsId,
});

const initialState = {
  strategyTabs: {
    tabs: [
      "StrategyClassicTitle",
      "StrategyBalancedTitle",
      "StrategySpecialTitle",
    ],
    activeId: 0,
  },
  balancedTabs: {
    activeId: 1,
  },
  portfolioTabs: {
    activeId: 1,
  },
  withdrawalTabs: {
    activeId: 1,
  },
  myInvitationsTabs: {
    activeId: 1,
  },
};

const NTabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABS_SET_ACTIVID:
      return {
        ...state,
        [action.tabsId]: { ...state[action.tabsId], activeId: action.activeId },
      };
    default:
      return state;
  }
};

export default NTabsReducer;
