import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import createAccount from "../reducers/create-account/account.reducer";
import depositing from "./depositing/depositing.reducer";
import auth from "./login/auth.reducer";
import myInvestments from "./my-investments/investments.reducer";
import portfolio from "./portfolio/portfolio.reducer";
import profile from "./profile/profile.reducer";
import strategys from "./profile/strategys.reducer";
import settings from "./settings/settings.reducer";
import forms from "./utils/forms.reducer";
import loading from "./utils/loading.reducer";
import modals from "./utils/modals.reducer";
import person from "./utils/person.reducer";
import tabs from "./utils/tabs.reducer";
import language from "./utils/lang.reducer";
import withdrawal from "./withdrawal/withdrawal.reducer";
import operations from "./operations/operations.reducer";
import partner from "./partner/partner.reducer";
import support from "./support/support.reducer";
import veriff from "./veriff/veriff.reducer";
import { LOGOUT } from "../constants/login/auth.constants";

//New
import tabsComponentReducer from "../../update/redux/tabs-reducer";
import BalancedReducer from "../../update/redux/balanced-reducer";
import VerifyReducer from "../../update/redux/verify-reducer";
import LayoutReducer from "../../update/redux/layout-reducer";
import InvestmentReducer from "../../update/redux/investment-reducer";
import PortfolioReducer from "../../update/redux/portfolio-reducer";
import HandlerReducer from "../../update/redux/handler-reducer";
import PopupReducer from "../../update/redux/popup-reducer";
import filterReducer from "../../update/redux/filter-reducer";
import judicialReducer from "../../update/redux/judicial-reducer";
import WithdrawalReducer from "../../update/redux/withdrawal-reducer";
import paytoolsReducer from "../../update/redux/paytool-reducer";
import banReducer from "../../update/redux/ban-reducer";
import strategysReducer from "../../update/redux/strategys-reducer";
import invitefriendsReducer from "../../update/redux/invitefriends-reducer";
import popupInfoReducer from "../../update/redux/popup-info-reducer";

const appReducer = combineReducers({
  //utils
  auth,
  tabs,
  forms,
  form: formReducer,
  loading,
  modals,
  language,

  myInvestments,
  profile,
  portfolio,
  strategys,
  person,
  settings,
  createAccount,
  depositing,
  operations,
  withdrawal,
  partner,
  support,
  veriff,

  //NEW
  tabsComponentReducer,
  BalancedReducer,
  VerifyReducer,
  LayoutReducer,
  InvestmentReducer,
  PortfolioReducer,
  HandlerReducer,
  PopupReducer,
  filterReducer,
  judicialReducer,
  WithdrawalReducer,
  paytoolsReducer,
  banReducer,
  strategysReducer,
  invitefriendsReducer,
  popupInfoReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    for (let key in state) {
      state[key] = undefined;
    }
  }
  return appReducer(state, action);
};
