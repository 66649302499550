import React, {useState} from 'react';
import Panel from "../Panel/Panel";
import classes from './CardLoan.module.scss'
import {VERIFY_CONFIRM} from "../../../redux/constants/person.constants";
import {connect} from "react-redux";
import {MODAL_IS_OPEN} from "../../../redux/constants/modals.constants";
import {getVeriffSession} from "../../../redux/actions/veriff/veriff.actions";
import {depositCurrentPortfolio} from "../../redux/portfolio-reducer";
import {currentFormatting, dateFormatting} from "../../../redux/utils/formatting";
import IconLink from "../Buttons/IconLink/IconLink";
import iconDocs from '../../../static/image/icons/icon-docs.svg'
import {Resource} from "../../../redux/actions/lang.actions";
import {Link} from "react-router-dom";


const CardInfo = props => {
  return (
    <div className={[classes.info, props.className].join(' ')}>
      <span className={classes.label}>{props.label}</span>
      <span className={classes.value} style={props.textStyle}>{props.value}</span>
    </div>
  )
}

const CardLoan = props => {
  
  // const addedZeroForDate = (date) => date < 10 ? '0' + date : date
  //
  // const getCountDays = (endDate, disabled) => {
  //   const currentDateDay = new Date();
  //   const endDateParse = new Date(Date.parse(endDate)),
  //         day = addedZeroForDate(endDateParse.getDate()),
  //         month = addedZeroForDate(endDateParse.getMonth() + 1),
  //         year = endDateParse.getFullYear();
  //   const deltaDays = Math.floor((endDateParse.getTime() - currentDateDay.getTime())/(1000*60*60*24));
  //   return disabled ? `${month}.${day}.${year}` : `${deltaDays} дней / ${month}.${day}.${year}`
  // }
  
  return (
    <Panel customClass={classes.card}>
      <div className={classes.row}>
        <span className={classes.country}>{props.data?.mfcName ? props.data?.mfcName : ''}</span>
        <span className={classes.term}>{Resource('ClosingDate')}: {dateFormatting(props.data.dateClose)}</span>
      </div>
      <div className={classes.row}>
        <CardInfo
          label={Resource('Investment_amount')}
          value={currentFormatting(props.data.amount)}
        />
        <CardInfo
          label={Resource('Investment_date')}
          value={dateFormatting(props.data.dateCreate)}
          textStyle={{textAlign: 'right'}}
        />
      </div>
      <div className={classes.row}>
        <span className={classes.expected}>{Resource('ExpectedIncome')}: {currentFormatting(props.data.interest)}</span>
        <IconLink className={classes} icon={iconDocs} link={props.data.documentUrl ?? null}>{Resource('Agreement')}</IconLink>
      </div>
    </Panel>
  )
}

const mapStateToProps = state => {
  
  return {
    person: state.person,
    balancedReducer: state.BalancedReducer,
    language: state.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVeriffSession: params => dispatch(getVeriffSession(params)),
    onToggleModal: (id, isOpen) => dispatch({ type: MODAL_IS_OPEN, isOpen, id }),
    depositCurrentPortfolio: (portfolioId, amount) => dispatch(depositCurrentPortfolio(portfolioId, amount))
  } 
}

export default connect(mapStateToProps, mapDispatchToProps)(CardLoan)