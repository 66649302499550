export const DEPOSITING_DATA_SUCCESS = 'DEPOSITING/DATA_SUCCESS'
export const DEPOSITING_DATA_FAILURE = 'DEPOSITING/DATA_FAILURE'

export const DEPOSITING_DATA_SAVE_SUCCESS = 'DEPOSITING/DATA_SAVE_SUCCESS'
export const DEPOSITING_DATA_SAVE_FAILURE = 'DEPOSITING/DATA_SAVE_FAILURE'

export const DEPOSITING_CHANGE_STATUS = 'DEPOSITING/CHANGE_STATUS'

export const DEPOSITING_GET_URL_SUCCESS = 'DEPOSITING/GET_URL_SUCCEESS'
export const DEPOSITING_GET_URL_FAILURE = 'DEPOSITING/GET_URL_FAILURE'

export const DEPOSITING_BANK_STATUS_SUCCESS = 'DEPOSITING/BANK_STATUS_SUCCESS'
export const DEPOSITING_BANK_STATUS_FAILURE = 'DEPOSITING/BANK_STATUS_FAILURE'

export const DEPOSITING_SET_VALUE = 'DEPOSITING/SET_VALUE'