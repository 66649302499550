import React from 'react'
import { reduxForm, getFormValues } from 'redux-form'
import RenderInput from '../../formComponents/RenderInput'
import { Resource } from '../../../../redux/actions/lang.actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { validationCurrency } from '../../../../redux/utils/formatting'
import LoaderButton from '../../LoaderButton'
import { WITHDRAWAL_REQUEST_SUCCESS } from '../../../../redux/constants/withdrawal/withdrawal.constants'

const WithdrawalForm = ({ data, handleSubmit, valuesForm, loading }) => {
    return (
        <form onSubmit={event => {
            event.preventDefault()
            !!valuesForm?.withdrawalValue && validationCurrency(valuesForm.withdrawalValue.replace(',', '.'), data.fields['withdrawalValue'].validate.range)
            handleSubmit()
        }} className="columns_1" noValidate="novalidate">
            <div className="box card">
                <h3 className="box__heading">{Resource(data.title)}</h3>
                <p dangerouslySetInnerHTML={{ __html: Resource(data.description[0]) + ' ' + Resource(data.description[1]) }} className="box__text box__text_accent"></p>
                <div className="form__group">
                    <label htmlFor="withdrawalValue" className="form__label">{Resource(data.fields['withdrawalValue'].labelDown)}</label>
                    <div className="withdrawal__form">
                        <RenderInput data={data.fields['withdrawalValue']} />
                        <LoaderButton
                            type="submit"
                            disabled={true}
                            loading={loading[WITHDRAWAL_REQUEST_SUCCESS]}
                        >{Resource('SendRequest')}</LoaderButton>
                    </div>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        valuesForm: getFormValues('withdrawalForm')(state),
        loading: state.loading
    }
}

export default compose(connect(mapStateToProps, null), reduxForm({ form: 'withdrawalForm' }))(WithdrawalForm)