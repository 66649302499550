import { FILTER } from "./actions/actionsTypes";

const initialState = {
    filterOpen: false,
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER.FILTER_TOGGLE:
            return {
                ...state,
                filterOpen: action.payload
            }
        default:
            return state
    }
}

export default  filterReducer;