import axios from "axios";
import { logout } from "../actions/login/auth.actions";

const api = axios.create({ baseURL: 'https://api-preprod.nibble.finance/', withCredentials: true });
// api.interceptors.request.use(request => {
//     requestInterceptor(request)
// });

// api.interceptors.response.use(
//     response => successHandler(response),
//     error => errorHandler(error)
// )

const requestInterceptor = (request) => {
    request.withCredentials = true;
    return request;
}

const successHandler = (response) => {
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            resolve(response.data)
        }
    });
}

const errorHandler = (error) => {
    return new Promise((resolve, reject) => {
        // TODO: Обрабатывайте тут свои ошибки
        if (error.response.status === 400) {
            return reject({ message: 'ERROR' })
        }
        if (error.response.status === 401 || error.response.status === 403) {
            if ([401, 403].indexOf(error.response.status) !== -1) {
                logout()
            }
            reject();
        }

        return reject();
    })
}

export default api;