import React, { Component } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import "./static/scss/styles.scss";
import "./static/scss/vendor/_rs-table.scss";
import "./static/scss/vendor/_reactToastify.scss";

import PaymentError from "./components/UI/statusPayment/PaymentError";
import PaymentProcess from "./components/UI/statusPayment/PaymentProcess";
import PaymentSuccess from "./components/UI/statusPayment/PaymentSuccess";
import { ToastContainer } from "react-toastify";
import RoutingPage from "./pages/Routing/Routing.page";
import VeriffError from "./components/UI/statusVeriff/VeriffError";
import VeriffProcess from "./components/UI/statusVeriff/VeriffProcess";
import VeriffSuccess from "./components/UI/statusVeriff/VeriffSuccess";
import { utmInit } from "./redux/utils/utm";
import PaymentForm from "./components/UI/statusPayment/PaymentForm";

class App extends Component {
  static displayName = App.name;

  // componentDidUpdate(prevProps){
  //   console.log('path', this.props.location.pathname)
  //   if (this.props.location !== prevProps.pathname) {
  //     window.gtag("config", "UA-156016044-1", {
  //       page_title: this.props.location.pathname,
  //       page_path: this.props.location.pathname,
  //     })
  //     window.gtag("config", "AW-656305309", {
  //       page_title: this.props.location.pathname,
  //       page_path: this.props.location.pathname,
  //     })
  //   }
  // }

  render() {
    return (
      <>
        <Switch>
          <Route path="/paymentError" component={PaymentError} />
          <Route path="/paymentProcess" component={PaymentProcess} />
          <Route path="/paymentSuccess" component={PaymentSuccess} />
          {/* TODO: Отключил функционал новой оплаты */}
          <Route path="/webform/paymentCardForm" component={PaymentForm} />
          <Route path="/webform/result/:id" component={PaymentProcess} />
          <Route path="/veriffError" component={VeriffError} />
          <Route path="/veriffProcess" component={VeriffProcess} />
          <Route path="/veriffSuccess" component={VeriffSuccess} />
          <Route component={RoutingPage} />
        </Switch>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          limit={7}
        />
      </>
    );
  }
}

export default App;
