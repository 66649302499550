import React from 'react'

const ButtonSocial = ({ data: { socialLinks, link } }) => {

    const renderButtonLink = () => {
        return Object.keys(socialLinks).map(key => {
            // eslint-disable-next-line
            return <a key={key + socialLinks[key]} rel="noopener noreferrer" target="_blank" className={`register-socials__icon icon-oauth icon_${key}`} href={socialLinks[key] + link}></a>
        })
    }

    return (
        <nav className="partner-socials">
            {renderButtonLink()}
        </nav>
    )
}

export default ButtonSocial