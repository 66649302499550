import {DepositingAPI} from "../api/REST-API";
import {currentFormatting, dateFormatting} from "../../redux/utils/formatting";
import {Resource} from "../../redux/actions/lang.actions";
import {errorHandler, requestGet} from "../../redux/api/request";
import {isCurrency, isNumber, rangeNumbers} from "../components/tools";
import {history} from "../../redux/history";

const WITHDRAWAL_DATA = 'WITHDRAWAL/GET_DATA';
const WITHDRAWAL_SET_VALUE_FIELD = 'WITHDRAWAL/SET_VALUE_FIELD';
const CODE_STATUS = {
    64: 'STATUS_005',
    63: 'STATUS_004',
    65: 'STATUS_006',
}
const CODE_OPERATIONS = {
    3: 'BankTransferReplenishment',
    4: 'PortfolioCreated',
    5: 'PortfolioIncome',
    6: 'BankTransferWithdrawal',
    7: 'CardTransferWithdrawal'
}

export const setWithdrawalNew = (payload) => dispatch => {
    let data = payload.map(payment => {
        const {id, operations, withdrawal, status, dateOperation, type} = payment
        return {
            id,
            operations: operations !== 6 ? CODE_OPERATIONS[operations] : ( type === 550 ? CODE_OPERATIONS[operations] : CODE_OPERATIONS[7] ),
            withdrawal,
            status: CODE_STATUS[status],
            dateOperation
        }
    })
    dispatch({ type: WITHDRAWAL_DATA, data })
}

export const setDepositValueField = (payload) => dispatch => {
    dispatch({ type: WITHDRAWAL_SET_VALUE_FIELD, payload })
}

export const getWithdrawalNew = () => dispatch => {
    return requestGet('Transaction/GetWithdrawal')
      .then(resp => {
          if(resp.status === 200) {
            dispatch(setWithdrawalNew(resp.data.withdrawalTableModel))
          } else {
              throw new Error();
          }
      })
      .catch(error => {
          errorHandler(dispatch, error)
      })
}

const initialState = {
    withdrawalTable: {
        headers: [
            {
                Header: 'Operation',
                accessor: 'operations',
                Cell: props => props.value ? Resource(props.value) : ''
            },
            {
                Header: 'Withdrawal',
                accessor: 'withdrawal',
                Cell: props => props.value ? '- ' + currentFormatting(props.value) : ''
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: props => props.value ? Resource(props.value) : ''
            },
            {
                Header: 'OperationDate',
                accessor: 'dateOperation',
                Cell: props => props.value ? dateFormatting(props.value) : ''
            },
        ],
        withdrawalPayments: []
    },
    fields: {
        cardWithdrawal:[{
            id: 'cardWithdrawal',
            label: 'Sum',
            maxLength: 9,
            validates: {
                required: 'required',
                validate: rangeNumbers.bind(this, [50, 15000]),
                onChange: isCurrency.bind(this, {prefix: '€'})
            },
        }],
        bankWithdrawal:[{
            id: 'bankWithdrawal',
            label: 'Sum',
            maxLength: 9,
            validates: {
                required: 'required',
                validate: rangeNumbers.bind(this, [50, 15000]),
                onChange: isCurrency.bind(this, {prefix: '€'})
            },
        }],
},
    valueField: null
}

const WithdrawalReducer = (state = initialState, action) => {
    switch (action.type) {
        case WITHDRAWAL_DATA:
            return {
                ...state,
                withdrawalTable: {
                    ...state.withdrawalTable,
                    withdrawalPayments: [...action.data]
                }
            }
        case WITHDRAWAL_SET_VALUE_FIELD:
            return {
                ...state,
                valueField: String(action.payload)
            }
        default:
            return state
    }
}

export default WithdrawalReducer;
