import { POPUP } from "./actions/actionsTypes";

const initialState = {
    gradeOpen: false,
    stopWarOpen: false,
    judicialOpen:  false,
    judicialInfoOpen:  false,
    infoOpen: false,
    portfoliosInfoOpen: false,
    portfoliosInfoOpen2: false,
};

const PopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case POPUP.GRADE_TOGGLE:
            return {
                ...state,
                gradeOpen: action.payload
            }
        case POPUP.STOPWAR_TOGGLE:
            return {
                ...state,
                stopWarOpen: action.payload
            }
        case POPUP.JUDICIAL_TOGGLE:
            return {
                ...state,
                judicialOpen: action.payload
            }
        case POPUP.JUDICIAL_INFO_TOGGLE:
            return {
                ...state,
                judicialInfoOpen: action.payload
            }
        case POPUP.JUDICIAL_DB_TOGGLE:
            return {
                ...state,
                judicialDbOpen: action.payload
            }
        case POPUP.PORTFOLIOS_INFO_TOGGLE:
            return {
                ...state,
                portfoliosInfoOpen: action.payload
            }
        case POPUP.PORTFOLIOS_INFO2_TOGGLE:
            return {
                ...state,
                portfoliosInfoOpen2: action.payload
            }
        case POPUP.INFO_TOGGLE:
                return {
                    ...state,
                    infoOpen: action.payload
                }
        default:
            return state
    }
}

export default  PopupReducer;