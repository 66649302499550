import React from 'react'
import { Field } from 'redux-form'
import { renderValidation } from './Validation'
import { Resource } from '../../../redux/actions/lang.actions'
import { connect } from 'react-redux'

const renderField = ({ input, id, name, type, className, placeholder, disabled, meta: { touched, error } }) => {
    return (
        <>
            <textarea
                {...input}
                id={id}
                name={name}
                type={type}
                className={[className/*, touched && (error ? 'invalid' : null)*/].join(' ')}
                placeholder={placeholder}
                disabled={disabled}
            ></textarea>
        </>
    )
}

const RenderTextarea = ({
    data: {
        id, name, type, placeholder, validate
    },
    disabled
}) => {

    return (
        <Field
            component={renderField}
            id={id}
            name={name}
            type={type}
            className={'field__textarea'}
            placeholder={Resource(placeholder)}
            disabled={disabled}
            validate={
                renderValidation({
                    required: validate?.required,
                    email: validate?.email,
                    only: validate?.only,
                    minLength: validate?.minLength,
                    maxLength: validate?.maxLength
                })
            }
        />
    )
}

const mstp = state => {
    return {
        lang: state.language.langActive
    }
}

export default connect(mstp, null)(RenderTextarea)