import React, { useEffect, useState } from "react";
import {connect, useDispatch} from "react-redux";
import classes from "./statusPayment.module.scss";
import { Resource } from "../../../redux/actions/lang.actions";
import success from "../../../static/image/icons/icon-payment-success.svg";
import DefaultButton from "../../../update/components/Buttons/DefaultButton/DefaultButton";
import LinkButton from "../../../update/components/Buttons/LinkButton/LinkButton";
import SecureIcon from "./../../../static/image/icons/secure.svg";
import { currencyFormatProfit } from "../../../redux/utils/formatting";
import { sendPaymentFormData } from "../../../redux/actions/depositing/depositing.actions";
import Cleave from "cleave.js/react";
import { useForm, Controller } from "react-hook-form";
import {LOADING} from "../../../redux/constants/loading.constants";
import {DEPOSITING_BANK_STATUS_FAILURE} from "../../../redux/constants/depositing/depositing.constants";

export const getParamsOutUrl = (url) => {
  let params = {};
  url &&
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      params[key] = value;
    });
  return params;
};

const MESSAGES = {
  required: "required",
};

const ErrorMessage = (props) => {
  if (!props.data) return <></>;
  return <span className={classes.error}>{props.data?.message}</span>;
};

const PaymentForm = (props) => {
  const [queryState, setQueryState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm(
      //TODO: Сейчас не получится тестировать на локалке оплату. Так как редирект идет интегратором идет на страницу где пользователь не зарегестрирован.
      // сделать эмуляцию для оплаты на локальной машине.
      {
    // defaultValues: {
    //   card_number: '4444444444444455',
    //   card_date: '09 / 33',
    //   card_cvv: '023',
    //   card_cardholder: 'test holder'
    // }
  }
  );

  useEffect(() => {
    setQueryState(getParamsOutUrl(window.location.search));
    // setQueryState(getParamsOutUrl(props.data));
  }, []);

  const onSubmit = (values) => {
    const { card_date, card_number, card_cvv, card_cardholder } = values;

    const date = card_date?.split(" / ");
    
    if (!date) return;
    const [month, year] = date;
    const dataCard = {
      CardNumber: card_number?.split(" ").join("") || "",
      Cvv2: card_cvv || null,
      ExpireMonth: month || null,
      ExpireYear: year || null,
      CardPrintedName: card_cardholder || "",
    };

    setIsLoading(true)
        
    const result = props.sendPaymentFormData({
      ...dataCard,
      Amount: Number(
          queryState.orderPrice
      ) || null,
      // CustomerKey: Number(queryState.customerKey) || null, // Продкидывается черезе .Net
      RequestKey: Number(queryState.requestKey) || null,
    });
    result.finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <main className={classes.main}>
      <section>
        <div className={classes.container}>
          <h1>Order summary</h1>
          <div className={classes.wrapper}>
            <div className={classes.info}>
              <span className={classes.total}>
                <b>Total:</b>{" "}
                {currencyFormatProfit(Number(queryState.orderPrice))}
              </span>
              <p className={classes.info__desc}>Increase balance of investor</p>
              <div className={[classes.tag, classes["hidde--m"]].join(" ")}>
                <img src={SecureIcon} alt="lock" />
                <span>SECURE CONNECTION</span>
              </div>
            </div>
            <p
              className={[classes.info__label, classes["hidden--d"]].join(" ")}
            >
              Pay securely with card
            </p>
            <form
              className={classes.form}
              id="payForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={classes.form__wrapper}>
                <div className={classes.field}>
                  <Controller
                    name="card_number"
                    control={control}
                    rules={{ required: MESSAGES.required }}
                    render={({ field }) => (
                      <Cleave
                        {...field}
                        options={{ creditCard: true }}
                        placeholder="Credit or Debit card number"
                      />
                    )}
                  />
                  <ErrorMessage data={errors?.card_number} />
                  {/*<input name="CARDNO" data-mask="card" type="text" placeholder="Credit or Debit card number" maxLength="19" />*/}
                </div>
                <div className={classes.form__fields}>
                  <div className={classes.field}>
                    <Controller
                      name="card_date"
                      control={control}
                      rules={{ required: MESSAGES.required }}
                      render={({ field }) => (
                        <Cleave
                          {...field}
                          options={{
                            delimiters: ["", " / ", ""],
                            blocks: [2, 0, 2],
                          }}
                          placeholder="MM / YY"
                        />
                      )}
                    />
                    <ErrorMessage data={errors?.card_date} />
                    {/*<input name="DATE" data-mask="dateCard" type="text" placeholder="MM / YY" />*/}
                  </div>
                  <div className={classes.field}>
                    <input
                      {...register("card_cvv", { required: MESSAGES.required })}
                      type="password"
                      placeholder="CVV"
                    />
                    <ErrorMessage data={errors?.card_cvv} />
                  </div>
                </div>
                <div className={classes.field}>
                  <input
                    {...register("card_cardholder", {
                      required: MESSAGES.required,
                    })}
                    type="text"
                    placeholder="Card printed name"
                  />
                  <ErrorMessage data={errors?.card_cardholder} />
                </div>
              </div>
              <div className={classes.form__footer}>
                <div className={[classes.tag, classes["hidden--d"]].join(" ")}>
                  <img src={SecureIcon} alt="lock" />
                  <span>SECURE CONNECTION</span>
                </div>
                <button className={classes.button} type="submit" disabled={isLoading}>
                  Deposit the account
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendPaymentFormData: (data) => dispatch(sendPaymentFormData(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    state,
    loading: state.loading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
