import React, { Component, createRef } from "react";
import { ScrollTo, ScrollArea } from "react-scroll-to";
import Stats from "./Stats";
import { PORTFOLIO_DETAILS_SUCCESS } from "../../redux/constants/portfolio/portfolio.constants";

export default class Slider extends Component {

    constructor(props) {
        super(props)
        this.refStats = createRef()
        this.count = 0
    }

    state = {
        arrowLeft: true,
        arrowRight: false
    }

    disabledArrow() {
        if (this.count === 0) {
            this.setState({
                arrowLeft: true,
                arrowRight: false,
            })
        }
        if (this.count === 2) {
            this.setState({
                arrowLeft: false,
                arrowRight: true,
            })
        }
        if (this.count === 1) {
            this.setState({
                arrowLeft: false,
                arrowRight: false,
            })
        }
    }

    //renderChildren() {
    //    return React.Children.map(this.props.children, (child, index) => {
    //        return React.cloneElement(child, {
    //            id: index
    //        })
    //    })
    //}

    moveRight(scroll) {
        if (this.count < 2) {
            this.count++
        }
        scroll({ x: this.count * this.refStats.current.offsetParent.clientWidth, smooth: true })
        this.disabledArrow()
    }

    moveLeft(scroll) {
        if (this.count > 0) {
            this.count--
        }
        scroll({ x: this.count * this.refStats.current.offsetParent.clientWidth, smooth: true })
        this.disabledArrow()
    }

    render() {
        return (
            <ScrollTo>
                {
                    ({ scroll }) => (
                        <ScrollArea className={['widget widget_panel', this.props.className].join(' ')}>
                            <Stats ref={this.refStats} id='portfolioAmount' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <Stats id='interest' prefix='+' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <Stats id='expectedInterest' prefix='+' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <Stats id='loanCount' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <Stats id='loanCountActive' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <Stats id='loanCountExpired' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <Stats id='loanCountClosed' data={this.props.data} load={PORTFOLIO_DETAILS_SUCCESS} reload />
                            <span onClick={this.moveLeft.bind(this, scroll)} className={['js-scroll-widget widget-scroller widget-scroller_prev ', this.state.arrowLeft ? 'disabled' : null].join(' ')}></span>
                            <span onClick={this.moveRight.bind(this, scroll)} className={['js-scroll-widget widget-scroller widget-scroller_next ', this.state.arrowRight ? 'disabled' : null].join(' ')}></span>
                        </ScrollArea>
                    )
                }
            </ScrollTo>
        );
    }
}