import React, {useEffect, useState} from 'react'
import { PERSON_DATA_SUCCESS, VERIFY_REJECT } from '../../../redux/constants/person.constants'
import Loader from '../../UI/Loader'
import { connect } from 'react-redux'
import VeriffForm, {changedFields} from '../../UI/formControls/veriff/veriff.form'
import { Resource } from '../../../redux/actions/lang.actions'
import {toggleVeriffPanel, saveRequiredVeriff, getVeriffSession} from '../../../redux/actions/veriff/veriff.actions'
import Tutorial from '../../UI/Tutorial'
import DepositVeriffForm from "../../UI/formControls/veriff/depositVeriff.form";
import Modal from "../../UI/Modal/Modal";
import ModalButton from "../../UI/Modal/ModalButton";
import {isValid} from "redux-form";
import {getPersonSettings} from "../../../redux/actions/settings/settings.actions";
import LoaderButton from "../../UI/LoaderButton";

const ProgressBar = (props) => {

    const [disableVerifBtn, setVerifBtnStatus] = useState(false)

    useEffect(() => {
        props.getPersonSettings()
    }, [])

    const sumbitSaveVeriff = (values) => {
        setVerifBtnStatus(true);
        const { prefix, verifyCountry } = props.selects
        const { verifyAddress, verifyCity, verifyZip, phone } = values

        props.saveRequiredVeriff({
            verifyAddress,
            verifyCity,
            verifyCountry: verifyCountry ? verifyCountry.id : props.fields.verifyCountry.value,
            verifyZip,
            prefix: prefix !== undefined ? prefix.id : props.fields.prefix.value,
            phone: props.fields.phone.value !== phone
                ? !!phone ? (prefix !== undefined ? prefix.id : String(props.fields.prefix.value)) + phone : null
                : !!phone ? (prefix !== undefined ? prefix.id : String(props.fields.prefix.value)) + String(props.fields.phone.value).substring(String(props.fields.prefix.value).length) : null,
            personId: props.person.id,
            firstName: props.person.firstName,
            lastName: props.person.lastName,
            setVerifBtnStatus
        })
    }

    return (
        <section id="veriffPanel">
            <div className="box panel veriff">

                <div className="veriff-status">
                    <p className="box__text box__text_accent">
                                <span
                                  dangerouslySetInnerHTML={
                                      { __html: [Resource(props.progressText), props.person.veriffStatusId === VERIFY_REJECT ? Resource(props.expiredText) : null].join(' ') }
                                  }
                                />
                    </p>

                    {
                        !props.isFullFields
                            ? <ModalButton
                                // type="submit"
                                isOpen={!props.modalDepositingVeriff.isOpen}
                                id={props.modalDepositingVeriff.id}
                                button
                                addClass={'button_primary'}
                              >{Resource('ProgressIdentifyStep')}</ModalButton>
                            : <LoaderButton
                                type="button"
                                disabled={!disableVerifBtn}
                                addClass={'button_primary'}
                                onClick={() => {
                                    setVerifBtnStatus(true)
                                    props.getVeriffSession({personId: props.person.id, setVerifBtnStatus, firstName: props.person.firstName, lastName: props.person.lastName})
                                }}
                              >
                                  {Resource('ProgressIdentifyStep')}
                              </LoaderButton>
                    }
                </div>

                <Modal
                  isOpen={props.modalDepositingVeriff.isOpen}
                  id={props.modalDepositingVeriff.id}
                  isForm={false}
                  addClass={'veriff__modal'}
                  isOutside={true}
                  onStart={() => {
                      console.log('Open verify modal')
                      window.ym(56965879,'reachGoal','Veriff_prelend_open')
                  }}
                >
                    <VeriffForm
                      onSubmit={sumbitSaveVeriff.bind(this)}
                      disableVerifBtn={disableVerifBtn}
                      toggleVeriffPanel={props.toggleVeriffPanel}
                      fields={props.fields}
                    />
                </Modal>
                
            </div>
        </section>
    )
}

const mapStateToProps = state => {

    const fields = state.veriff.fields
    const fieldsValues = [
        fields.verifyCountry.value,
        fields.verifyCity.value,
        fields.verifyAddress.value,
        fields.verifyZip.value,
        fields.prefix.value,
        fields.phone.value
    ]

    return {
        person: state.person,
        fields: state.veriff.fields,
        showVeriff: state.veriff.showVeriff,
        progressText: state.veriff.progressText,
        expiredText: state.veriff.expiredText,
        selects: state.forms.selects,
        lang: state.language.langActive,

        isFullFields: fieldsValues.every((value) => !!value),
        // isValid: isValid('veriffForm')(state),
        modalVeriff: state.modals.modalVeriff,
        modalDepositingVeriff: state.modals.modalDepositingVeriff,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        toggleVeriffPanel: () => dispatch(toggleVeriffPanel()),
        saveRequiredVeriff: veriffModel => dispatch(saveRequiredVeriff(veriffModel)),
        getPersonSettings: () => dispatch(getPersonSettings()),
        getVeriffSession: (personData) => dispatch(getVeriffSession(personData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar)